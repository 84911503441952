import { LRS_OWNER_CONTACT_FLAG } from "@app/core/contacts/_id/components/reference-sidebar/detail/constant";
import { getContactDetailById } from "@app/products/property/contacts-central-names/[id]/components/reference-sidebar/detail/api";
import { DTO_Entity_Details } from "@app/products/property/contacts-central-names/[id]/components/reference-sidebar/detail/model";
import { ContactCentralNameDetailTabEventType } from "@app/products/property/contacts-central-names/[id]/components/reference-sidebar/detail/util";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import {
  CURRENCY_FORMAT,
  DATETIME_FORMAT,
} from "@common/constants/common-format";
import { useAddUniqueEventEmitter } from "@common/hooks/event-emitter/useAddUniqueEventEmitter";
import { OrganisationMode } from "@common/stores/products/model";
import { useCommonProductStore } from "@common/stores/products/store";
import { formatDisplayValue } from "@common/utils/formatting";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import Loading from "@components/loading/Loading";
import { NoData } from "@components/no-data/NoData";
import {
  ProductReferenceBlock,
  ProductReferenceBody,
} from "@components/reference-sidebar/ProductReferenceBody";
import { ProductReferenceHeading } from "@components/reference-sidebar/ProductReferenceHeading";
import { ProductReferenceRow } from "@components/reference-sidebar/ProductReferenceRow";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export const ContactCentralNameDetailTab = observer(() => {
  const { lastSelectedId } = useCCProductListViewStore();
  const { currentOrganisationMode } = useCommonProductStore();
  const [isLoading, setIsLoading] = useState(false);
  const [responseLoadError, setResponseLoadError] = useState<
    APIResponseError | undefined
  >();
  const [entityInfo, setEntityInfo] = useState<
    DTO_Entity_Details | undefined
  >();

  const params: { id: string } = useParams();
  const contactId = params.id ?? lastSelectedId;
  const isLLS = currentOrganisationMode(OrganisationMode.LLS);

  const loadData = async (contactId: string | undefined) => {
    if (contactId) {
      let errorResponse = undefined;
      setIsLoading(true);
      const response = await getContactDetailById(parseInt(contactId));
      setIsLoading(false);
      if (isSuccessResponse(response)) {
        if (isSuccessResponse(response) && response?.data) {
          setEntityInfo(response.data);
        } else {
          errorResponse = {
            status: response.status,
            error: response.error ?? "Load detail failed",
          };
        }
      } else {
        errorResponse = {
          status: response.status,
          error: response.error ?? "Load detail failed",
        };
      }
      setResponseLoadError(errorResponse);
    }
  };

  useEffect(() => {
    loadData(contactId);
  }, [contactId]);

  useAddUniqueEventEmitter([
    {
      eventType: ContactCentralNameDetailTabEventType.RefreshData,
      listener: () => {
        loadData(contactId);
      },
    },
  ]);

  if (isLoading) return <Loading isLoading={isLoading} />;
  if (responseLoadError) {
    return (
      <CCLoadFailed
        responseError={responseLoadError}
        onReload={() => {
          loadData(contactId);
        }}
      />
    );
  }
  if (!entityInfo) return <NoData />;

  return (
    <ProductReferenceBody>
      <PanelBar expandMode={"single"}>
        <PanelBarItem
          expanded={true}
          title={
            <ProductReferenceHeading
              title={"Contact:"}
              value={entityInfo?.Name}
            />
          }
        >
          <ProductReferenceBlock>
            {!isLLS && (
              <>
                <ProductReferenceRow
                  title={"Overdue:"}
                  value={formatDisplayValue(
                    entityInfo?.OverDue,
                    CURRENCY_FORMAT.CURRENCY1
                  )}
                  format="currency"
                />
                <ProductReferenceRow
                  title={"Due:"}
                  value={formatDisplayValue(
                    entityInfo?.Due,
                    CURRENCY_FORMAT.CURRENCY1
                  )}
                  format="currency"
                />
                <ProductReferenceRow
                  title={"Due + Overdue:"}
                  value={formatDisplayValue(
                    entityInfo?.Overdue_Due,
                    CURRENCY_FORMAT.CURRENCY1
                  )}
                  format="currency"
                />
                <ProductReferenceRow
                  title={"Pending:"}
                  value={formatDisplayValue(
                    entityInfo?.Pending,
                    CURRENCY_FORMAT.CURRENCY1
                  )}
                  format="currency"
                />
                <ProductReferenceRow
                  title={"Assessment Balance Owing:"}
                  value={formatDisplayValue(
                    entityInfo?.Assessment_Balance_Owing,
                    CURRENCY_FORMAT.CURRENCY1
                  )}
                  format="currency"
                />
              </>
            )}

            <ProductReferenceRow
              title={"Contact ID:"}
              value={entityInfo?.Contact_Id + ""}
            />
            {entityInfo?.Preferred_Contact_Method ? (
              <ProductReferenceRow
                title={"Preferred contact method:"}
                value={entityInfo.Preferred_Contact_Method}
              />
            ) : null}
            {entityInfo?.PhoneNumber_Business ? (
              <ProductReferenceRow
                title={"Business:"}
                value={entityInfo.PhoneNumber_Business}
              />
            ) : null}
            {entityInfo?.PhoneNumber_Mobile ? (
              <ProductReferenceRow
                title={"Mobile:"}
                value={entityInfo.PhoneNumber_Mobile}
              />
            ) : null}
            {entityInfo?.PhoneNumber_Private ? (
              <ProductReferenceRow
                title={"Private:"}
                value={entityInfo.PhoneNumber_Private}
              />
            ) : null}
            {entityInfo?.Email ? (
              <ProductReferenceRow title={"Email:"} value={entityInfo.Email} />
            ) : null}
            <ProductReferenceRow
              title={"Change on:"}
              value={formatDisplayValue(
                entityInfo?.Changed_On,
                DATETIME_FORMAT.DATETIME
              )}
            />
            <ProductReferenceRow
              title={"Change by:"}
              value={entityInfo?.Changed_By}
            />
            <ProductReferenceRow
              title={"Address:"}
              value={entityInfo?.Address}
            />
            {entityInfo?.Is_Entity_locked ? (
              <ProductReferenceBlock flex>
                <ProductReferenceRow
                  badgeSmall
                  value={LRS_OWNER_CONTACT_FLAG}
                  success
                />
              </ProductReferenceBlock>
            ) : null}
            {entityInfo?.Alert ? (
              <ProductReferenceRow badge value={entityInfo.Alert} danger />
            ) : null}
          </ProductReferenceBlock>
        </PanelBarItem>
      </PanelBar>
    </ProductReferenceBody>
  );
});
