import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { IKeyValuePacket } from "@common/models/keyValuePacket";

//#region Mock models
export class LocalLaws {
  // LOAD BY DEFAULT
  id?: string | number = "";
  ID?: string | number = "";
  PermitNumber?: string | number = "";
  PermitHolder?: string = "";
  Address?: string = "";
  Locality?: string = "";
  PermitType?: string = "";
  Status?: string = "";
  Officer?: string;
  EffectiveFrom?: Date;
  EffectiveTo?: Date;
  Registration_ID: number = 1;
}

export interface LocalLawsLOVs {
  LocalLawsTypes: IKeyValuePacket[];
  Animals_DogBreeds: IKeyValuePacket[];
  Animals_CatBreeds: IKeyValuePacket[];
  Animals_OtherBreeds: IKeyValuePacket[];
  Animals_Colour: IKeyValuePacket[];
  Animals_Size: IKeyValuePacket[];
  Animals_Condition: IKeyValuePacket[];
  Animals_Disposition: IKeyValuePacket[];
  Animals_TagColour: IKeyValuePacket[];
  Animals_ProofOfObedience: IKeyValuePacket[];
  Animals_ProofOfSterilisation: IKeyValuePacket[];
  Animals_MicrochipProofExemption: IKeyValuePacket[];

  Core_Judgement: IKeyValuePacket[];
}

export interface ILocalLaws {
  ID?: string | number;
  PermitNo?: string;
  PermitType?: string;
  AreaOfCouncil?: string;
  PermitHolder?: string;
  Address?: string;
  PostalAddress?: string;
  WorkPhone?: string;
  HomePhone?: string;
  PreferredContact?: string;
  Mobile?: string;
  Email?: string;
  SiteAddress?: string;
  Owner?: string;
  Details?: string;
  Description?: string;
  DateEffectiveFrom?: Date | string;
  DateEffectiveTo?: Date | string;
  InspectionType_ID?: number;
  InspectionType?: string;
  SecondaryOfficer?: string;
  SecondaryOfficer_ID?: number;
  Officer_ID?: number;
  Officer?: string;
  Chairs?: number;
  Status?: string;
  Tables?: number;
  InsuranceCompany?: string;
  PolicyNumber?: number;
  PolicyIssueDate?: Date | string;
  PolicyExpiresDate?: Date | string;
  DebtorNumber?: number;
  FileNumber?: number;
  OSFees?: number;
  Status_ENUM: string;
}

export interface IListLocalLaws {
  ID?: number;
  PermitNumber?: number;
  Applicant?: number;
  SiteAddress?: string;
  Locality?: string;
  Type?: string;
  StatusDueStatus?: string;
  InsuranceDueStatus?: string;
  Officer?: string;
  EffectiveFrom?: string;
  EffectiveTo?: string;
  UsePermitEventName?: string;
  Area?: string;
  DateInspected?: string;
  ReferenceNumber?: number;
  ContactName?: string;
  InspectionType?: string;
  Compliance?: string;
  InsurancePolicyNumber?: number;
  InsuranceCompany?: string;
  AmountOS?: number;
  DateRecorded?: string;
  RecordedBy?: string;
  OrgStructure?: string;
  Reason?: string;
  ActionOfficer?: string;
  Coordinator?: string;
  DateWork?: string;
  FileNumber?: string;
  EnquiryRefNo?: number;
  Event_ReferenceNoAdditional?: number;
  OnBehalfOf?: number;
  Flag_ITSupport?: boolean;
  Flag_Safety?: boolean;
  Flag_Risk?: boolean;
  Flag_VIP?: boolean;
  DateDue?: string;
  DateRevoked?: string;
  DateServed?: string;
  CRMSEventReferenceNumber?: number;
  CreatedDate?: string;
  FeeType?: string;
  AmountIssued?: number;
  AmountOutstanding?: number;
  CostCode?: string;
  InvoiceNumber?: number;
  DebtorNumber?: number;
  Name?: string;
  Description?: string;
  SortIndex?: number;
  Title?: string;
}
export class ILocalLawsRecordsState {
  actions: number = 0;
  comments: number = 0;
  contacts: number = 0;
  documents: number = 0;
  fees: number = 0;
  inspections: number = 0;
  samples: number = 0;
}

export interface ILocalLawsForm {
  Application_ID: string;
  Status_ENUM: string;
  Sys_StatClockStatus_ENUM: string;
  Sys_CreatedDate: string;
  Date_Created: string;
  LodgedDate: string;
  StatDays: {
    TargetDate: string;
  };
  Applicant: {
    Contact: {
      DisplayName: string;
      _Mobile: string;
      PreferredAddress: {
        StreetName: string;
      };
    };
  };
  PreferredContact: {
    Contact: {
      DisplayName: string;
      _Mobile: string;
      PreferredAddress: {
        StreetName: string;
      };
    };
  };
  SiteAddress: {
    PropertyAssessment: {
      Owners_Address: string;
      Zones: string;
      Overlay: string;
      Owners_Name: string;
      Legal_Description: string;
      Ward: string;
      RIN: string;
      Assessment_LandSize: string;
      LotNo: string;
    };
    ReasonForPermit: string;
    CostOfWorks: number;
    Planner: string;
    FileNo: string;
    DebtorNumber: string;
    OsFees: number;
    Decision_ENUM: string;
    DecisionDate: string;
    DecisionType_ENUM: string;
  };
}
//#endregion

export interface PermitRenewals {
  ID: number;
  PermitNumber: string;
  Applicant: string;
  SiteAddress: string;
  Locality: string;
  Owner: string;
  Type: string;
  StatusDueStatus: string;
  Status: string;
  DueStatus: string;
  LodgedDate?: Date;
  EffectiveFrom?: Date;
  EffectiveTo?: Date;
  InsuranceCompany: string;
  InsurancePolicyNumber: string;
  InsuranceDateExpires?: Date;
  Officer: string;
  FileNo: string;
  RefNo: string;
  AmountOS: number;
}

export enum PermitRenewalsTypes {
  PermitsDueForRenewal,
  PermitsRenewalsRequiresFee,
  PermitsRenewalsRequiresNotice,
  PermitsRenewalsRequiresRenewal,
}

export enum LLPermitsMenu {
  Unspecified = 0,
  Permits = 1,
  PermitsRegister = 2,
  PermitsByType = 3,
  PermitsByStatus = 4,
  PermitsByLocation = 5,
  PermitsByOfficer = 6,
  PermitsByArea = 7,
  PermitsNew = 8,
  PermitsLodged = 9,
  PermitsRefused = 10,
  PermitsExpired = 11,
  PermitsDeactivated = 12,
  PermitsWithdrawn = 13,
  PermitsInsurance = 14,
  LLSPermits = 20,
  LLSPermitsRegister = 21,
  LLSPermitsRegisterNewApplications = 22,
  LLSPermitsRegisterActive = 23,
  LLSPermitsRegisterRejectedOrOnhold = 24,
  LLSPermitsRegisterCancelled = 25,
  LLSPermitsRegisterRevoked = 26,
  LLSPermitsRegisterExpired = 27,
  LLSPermitsRegisterWithdrawn = 28,
  LLSPermitsRegisterByType = 29,
  LLSPermitsRegisterByStatus = 30,
  LLSPermitsRegisterByRegion = 31,
  LLSPermitsRegisterByOfficer = 32,
  LLSPermitsRegisterByAOApprover = 33,
  LLSPermitsRegisterByBIOApprover = 34,
  LLSPermitsRegisterInvoicing = 35,
  LLSPermitsRegisterDueForInvoice = 36,
  LLSPermitsRegisterAnnualInvoiced = 37,
  Tenders = 40,
  LLSTendersRegister = 41,
  LLSTendersRegisterByRegion = 42,
  LLSTendersRegisterByOfficer = 43,
  LLSTendersRegisterByType = 44,
  LLSTendersRegisterByStatus = 45,
  Renewals = 50,
  PermitsByRenewal = 51,
  RenewalsReqFee = 52,
  RenewalsReqNotice = 53,
  RenewalsReqRenewal = 54,
  Inspections = 60,
  InspectionsByDate = 61,
  InspectionsByType = 62,
  InspectionsByOfficer = 63,
  InspectionsByMonth = 64,
  Complaints = 70,
  ComplaintsByDate = 71,
  ComplaintsByStatus = 72,
  ComplaintsByCategory = 73,
  ComplaintsByDateUnrelated = 74,
  EventsByRefNo = 75,
  AllEventsByCategory = 76,
  AllEventsByStatus = 77,
  Notices = 80,
  NoticesByDate = 81,
  NoticesByDueStatus = 82,
  CRMSPermits = 90,
  CRMSDraftPermits = 91,
  Finance = 100,
  DebtorSummaryAll = 101,
  DebtorSummaryOutstanding = 102,
  FeesOutstanding = 103,
  FeesPaid = 104,
  FeesNotSentToFinance = 105,
  FeesSentToFinance = 106,
  InvoicesNotSentToFinance = 107,
  InvoicesSentToFinance = 108,
  FeesAll = 109,
  FeeSetup = 110,
  FeeSetupByPermitType = 111,
  FeeSetupByRegion = 112,
  FeeSetupByFeeType = 113,
  Administration = 120,
  PermitTypes = 121,
  LLSPermitTypes = 122,
  Conditions = 123,
  Settings = 124,
}

export const localLawsSettingEnum = [
  ECorporateSettingsField.LLP_Views_Conditions_DisplayConditionsInGroup,
  ECorporateSettingsField.LLP_UsePermitEventName,
];
