import { getViewConfigurations } from "@app/products/property/api";
import { DTO_Title } from "@app/products/property/assessments/components/form-steps/reactivate-assessment/components/form-elements/associations-parcel/model";
import {
  colFoliosAssessment,
  colPICAmalgamate,
} from "@app/products/property/certificates/[id]/components/form-steps/replace-certificate/components/form-elements/certificate-details/config";
import {
  eCertificateType,
  eCertificateTypeComponent,
  eField,
} from "@app/products/property/certificates/[id]/components/form-steps/replace-certificate/components/form-elements/certificate-details/model";
import { useCertificateDetailStepStore } from "@app/products/property/certificates/[id]/components/form-steps/replace-certificate/components/form-elements/certificate-details/store";
import { certificateDetailValidator } from "@app/products/property/certificates/[id]/components/form-steps/replace-certificate/components/form-elements/certificate-details/util";
import { getValueName } from "@app/products/property/charge-and-notice-runs/charge-runs/components/form-steps/create-charge-run/components/form-elements/details/util";
import { getSearchAssessmentLookup } from "@app/products/property/components/dialogs/add-assessment-lookup/api";
import {
  colAssessmentLookup,
  eOptionSearchAssessmentLookup,
} from "@app/products/property/components/dialogs/add-assessment-lookup/config";
import {
  DTO_Assessment,
  fnt_Assessment_LookupResult,
} from "@app/products/property/components/dialogs/add-assessment-lookup/model";
import { AddAssessmentLookupDialog } from "@app/products/property/components/dialogs/add-assessment-lookup/_index";
import { getSearchParcelLookup } from "@app/products/property/components/dialogs/add-parcel-lookup/api";
import {
  colParcelLookup,
  eOptionSearchParcelLookup,
} from "@app/products/property/components/dialogs/add-parcel-lookup/config";
import { DTO_Parcel } from "@app/products/property/components/dialogs/add-parcel-lookup/model";
import { AddParcelLookupDialog } from "@app/products/property/components/dialogs/add-parcel-lookup/_index";
import { DTO_PIC } from "@app/products/property/components/dialogs/add-pic-lookup/model";
import { AddPICLookupDialog } from "@app/products/property/components/dialogs/add-pic-lookup/_index";
import { getSearchTitleLookup } from "@app/products/property/components/dialogs/add-title-lookup/api";
import { eOptionSearchTitleLookup } from "@app/products/property/components/dialogs/add-title-lookup/config";
import { fnt_Title_LookupResult } from "@app/products/property/components/dialogs/add-title-lookup/model";
import { AddTitleLookupDialog } from "@app/products/property/components/dialogs/add-title-lookup/_index";
import { ECustomColNameProperty } from "@app/products/property/config";
import {
  EListSubmitButton,
  nameOfLov,
  ViewConfiguration,
} from "@app/products/property/model";
import { processCombineData } from "@app/products/property/pic/list/components/action-bar/form-steps/new-pic/components/form-element/associations/util";
import { VO_PIC } from "@app/products/property/pic/list/model";
import { processDynamicColumns } from "@app/products/property/util";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { APIResponseStatus } from "@common/constants/response-status";
import { Label } from "@common/stores/products/config";
import { getDropdownValue, nameOfFactory } from "@common/utils/common";
import { validateRequired } from "@common/utils/field-validators";
import { IFormStepElement } from "@components/cc-form-step/model";
import { IColumnFields } from "@components/cc-grid/model";
import { CCGrid } from "@components/cc-grid/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import Loading from "@components/loading/Loading";
import { Button } from "@progress/kendo-react-buttons";
import { Field, FieldArray } from "@progress/kendo-react-form";
import { difference, isEqual, remove, union } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useCallback, useEffect, useState } from "react";
import { useEffectOnce } from "react-use";
import "./_index.scss";

const nameOfPIC = nameOfFactory<VO_PIC>();
const nameOfFolio = nameOfFactory<DTO_Title>();
const nameOfAssessment = nameOfFactory<DTO_Assessment>();
const nameOfParcel = nameOfFactory<DTO_Parcel>();
export const CertificateDetailFormStep = (props: IFormStepElement) => {
  const {
    options: { isReadOnly = false, isLLS = false },
  } = props;

  const newCertificateDetailValidator = useCallback(
    (value: any) => {
      if (isReadOnly) return undefined;
      return certificateDetailValidator(value, isLLS);
    },
    [isReadOnly, isLLS]
  );

  return (
    <FieldArray
      name={props.nameOf()}
      {...props}
      component={FormStepElement}
      validator={newCertificateDetailValidator}
    />
  );
};

const FormStepElement = observer(
  ({
    formRenderProps,
    localNotificationRef,
    nameOf,
    options = {
      isReadOnly: false,
      isLLS: false,
    },
    setIsLoadingStep,
    isLoadingStep,
  }: IFormStepElement) => {
    const { valueGetter, onChange, onSubmit } = formRenderProps;
    const getFieldValue = (name: string) => valueGetter(nameOf(name));
    //store
    const { certificateDetailStepLOVs } = useCertificateDetailStepStore();
    //get value
    const parcels = getFieldValue("Parcels") ?? [];
    const assessments = getFieldValue("Assessments") ?? [];
    const parcelSelected = getFieldValue("ParcelSelected") ?? [];
    const assessmentSelected = getFieldValue("AssessmentSelected") ?? [];
    const loadingMode = getFieldValue("_option.Loading");
    //state
    const [isShowDialog, setIsShowDialog] = useState<eCertificateType>();
    const [columnsAssessment, setColumnsAssessment] =
      useState<IColumnFields[]>(colAssessmentLookup);
    const [responseLoadError, setResponseLoadError] = useState<
      APIResponseError | undefined
    >(undefined);
    const [columnsParcel, setProcessedParcel] =
      useState<IColumnFields[]>(colParcelLookup);
    const [columnsPIC, setProcessedPIC] =
      useState<IColumnFields[]>(colPICAmalgamate);

    //Get labels
    const [
      assessmentLabel,
      assessmentLowercaseLabel,
      titleLowercaseLabel,
      titleLabel,
      titlesLabel,
    ] = Label.CommunityProperty.getLabel([
      ECustomColNameProperty.Assessment,
      ECustomColNameProperty.AssessmentLowercase,
      ECustomColNameProperty.TitleLowercase,
      ECustomColNameProperty.Title,
      ECustomColNameProperty.Titles,
    ]);

    //Field value
    const certificateType = getFieldValue("CertificateType");
    const certificateTypeComponent = getValueName(
      certificateType,
      certificateDetailStepLOVs ?? [],
      "Component"
    );
    const selectedPIC = getFieldValue("_option.PICSelected") ?? [];
    const pics = getFieldValue("PICs") ?? [];
    const folio = getFieldValue("AssociateTitles") ?? [];
    const folioSelected = getFieldValue("_option.FolioSelected") ?? [];
    const picIds = options?.listIDs ?? [];

    useEffect(() => {
      onChange(nameOf("_option.CertificateTypeComponent"), {
        value: certificateTypeComponent,
      });
      onChange(nameOf("PIC_Ids"), {
        value: picIds,
      });
      // eslint-disable-next-line
    }, [certificateTypeComponent, picIds]);

    /**
     * handle add item
     * @param data
     * @param field
     */
    const handleAddItem = async (
      lookupSelectedRowsData: any[],
      field: string
    ) => {
      //reset notification
      localNotificationRef?.current?.resetNotifications();
      //get previous Parcel and Holding
      const previousParcel =
        parcels?.length && !options?.isLLS ? [...parcels] : [];
      const previousAssessment = assessments?.length ? [...assessments] : [];
      switch (field) {
        case eField.Assessments:
          handleAddAssessment(
            previousAssessment,
            lookupSelectedRowsData,
            previousParcel
          );
          break;
        case eField.Parcels:
          handleAddParcel(
            previousParcel,
            lookupSelectedRowsData,
            previousAssessment
          );
          break;
      }
    };

    const handleAddAssessment = async (
      previousAssessment: DTO_Assessment[],
      lookupAssessmentData: fnt_Assessment_LookupResult[],
      previousParcel: DTO_Parcel[]
    ) => {
      options?.setIsLoadingInStep(true);
      const assessmentLookupValue = lookupAssessmentData
        .map((item) => item?.Assessment_Id)
        .toString();
      onChange(nameOf("_option.Loading"), {
        value: eCertificateType.Assessment,
      });
      const response = await getSearchAssessmentLookup({
        LookupKey: eOptionSearchAssessmentLookup.AssessmentId,
        LookupValue: assessmentLookupValue,
        Statuses: [0], //Fixed now (0: Active)
      });
      onChange(nameOf("_option.Loading"), {
        value: undefined,
      });
      if (isSuccessResponse(response) && response?.data) {
        const resAssessment = response?.data?.Assessments ?? [];
        if (resAssessment?.length) {
          const newData = processCombineData(
            previousAssessment,
            resAssessment,
            nameOfAssessment("Assessment_Id")
          );
          onChange(nameOf("Assessments"), {
            value: newData,
          });
        }
      } else {
        localNotificationRef?.current?.pushNotification({
          title: `Load ${assessmentLabel} failed`,
          type: "error",
          autoClose: false,
        });
      }
      //Calling api for parcel list
      if (lookupAssessmentData && !options?.isLLS) {
        onChange(nameOf("_option.Loading"), {
          value: eCertificateType.Parcel,
        });
        const response = await getSearchParcelLookup({
          LookupKey: eOptionSearchParcelLookup.AssessmentId, // 6 - Assessment Id
          LookupValue: assessmentLookupValue,
          Statuses: [0], //Fixed now (0: Active)
        });
        if (isSuccessResponse(response) && response?.data) {
          const resParcels = response?.data?.Parcels ?? [];
          if (resParcels?.length) {
            onChange(nameOf("Parcels"), {
              value: processCombineData(
                previousParcel,
                resParcels,
                nameOfParcel("Parcel_Id")
              ),
            });
          }
        } else {
          localNotificationRef?.current?.pushNotification({
            title: "Load Parcel failed",
            type: "error",
            autoClose: false,
          });
        }
        onChange(nameOf("_option.Loading"), {
          value: undefined,
        });
      }
      options?.setIsLoadingInStep(false);
    };

    const handleAddParcel = async (
      previousParcel: DTO_Parcel[],
      lookupParcelData: DTO_Parcel[],
      previousAssessment: DTO_Assessment[]
    ) => {
      options?.setIsLoadingInStep(true);
      onChange(nameOf("Parcels"), {
        value: processCombineData(
          previousParcel,
          lookupParcelData,
          nameOf("Parcel_Id")
        ),
      });
      //Calling api for assessment list
      if (lookupParcelData) {
        onChange(nameOf("_option.Loading"), {
          value: eCertificateType.Assessment,
        });
        const parcelLookupValue = lookupParcelData
          .map((item) => item?.Parcel_Id)
          .toString();
        const response = await getSearchAssessmentLookup({
          LookupKey: eOptionSearchAssessmentLookup.ParcelId, // 7 - Parcel Id
          LookupValue: parcelLookupValue,
          Statuses: [0], //Fixed now (0: Active)
        });
        if (isSuccessResponse(response) && response?.data) {
          const resAssessment = response?.data?.Assessments ?? [];
          if (resAssessment?.length) {
            onChange(nameOf("Assessments"), {
              value: processCombineData(
                previousAssessment,
                resAssessment,
                nameOfAssessment("Assessment_Id")
              ),
            });
          }
        } else {
          localNotificationRef?.current?.pushNotification({
            title: "Load Assessment failed",
            type: "error",
            autoClose: false,
          });
        }
        onChange(nameOf("_option.Loading"), {
          value: undefined,
        });
        options?.setIsLoadingInStep(false);
      }
    };

    /**
     * Load view configuration
     */
    const loadViewConfiguration = async () => {
      onChange(nameOf("_option.Loading"), {
        value: eCertificateType.ViewConfiguration,
      });
      setIsLoadingStep(true);
      const response = await Promise.all([
        getViewConfigurations(ViewConfiguration.AssessmentSearch),
        getViewConfigurations(ViewConfiguration.ParcelSearch),
        getViewConfigurations(ViewConfiguration.PIC_Lookup),
      ]);
      setIsLoadingStep(false);
      const [viewAssessment, viewParcel, viewPIC] = response;
      let errorResponse = undefined;
      if (Array.isArray(response)) {
        if (viewAssessment?.data) {
          setColumnsAssessment(
            processDynamicColumns(
              colAssessmentLookup,
              viewAssessment?.data?.ColumnDefinitions?.Columns
            )
          );
        }
        if (viewParcel?.data) {
          setProcessedParcel(
            processDynamicColumns(
              colParcelLookup,
              viewParcel?.data?.ColumnDefinitions?.Columns
            )
          );
        }
        if (viewPIC?.data) {
          const newColumnPIC = processDynamicColumns(
            colPICAmalgamate,
            viewPIC?.data?.ColumnDefinitions?.Columns
          );
          setProcessedPIC(newColumnPIC);
        }
      } else {
        errorResponse = {
          status: APIResponseStatus.INTERNAL_SERVER_ERROR,
          error: "Server error",
        };
      }
      setResponseLoadError(errorResponse);
      onChange(nameOf("_option.Loading"), { value: undefined });
    };

    /**
     * handle delete item
     * @param field
     */
    const handleDeleteItem = (field: string) => {
      switch (field) {
        case eField.Assessments:
          handleDeleteAssessment();
          break;
        case eField.Parcels:
          handleDeleteParcel();
          break;
      }
    };

    const handleDeleteAssessment = () => {
      const assessmentId = assessmentSelected?.[0]?.Assessment_Id;
      const newAssessment = assessments.filter(
        (item: DTO_Assessment) => item.Assessment_Id !== assessmentId
      );
      onChange(nameOf("Assessments"), {
        value: newAssessment,
      });
      onChange(nameOf("AssessmentSelected"), {
        value: [],
      });
    };

    const handleDeleteParcel = () => {
      const parcelId = parcelSelected?.[0]?.Parcel_Id;
      const newParcel = parcels.filter(
        (item: DTO_Parcel) => parcelId !== item.Parcel_Id
      );
      onChange(nameOf("Parcels"), {
        value: newParcel,
      });
      onChange(nameOf("ParcelSelected"), {
        value: [],
      });
    };

    /**
     * Submit and Reload form
     */
    const submitAndReloadForm = async () => {
      onSubmit({
        currentTarget: {
          id: EListSubmitButton.Save,
          actions: { reloadForm: true },
        },
      } as any);
    };

    /**
     * handle add item (Holding and PIC)
     * @param data
     * @param field
     */
    const handleAddItemPIC = async (data: DTO_PIC[]) => {
      const picIds = data?.map((item) => item.PIC_Id) ?? [];
      const picListId = getFieldValue("PIC_Ids") ?? [];
      const differentListID = difference(picIds, picListId);
      if (differentListID) {
        const concatListPIC = union(picListId, picIds);
        onChange(nameOf("PIC_Ids"), {
          value: concatListPIC,
        });
        await submitAndReloadForm();
      }
    };

    /**
     * handle delete item
     * @param field
     */
    const handleDeleteItemPIC = async () => {
      //get selected pic
      const aPIC: DTO_PIC = selectedPIC?.[0];
      const picId = aPIC?.PIC_Id;
      remove(picIds, (id: number) => id === picId);
      onChange(nameOf("PIC_Ids"), { value: picIds });
      submitAndReloadForm();
    };

    const handleAddItemFolio = async (
      lookupTitleData: fnt_Title_LookupResult[]
    ) => {
      const titleLookupValue = lookupTitleData
        .map((item) => item?.Title_Id)
        .toString();
      onChange(nameOf("_option.Loading"), {
        value: eCertificateType.Lot,
      });
      options?.setIsLoadingInStep(true);
      const responseTitleLookup = await getSearchTitleLookup({
        LookupKey: eOptionSearchTitleLookup.TitleId,
        LookupValue: titleLookupValue,
        Statuses: [0], //default task 12866
      });
      onChange(nameOf("_option.Loading"), {
        value: undefined,
      });
      if (isSuccessResponse(responseTitleLookup) && responseTitleLookup?.data) {
        const resTitle = responseTitleLookup?.data?.Titles ?? [];
        const newData: DTO_Title[] = resTitle.map((item: DTO_Title) => {
          return {
            ...item,
            AssociationFactorPercentage: 100,
          };
        });
        const previousFolio = folio?.length ? [...folio] : [];
        const newFolio = processCombineData(
          previousFolio,
          newData,
          nameOfFolio("TitleId")
        );
        onChange(nameOf("AssociateTitles"), {
          value: newFolio,
        });
      } else {
        localNotificationRef?.current?.pushNotification({
          title: `Load ${titleLowercaseLabel}s failed`,
          type: "error",
          autoClose: false,
        });
      }
      options?.setIsLoadingInStep(false);
    };

    const handleDataChange = async (
      dataRow: any,
      gridName: string,
      primaryKey: "TitleId" | "Parcel_Id",
      gridData: DTO_Title[] | DTO_Parcel[]
    ) => {
      const id = dataRow?.[primaryKey];
      let newGridData = [...gridData];
      newGridData = newGridData?.map((item: any) =>
        item?.[primaryKey] === id ? { ...dataRow } : item
      );
      onChange(nameOf(gridName), { value: newGridData });
    };

    const handleDeleteItemFolio = () => {
      const folioId = folioSelected?.[0]?.TitleId;
      const newFolio = folio.filter(
        (item: DTO_Title) => folioId !== item.TitleId
      );
      onChange(nameOf("AssociateTitles"), {
        value: newFolio,
      });
      onChange(nameOf("_option.FolioSelected"), {
        value: [],
      });
    };

    useEffectOnce(() => {
      (async () => {
        await loadViewConfiguration();
      })();
    });

    if (isLoadingStep) return <Loading isLoading={isLoadingStep} />;

    return (
      <div className="cc-certificate-details">
        <section className="cc-field-group">
          <div className="cc-field">
            <CCLabel title="Certificate type" isMandatory />
            <Field
              name={nameOf("CertificateType")}
              component={CCSearchComboBox}
              validator={!options?.isReadOnly ? validateRequired : undefined}
              data={certificateDetailStepLOVs ?? []}
              textField={nameOfLov("Name")}
              dataItemKey={nameOfLov("Code")}
              value={getDropdownValue(
                certificateType?.toString(),
                certificateDetailStepLOVs,
                nameOfLov("Code")
              )}
              disabled
            />
          </div>
        </section>
        <br />
        <section className="cc-field-group">
          {!isEqual(certificateTypeComponent, eCertificateTypeComponent.PIC) ? (
            <>
              <p className="cc-description-error">
                You must add either a{" " + titleLabel} or
                {" " + assessmentLabel} to proceed.
              </p>
              {loadingMode === eCertificateType.ViewConfiguration ? (
                <Loading isLoading />
              ) : responseLoadError ? (
                <CCLoadFailed
                  responseError={responseLoadError}
                  onReload={() => {
                    loadViewConfiguration();
                  }}
                />
              ) : (
                <>
                  {options?.isLLS ? (
                    <div className="cc-field">
                      <CCLabel
                        title={titlesLabel}
                        isMandatory={isEqual(
                          certificateTypeComponent,
                          eCertificateTypeComponent.Parcel
                        )}
                        isLoading={
                          getFieldValue("_option.Loading") ===
                          eCertificateType.Lot
                        }
                      />
                      <CCGrid
                        selectableMode="single"
                        readOnly={options?.isReadOnly}
                        data={folio ?? []}
                        columnFields={colFoliosAssessment}
                        primaryField={nameOfFolio("TitleId")}
                        editableMode="cell"
                        onSelectionChange={(dataItems: DTO_Title[]) => {
                          onChange(nameOf("_option.FolioSelected"), {
                            value: dataItems ?? [],
                          });
                        }}
                        onDataRowChange={(dataRow) =>
                          handleDataChange(
                            dataRow,
                            "AssociateTitles",
                            "TitleId",
                            folio
                          )
                        }
                        toolbar={
                          !options?.isReadOnly ? (
                            <div className="cc-grid-tools-bar">
                              <Button
                                iconClass="fas fa-plus"
                                title={`Add ${titleLowercaseLabel}`}
                                onClick={() => {
                                  setIsShowDialog(eCertificateType.Lot);
                                }}
                              />
                              <Button
                                iconClass="fas fa-minus"
                                title={`Remove ${titleLowercaseLabel}`}
                                onClick={handleDeleteItemFolio}
                                disabled={folioSelected?.length < 1}
                              />
                            </div>
                          ) : null
                        }
                      />
                    </div>
                  ) : (
                    <div className="cc-field">
                      <CCLabel
                        title="Parcel(s) included in certificate"
                        isMandatory={isEqual(
                          certificateTypeComponent,
                          eCertificateTypeComponent.Parcel
                        )}
                        isLoading={
                          getFieldValue("_option.Loading") ===
                          eCertificateType.Parcel
                        }
                      />
                      <CCGrid
                        toolbar={
                          <div className="cc-grid-tools-bar">
                            <Button
                              iconClass="fas fa-plus"
                              type="button"
                              title="Add parcel"
                              onClick={() => {
                                setIsShowDialog(eCertificateType.Parcel);
                              }}
                              disabled={options?.isReadOnly}
                            />
                            <Button
                              type="button"
                              iconClass="fas fa-minus"
                              title="Remove parcel"
                              disabled={
                                options?.isReadOnly ||
                                parcelSelected?.length <= 0
                              }
                              onClick={() => {
                                handleDeleteItem(eField.Parcels);
                              }}
                            />
                          </div>
                        }
                        selectableMode="single"
                        data={parcels ?? []}
                        primaryField={nameOfParcel("Parcel_Id")}
                        columnFields={columnsParcel}
                        selectedRows={parcelSelected ?? []}
                        onSelectionChange={(dataItem: DTO_Parcel[]) => {
                          onChange(nameOf("ParcelSelected"), {
                            value: dataItem ?? [],
                          });
                        }}
                        readOnly={options?.isReadOnly}
                      />
                    </div>
                  )}
                  <div className="cc-field">
                    <CCLabel
                      title={`${assessmentLabel}(s) included in certificate`}
                      isMandatory={isEqual(
                        certificateTypeComponent,
                        eCertificateTypeComponent.Assessment
                      )}
                      isLoading={
                        getFieldValue("_option.Loading") ===
                        eCertificateType.Assessment
                      }
                    />
                    <CCGrid
                      toolbar={
                        <div className="cc-grid-tools-bar">
                          <Button
                            iconClass="fas fa-plus"
                            type="button"
                            title={`Add ${assessmentLowercaseLabel}`}
                            onClick={() => {
                              setIsShowDialog(eCertificateType.Assessment);
                            }}
                            disabled={options?.isReadOnly}
                          />
                          <Button
                            type="button"
                            iconClass="fas fa-minus"
                            title={`Remove ${assessmentLowercaseLabel}`}
                            disabled={
                              options?.isReadOnly ||
                              assessmentSelected?.length <= 0
                            }
                            onClick={() => {
                              handleDeleteItem(eField.Assessments);
                            }}
                          />
                        </div>
                      }
                      selectableMode="single"
                      data={assessments ?? []}
                      selectedRows={assessmentSelected ?? []}
                      primaryField={nameOfAssessment("Assessment_Id")}
                      columnFields={columnsAssessment}
                      onSelectionChange={(dataItem: DTO_Assessment[]) => {
                        onChange(nameOf("AssessmentSelected"), {
                          value: dataItem ?? [],
                        });
                      }}
                      readOnly={options?.isReadOnly}
                    />
                  </div>
                </>
              )}
            </>
          ) : (
            <div className="cc-field">
              <CCLabel
                title="PIC(s) included in certificate"
                isMandatory={isEqual(
                  certificateTypeComponent,
                  eCertificateTypeComponent.PIC
                )}
                isLoading={
                  getFieldValue("_option.Loading") === eCertificateType.PIC
                }
              />
              <CCGrid
                data={pics ?? []}
                columnFields={columnsPIC}
                selectableMode="single"
                primaryField={nameOfPIC("PIC_Id")}
                onSelectionChange={(dataItems) => {
                  onChange(nameOf("_option.PICSelected"), {
                    value: dataItems,
                  });
                }}
                readOnly={options?.isReadOnly}
                toolbar={
                  !options?.isReadOnly ? (
                    <div className="cc-grid-tools-bar">
                      <Button
                        iconClass="fas fa-plus"
                        title="Add a PIC"
                        onClick={() => {
                          setIsShowDialog(eCertificateType.PICNew);
                        }}
                      />
                      <Button
                        iconClass="fas fa-minus"
                        title="Remove a PIC"
                        onClick={() =>
                          setIsShowDialog(eCertificateType.PICDelete)
                        }
                        disabled={selectedPIC.length !== 1}
                      />
                    </div>
                  ) : null
                }
              />
            </div>
          )}
        </section>
        {isShowDialog === eCertificateType.Lot && (
          <AddTitleLookupDialog
            onClose={() => setIsShowDialog(undefined)}
            handleAddTitle={(data: fnt_Title_LookupResult[]) => {
              setIsShowDialog(undefined);
              handleAddItemFolio(data);
            }}
          />
        )}
        {isShowDialog === eCertificateType.Parcel && (
          <AddParcelLookupDialog
            onClose={() => setIsShowDialog(undefined)}
            handleAddParcel={async (data: DTO_Parcel[]) => {
              await handleAddItem(data, eField.Parcels);
              setIsShowDialog(undefined);
            }}
            isLoadingFinish={loadingMode === eCertificateType.Parcel}
          />
        )}
        {isShowDialog === eCertificateType.Assessment && (
          <AddAssessmentLookupDialog
            onClose={() => setIsShowDialog(undefined)}
            handleAddAssessment={async (
              data: fnt_Assessment_LookupResult[]
            ) => {
              await handleAddItem(data, eField.Assessments);
              setIsShowDialog(undefined);
            }}
            isLoadingFinish={loadingMode === eCertificateType.Assessment}
          />
        )}
        {isShowDialog === eCertificateType.PICNew && (
          <AddPICLookupDialog
            onClose={() => setIsShowDialog(undefined)}
            handleAddPIC={async (data: any) => {
              setIsShowDialog(undefined);
              await handleAddItemPIC(data);
            }}
          />
        )}
        {isShowDialog === eCertificateType.PICDelete && (
          <ConfirmDialog
            title="Confirmation"
            subMessage={`Any associated ${assessmentLowercaseLabel}(s) will also be removed.`}
            onClosePopup={() => setIsShowDialog(undefined)}
            onConfirmYes={handleDeleteItemPIC}
          />
        )}
      </div>
    );
  }
);
