import { IEditAddAddressLOVs } from "@app/products/property/assessments/components/form-steps/modify-assessment/dialog/new-address/models";
import { DTO_Address } from "@app/products/property/assessments/components/form-steps/modify-assessment/model";
import { VO_Street_Locality } from "@app/products/property/assessments/components/form-steps/new-assessment/components/street-search/model";
import { getDropdownValue, nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";

const nameOf = nameOfFactory<DTO_Address>();

export const processPropertyAddress = (
  valueGetter: (name: string) => any,
  assessmentLOVs: IEditAddAddressLOVs | undefined
) => {
  const getFieldValue = (name: keyof DTO_Address) => valueGetter(nameOf(name));
  const address =
    valueGetter("Full_Street_Name") ||
    valueGetter("StreetAndLocality.Full_Street_Name") ||
    "";
  const floorType =
    getDropdownValue(
      getFieldValue("FloorType"),
      assessmentLOVs?.Address_FloorType ?? [],
      "Code"
    )?.Name ?? "";

  const buildingUnitNumber =
    getDropdownValue(
      getFieldValue("BuildingUnitNumber"),
      assessmentLOVs?.Address_UnitType ?? [],
      "Code"
    )?.Code ?? "";

  const buildingName = getFieldValue("BuildingName") ?? "";
  const concatNumberFromTo = (
    numberFromField: keyof DTO_Address,
    numberToField: keyof DTO_Address
  ) => {
    return [getFieldValue(numberFromField), getFieldValue(numberToField)]
      ?.join("-")
      ?.replace(/^-/, "")
      ?.replace(/-$/, "");
  };
  return [
    buildingUnitNumber,
    concatNumberFromTo("UnitNumberFrom", "UnitNumberTo"),
    floorType,
    concatNumberFromTo("FloorNumberFrom", "FloorNumberTo"),
    buildingName,
    concatNumberFromTo("HouseFrom", "HouseTo"),
    (address + "").trim(),
  ]
    .filter((item: string) => {
      return item !== "";
    })
    .join(" ");
};

export const validateStreetAndLocality = (value: VO_Street_Locality) => {
  return requiredValidator(value?.Street_Name_Locality);
};
