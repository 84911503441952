import { fieldName } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/change-of-ownership/components/form-elements/names/config";
import {
  addUniqueKey,
  formatNames,
  getFormatMask,
} from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/change-of-ownership/components/form-elements/names/util";
import { DTO_ChangeOfOwnership_RetainNames } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/change-of-ownership/components/form-elements/property/model";
import {
  COONotRetainOwnersTypes,
  eChangeOfOwnershipType,
} from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/change-of-ownership/components/form-elements/type/model";
import {
  DTO_COO_Type,
  DTO_ChangeOfOwnership_LOVs,
  DTO_Entity_Details,
  DTO_Role,
  EKeysOfSteps,
  RoleId,
  RoleTypes,
} from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/change-of-ownership/model";
import { loadNameDetail } from "@app/products/property/components/fields/search-name/api";
import { DTO_Entity_Name_Address } from "@app/products/property/components/fields/search-name/model";
import { isSuccessResponse } from "@common/apis/util";
import { isNil, uniqBy } from "lodash";
import { useMemo } from "react";

export const useFormatNameStep = (
  lovsData: DTO_ChangeOfOwnership_LOVs | undefined,
  onChange: (key: string, value: any) => void,
  valueGetter: (key: string) => any,
  setIsLoadingStep: (isLoading: boolean) => void = () => {}
) => {
  const selectedType: DTO_COO_Type = valueGetter(
    `${EKeysOfSteps.Type}._option.Type`
  );
  const isDisplayOwner = selectedType?.COOT_Display_Owner_Roles;
  const selectedTypeID = valueGetter(`${EKeysOfSteps.Type}.Type`);
  const isPICType =
    selectedTypeID === eChangeOfOwnershipType.PIC_Name_and_Address_Change;
  const isAssociatedType =
    selectedTypeID === eChangeOfOwnershipType.Change_of_Associated_Name_Address;

  const allNames: DTO_Entity_Details[] = valueGetter(
    EKeysOfSteps.NamesGridData
  );

  const groupedRoles = useMemo(() => {
    const ownerRoles =
      lovsData?.Roles?.filter(
        (roleItem: DTO_Role) => roleItem?.IsOwner || roleItem?.IsPICRole
      )?.map((roleItem: DTO_Role) => roleItem?.Role_Name) ?? [];
    const ratepayerRoles =
      lovsData?.Roles?.filter(
        (roleItem: DTO_Role) => roleItem?.IsRatepayer
      )?.map((roleItem: DTO_Role) => roleItem?.Role_Name) ?? [];
    const duplicateRoles =
      lovsData?.Roles?.filter(
        (roleItem: DTO_Role) =>
          roleItem?.Role_Type_Id === RoleTypes.Duplicate_Owner
      )?.map((roleItem: DTO_Role) => roleItem?.Role_Name) ?? [];
    const associatedRoles =
      lovsData?.Roles?.filter(
        (roleItem: DTO_Role) => roleItem?.IsAssociatedName
      )?.map((roleItem: DTO_Role) => roleItem?.Role_Name) ?? [];
    return {
      Owner: ownerRoles,
      Ratepayer: ratepayerRoles,
      Duplicate: duplicateRoles,
      Associated: associatedRoles,
    };
  }, [lovsData?.Roles]);

  const rolesBySelectedType: DTO_Role[] = useMemo(() => {
    return (
      lovsData?.Roles?.filter((roleItem: DTO_Role) => {
        return (
          (selectedType?.COOT_Display_Owner_Roles && roleItem?.IsOwner) ||
          (selectedType?.COOT_Display_Associated_Names &&
            roleItem?.IsAssociatedName) ||
          (selectedType?.COOT_Display_RatePayer_Roles &&
            roleItem?.IsRatepayer) ||
          (selectedType?.COOT_Display_PIC_Roles && roleItem?.IsPICRole) ||
          (selectedType?.COOT_Display_Register_Roles &&
            roleItem?.IsRegisterAccount)
        );
      }) ?? []
    );
  }, [selectedType, lovsData?.Roles]);

  const setFormatNameAddressForStep = (
    step: EKeysOfSteps,
    contactDetail: DTO_Entity_Name_Address
  ) => {
    const changedFields = [
      fieldName.Name,
      fieldName.AttentionOf,
      fieldName.CareOf,
      fieldName.Address,
      fieldName.Locality,
      fieldName.State,
      fieldName.Country,
      fieldName.Postcode,
      fieldName.DPID,
      fieldName.FormattedNameAddress,
      fieldName.NoticeId,
    ];
    changedFields.forEach((field: string) => {
      onChange(`${step}.${field}`, {
        value: contactDetail?.[field as keyof DTO_Entity_Name_Address],
      });
    });
  };

  const handleChangeFormatEachType = async (
    names: DTO_Entity_Details[],
    formatMask: string,
    step: EKeysOfSteps
  ) => {
    const namesFormatted = formatNames(names, formatMask);
    const firstEntityId: number = names?.[0]?.Entity_Id ?? 0;
    const formattedNamesAddressValue =
      namesFormatted + `\r\n` + (names?.[0]?.Address || "");
    //Set value for formatted text area
    onChange(`${step}.${fieldName.FormattedNameAddress}`, {
      value: formattedNamesAddressValue,
    });
    //Use first entityId to load contact detail formatted dialog
    const isNewFirstEntity =
      valueGetter(`${step}.${fieldName.FirstEntity}`) !== firstEntityId;
    if (!firstEntityId) {
      setFormatNameAddressForStep(step, {
        Formatted_Name_Address: formattedNamesAddressValue,
      } as DTO_Entity_Name_Address);
    } else if (isNewFirstEntity) {
      const response = await loadNameDetail(firstEntityId);
      if (isSuccessResponse(response)) {
        const contactDetail = response?.data?.Entity_Name_Address;
        setFormatNameAddressForStep(step, {
          ...contactDetail,
          Formatted_Name_Address: formattedNamesAddressValue,
        });
      }
    }
    //Set first entityId
    onChange(`${step}.${fieldName.FirstEntity}`, {
      value: firstEntityId,
    });
    //Set formatted
    onChange(`${step}.${fieldName.Name}`, { value: namesFormatted });
  };

  const handleChangeNamesGrid = async (
    newNames: DTO_Entity_Details[],
    isNeedToProcessFormat: boolean = true
  ) => {
    const ownerNames =
      newNames?.filter((nameItem: DTO_Entity_Details) =>
        groupedRoles.Owner.includes(nameItem?.Role_Name ?? "")
      ) ?? [];
    const ratePayerNames =
      newNames?.filter((nameItem: DTO_Entity_Details) =>
        groupedRoles.Ratepayer.includes(nameItem?.Role_Name ?? "")
      ) ?? [];
    const duplicateNames =
      newNames?.filter((nameItem: DTO_Entity_Details) =>
        groupedRoles.Duplicate.includes(nameItem?.Role_Name ?? "")
      ) ?? [];
    const associatedNames =
      newNames?.filter((nameItem: DTO_Entity_Details) =>
        groupedRoles.Associated.includes(nameItem?.Role_Name ?? "")
      ) ?? [];

    //Set value for each DTO names step
    onChange(EKeysOfSteps.NamesGridData, { value: newNames });
    onChange(`${EKeysOfSteps.Owners}.${fieldName.Names}`, {
      value: ownerNames,
    });
    onChange(`${EKeysOfSteps.RatePayers}.${fieldName.Names}`, {
      value: ratePayerNames,
    });
    onChange(`${EKeysOfSteps.DuplicateRatePayers}.${fieldName.Names}`, {
      value: duplicateNames,
    });
    onChange(EKeysOfSteps.AssociatedNames, {
      value: associatedNames,
    });

    if (!isNeedToProcessFormat) return;

    const ownerNameFormatMask = getFormatMask(
      lovsData?.OwnerNameFormat?.Name ?? ""
    );
    const nameFormatMask = getFormatMask(lovsData?.NameFormat?.Name ?? "");

    setIsLoadingStep(true);
    await Promise.all([
      handleChangeFormatEachType(
        ownerNames,
        ownerNameFormatMask,
        EKeysOfSteps.Owners
      ),
      handleChangeFormatEachType(
        ratePayerNames,
        nameFormatMask,
        EKeysOfSteps.RatePayers
      ),
      handleChangeFormatEachType(
        duplicateNames,
        nameFormatMask,
        EKeysOfSteps.DuplicateRatePayers
      ),
    ]);
    setIsLoadingStep(false);
  };

  const checkPercentageRoles = (role: string) => {
    return groupedRoles.Owner.includes(role);
  };

  const calculatePercentage = (names: any[]) => {
    let countPercentageRole = 0;
    let lastIndex = 0;

    names.forEach((item, index) => {
      if (checkPercentageRoles(item[fieldName.Role])) {
        countPercentageRole += 1;
        lastIndex = index;
      }
    });
    let normalPercentage: number = parseFloat(
      (100 / countPercentageRole).toFixed(2)
    );
    let lastPercentage: number = normalPercentage;
    if (countPercentageRole > 0) {
      lastPercentage = parseFloat(
        (100 - (countPercentageRole - 1) * normalPercentage).toFixed(2)
      );
    }

    const newNames = names.map((item, index) => ({
      ...item,
      [fieldName.Percentage]: checkPercentageRoles(item[fieldName.Role])
        ? index !== lastIndex
          ? normalPercentage
          : lastPercentage
        : null,
    }));

    return newNames;
  };

  const handleRetainNames = (
    names: DTO_ChangeOfOwnership_RetainNames,
    isRetainRatePayers?: boolean,
    isRetainAssociatedNames?: boolean
  ) => {
    let retainedOwners = COONotRetainOwnersTypes.includes(selectedTypeID)
      ? []
      : names?.OwnerDetails?.Contacts || [];
    if (selectedTypeID === eChangeOfOwnershipType.Full_Transfer_of_Occupier) {
      retainedOwners = handleSwitchRoleToOwner(retainedOwners);
    }
    const retainedRatePayers = (
      !isNil(isRetainRatePayers)
        ? isRetainRatePayers
        : valueGetter(`${EKeysOfSteps.Details}.RetainRatePayerDetails`)
    )
      ? names?.RatePayerDetails?.Contacts || []
      : [];
    const retainedAssociatedNames = (
      !isNil(isRetainAssociatedNames)
        ? isRetainAssociatedNames
        : valueGetter(`${EKeysOfSteps.Details}.RetainAssociatedNames`)
    )
      ? names?.AssociatedNamesDetails || []
      : [];
    let newNames = uniqBy(
      [
        ...allNames,
        ...retainedOwners,
        ...retainedRatePayers,
        ...retainedAssociatedNames,
      ],
      (name: DTO_Entity_Details) => `${name.Entity_Id}-${name.Role_Name}`
    );
    if (retainedOwners.length > 0) {
      newNames = calculatePercentage(newNames);
    }
    newNames = addUniqueKey(newNames);
    handleChangeNamesGrid(newNames);
  };

  const handleRemoveRetainedAsociatedNames = () => {
    const newNames = allNames.filter((name: DTO_Entity_Details) => {
      return (
        !name.isFromRetainNames ||
        !groupedRoles.Associated.includes(name.Role_Name ?? "")
      );
    });
    handleChangeNamesGrid(newNames);
  };

  const handleRemoveRetainedRatepayers = () => {
    const newNames = allNames.filter((name: DTO_Entity_Details) => {
      return (
        !name.isFromRetainNames ||
        !groupedRoles.Ratepayer.includes(name.Role_Name ?? "")
      );
    });
    handleChangeNamesGrid(newNames);
  };

  const handleUpdateRetainedNames = (
    newRetainedNames: DTO_Entity_Details[]
  ) => {
    let newNames = allNames.filter((name: DTO_Entity_Details) => {
      return (
        !name.isFromRetainNames ||
        newRetainedNames.some(
          (newName: DTO_Entity_Details) => newName.Entity_Id === name.Entity_Id
        )
      );
    });
    newNames = calculatePercentage(newNames);
    newNames = addUniqueKey(newNames);
    handleChangeNamesGrid(newNames);
  };

  const handleSwitchRoleToOwner = (names: DTO_Entity_Details[]) => {
    const ownerRatepayerRole = lovsData?.Roles?.find(
      (role: DTO_Role) => role.Role_Id === RoleId.Owner_RatePayer
    );
    const ownerRole = lovsData?.Roles?.find(
      (role: DTO_Role) => role.Role_Id === RoleId.Owner
    );
    const newNames = names.map((name: DTO_Entity_Details) => {
      if (name.Role_Name === ownerRatepayerRole?.Role_Name) {
        return {
          ...name,
          Role_Name: ownerRole?.Role_Name ?? "Owner",
        };
      }
      return name;
    });
    return newNames;
  };

  return {
    selectedTypeID,
    isAssociatedType,
    isPICType,
    isDisplayOwner,
    selectedType,
    rolesBySelectedType,
    groupedRoles,
    handleChangeNamesGrid,
    calculatePercentage,
    checkPercentageRoles,
    handleRetainNames,
    handleRemoveRetainedAsociatedNames,
    handleRemoveRetainedRatepayers,
    setFormatNameAddressForStep,
    handleSwitchRoleToOwner,
    handleUpdateRetainedNames,
  };
};
