import { VO_Workflow_Draft } from "@app/products/property/actions/model";
import { listSubmitButton } from "@app/products/property/assessments/components/form-steps/new-assessment/config";
import { ConfirmCloseButton } from "@app/products/property/components/action-bar/dialog/new-debt-recovery/dialog/confirm-close-button/_index";
import { abandonWorkflow } from "@app/products/property/components/action-bar/property-workflow/api";
import { useConfirmCancelDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-cancel/store";
import { CommentsFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/comments/_index";
import { DocumentsFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/documents/_index";
import { usePropertyWorkflow } from "@app/products/property/components/action-bar/property-workflow/component/hooks/useProprtyWorkflow/usePropertyWorkflow";
import { getTitleWorkflow } from "@app/products/property/components/action-bar/property-workflow/util";
import {
  getInitialDataDeferredDuty,
  postNewDeferredDuty,
} from "@app/products/property/deferred-duty/components/action-bar/dialogs/create-deferred-duty/api";
import { DetailsFormStep } from "@app/products/property/deferred-duty/components/action-bar/dialogs/create-deferred-duty/form-elements/details/_index";
import { OtherInformationFormStep } from "@app/products/property/deferred-duty/components/action-bar/dialogs/create-deferred-duty/form-elements/other-information/_index";
import {
  DTO_Workflow_CreateDeferredDuty,
  EKeysOfStepsCreateDeferredDuty,
  keysOfSendStepsCreateDeferredDuty,
} from "@app/products/property/deferred-duty/components/action-bar/dialogs/create-deferred-duty/model";
import { useCreateDeferredDutyDialogStore } from "@app/products/property/deferred-duty/components/action-bar/dialogs/create-deferred-duty/store";
import {
  DTO_WorkflowHeader,
  EListSubmitButton,
  EWorkflowStatus,
  WorkflowProcessMode,
  WorkflowTypes,
} from "@app/products/property/model";
import { isShowParkButton } from "@app/products/property/util";
import { APIResponse } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { RECORDTYPE } from "@common/constants/recordtype";
import { ResponsePacket } from "@common/models/identityPacket";
import { useCommonProductStore } from "@common/stores/products/store";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCDialog } from "@components/cc-dialog/_index";
import {
  CCFormStep,
  ICCFormStepNotificationHandle,
  ICCFormStepRender,
} from "@components/cc-form-step/_index";
import { IStep } from "@components/cc-form-step/model";
import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import { Button } from "@progress/kendo-react-buttons";
import { pickBy } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo, useRef, useState } from "react";
import { useEffectOnce } from "react-use";

interface ICreateDeferredDutyDialogProps {
  onClose: () => void;
  onSubmit: (data: any) => void;
  dataFromActionList?: VO_Workflow_Draft;
  prefixTitle?: string;
  suffixTitle?: string;
  isShowSearchType?: boolean;
  isShowReasonRejection?: boolean;
  isShowReason?: boolean;
  isSaveOnNextStep?: boolean;
}

export const CreateDeferredDutyDialog = observer(
  ({
    onClose,
    dataFromActionList,
    prefixTitle,
    suffixTitle,
    isSaveOnNextStep = false,
  }: ICreateDeferredDutyDialogProps) => {
    const { currentFormTitle } = useCommonProductStore();
    const { setDataForCancelDialog } = useConfirmCancelDialogStore();
    const { pushNotification } = useCCAppNotificationStore();
    const {
      setIsLoadingOnDialog,
      setIsLoadingOnDialogCancel,
      isLoadingApprove,
    } = useCreateDeferredDutyDialogStore();
    const [newDeferredDutyInitData, setNewDeferredDutyInitData] =
      useState<DTO_Workflow_CreateDeferredDuty>();
    const [workflowHeader, setWorkflowHeader] = useState<DTO_WorkflowHeader>({
      WorkflowDraft: { Workflow_Draft_Id: 0 },
      AvailableSecondaryWorkflows: [],
      WorkflowApprovals: [],
    });
    const [isShowCloseDialog, setIsShowCloseDialog] = useState<boolean>(false);
    const [finishConfirmDialogData, setFinishConfirmDialogData] =
      useState<any>();
    const [isLoadingOnNext, setIsLoadingOnNext] = useState<boolean>(false);
    const [isLoadingFinish, setIsLoadingFinish] = useState<boolean>(false);
    const [isLoadingPark, setIsLoadingPark] = useState<boolean>(false);
    const [isFirstSave, setIsFirstSave] = useState<boolean>(true);
    const [workflowDraftId, setWorkflowDraftId] = useState<number>(0);

    const notificationFormStepRef =
      useRef<ICCFormStepNotificationHandle | null>(null);

    //Hooks
    const {
      isReadOnly,
      isIncompleteMode,
      isShowCancelWorkflowButton,
      statusBadge,
      isFromActionList,
      isToBeApprovalMode,
    } = usePropertyWorkflow(dataFromActionList);

    const titleHeader = useMemo(() => {
      const formId = workflowHeader?.WorkflowDraft?.WD_Form_Id;
      const title = currentFormTitle(formId ?? 0) || "Create deferred duty";
      return getTitleWorkflow(title, prefixTitle, suffixTitle);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [prefixTitle, suffixTitle, workflowHeader]);

    const steps: IStep[] = [
      {
        label: "Details",
        component: DetailsFormStep,
        visible: true,
        key: EKeysOfStepsCreateDeferredDuty.Details,
        options: {
          isReadOnly,
        },
      },
      {
        label: "Other information",
        component: OtherInformationFormStep,
        visible: true,
        key: EKeysOfStepsCreateDeferredDuty.OtherInfo,
        options: {
          isReadOnly,
        },
      },
      {
        label: "Comments",
        component: CommentsFormStep,
        visible: true,
        key: EKeysOfStepsCreateDeferredDuty.Comments,
        customClassName: "cc-comment-step-fixed-height-grid",
        initialValues: {},
        options: {
          isReadOnly,
          workflowDraftId,
          recordType: RECORDTYPE.CommunityProperty_Deferred_Duty_Account,
        },
      },
      {
        label: "Documents",
        component: DocumentsFormStep,
        initialValues: {},
        visible: true,
        key: EKeysOfStepsCreateDeferredDuty.Documents,
        options: {
          isReadOnly,
          workflowDraftId,
          workflowType: WorkflowTypes.Create_DeferredDuty,
        },
      },
    ];

    const handleSubmit = async (data: any, buttonId?: string) => {
      switch (buttonId) {
        case EListSubmitButton.Finish:
          setFinishConfirmDialogData(processData(data));
          break;
        case EListSubmitButton.Cancel:
          handleCancelButton(processData(data));
          break;
        case EListSubmitButton.Park:
        case EListSubmitButton.ConfirmCloseYes:
        case EListSubmitButton.Close:
          await sendParkDeferredDuty(processData(data));
          break;
      }
    };

    const sendParkDeferredDuty = async (
      payload: DTO_Workflow_CreateDeferredDuty
    ) => {
      setIsLoadingPark(true);
      setIsLoadingOnDialog(true);
      const defaultSuccessMessage = "Deferred duty was parked successfully.";
      const defaultFailedMessage = "Park deferred duty failed";
      const response = await postNewDeferredDuty(
        WorkflowProcessMode.Park,
        payload
      );
      if (isSuccessResponse(response)) {
        if (response?.data?.IsSuccess) {
          onClose();
          pushNotification({
            title: response?.data?.SuccessMessage ?? defaultSuccessMessage,
            type: "success",
          });
        } else {
          notificationFormStepRef?.current
            ?.getNotificationFormStep()
            ?.current?.pushNotification({
              title: response?.data?.ErrorMessage ?? defaultFailedMessage,
              type: "error",
              autoClose: false,
            });
        }
      } else {
        notificationFormStepRef?.current
          ?.getNotificationFormStep()
          ?.current?.pushNotification({
            title: response?.data?.ErrorMessage ?? defaultFailedMessage,
            type: "error",
            autoClose: false,
          });
      }
      setIsLoadingOnDialog(false);
      setIsLoadingPark(false);
    };

    const handleCancelButton = (data: any) => {
      if (isFromActionList || !isFirstSave) {
        setDataForCancelDialog({
          cancelAPI: postNewDeferredDuty,
          dataCancel: data,
          defaultSuccessMessage:
            "Create deferred duty was cancelled successfully.",
          defaultErrorMessage: "Create deferred duty could not be cancelled.",
        });
      } else {
        onClose();
      }
      onClose();
    };

    const handleCloseDialog = (renderProps: ICCFormStepRender) => {
      if (!isFromActionList && !isFirstSave) {
        setIsShowCloseDialog(true);
      } else if (
        isIncompleteMode &&
        dataFromActionList?.Workflow_Status_Name &&
        dataFromActionList.Workflow_Status_Name === EWorkflowStatus.Park
      ) {
        onClose();
      } else if (
        dataFromActionList?.Workflow_Status_Name ===
          EWorkflowStatus.Incomplete &&
        !isFirstSave
      ) {
        const newEvent = {
          currentTarget: { id: EListSubmitButton.Close },
        };
        renderProps.submitButton.onClick(newEvent);
      } else {
        onClose();
      }
    };

    const handleConfirmNoRetain = async () => {
      setIsLoadingOnDialogCancel(true);
      await abandonWorkflow().then(() => {
        setIsLoadingOnDialogCancel(false);
        onClose();
      });
    };

    const handleNextButton = async (data: any) => {
      setIsLoadingOnNext(true);
      return await sendSaveDeferredDuty(processData(data));
    };

    const sendSaveDeferredDuty = async (
      payload: DTO_Workflow_CreateDeferredDuty,
      isCloseDialog: boolean = false
    ) => {
      const response = await postNewDeferredDuty(
        WorkflowProcessMode.Save,
        payload
      );
      setIsLoadingOnNext(false);
      const defaultSuccessMessage = "Deferred duty saved successfully";
      const defaultFailedMessage =
        "Create new deferred duty process could not be saved.";
      if (isSuccessResponse(response)) {
        if (response?.data?.IsSuccess) {
          if (isCloseDialog) {
            onClose();
            pushNotification({
              title: response?.data?.SuccessMessage ?? defaultSuccessMessage,
              type: "success",
            });
          }
          if (isFirstSave && workflowDraftId === 0) {
            setIsFirstSave(false);
            setWorkflowDraftId(response?.data?.ID ?? 0);
            setWorkflowHeader({
              ...workflowHeader,
              WorkflowDraft: {
                ...workflowHeader.WorkflowDraft,
                Workflow_Draft_Id: response?.data?.ID ?? 0,
              },
            });
          }
          return true;
        } else {
          notificationFormStepRef?.current
            ?.getNotificationFormStep()
            ?.current?.pushNotification({
              title: response.data?.ErrorMessage ?? defaultFailedMessage,
              type: "error",
              autoClose: false,
            });
          return false;
        }
      } else {
        notificationFormStepRef?.current
          ?.getNotificationFormStep()
          ?.current?.pushNotification({
            title: response?.data?.ErrorMessage ?? defaultFailedMessage,
            type: "error",
            autoClose: false,
          });
        return false;
      }
    };

    const handleFinish = async (payload: DTO_Workflow_CreateDeferredDuty) => {
      setIsLoadingFinish(true);
      const defaultSuccessMessage = "Deferred duty was finished successfully";
      const defaultFailedMessage = "Finish deferred duty failed";
      const response = await postNewDeferredDuty(
        WorkflowProcessMode.Finish,
        payload
      );
      if (isSuccessResponse(response)) {
        if (response?.data?.IsSuccess) {
          onClose();
          pushNotification({
            title: response?.data?.SuccessMessage ?? defaultSuccessMessage,
            type: "success",
          });
        } else {
          notificationFormStepRef?.current
            ?.getNotificationFormStep()
            ?.current?.pushNotification({
              title: response?.data?.ErrorMessage ?? defaultFailedMessage,
              type: "error",
              autoClose: false,
            });
        }
      } else {
        notificationFormStepRef?.current
          ?.getNotificationFormStep()
          ?.current?.pushNotification({
            title: response?.data?.ErrorMessage ?? defaultFailedMessage,
            type: "error",
            autoClose: false,
          });
      }
      setIsLoadingFinish(false);
      setFinishConfirmDialogData(undefined);
    };

    const processData = (data: any) => {
      let workflowDetail: any = {};
      let workflowDetailConvert: any = {};
      const sendSteps = pickBy(data, function (value, key) {
        if (
          keysOfSendStepsCreateDeferredDuty.includes(
            key as EKeysOfStepsCreateDeferredDuty
          )
        ) {
          return { [key]: value };
        }
      });

      if (sendSteps) {
        for (const [key, value] of Object.entries(sendSteps)) {
          if (value && value._option) {
            delete value._option;
          }
          const dataStep = { ...value };
          workflowDetail[key as keyof DTO_Workflow_CreateDeferredDuty] =
            dataStep;
        }
      }

      //covert model to save
      const { Detail, OtherInfo } = workflowDetail;

      workflowDetailConvert = {
        AccountDetails: Detail,
        Interest: {
          InterestCalculatedToDate: OtherInfo?.InterestCalculatedToDate,
          InterestRate: OtherInfo?.InterestRate,
          MinimumAmountForInterest: OtherInfo?.MinimumAmountForInterest,
          PremiumRate: OtherInfo?.PremiumRate,
        },
        TRSInformation: {
          ClientId: OtherInfo?.ClientId,
          AssessmentId: OtherInfo?.AssessmentId,
          LodgementNumber: OtherInfo?.LodgementNumber,
          RevenueType: OtherInfo?.RevenueType,
        },
      };

      return {
        WorkflowHeader: workflowHeader,
        WorkflowDetail: workflowDetailConvert,
      };
    };

    const getWorkflowData = () => {
      const draftId = dataFromActionList?.Workflow_Draft_Id;
      notificationFormStepRef?.current?.setLoadingFormStep(true);

      getInitialDataDeferredDuty(draftId).then((response) => {
        if (isSuccessResponse(response)) {
          const workflowDataResponse = response;
          const workflowData = workflowDataResponse?.data;
          if (workflowData) {
            //set New InitData
            setNewDeferredDutyInitData({
              WorkflowDetail: workflowData?.WorkflowDetail,
              WorkflowHeader: workflowData?.WorkflowHeader,
            });
            //set workflow header and workflow Draft Id
            if (workflowData?.WorkflowHeader) {
              setWorkflowHeader(workflowData.WorkflowHeader);
              setWorkflowDraftId(
                workflowData?.WorkflowHeader?.WorkflowDraft
                  ?.Workflow_Draft_Id ?? 0
              );
            }
            notificationFormStepRef?.current?.setLoadingFormStep(false);
          } else {
            const responseError = response as APIResponse<
              DTO_Workflow_CreateDeferredDuty | ResponsePacket
            >;
            notificationFormStepRef?.current?.setLoadingFormStep(false);
            notificationFormStepRef?.current?.setLoadFailedFormStep({
              onReload: () => getWorkflowData(),
              responseError: {
                status: responseError.status,
                error:
                  (responseError.data as ResponsePacket)?.Errors ??
                  "Load workflow failed",
              },
            });
          }
        } else {
          const responseError = response as APIResponse;
          notificationFormStepRef?.current?.setLoadingFormStep(false);
          notificationFormStepRef?.current?.setLoadFailedFormStep({
            onReload: () => getWorkflowData(),
            responseError: {
              status: responseError.status,
              error: "Load workflow failed",
            },
          });
        }
      });
    };

    const initialValues = useMemo(() => {
      let initDetails: any = {};
      let initOtherInfo: any = {};

      if (newDeferredDutyInitData) {
        const workflowDetail = newDeferredDutyInitData?.WorkflowDetail;
        //Details
        if (workflowDetail?.AccountDetails) {
          initDetails = {
            ...initDetails,
            ...workflowDetail?.AccountDetails,
          };
        }
        //Other information
        if (workflowDetail?.Interest) {
          initOtherInfo = {
            ...initOtherInfo,
            ...workflowDetail?.Interest,
          };
        }
        if (workflowDetail?.TRSInformation) {
          initOtherInfo = {
            ...initOtherInfo,
            ...workflowDetail?.TRSInformation,
          };
        }
      }
      return {
        [EKeysOfStepsCreateDeferredDuty.Details]: initDetails,
        [EKeysOfStepsCreateDeferredDuty.OtherInfo]: initOtherInfo,
        [EKeysOfStepsCreateDeferredDuty.Comments]: {},
        [EKeysOfStepsCreateDeferredDuty.Documents]: {},
      };
    }, [newDeferredDutyInitData]);

    useEffectOnce(() => {
      getWorkflowData();
    });

    return (
      <>
        <CCFormStep
          ref={notificationFormStepRef}
          onSubmit={handleSubmit}
          listButtonId={listSubmitButton}
          initialValues={initialValues}
          initialSteps={steps}
          saveOnNextStep={isSaveOnNextStep ? handleNextButton : undefined}
          renderForm={(renderProps: ICCFormStepRender) => (
            <CCDialog
              maxWidth="60%"
              disabled={isLoadingApprove}
              titleHeader={titleHeader}
              onClose={() => handleCloseDialog(renderProps)}
              bodyElement={renderProps.children}
              badge={statusBadge}
              footerElement={
                <>
                  <div className={"cc-dialog-footer-actions-right"}>
                    {isShowParkButton(isFromActionList, isIncompleteMode) && (
                      <Button
                        iconClass={
                          isLoadingPark ? "fas fa-spinner fa-spin" : ""
                        }
                        className={"cc-dialog-button"}
                        id={EListSubmitButton.Park}
                        onClick={renderProps.submitButton.onClick}
                        disabled={
                          renderProps.nextButton.disabled || isLoadingPark
                        }
                      >
                        Park
                      </Button>
                    )}
                    {isShowCancelWorkflowButton && (
                      <Button
                        className={"cc-dialog-button"}
                        disabled={
                          isLoadingApprove ||
                          isLoadingFinish ||
                          isLoadingOnNext ||
                          isLoadingPark
                        }
                        id={EListSubmitButton.Cancel}
                        onClick={renderProps.submitButton.onClick}
                      >
                        Cancel
                      </Button>
                    )}
                    {!renderProps.prevButton.disabled && (
                      <Button
                        className={"cc-dialog-button"}
                        themeColor="primary"
                        onClick={renderProps.prevButton.onClick}
                      >
                        Previous
                      </Button>
                    )}
                    {isToBeApprovalMode || isReadOnly ? (
                      !renderProps.isLastStep && (
                        <Button
                          themeColor="primary"
                          id="cc-next-step-button"
                          disabled={
                            isLoadingOnNext || renderProps.nextButton.disabled
                          }
                          className={"cc-dialog-button"}
                          iconClass={
                            isLoadingOnNext ? "fas fa-spinner fa-spin" : ""
                          }
                          onClick={renderProps.nextButton.onClick}
                        >
                          {isLoadingOnNext
                            ? "Saving"
                            : renderProps.nextButton.label}
                        </Button>
                      )
                    ) : (
                      <Button
                        themeColor="primary"
                        id={renderProps.nextButton.idButton}
                        disabled={
                          isLoadingOnNext || renderProps.nextButton.disabled
                        }
                        iconClass={
                          isLoadingOnNext ? "fas fa-spinner fa-spin" : ""
                        }
                        className={"cc-dialog-button"}
                        onClick={renderProps.nextButton.onClick}
                      >
                        {isLoadingOnNext
                          ? "Saving"
                          : renderProps.nextButton.label}
                      </Button>
                    )}
                  </div>
                  {isShowCloseDialog && (
                    <ConfirmCloseButton
                      onClose={() => setIsShowCloseDialog(false)}
                      onConfirmYes={renderProps.submitButton.onClick}
                      onConfirmNo={handleConfirmNoRetain}
                    />
                  )}
                </>
              }
            />
          )}
        />
        {finishConfirmDialogData && (
          <ConfirmDialog
            title="Confirmation"
            subMessage={
              "The deferred duty will be created based on the information provided. Are you sure you want to submit?"
            }
            isLoadingYes={isLoadingFinish}
            onClosePopup={() => setFinishConfirmDialogData(undefined)}
            onAsyncConfirm={() => {
              return handleFinish(finishConfirmDialogData);
            }}
          />
        )}
      </>
    );
  }
);
