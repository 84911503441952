import { useLocalLawsStore } from "@app/products/local-laws/[id]/store";
import { LocalLawsContactsTab } from "@app/products/local-laws/components/sidebar/contacts/_index";
import { LocalLawsDetailsTab } from "@app/products/local-laws/components/sidebar/details/_index";
import { LocalLawsHistoryTab } from "@app/products/local-laws/components/sidebar/history/_index";
import { useIsNew } from "@common/hooks/useIsNew";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import { observer } from "mobx-react-lite";
import React from "react";
import { useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";
import { ExistManageLocalLaws } from "./existed/_index";
import { NewManageLocalLaws } from "./new/_index";

const ManageLocalLaws = observer(() => {
  const { id } = useParams<{ id: string }>();
  const isNew = useIsNew();
  const { loadLocalLaws, resetStore } = useLocalLawsStore();

  useEffectOnce(() => {
    if (isNew) return;
    loadLocalLaws(parseInt(id));
    return () => {
      resetStore();
    };
  });

  useReferenceSideBar({
    referenceComponents: [
      {
        title: "Details",
        component: <LocalLawsDetailsTab />,
      },
      { title: "Contacts", component: <LocalLawsContactsTab /> },
      { title: "History", component: <LocalLawsHistoryTab /> },
    ],
  });

  if (isNew) {
    return <NewManageLocalLaws />;
  }

  return <ExistManageLocalLaws />;
});

export default ManageLocalLaws;
