import { putSetting } from "@app/products/property/system-admin/settings/api";
import { useSettingNewButtonStore } from "@app/products/property/system-admin/settings/components/action-bar/buttons/new/store";
import { CCSettingPanel } from "@app/products/property/system-admin/settings/components/setting-panel/_index";
import {
  ICollapseState,
  IGroupSettingField,
  ILookupItem,
  SettingField,
  SettingFolder,
  SettingsDataType
} from "@app/products/property/system-admin/settings/model";
import { useSettingConfirmExitStore } from "@app/products/property/system-admin/settings/_id/components/dialogs/confirm-exit/store";
import { ConfirmExit } from "@app/products/property/system-admin/settings/_id/components/dialogs/confirm-exit/_index";
import { ICheckMandatoryList } from "@app/products/property/system-admin/settings/_id/model";
import { usePropertySettingManagePageStore } from "@app/products/property/system-admin/settings/_id/store";
import { isSuccessResponse } from "@common/apis/util";
import { APIResponseStatus } from "@common/constants/response-status";
import { DATA_TYPE } from "@common/pages/settings/constant";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import {
  CCAppNotification,
  CCLocalNotification,
  ICCLocalNotificationHandle
} from "@components/cc-app-notification/_index";
import Loading from "@components/loading/Loading";
import { SettingCurrencyInput } from "@components/setting/setting-currency-input/_index";
import { SettingDateInput } from "@components/setting/setting-date-input/_index";
import { SettingDateTimeInput } from "@components/setting/setting-date-time-input/_index";
import { SettingComboboxList } from "@components/setting/setting-drop-down-list/_index";
import { SettingListOption } from "@components/setting/setting-list-option/_index";
import { SettingNumericInput } from "@components/setting/setting-numeric-input/_index";
import { SettingPercentInput } from "@components/setting/setting-percent-input/_index";
import { SettingSwitchInput } from "@components/setting/setting-switch-input/_index";
import { SettingTextInput } from "@components/setting/setting-text-input/_index";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps
} from "@progress/kendo-react-form";
import { cloneDeep, isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { ReactElement, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useEffectOnce } from "react-use";
import {
  assignChangedGroup,
  assignValueOnchange,
  getFormInitialValue,
  getIdFirstGroup,
  groupValidator,
  isModifiedForm,
  isNoneGroup,
  messageCurrencyValidator,
  messageDateValidator,
  messageDropDownValidator,
  messageInputValidator,
  messageListValidator,
  messageNumericValidator,
  messagePercentValidator,
  processDataAfterSaving,
  propsValuesCurrency,
  propsValuesDate,
  propsValuesDrop,
  propsValuesInput,
  propsValuesList,
  propsValuesNumeric,
  propsValuesSwitch,
  resetDataAGroup,
  resultPreprocessData,
  saveDataAGroup,
  setStateEditChange
} from "../utils";
import "./_index.scss";

const ExistedSettingManage = (props: any) => {
  const history = useHistory();
  const { setConfirmExit } = useSettingConfirmExitStore();
  const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);
  const [data, setData] = useState<IGroupSettingField[]>([]);
  const [originData, setOriginData] = useState<IGroupSettingField[]>([]);
  const [checkHitSaveButton, setCheckHitSaveButton] = useState(false);
  const [isShowDialogExit, setIsShowDialogExit] = useState(false);
  const [collapseState, setCollapseState] = useState<ICollapseState>({});
  const { pushNotification, clearErrorNotification } =
    useCCAppNotificationStore();
  const { showNotification, emptyNotification } =
    usePropertySettingManagePageStore();
  const [isNotificationNew, setIsNotificationNew] = useState(false);
  const [isLoadingSaving, setIsLoadingSaving] = useState<{
    [key: string]: { isLoading: boolean; groupLoading: string | null };
  } | null>();
  const [checkMandatoryList, setCheckMandatoryList] = useState<
    ICheckMandatoryList | undefined
  >();

  let listFieldNoneGroup: IGroupSettingField[] = [];
  const { state }: any = history.location;
  const { isExistAfterCreated, setIsExistAfterCreated } =
    useSettingNewButtonStore();

  useEffect(() => {
    setConfirmExit(isModifiedForm(data));
  }, [setConfirmExit, data]);

  useEffectOnce(() => {
    if (!state || state.createdNew === undefined) return;

    if (state.createdNew && isExistAfterCreated && showNotification?.props) {
      setIsNotificationNew(true);
      clearErrorNotification();
      pushNotification(showNotification?.props);
    }
    const timer = setTimeout(() => {
      setIsExistAfterCreated(false);
      setIsNotificationNew(false);
      emptyNotification();
    }, 5000);
    return () => {
      clearTimeout(timer);
    };
  });

  useEffect(() => {
    if (props.data.SettingFields) {
      const processedData = preprocessData(props.data.SettingFields);
      setData(cloneDeep(processedData));
      setOriginData(cloneDeep(processedData));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data]);

  /**
   * preprocess Data
   * process fields to manage by group and render data
   * @param data
   * @returns
   */
  const preprocessData = (data: any): IGroupSettingField[] => {
    let result: any = null;
    if (!data) return result;

    result = resultPreprocessData(data, props.data.EditGroups);
    collapseFirstGroup(result);
    return result;
  };

  /**
   * Set the first group to expand default
   * @param resultData
   * @returns
   */
  const collapseFirstGroup = (resultData: IGroupSettingField[]): void => {
    if (!resultData) return;
    let firstGroupId = getIdFirstGroup(resultData);
    if (collapseState) setCollapseState({ [firstGroupId]: true });
  };

  /**
   * Handle Change Input Fields
   * Handle Change Switch
   * @param event
   */
  const handleInputChange = (
    event: any,
    groupId: number,
    formRenderProps: FormRenderProps
  ): void => {
    const { name, value } = event.target;
    let valueField = value;
    if (typeof valueField === "string")
      valueField = value.length === 0 ? null : value;
    const cloneData: IGroupSettingField[] = data.map(
      (group: IGroupSettingField) => {
        if (group.groupId === groupId) {
          group.settingFields.forEach((field: SettingField) => {
            if (field.FieldName === name) field.Value = valueField;
          });
        }
        return group;
      }
    );
    formRenderProps.onChange(name, {
      value: value,
    });
    setData(cloneData);
  };

  /**
   * Handle Change Input Percentage Fields
   * @param event
   */
  const handleInputPercentageChange = (
    event: any,
    groupId: number,
    formRenderProps: FormRenderProps
  ): void => {
    const { name, value } = event.target;
    let valueField: number | undefined;
    if (!isNil(value)) {
      valueField = value * 100;
    }
    const cloneData: IGroupSettingField[] = data.map(
      (group: IGroupSettingField) => {
        if (group.groupId === groupId) {
          group.settingFields.forEach((field: SettingField) => {
            if (field.FieldName === name) field.Value = valueField;
          });
        }
        return group;
      }
    );
    formRenderProps.onChange(name, {
      value: valueField,
    });
    setData(cloneData);
  };

  /**
   *
   * Handle Change List Options
   * @param event
   * @param groupId
   * @param formRenderProps
   */
  const handleListChange = (
    event: any,
    item: SettingField,
    groupId: number,
    formRenderProps: FormRenderProps,
    gridFieldsData: any
  ): void => {
    const dataList = event.every((i: any) => i.IsSelected === false);
    const listCheckValidSettingList = !isNil(checkMandatoryList)
      ? checkMandatoryList
      : {};
    if (dataList && item.IsMandatory)
      setCheckMandatoryList({
        ...listCheckValidSettingList,
        [item.FieldName]: dataList && item.IsMandatory,
      });
    else
      setCheckMandatoryList({
        ...listCheckValidSettingList,
        [item.FieldName]: (dataList && item.IsMandatory) ?? false,
      });

    const dataListItems = event?.filter((i: any) => i.IsSelected === true)?.map((itemList: any) => {
      const removePrefixGridFields = itemList.GridFields.map((field: any) => {
        const fieldName = field.Name.split("-")[1];
        return {
          ...field,
          Name: fieldName,
        };
      });

      return {
        Name: itemList.Name,
        Title: itemList.Title,
        ItemId: itemList.ItemId,
        ProductType_Enum: itemList.ProductType_Enum,
        SettingCategoryName: itemList.SettingCategoryName,
        Mode: itemList.Mode,
        IsNew: itemList?.IsNew,
        IsSelected: itemList.IsSelected,
        IsEditable: itemList.IsEditable,
        IsChildFolder: itemList.IsChildFolder,
        GridFields: removePrefixGridFields,
        EditGroups: itemList.EditGroups,
        SettingFields: itemList.SettingFields,
      };
    }) ?? [];
    let gridFields = gridFieldsData;
    const cloneData: IGroupSettingField[] = data.map(
      (group: IGroupSettingField) => {
        if (group.groupId === groupId) {
          group.settingFields.forEach((field: SettingField) => {
            if (field.FieldName === item.FieldName) {
              const listItems: SettingFolder = field.ListItems;
              gridFields = listItems.GridFields;
              const newListItems = {
                ...listItems,
                GridFields: gridFields,
                SettingsFolders: dataListItems,
              };
              field.ListItems = newListItems;
            }
          });
        }
        return group;
      }
    );
    formRenderProps.onChange(item.FieldName, {
      value: event,
    });
    setData(cloneData);
  };
  /**
   *
   * Handle Change Dropdown Fields
   * @param event
   */
  const handleDropDownChange = (
    event: any,
    groupId: number,
    formRenderProps: FormRenderProps
  ): void => {
    const { name, value } = event.target;
    const cloneData: IGroupSettingField[] | undefined = assignValueOnchange(
      data,
      name,
      value,
      groupId
    );
    if (!cloneData) return;
    formRenderProps.onChange(name, {
      value: value?.Id,
    });
    setData(cloneData);
  };

  /**
   * Handle submit form
   * show notification when users submit
   * @param values
   * @param event
   */
  const handleSubmit = async (values: { [name: string]: any }) => {
    if (Object.keys(values).length === 0) return;
    const groupName = Object.keys(values)[0];
    setIsLoadingSaving((pre) => ({
      ...pre,
      [groupName]: { isLoading: true, groupLoading: groupName },
    }));
    let cloneData: any = [...data];
    cloneData = await Promise.all(
      cloneData.map(async (item: IGroupSettingField) => {
        if (
          (item.groupId === values.GroupId && !isNoneGroup(values.GroupId)) ||
          (item.name === groupName && isNoneGroup(values.GroupId))
        ) {
          const savedData = handleSaveChange(values.GroupId, groupName);
          const resultSubmit = processDataAfterSaving(
            savedData,
            props,
            values.GroupId,
            groupName
          );

          if (!resultSubmit) {
            notificationRef.current?.pushNotification({
              title: `Some thing went wrong`,
              autoClose: false,
              type: "error",
            });
          }
          let dataGroupOrigin: SettingField[] | undefined = undefined;
          let isFailedCallAPI = false;
          if (resultSubmit) {
            const response = await putSetting(resultSubmit);
            if (isSuccessResponse(response) && response?.data) {
              // the setTimeout is for solve the issue: the notification is not shown when use it with ref and async function
              // previous, it only show on the second time or more when notification is push
              if (response?.data?.SettingFields) {
                const result = resultPreprocessData(
                  response?.data?.SettingFields,
                  response?.data?.EditGroups
                );
                if (
                  (item.groupId === values.GroupId &&
                    !isNoneGroup(values.GroupId)) ||
                  (item.name === groupName && isNoneGroup(values.GroupId))
                ) {
                  if (isNoneGroup(values.GroupId)) {
                    item.settingFields = result?.find(
                      (item) => item?.name === groupName
                    )?.settingFields as SettingField[];
                  } else {
                    item.settingFields = result?.find(
                      (item) => item?.groupId === values.GroupId
                    )?.settingFields as SettingField[];
                  }
                }
                setOriginData(cloneDeep(result));
              } else {
                setOriginData(cloneDeep(data));
              }
              setTimeout(() => {
                notificationRef.current?.pushNotification({
                  title: `Updated successfully`,
                  type: "success",
                });
              }, 10);
            } else if (response.status === APIResponseStatus.BAD_REQUEST) {
              const res = response.data;
              const messages = res?.Messages ? res.Messages : res?.Errors ?? [];
              const typeMessage =
                res?.MessageType === "Flash Information"
                  ? "info"
                  : res?.MessageType?.toLowerCase() ?? isSuccessResponse(res)
                    ? "info"
                    : "error";
              const isClose =
                res.MessageType === "Flash Information" ? true : false;
              setTimeout(() => {
                notificationRef.current?.pushNotification({
                  title: res.HeaderMessage,
                  autoClose: isClose,
                  description: messages,
                  type: typeMessage,
                });
              }, 10);
            } else {
              setTimeout(() => {
                notificationRef.current?.pushNotification({
                  title: `Error occurred`,
                  autoClose: false,
                  type: "error",
                });
              }, 10);
            }
            if (!isSuccessResponse(response)) {
              isFailedCallAPI = true;
              const newOriginData = cloneDeep(originData);
              if (
                (item.groupId === values.GroupId &&
                  !isNoneGroup(values.GroupId)) ||
                (item.name === groupName && isNoneGroup(values.GroupId))
              ) {
                if (isNoneGroup(values.GroupId)) {
                  dataGroupOrigin = newOriginData?.find(
                    (item) => item?.name === groupName
                  )?.settingFields;
                } else {
                  dataGroupOrigin = newOriginData?.find(
                    (item) => item?.groupId === values.GroupId
                  )?.settingFields;
                }
              }
            }
          }
          return {
            ...item,
            settingFields:
              isFailedCallAPI && !isNil(dataGroupOrigin)
                ? dataGroupOrigin
                : item.settingFields,
            status: true,
            isEditing: false,
          };
        }
        return { ...item, status: false };
      })
    );

    setIsLoadingSaving((pre) => ({
      ...pre,
      [groupName]: { isLoading: false, groupLoading: groupName },
    }));
    setData(cloneData);
  };

  const isLoadingSavingByGroup = (fieldName: string): boolean | undefined => {
    return (
      isLoadingSaving?.[fieldName]?.isLoading &&
      fieldName === isLoadingSaving?.[fieldName]?.groupLoading
    );
  };

  /**
   * Handle cancel change
   * After clicking cancel button reset form and return previous values
   * @param groupId
   * @param formRenderProps
   */
  const handleCancelChange = (
    groupId: number,
    formRenderProps: FormRenderProps,
    name: string
  ): void => {
    let restoreData: IGroupSettingField[] | undefined;
    const changedDataGroup: IGroupSettingField | undefined = resetDataAGroup(
      groupId,
      name,
      originData
    );
    if (!changedDataGroup) return;
    restoreData = assignChangedGroup(groupId, name, data, changedDataGroup);
    if (!restoreData) return;
    setData(cloneDeep(restoreData));
    formRenderProps.onFormReset();
  };

  /**
   * Handle save change
   * assign new data into originData and reset data is empty array in list options
   * @param groupId
   */
  const handleSaveChange = (
    groupId: number,
    groupName: string
  ): IGroupSettingField[] => {
    let saveData: IGroupSettingField[] | undefined;
    const savedDataGroup: IGroupSettingField | undefined = saveDataAGroup(
      groupId,
      groupName,
      data
    );
    if (!savedDataGroup) return [];
    saveData = assignChangedGroup(groupId, groupName, data, savedDataGroup);
    if (!saveData) return [];
    return saveData;
  };

  /**
   * Check validation input fields
   * check mandatory and max length
   * @param value
   * @param valueGetter
   * @param fieldProps
   * @returns
   */
  const fieldInputValidator = (
    value: string,
    valueGetter: (name: string) => any,
    fieldProps: {
      name: string;
    }
  ): string | undefined => {
    const groupId = valueGetter("GroupId");
    let getGroupSettingField: SettingField[] | undefined;
    getGroupSettingField = groupValidator(groupId, data, fieldProps);
    if (!getGroupSettingField) return undefined;
    const settingField = getGroupSettingField.find(
      (itemFieldInput: SettingField) =>
        itemFieldInput.FieldName === fieldProps.name
    );
    if (!settingField) return undefined;
    return messageInputValidator(settingField, value);
  };

  /**
   * Check validation number input fields
   * check mandatory
   * @param value
   * @param valueGetter
   * @param fieldProps
   * @returns
   */
  const fieldNumericValidator = (
    value: number,
    valueGetter: (name: string) => any,
    fieldProps: {
      name: string;
    }
  ): string | undefined => {
    const groupId = valueGetter("GroupId");
    let getGroupSettingField: SettingField[] | undefined;
    getGroupSettingField = groupValidator(groupId, data, fieldProps);
    if (!getGroupSettingField) return undefined;
    const settingField = getGroupSettingField.find(
      (itemFieldInput: SettingField) =>
        itemFieldInput.FieldName === fieldProps.name
    );
    if (!settingField) return undefined;
    return messageNumericValidator(settingField, value);
  };

  /**
   * Check validation percent input fields
   * check mandatory
   * @param value
   * @param valueGetter
   * @param fieldProps
   * @returns
   */
  const fieldPercentValidator = (
    value: number,
    valueGetter: (name: string) => any,
    fieldProps: {
      name: string;
    }
  ): string | undefined => {
    const groupId = valueGetter("GroupId");
    let getGroupSettingField: SettingField[] | undefined;
    getGroupSettingField = groupValidator(groupId, data, fieldProps);
    if (!getGroupSettingField) return undefined;
    const settingField = getGroupSettingField.find(
      (itemFieldInput: SettingField) =>
        itemFieldInput.FieldName === fieldProps.name
    );
    if (!settingField) return undefined;
    return messagePercentValidator(settingField, value);
  };
  /**
   * Check validation dropdown fields
   * @param value
   * @param valueGetter
   * @param fieldProps
   * @returns
   */
  const fieldDropDownValidator = (
    value: ILookupItem,
    valueGetter: (name: string) => any,
    fieldProps: {
      name: string;
    }
  ): string | undefined => {
    const groupId = valueGetter("GroupId");
    let getGroupSettingField: SettingField[] | undefined;
    getGroupSettingField = groupValidator(groupId, data, fieldProps);
    if (!getGroupSettingField) return;
    const settingField = getGroupSettingField.find(
      (itemFieldInput: SettingField) =>
        itemFieldInput.FieldName === fieldProps.name
    );
    if (!settingField) return;
    return messageDropDownValidator(settingField, value);
  };

  /**
   * Check validation currency fields
   * @param value
   * @param valueGetter
   * @param fieldProps
   * @returns
   */
  const fieldCurrencyValidator = (
    value: number,
    valueGetter: (name: string) => any,
    fieldProps: {
      name: string;
    }
  ): string | undefined => {
    const groupId = valueGetter("GroupId");
    let getGroupSettingField: SettingField[] | undefined;
    getGroupSettingField = groupValidator(groupId, data, fieldProps);
    if (!getGroupSettingField) return;
    const settingField = getGroupSettingField.find(
      (itemFieldInput: SettingField) =>
        itemFieldInput.FieldName === fieldProps.name
    );
    if (!settingField) return;
    return messageCurrencyValidator(settingField, value);
  };

  /**
   * Check validation date fields
   * @param value
   * @param valueGetter
   * @param fieldProps
   * @returns
   */
  const fieldDateValidator = (
    value: number,
    valueGetter: (name: string) => any,
    fieldProps: {
      name: string;
    }
  ): string | undefined => {
    const groupId = valueGetter("GroupId");
    let getGroupSettingField: SettingField[] | undefined;
    getGroupSettingField = groupValidator(groupId, data, fieldProps);
    if (!getGroupSettingField) return;
    const settingField = getGroupSettingField.find(
      (itemFieldInput: SettingField) =>
        itemFieldInput.FieldName === fieldProps.name
    );
    if (!settingField) return;
    return messageDateValidator(settingField, value);
  };

  /**
   * Check validation for list
   * check mandatory and value is not empty
   * @param value
   * @param valueGetter
   * @param fieldProps
   * @returns
   */
  const fieldListValidator = (
    value: SettingFolder[],
    valueGetter: (name: string) => any,
    fieldProps: {
      name: string;
    }
  ): string | undefined => {
    const groupId = valueGetter("GroupId");
    let getGroupSettingField: SettingField[] | undefined;
    getGroupSettingField = groupValidator(groupId, data, fieldProps);
    if (!getGroupSettingField) return;
    const settingField = getGroupSettingField.find(
      (itemFieldInput: SettingField) =>
        itemFieldInput.FieldName === fieldProps.name
    );
    if (!settingField) return;

    const messageError = messageListValidator(settingField, value);
    if (settingField.IsMandatory && messageError && checkHitSaveButton) {
      const listCheckValidSettingList = !isNil(checkMandatoryList)
        ? checkMandatoryList
        : {};
      setCheckMandatoryList({
        ...listCheckValidSettingList,
        [fieldProps.name]: true,
      });
    }
    return messageError;
  };

  /**
   * Generate list option
   * @param item
   * @param groupId
   * @param formRenderProps
   * @returns
   */
  const generateList = (
    item: SettingField,
    groupId: number,
    formRenderProps: FormRenderProps
  ): ReactElement => {
    const props_values_List = propsValuesList({
      item,
      groupId,
      formRenderProps,
      data,
    });
    const props_handle_List = {
      onChangeCustom: ({ event, fieldsData }: any) =>
        handleListChange(event, item, groupId, formRenderProps, fieldsData),
      setCheckMandatoryList,
      checkMandatoryList,
      handleOnCancel: () =>
        handleCancelChange(groupId, formRenderProps, item.FieldName),
      handleOnEdit: () => handleEditChange(groupId, true, item.FieldName),
    };

    /**
     * Dialog child setting manage form: step 1
     * handle click
     */
    /**
     * TODO
     */

    const props_List = {
      ...props_values_List,
      ...props_handle_List,
    };

    return (
      <Field
        {...props_List}
        component={SettingListOption}
        validator={fieldListValidator}
      />
    );
  };

  /**
   * generate Input
   * @param item
   * @param groupId
   * @param formRenderProps
   * @returns
   */
  const generateInput = (
    item: SettingField,
    groupId: number,
    formRenderProps: FormRenderProps
  ): ReactElement => {
    const isLoading = isLoadingSavingByGroup(item.FieldName);
    const props_values_Input = propsValuesInput({
      item,
      groupId,
      formRenderProps,
      data,
      isLoading,
    });
    const props_handle_Input = {
      onChange: (event: any) =>
        handleInputChange(event, groupId, formRenderProps),
      handleOnCancel: () =>
        handleCancelChange(groupId, formRenderProps, item.FieldName),
      handleOnEdit: () => handleEditChange(groupId, true, item.FieldName),
    };
    const props_Input = {
      ...props_values_Input,
      ...props_handle_Input,
    };

    return (
      <Field
        {...props_Input}
        validator={fieldInputValidator}
        component={SettingTextInput}
      />
    );
  };

  /**
   * generate Numeric
   * @param item
   * @param groupId
   * @param formRenderProps
   * @returns
   */
  const generateNumeric = (
    item: SettingField,
    groupId: number,
    formRenderProps: FormRenderProps
  ): ReactElement => {
    const isLoading = isLoadingSavingByGroup(item.FieldName);
    const props_values_Numeric = propsValuesNumeric({
      item,
      groupId,
      formRenderProps,
      data,
      isLoading,
    });
    const props_handle_Numeric: any = {
      onChange: (event: any) => {
        if (item.DataType === SettingsDataType.Percent) {
          handleInputPercentageChange(event, groupId, formRenderProps);
        } else {
          handleInputChange(event, groupId, formRenderProps);
        }
      },
      handleOnCancel: () =>
        handleCancelChange(groupId, formRenderProps, item.FieldName),
      handleOnEdit: () => handleEditChange(groupId, true, item.FieldName),
    };

    const props_Numeric = {
      ...props_values_Numeric,
      ...props_handle_Numeric,
    };
    if (item.DataType === SettingsDataType.Percent) {
      return (
        <Field
          {...props_Numeric}
          validator={fieldPercentValidator}
          component={SettingPercentInput}
        />
      );
    }

    return (
      <Field
        {...props_Numeric}
        validator={fieldNumericValidator}
        component={SettingNumericInput}
      />
    );
  };

  /**
   * generate Date and DateTime
   * @param item
   * @param groupId
   * @param formRenderProps
   * @returns
   */
  const generateDate = (
    item: SettingField,
    groupId: number,
    formRenderProps: FormRenderProps
  ): ReactElement => {
    const isLoading = isLoadingSavingByGroup(item.FieldName);
    const props_values_Date = propsValuesDate({
      item,
      groupId,
      formRenderProps,
      data,
      isLoading,
    });
    const props_handle_Date = {
      onChange: (event: any) =>
        handleInputChange(event, groupId, formRenderProps),
      handleOnCancel: () =>
        handleCancelChange(groupId, formRenderProps, item.FieldName),
      handleOnEdit: () => handleEditChange(groupId, true, item.FieldName),
    };
    const props_Date = {
      ...props_values_Date,
      ...props_handle_Date,
    };
    let component;
    if (item.DataType === SettingsDataType.Date) component = SettingDateInput;
    else component = SettingDateTimeInput;

    return (
      <Field
        {...props_Date}
        validator={fieldDateValidator}
        component={component}
      />
    );
  };

  /**
   * generate switch
   * @param item
   * @param groupId
   * @param formRenderProps
   * @returns
   */
  const generateSwitch = (
    item: SettingField,
    groupId: number,
    formRenderProps: FormRenderProps
  ): ReactElement => {
    const isLoading = isLoadingSavingByGroup(item.FieldName);
    const props_values_Switch = propsValuesSwitch({
      item,
      groupId,
      formRenderProps,
      data,
      isLoading,
    });
    const props_handle_Switch = {
      onChange: (event: any) =>
        handleInputChange(event, groupId, formRenderProps),
      handleOnCancel: () =>
        handleCancelChange(groupId, formRenderProps, item.FieldName),
      handleOnEdit: () => handleEditChange(groupId, true, item.FieldName),
    };
    const props_Switch = {
      ...props_values_Switch,
      ...props_handle_Switch,
    };
    return <Field {...props_Switch} component={SettingSwitchInput} />;
  };

  /**
   * generate Dropdown
   * @param item
   * @param groupId
   * @param formRenderProps
   * @returns
   */
  const generateDrop = (
    item: SettingField,
    groupId: number,
    formRenderProps: FormRenderProps
  ): ReactElement => {
    const isLoading = isLoadingSavingByGroup(item.FieldName);
    const props_values_Drop = propsValuesDrop({
      item,
      groupId,
      formRenderProps,
      data,
      isLoading,
    });
    const props_handle_Drop = {
      onChange: (event: any) =>
        handleDropDownChange(event, groupId, formRenderProps),
      handleOnCancel: () =>
        handleCancelChange(groupId, formRenderProps, item.FieldName),
      handleOnEdit: () => handleEditChange(groupId, true, item.FieldName),
    };
    const props_Drop = {
      ...props_values_Drop,
      ...props_handle_Drop,
      isLoading,
    };

    return (
      <Field
        {...props_Drop}
        component={SettingComboboxList}
        validator={fieldDropDownValidator}
      />
    );
  };

  /**
   * generate Currency
   * @param item
   * @param groupId
   * @param formRenderProps
   * @returns
   */
  const generateCurrency = (
    item: SettingField,
    groupId: number,
    formRenderProps: FormRenderProps
  ): ReactElement => {
    const isLoading = isLoadingSavingByGroup(item.FieldName);
    const props_values_Currency = propsValuesCurrency({
      item,
      groupId,
      formRenderProps,
      data,
      isLoading,
    });
    const props_handle_Currency = {
      onChange: (event: any) =>
        handleInputChange(event, groupId, formRenderProps),
      handleOnCancel: () =>
        handleCancelChange(groupId, formRenderProps, item.FieldName),
      handleOnEdit: () => handleEditChange(groupId, true, item.FieldName),
    };
    const props_Currency = {
      ...props_values_Currency,
      ...props_handle_Currency,
    };

    return (
      <Field
        {...props_Currency}
        component={SettingCurrencyInput}
        validator={fieldCurrencyValidator}
      />
    );
  };

  /**
   * Function use for generate dynamic field types
   * Prop for fields to pass value
   * @param item
   * @returns
   */
  const getForm = (
    item: SettingField,
    groupId: number,
    formRenderProps: FormRenderProps
  ): ReactElement | null => {
    if (!item.IsVisible) return null;
    switch (item.DataType) {
      case SettingsDataType.List:
        return generateList(item, groupId, formRenderProps);
      case SettingsDataType.String:
        return generateInput(item, groupId, formRenderProps);
      case SettingsDataType.Boolean:
        return generateSwitch(item, groupId, formRenderProps);
      case SettingsDataType.Lookup:
        return generateDrop(item, groupId, formRenderProps);
      case SettingsDataType.Int:
      case SettingsDataType.Decimal:
      case SettingsDataType.Percent:
        return generateNumeric(item, groupId, formRenderProps);
      case SettingsDataType.Date:
      case SettingsDataType.DateTime:
        return generateDate(item, groupId, formRenderProps);
      case SettingsDataType.Money:
        return generateCurrency(item, groupId, formRenderProps);
    }
    return null;
  };

  /**
   * Handle edit change
   * @param groupId
   * @param state
   */
  const handleEditChange = (
    groupId: number,
    stateEdit: boolean,
    name: string
  ) => {
    const cloneData: IGroupSettingField[] = setStateEditChange(
      groupId,
      stateEdit,
      name,
      data
    );
    setData(cloneData);
  };

  const checkBeforeElementNoneGroup = (id: number) => {
    const position = id - 1;
    if (position >= 0 && position < data.length - 1) {
      const beforeElement = data[position];
      if (beforeElement.groupId === -1) return true;
    }
    return false;
  };

  const checkNextElementNoneGroup = (
    id: number,
    element: IGroupSettingField
  ) => {
    listFieldNoneGroup.push(element);
    const position = id + 1;
    if (position < data.length) {
      const nextElement = data[id + 1];
      if (nextElement.groupId === -1) {
        return true;
      }
    }
    return false;
  };

  if (!data) return <Loading isLoading isFullScreen />;
  return (
    <div className="cc-exist-setting-manage-form">
      {isNotificationNew && isExistAfterCreated && <CCAppNotification />}
      {data &&
        data.map((element: IGroupSettingField, index: number) => {
          const isGroupLoading = isLoadingSavingByGroup(
            element?.settingFields?.[0]?.FieldName
          );
          if (element.groupId !== -1) {
            if (checkBeforeElementNoneGroup(index)) listFieldNoneGroup = [];
            return (
              <div key={index} className="cc-setting-element-form">
                <Form
                  key={index}
                  onSubmit={handleSubmit}
                  initialValues={getFormInitialValue(
                    element.groupId,
                    element.name,
                    data
                  )}
                  render={(formRenderProps: FormRenderProps) => (
                    <FormElement>
                      <CCSettingPanel
                        isExpanded={collapseState[element.groupId]}
                        isInvalid={
                          !formRenderProps.valid && formRenderProps.modified
                        }
                        handleDivButton={() =>
                          setCollapseState({
                            ...collapseState,
                            [element.groupId]: !collapseState[element.groupId],
                          })
                        }
                        isBoldCollapse={element.groupId === 1}
                        title={element.name}
                        buttonGroupElement={
                          <>
                            <Button
                              className={`${
                                element.isEditing || isGroupLoading
                                  ? "cc-edit-hidden "
                                  : " "
                                }cc-manage-button`}
                              onClick={(e: any) => {
                                formRenderProps.onFormReset();
                                e.stopPropagation();
                                e.preventDefault();
                                handleEditChange(
                                  element.groupId,
                                  true,
                                  element.name
                                );
                                setCollapseState({
                                  ...collapseState,
                                  [element.groupId]: true,
                                });
                              }}
                              iconClass="fa fa-pencil"
                              fillMode="flat"
                              title="Edit"
                            />
                            {(element.isEditing || isGroupLoading) && (
                              <>
                                <Button
                                  className={`cc-save-button${
                                    !formRenderProps.valid ||
                                      !formRenderProps.modified ||
                                      checkMandatoryList?.[element.groupId]
                                      ? " cc-button-disabled"
                                      : " cc-button-enabled"
                                    }`}
                                  disabled={
                                    !formRenderProps.valid ||
                                      isGroupLoading ||
                                      !formRenderProps.modified ||
                                      checkMandatoryList?.[element.groupId]
                                      ? true
                                      : false
                                  }
                                  id="btn-save"
                                  onClick={(e: any) => {
                                    e.stopPropagation();
                                    formRenderProps.onSubmit(e);
                                    formRenderProps.onFormReset();
                                    setCheckHitSaveButton(true);
                                  }}
                                  iconClass={
                                    isGroupLoading
                                      ? "fas fa-spinner fa-spin"
                                      : "fa fa-check-circle"
                                  }
                                  fillMode="flat"
                                  title="Save"
                                />

                                <Button
                                  className="cc-cancel-button"
                                  onClick={(e: any) => {
                                    e.stopPropagation();
                                    handleCancelChange(
                                      element.groupId,
                                      formRenderProps,
                                      element.name
                                    );
                                  }}
                                  disabled={isGroupLoading}
                                  iconClass="fa fa-times-circle"
                                  fillMode="flat"
                                  title="Cancel"
                                />
                              </>
                            )}
                          </>
                        }
                      >
                        <>
                          {element.status && (
                            <>
                              <CCLocalNotification
                                ref={notificationRef}
                                key={index}
                              />
                            </>
                          )}
                          {isGroupLoading ? (
                            <Loading isLoading />
                          ) : (
                              <div className="cc-cols-1">
                                {element.settingFields?.map(
                                  (i: SettingField, id: number) => {
                                    if (!i.IsVisible) return null;
                                    else if (
                                      i.DataType &&
                                      i.DataType === SettingsDataType.Label
                                    )
                                      return (
                                        <div key={id} className="cc-col-span-3">
                                          <label className="cc-label">
                                            {i.Title}
                                          </label>
                                        </div>
                                      );
                                    return (
                                      <div
                                        key={id}
                                        className={`${
                                          i.IsBigField
                                            ? "cc-col-span-3"
                                            : "cc-col-span-1"
                                          }`}
                                      >
                                        {getForm(
                                          i,
                                          element.groupId,
                                          formRenderProps
                                        )}
                                      </div>
                                    );
                                  }
                                )}
                              </div>
                            )}
                        </>
                      </CCSettingPanel>
                    </FormElement>
                  )}
                />
              </div>
            );
          }
          if (!checkBeforeElementNoneGroup(index)) listFieldNoneGroup = [];
          if (checkNextElementNoneGroup(index, element)) return null;
          return (
            <div className="cc-none-group">
              <div className="cc-cols-1 ">
                {listFieldNoneGroup.map(
                  (itemField: IGroupSettingField, p: number) => {
                    return (
                      <div
                        key={p}
                        className={`${
                          itemField.settingFields[0].IsBigField
                            ? "cc-col-span-3"
                            : "cc-col-span-1"
                          }`}
                      >
                        <Form
                          onSubmit={handleSubmit}
                          initialValues={getFormInitialValue(
                            itemField.groupId,
                            itemField.name,
                            data
                          )}
                          render={(formRenderProps: FormRenderProps) => (
                            <FormElement>
                              {itemField.status && (
                                <>
                                  <CCLocalNotification
                                    ref={notificationRef}
                                    key={index}
                                  />
                                </>
                              )}
                              {isGroupLoading ? (
                                <Loading isLoading />
                              ) : (
                                  itemField.settingFields?.map(
                                    (i: any, id: number) => {
                                      if (!i.IsVisible) return null;
                                      else if (
                                        i.DataType &&
                                        i.DataType === DATA_TYPE.LABEL
                                      )
                                        return (
                                          <label key={id} className="cc-label">
                                            {i.Title}
                                          </label>
                                        );
                                      return (
                                        <div key={id}>
                                          {getForm(
                                            i,
                                            itemField.groupId,
                                            formRenderProps
                                          )}
                                        </div>
                                      );
                                    }
                                  )
                                )}
                            </FormElement>
                          )}
                        />
                      </div>
                    );
                  }
                )}
              </div>
            </div>
          );
        })}
      {isShowDialogExit && (
        <ConfirmExit
          onClose={() => setIsShowDialogExit(false)}
          onClick={() => {
            setIsShowDialogExit(false);
            setConfirmExit(false);
          }}
        />
      )}
    </div>
  );
};

export default observer(ExistedSettingManage);
