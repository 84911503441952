import { fieldName } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/change-of-ownership/components/form-elements/names/config";
import { DTO_Entity_Details } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/change-of-ownership/model";
import { getUUID, nameOfFactory } from "@common/utils/common";
import { trimSpace } from "@common/utils/formatting";
import { cloneDeep, includes, isArray, map } from "lodash";

const nameOfNames = nameOfFactory<DTO_Entity_Details>();

export const validatorFullPercentages = (values: any) => {
  if (!values || getTotalPercentages(values) !== 100)
    return "Please complete form.";
  return undefined;
};

export const validatorArrayIsNotEmpty = (values: any) => {
  if (values?.length) return undefined;
  return "Please complete form.";
};

export const validatorRoleIsRequired = (values: any) => {
  return includes(map(values, nameOfNames("Role_Name")), undefined)
    ? "A contact must be assigned a role."
    : "";
};

const getTotalPercentages = (values: any) => {
  if (!isArray(values)) return;
  return parseFloat(
    values
      .reduce(
        (total: number, name: any) =>
          name[fieldName.Percentage]
            ? total + name[fieldName.Percentage]
            : total,

        0
      )
      .toFixed(4)
  );
};

enum EPartialName {
  Title = "<Title>",
  Initials = "<Initials>",
  Initial = "<Initial>",
  Surname = "<SURNAME>",
  FirstName = "<First Name>",
  GivenNames = "<Given Names>",
}

const getInitialsName = (name?: string) => {
  if (!name) return "";
  const words = name.trim().split(/\s+/);
  const initials = words.map((word) => word.charAt(0).toUpperCase()).join(" ");
  return initials;
};

/**
 * Retrieves the format mask from the given setting option.
 *
 * @param settingOption - The setting option string.
 * `<Title> <Initials> <SURNAME> eg. Mr J D & Mrs S SMITH`
 * @returns The format mask extracted from the setting option.
 * `<Title> <Initials> <SURNAME>`
 */
export const getFormatMask = (settingOption: string) => {
  const lastIndexOfFormatString = settingOption.indexOf(" eg");
  return settingOption.substring(0, lastIndexOfFormatString);
};

/**
 * Formats a name based on the provided format mask and entity details.
 * @param name - The entity details containing the name information.
 * @param formatMask - The format mask to be applied to the name.
 * @returns The formatted name.
 */
const formatOneName = (name: DTO_Entity_Details, formatMask: string) => {
  const connectionTwoFormatIndex = formatMask.indexOf("> & <");
  const newFormatMask = formatMask.substring(0, connectionTwoFormatIndex + 1);
  let formattedName = connectionTwoFormatIndex > 0 ? newFormatMask : formatMask;
  formattedName = formattedName.replace(EPartialName.Title, name?.Title ?? "");
  const givenNameWords = name.GivenName?.split(" ") ?? "";
  const firstName = givenNameWords?.[0] ?? "";
  const lastName = name.GivenName?.replace(firstName, "") ?? "";
  formattedName = formattedName.replace(EPartialName.FirstName, firstName);
  formattedName = formattedName.replace(
    EPartialName.Initial,
    getInitialsName(lastName)
  );
  formattedName = formattedName.replace(
    EPartialName.Initials,
    getInitialsName(name?.GivenName ?? "")
  );
  formattedName = formattedName.replace(
    EPartialName.GivenNames,
    name?.GivenName ?? ""
  );
  formattedName = formattedName.replace(
    EPartialName.Surname,
    name?.Surname?.toUpperCase() ?? ""
  );
  return trimSpace(formattedName.trim());
};

/**
 * Formats an array of names according to the specified format mask.
 *
 * @param names - The array of names to be formatted.
 * @param formatMask - The format mask to be applied to the names.
 * @returns The formatted names as a string.
 */
export const formatNames = (
  names: DTO_Entity_Details[],
  formatMask: string
) => {
  let formattedNames = "";
  const initNames = cloneDeep(names);
  for (let i = 0; i < initNames.length; i++) {
    formattedNames += formatOneName(initNames[i], formatMask);
    const surnameInLastRegex = new RegExp(`${initNames[i].Surname}$`, "gi");
    const surnameInFirstRegex = new RegExp(
      `^${initNames[i].Surname},{0,1}`,
      "gi"
    );
    const lastIndexOfSurname = formatMask.lastIndexOf(EPartialName.Surname);
    for (let j = i + 1; j < initNames.length; j++) {
      if (
        initNames[i].Surname?.toLowerCase() ===
        initNames[j].Surname?.toLowerCase()
      ) {
        switch (lastIndexOfSurname) {
          case 0:
            formattedNames = formatOneName(initNames[j], formatMask).replace(
              surnameInFirstRegex,
              `${formattedNames} &`
            );
            break;
          case formatMask.length - EPartialName.Surname.length:
            formattedNames = formattedNames.replace(
              surnameInLastRegex,
              `& ${formatOneName(initNames[j], formatMask)}`
            );
            break;
          default:
            formattedNames += " & " + formatOneName(initNames[j], formatMask);
        }
        initNames.splice(j, 1);
        j--;
      }
    }
    if (i !== initNames.length - 1) {
      formattedNames += " & ";
    }
  }
  return formattedNames;
};

export const addUniqueKey = (names: DTO_Entity_Details[]) => {
  return names.map((name) => {
    return {
      ...name,
      [fieldName.UniqueKey]: getUUID(),
    };
  });
};
