import { FormatNameAndAddressDialog } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/change-of-ownership/components/dialogs/format-name-and-address/_index";
import {
  changeOfOwnershipNameStepTooltips,
  colNames,
  colNamesHiddenPercenCol,
  fieldName,
} from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/change-of-ownership/components/form-elements/names/config";
import {
  validatorArrayIsNotEmpty,
  validatorFullPercentages,
  validatorRoleIsRequired,
} from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/change-of-ownership/components/form-elements/names/util";
import { eChangeOfOwnershipType } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/change-of-ownership/components/form-elements/type/model";
import { useFormatNameStep } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/change-of-ownership/hooks/useChangeOfOwnership";
import {
  DTO_Entity_Details,
  EKeysOfSteps,
} from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/change-of-ownership/model";
import { useChangeOfOwnershipDialogStore } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/change-of-ownership/store";
import { isDisabledByType } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/change-of-ownership/util";
import { SearchNameCombobox } from "@app/products/property/components/fields/search-name/_index";
import { loadNameDetail } from "@app/products/property/components/fields/search-name/api";
import { EAddNameMode } from "@app/products/property/components/fields/search-name/model";
import {
  ISearchNameProcessParams,
  handleSearchNameProcess,
} from "@app/products/property/components/fields/search-name/util";
import { ECustomColNameProperty } from "@app/products/property/config";
import { DTO_Workflow_CreateContact } from "@app/products/property/contacts-central-names/list/components/dialogs/new-contact/model";
import { UpdateContactDialog } from "@app/products/property/contacts-central-names/list/components/dialogs/update-contact/_index";
import { ResponseMessage } from "@app/products/property/model";
import { isSuccessResponse } from "@common/apis/util";
import { ContactLookahead_JSON } from "@common/models/contact";
import { Label } from "@common/stores/products/config";
import { getUUID } from "@common/utils/common";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCGrid } from "@components/cc-grid/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { moveGridRow } from "@components/cc-grid/util";
import { CCLabel } from "@components/cc-label/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { CCTooltip, ICCTooltipProps } from "@components/cc-tooltip/_index";
import { Button } from "@progress/kendo-react-buttons";
import { Field, FieldArray } from "@progress/kendo-react-form";
import { lowerFirst } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useCallback, useMemo, useState } from "react";
import { useEffectOnce } from "react-use";
import "./_index.scss";

export const NamesFormStep = (props: IFormStepElement) => {
  const newValidator = useCallback(
    (value: any, valueGetter: (name: string) => any) => {
      if (props?.options?.isReadOnly) return undefined;
      const hasPercentCol = valueGetter(
        `${EKeysOfSteps.Type}._option.Type.COOT_Display_Percentage`
      );
      if (hasPercentCol)
        return (
          validatorFullPercentages(value) || validatorRoleIsRequired(value)
        );
      return validatorArrayIsNotEmpty(value) || validatorRoleIsRequired(value);
    },
    [props?.options]
  );

  return (
    <FieldArray
      name={props.nameOf()}
      {...props}
      component={FormStepElement}
      validator={newValidator}
    />
  );
};

const nameOf = (field: string) => {
  return `${EKeysOfSteps.Owners}.${field}`;
};

const FormStepElement = observer(
  ({
    formRenderProps,
    localNotificationRef,
    options = {
      isReadOnly: false,
      assessmentId: null,
      contactId: null,
    },
  }: IFormStepElement) => {
    const { valueGetter, onChange } = formRenderProps;
    const getFieldValue = (name: string) => valueGetter(nameOf(name));
    const { changeOfOwnershipLOVs, setDynamicRoles } =
      useChangeOfOwnershipDialogStore();
    const [formatNameAddressDialogMode, setFormatNameAddressDialogMode] =
      useState<EKeysOfSteps | undefined>();

    const {
      selectedTypeID,
      isAssociatedType,
      isPICType,
      isDisplayOwner,
      selectedType,
      rolesBySelectedType,
      calculatePercentage,
      handleChangeNamesGrid,
      checkPercentageRoles,
      setFormatNameAddressForStep,
    } = useFormatNameStep(
      changeOfOwnershipLOVs,
      onChange,
      valueGetter,
      options?.setIsLoadingStep
    );

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [updateEntityData, setUpdateEntityData] = useState<any>();
    const namesGridData = valueGetter(EKeysOfSteps.NamesGridData) || [];
    const selectedName = getFieldValue(fieldName.NameSelected);

    //Get label
    const ratepayersLabel = Label.CommunityProperty.getLabel(
      ECustomColNameProperty.Ratepayers
    );

    //Disable certain fields for the Change_of_Name_Address type.
    const isDisableByTypeChangeOfNameAddress = useMemo(() => {
      return isDisabledByType(
        selectedTypeID,
        eChangeOfOwnershipType.Change_of_Name_Address
      );
    }, [selectedTypeID]);

    useEffectOnce(() => {
      setDynamicRoles(rolesBySelectedType);
    });

    const handleLoadNameDetail = async (
      entityId: number,
      mode: EAddNameMode
    ) => {
      const response = await loadNameDetail(entityId);
      if (isSuccessResponse(response)) {
        const contactDetail = response?.data?.Entity_Name_Address;
        const newContact: DTO_Entity_Details = {
          Entity_Id: entityId,
          Entity_Name_Address_Id: contactDetail?.Entity_Name_Address_Id,
          Percentage: 0,
          Name: contactDetail?.Name,
          Address: contactDetail?.Full_Address ?? "",
          Surname: contactDetail?.Surname ?? "",
          GivenName: contactDetail?.GivenName ?? "",
          Title: contactDetail?.Title ?? "",
          isnew: mode === EAddNameMode.New,
          isFromRetainNames: false,
          Id: getUUID(),
        };
        let newNames;
        if (mode === EAddNameMode.Update) {
          newNames = namesGridData?.map((item: DTO_Entity_Details) => {
            if (item?.Entity_Id === entityId) {
              return {
                ...newContact,
                //Get Role Name from existing item
                Role_Name: item?.Role_Name ?? "",
              };
            }
            return item;
          });
        } else {
          newNames = [...namesGridData, newContact];
        }
        newNames = calculatePercentage(newNames);
        handleChangeNamesGrid(newNames);
      } else {
        localNotificationRef?.current?.pushNotification({
          title: response?.error ?? "Name could not be added",
          type: "error",
          autoClose: false,
        });
      }
    };

    const checkExistedEntity = (entityId: number | null): boolean => {
      return (
        namesGridData &&
        namesGridData?.some(
          (contactItem: DTO_Entity_Details) =>
            contactItem?.Entity_Id === entityId
        )
      );
    };

    const handleAddName = async (
      value: ContactLookahead_JSON,
      mode: EAddNameMode
    ) => {
      const entityIdInt = parseInt(value.Entity_Id);
      let existingProcess = undefined;
      const isExistingEntity =
        value?.Entity_Id &&
        mode === EAddNameMode.Existing &&
        checkExistedEntity(entityIdInt);

      if (isExistingEntity) {
        existingProcess = () => {
          localNotificationRef?.current?.pushNotification({
            title: `${value?.DisplayName} already exists`,
            type: "warning",
          });
        };
      }
      const dataProcess: ISearchNameProcessParams = {
        existingProcess,
        data: value,
        handleLoadNameDetail: async (data: any) => {
          if (checkExistedEntity(data?.Entity_Id)) {
            localNotificationRef?.current?.pushNotification({
              title: `${data?.Name ?? data?.DisplayName} already exists`,
              type: "warning",
            });
            return;
          }
          await handleLoadNameDetail(data?.Entity_Id, mode);
        },
        notificationAction: {
          canNotAddName: (response) => {
            localNotificationRef?.current?.pushNotification({
              title: response?.error ?? "Name could not be added",
              type: "error",
              autoClose: false,
            });
          },
        },
        setLoading: (isLoading: boolean) => {
          setIsLoading(isLoading);
        },
      };
      await handleSearchNameProcess(dataProcess);
    };

    const handleGridSelectionChange = (dataItem: any, field: string) => {
      if (options?.isReadOnly) return; //Do nothing while in readOnly mode
      let newSelected = dataItem ? dataItem[0] : undefined;
      onChange(nameOf(field), {
        value: newSelected,
      });
    };

    const handleRemoveName = async (name: any) => {
      if (name) {
        let newNames = namesGridData.filter(
          (item: any) => item[fieldName.UniqueKey] !== name[fieldName.UniqueKey]
        );
        handleGridSelectionChange([], fieldName.NameSelected);
        if (checkPercentageRoles(name[fieldName.Role])) {
          newNames = calculatePercentage(newNames);
        }
        await handleChangeNamesGrid(newNames);
      }
    };

    const handleNameInfoChange = async (field: string, value: any) => {
      let isCalculatedPercentage = false;

      if (selectedName) {
        let newNames = namesGridData.map((name: any) => {
          if (name[fieldName.UniqueKey] !== selectedName[fieldName.UniqueKey])
            return name;
          if (checkPercentageRoles(name[fieldName.Role])) {
            isCalculatedPercentage = true;
          }
          name[field] = value;
          return name;
        });
        if (field === fieldName.Role) {
          if (checkPercentageRoles(value) || isCalculatedPercentage) {
            newNames = calculatePercentage(newNames);
          }
        }
        handleChangeNamesGrid(newNames, field === fieldName.Role);
      }
    };

    const renderNamesColumn = (cols: IColumnFields[]) => {
      return cols.map((col: IColumnFields) => {
        switch (col.field) {
          case fieldName.Name:
            // Do nothing while in readOnly mode
            if (!options?.isReadOnly) {
              col.handleOnClick = (dataItem: any) => {
                onChange(nameOf(fieldName.NameSelected), {
                  value: dataItem,
                });
                setUpdateEntityData(dataItem);
              };
            }
            break;
          case fieldName.Percentage:
            col.disableEditField = (dataItem: any) => {
              return !checkPercentageRoles(dataItem?.Role_Name);
            };
            break;
          case fieldName.Role:
            // Task [14716]: type = "Change of Name\Address" the Role needs to be locked.
            col.editable = isDisableByTypeChangeOfNameAddress ? false : true;
            break;
          default:
            break;
        }
        return col;
      });
    };

    const handleSubmitNewContactDialog = (
      _payload: DTO_Workflow_CreateContact,
      responseMessage: ResponseMessage
    ) => {
      if (responseMessage?.IsSuccess && responseMessage?.Component_ID) {
        handleAddName(
          {
            Entity_Id: responseMessage.Component_ID + "",
          } as ContactLookahead_JSON,
          EAddNameMode.New
        );
      } else {
        localNotificationRef?.current?.pushNotification({
          title: responseMessage?.ErrorMessage ?? "Name could not be added",
          type: "error",
          autoClose: false,
        });
      }
    };

    return (
      <>
        <section className="cc-field-group cc-change-of-ownership-names-step">
          <div className="cc-form-cols-2">
            <SearchNameCombobox
              name={nameOf(fieldName.SearchName)}
              onSelectionChange={(values) =>
                handleAddName(values, EAddNameMode.Existing)
              }
              /**
               * Currently, Disable by readOnly mode
               * Task [14716]: Lock search with type Change_of_Name_Address
               */
              disabled={
                options?.isReadOnly || isDisableByTypeChangeOfNameAddress
              }
              selectedSearchOptions={getFieldValue("SearchOptions")}
              onError={(error: any) => {
                localNotificationRef?.current?.pushNotification({
                  type: "error",
                  title: error ?? "Search name error",
                  autoClose: false,
                });
              }}
              onSubmitNewContactDialog={handleSubmitNewContactDialog}
            />
            {/* [13524] Contact - Workflow dialog contact lookup:
            -  Search options – hide for all (not just LLS) */}
            {/* <div className="cc-field">
              <label className="cc-label">Search options</label>
              <Field
                name={nameOf("SearchOptions")}
                textField={nameOfLov("Name")}
                dataItemKey={nameOfLov("Code")}
                data={listSearchOptions}
                component={CCMultiSelectDropdown}
                disabled={options?.isReadOnly}
              />
            </div> */}
          </div>

          <div className="cc-form-cols-1">
            <div className="cc-field">
              <label className="cc-label">
                Names
                <div className="cc-name-tooltip-group">
                  {changeOfOwnershipNameStepTooltips.map(
                    (tooltipProps: ICCTooltipProps, index: number) => {
                      return (
                        <CCTooltip
                          {...tooltipProps}
                          key={`${index} - ${tooltipProps.content}`}
                        />
                      );
                    }
                  )}
                </div>
              </label>

              <CCGrid
                className="cc-owners-change-of-ownership"
                data={namesGridData || []}
                selectedRows={selectedName ? [selectedName] : undefined}
                columnFields={
                  selectedType?.COOT_Display_Percentage
                    ? renderNamesColumn(colNames)
                    : renderNamesColumn(colNamesHiddenPercenCol)
                }
                isLoading={isLoading}
                readOnly={options?.isReadOnly}
                selectableMode="single"
                onSelectionChange={(dataItem: any) => {
                  handleGridSelectionChange(dataItem, fieldName.NameSelected);
                }}
                primaryField={fieldName.UniqueKey}
                editableMode="cell"
                onDataRowChange={(_dataRow, fieldChange, valueChange) => {
                  if (
                    fieldChange === fieldName.Percentage &&
                    !checkPercentageRoles(selectedName?.Role_Name)
                  )
                    handleNameInfoChange(fieldChange, null);
                  else {
                    handleNameInfoChange(fieldChange, valueChange);
                  }
                }}
                toolbar={
                  <div className="cc-grid-tools-bar">
                    <Button
                      iconClass="fas fa-minus"
                      title="Remove Entity from list"
                      disabled={!selectedName}
                      onClick={() => {
                        handleRemoveName(selectedName);
                      }}
                    />
                    <Button
                      disabled={!selectedName}
                      iconClass="fas fa-chevron-up"
                      title="Move up"
                      onClick={() => {
                        const newNames = moveGridRow({
                          selectedItem: selectedName,
                          gridData: namesGridData,
                          direction: "up",
                          primaryField: fieldName.UniqueKey,
                        });
                        handleChangeNamesGrid(newNames);
                      }}
                    />
                    <Button
                      disabled={!selectedName}
                      iconClass="fas fa-chevron-down"
                      title="Move down"
                      onClick={() => {
                        const newNames = moveGridRow({
                          selectedItem: selectedName,
                          gridData: namesGridData,
                          direction: "down",
                          primaryField: fieldName.UniqueKey,
                        });
                        handleChangeNamesGrid(newNames);
                      }}
                    />
                  </div>
                }
              />
            </div>
            {isDisplayOwner || isPICType ? (
              <div className="cc-field">
                <div className="cc-label-control-between">
                  <CCLabel
                    title={`Formatted ${
                      isPICType ? "managers" : "owners"
                    } name and address`}
                  />
                  <div>
                    {!isPICType && (
                      <Button
                        fillMode="flat"
                        themeColor="primary"
                        className={"cc-edit-field-button"}
                        onClick={() => {
                          setFormatNameAddressForStep(
                            EKeysOfSteps.RatePayers,
                            getFieldValue("")
                          );
                        }}
                        title={`Clone to ${ratepayersLabel} name and address`}
                        iconClass="fa fa-arrow-down"
                        disabled={options?.isReadOnly}
                      />
                    )}
                    <Button
                      fillMode="flat"
                      themeColor="primary"
                      className={"cc-edit-field-button"}
                      onClick={() => {
                        setFormatNameAddressDialogMode(EKeysOfSteps.Owners);
                      }}
                      title="Edit Formatted Owners Name and Address"
                      iconClass="fa fa-edit"
                      disabled={options?.isReadOnly}
                    />
                  </div>
                </div>
                <Field
                  name={nameOf(fieldName.FormattedNameAddress)}
                  rows={4}
                  readOnly
                  component={CCTextArea}
                />
              </div>
            ) : null}

            {!(isPICType || isAssociatedType) ? (
              <>
                <div className="cc-field">
                  <div className="cc-label-control-between">
                    <CCLabel title={`${ratepayersLabel} name and address`} />
                    <Button
                      fillMode="flat"
                      themeColor="primary"
                      className={"cc-edit-field-button"}
                      onClick={() => {
                        setFormatNameAddressDialogMode(EKeysOfSteps.RatePayers);
                      }}
                      title={`Edit ${ratepayersLabel} Name and Address`}
                      iconClass="fa fa-edit"
                      disabled={options?.isReadOnly}
                    />
                  </div>
                  <Field
                    name={`${EKeysOfSteps.RatePayers}.${fieldName.FormattedNameAddress}`}
                    rows={4}
                    readOnly
                    component={CCTextArea}
                  />
                </div>
                <div className="cc-field">
                  <div className="cc-label-control-between">
                    <CCLabel
                      title={`Name and address for duplicate ${lowerFirst(
                        ratepayersLabel
                      )} notice`}
                    />

                    <Button
                      fillMode="flat"
                      themeColor="primary"
                      className={"cc-edit-field-button"}
                      title={`Edit Name and address for duplicate ${lowerFirst(
                        ratepayersLabel
                      )} notice`}
                      onClick={() => {
                        setFormatNameAddressDialogMode(
                          EKeysOfSteps.DuplicateRatePayers
                        );
                      }}
                      iconClass="fa fa-edit"
                      disabled={options?.isReadOnly}
                    />
                  </div>

                  <Field
                    name={`${EKeysOfSteps.DuplicateRatePayers}.${fieldName.FormattedNameAddress}`}
                    rows={4}
                    readOnly
                    component={CCTextArea}
                  />
                </div>
              </>
            ) : null}
          </div>
        </section>
        {formatNameAddressDialogMode ? (
          <FormatNameAndAddressDialog
            onClose={() => {
              setFormatNameAddressDialogMode(undefined);
            }}
            noticeGroupData={changeOfOwnershipLOVs?.NoticeGroups ?? []}
            onSubmit={(data) => {
              setFormatNameAddressForStep(formatNameAddressDialogMode, data);
              setFormatNameAddressDialogMode(undefined);
            }}
            initialValues={valueGetter(formatNameAddressDialogMode)}
          />
        ) : null}
        {updateEntityData ? (
          <UpdateContactDialog
            entityId={updateEntityData?.Entity_Id}
            onClose={() => {
              setUpdateEntityData(undefined);
            }}
            handleSubmitDialog={(values, response) => {
              if (response?.IsSuccess) {
                handleAddName(
                  {
                    Entity_Id: updateEntityData?.Entity_Id,
                  } as ContactLookahead_JSON,
                  EAddNameMode.Update
                );
              } else {
                localNotificationRef?.current?.pushNotification({
                  title: response?.ErrorMessage ?? "Name could not be updated",
                  type: "error",
                  autoClose: false,
                });
              }

              setUpdateEntityData(undefined);
            }}
            isSaveOnNextStep
            isUpdateFromChangeOfOwnership
          />
        ) : null}
      </>
    );
  }
);
