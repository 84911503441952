import { AddToSupplementaryButtonAtCollections } from "@app/products/property/assessments/collections/components/action-bar/buttons/add-to-supplementary/_index";
import { AmalgamateAssessmentWorkflowButtonAtCollections } from "@app/products/property/assessments/collections/components/action-bar/buttons/amalgamate-assessment/_index";
import { AssociateAssessmentButtonAtCollections } from "@app/products/property/assessments/collections/components/action-bar/buttons/associate-assessment/_index";
import { ChangeOfOwnerShipButtonAtCollections } from "@app/products/property/assessments/collections/components/action-bar/buttons/change-of-ownership/_index";
import { CreateDefermentListButtonAtCollections } from "@app/products/property/assessments/collections/components/action-bar/buttons/create-deferment/_index";
import { CreateAssessmentJournalButtonAtCollections } from "@app/products/property/assessments/collections/components/action-bar/buttons/create-journal/_index";
import { CreatePICButtonAtCollections } from "@app/products/property/assessments/collections/components/action-bar/buttons/create-pic/_index";
import { DisAssociateAssessmentButtonAtCollections } from "@app/products/property/assessments/collections/components/action-bar/buttons/disassociate-assessment/_index";
import { ModifyAssessmentButtonAtCollections } from "@app/products/property/assessments/collections/components/action-bar/buttons/modify-assessment/_index";
import { NewDebtRecoveryButtonAtCollections } from "@app/products/property/assessments/collections/components/action-bar/buttons/new-debt-recovery/_index";
import { RaiseAssessmentInterestButtonAtCollections } from "@app/products/property/assessments/collections/components/action-bar/buttons/raise-assessment-interest/_index";
import { ReactivateAssessmentButtonAtCollections } from "@app/products/property/assessments/collections/components/action-bar/buttons/reactivate-assessment/_index";
import { NewAssessmentButton } from "@app/products/property/assessments/components/action-bar/buttons/new-assessment/_index";
import { WriteOffSmallBalancesButton } from "@app/products/property/assessments/components/action-bar/buttons/write-off-small-balances/_index";
import { ECustomColNameProperty } from "@app/products/property/config";
import { Label } from "@common/stores/products/config";
import { OrganisationMode } from "@common/stores/products/model";
import { useCommonProductStore } from "@common/stores/products/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React from "react";

export const AssessCollectionsWorkflowButtons = observer(() => {
  const assessmentLabel = Label.CommunityProperty.getLabel(
    ECustomColNameProperty.Assessment
  );
  const { currentOrganisationMode } = useCommonProductStore();
  const isLLS = currentOrganisationMode(OrganisationMode.LLS);
  const isGeneral = currentOrganisationMode(OrganisationMode.General);
  return (
    <CCNavButton
      title={"Workflow"}
      type="sub-workflow"
      className="cc-workflow-button"
    >
      <CCNavButton
        title={assessmentLabel ?? ""}
        type="dropdown"
        id={`cc-nav-${ECustomColNameProperty.Assessment}`}
      >
        <AssociateAssessmentButtonAtCollections />
        <DisAssociateAssessmentButtonAtCollections />
        <AmalgamateAssessmentWorkflowButtonAtCollections />
        <ModifyAssessmentButtonAtCollections />
        <NewAssessmentButton />
        <ReactivateAssessmentButtonAtCollections />
      </CCNavButton>
      <CCNavButton title={"Supplementary"} type="dropdown">
        <AddToSupplementaryButtonAtCollections />
        {/* TODO: Hide menu until API ready (later sprint) */}
        {/* <BulkLevyUpdateButton /> */}
      </CCNavButton>
      <NewDebtRecoveryButtonAtCollections />
      <CCNavButton title={"Deferment"} type="dropdown" invisible={isLLS || isGeneral}>
        <CreateDefermentListButtonAtCollections />
      </CCNavButton>
      <ChangeOfOwnerShipButtonAtCollections />
      <CCNavButton title="Journal" type="dropdown">
        <CreateAssessmentJournalButtonAtCollections />
        <RaiseAssessmentInterestButtonAtCollections />
        <WriteOffSmallBalancesButton />
      </CCNavButton>
      {isLLS ? (
        <CCNavButton title={"PIC"} type="dropdown">
          <CreatePICButtonAtCollections />
        </CCNavButton>
      ) : (
        <></>
      )}
    </CCNavButton>
  );
});
