import Actions from "@app/core/actions/_index";
import { Comments } from "@app/core/comments/_index";
import { Documents } from "@app/core/documents/_index";
import Fees from "@app/core/fees/_index";
import Inspections from "@app/core/inspections/inspections-accordion/_index";
import Samples from "@app/core/samples/_index";
import { useLocalLawsStore } from "@app/products/local-laws/[id]/store";
import { loadLocalLawsRecords } from "@app/products/local-laws/api";
import { ILocalLawsRecordsState } from "@app/products/local-laws/model";
import { RECORDTYPE } from "@common/constants/recordtype";
import Contacts from "@common/pages/contacts/_index";
import { CustomPanelBar } from "@components/custom-panelbar/CustomPanelBar";
import { IListPanelBar } from "@components/custom-panelbar/model";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";

export const AccordionsLocalLawsPermit = observer(() => {
  const { localLawsId } = useLocalLawsStore();
  const idUrl: string = useParams<{ id: string }>().id;
  const id: string = localLawsId?.toString() || idUrl;

  const [localLawsRecords, setLocalLawsRecords] = useState<
    ILocalLawsRecordsState | undefined
  >(undefined);

  useEffectOnce(() => {
    loadLocalLawsRecords(parseInt(id), RECORDTYPE.LLP_Permit).then((res) => {
      setLocalLawsRecords(res);
    });
  });

  const listPanelBar: IListPanelBar[] = [
    {
      title: "Actions",
      totalRecord: localLawsRecords?.actions,
      component: <Actions id={id} recordType={RECORDTYPE.LLP_Permit} />,
    },
    {
      title: "Comments",
      totalRecord: localLawsRecords?.comments,
      component: <Comments id={id} recordType={RECORDTYPE.LLP_Permit} />,
    },
    {
      title: "Contacts",
      totalRecord: localLawsRecords?.contacts,
      component: <Contacts id={id} recordType={RECORDTYPE.LLP_Permit} />,
    },
    {
      title: "Documents",
      totalRecord: localLawsRecords?.documents,
      component: (
        <Documents id={parseInt(id)} recordType={RECORDTYPE.LLP_Permit} />
      ),
    },
    {
      title: "Fees",
      totalRecord: localLawsRecords?.fees,
      component: <Fees id={id} recordType={RECORDTYPE.LLP_Permit} />,
    },
    {
      title: "Inspections",
      totalRecord: localLawsRecords?.inspections,
      component: <Inspections id={id} recordType={RECORDTYPE.LLP_Permit} />,
    },
    {
      title: "Conditions",
      totalRecord: localLawsRecords?.samples,
      component: <Samples id={id} recordType={RECORDTYPE.LLP_Permit} />,
    },
    {
      title: "Related",
      totalRecord: localLawsRecords?.samples,
      component: <Samples id={id} recordType={RECORDTYPE.LLP_Permit} />,
    },
    {
      title: "Events",
      totalRecord: localLawsRecords?.samples,
      component: <Samples id={id} recordType={RECORDTYPE.LLP_Permit} />,
    },
    {
      title: "Bonds",
      totalRecord: localLawsRecords?.samples,
      component: <Samples id={id} recordType={RECORDTYPE.LLP_Permit} />,
    },
  ];

  return <CustomPanelBar listPanelBar={listPanelBar} />;
});
