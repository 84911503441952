import { eventEmitter } from "@/App";
import { deleteImportedFile } from "@app/products/property/changes-of-ownership/notice-of-sales/components/action-bar/buttons/delete-imported-file/api";
import { NoticeOfSaleStatus } from "@app/products/property/changes-of-ownership/notice-of-sales/constant";
import { VO_NoticeOfSale } from "@app/products/property/changes-of-ownership/notice-of-sales/model";
import { isSuccessResponse } from "@common/apis/util";
import { nameOfFactory } from "@common/utils/common";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCGridEventType } from "@components/cc-grid/constant";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import { groupBy } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";

interface IDeleteNoticeOfSaleInfo {
  isDisabledDeleteButton: boolean;
  selectedFileId: number | null;
}

const nameOf = nameOfFactory<VO_NoticeOfSale>();
export const DeleteImportedFileButton = observer(() => {
  const { gridSelectedRows, clearSelectedItems } = useCCProductListViewStore();
  const { pushNotification } = useCCAppNotificationStore();

  const [isShowConfirmDialog, setIsShowConfirmDialog] =
    useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const deleteNoticeOfSaleInfo: IDeleteNoticeOfSaleInfo = useMemo(() => {
    const selectedRowsGroupedByFileId = groupBy(
      gridSelectedRows,
      nameOf("File_Id")
    );

    // Get keys (file ID) of the grouped rows
    const groupKeys = Object.keys(selectedRowsGroupedByFileId);

    // If multiple file IDs or no file IDs are selected, disable Delete button
    if (groupKeys.length !== 1) {
      return {
        isDisabledDeleteButton: true,
        selectedFileId: null,
      };
    }

    // If only one file ID is selected, enable Delete button and set selected file ID
    const selectedRows = selectedRowsGroupedByFileId[groupKeys[0]];
    return {
      isDisabledDeleteButton: selectedRows.some(
        (item: VO_NoticeOfSale) =>
          item.Status !== NoticeOfSaleStatus.Imported &&
          item.Status !== NoticeOfSaleStatus.Suspended
      ),
      selectedFileId: selectedRows[0].File_Id,
    };
  }, [gridSelectedRows]);

  /**
   * Handle delete files
   */
  const handleDelete = async () => {
    setIsLoading(true);
    const response = await deleteImportedFile({
      File_Id: deleteNoticeOfSaleInfo.selectedFileId, // @TODO - 13047: Waiting for API to support multiple file deletion
    });
    setIsLoading(false);
    setIsShowConfirmDialog(false);
    if (isSuccessResponse(response) && response?.data?.IsSuccess) {
      eventEmitter.emit(CCGridEventType.RefreshOData); //Reload notice of sale grid
      pushNotification({
        title:
          response.data.SuccessMessage ??
          "Imported files deleted successfully.",
        type: "success",
      });
      clearSelectedItems();
    } else {
      //Local notification
      pushNotification({
        autoClose: false,
        title: response.data.ErrorMessage ?? `Delete imported files failed.`,
        type: "error",
      });
    }
  };

  return (
    <>
      <CCNavButton
        title="Delete imported file"
        onClick={() => {
          setIsShowConfirmDialog(true);
        }}
        disabled={deleteNoticeOfSaleInfo.isDisabledDeleteButton}
      />
      {isShowConfirmDialog && (
        <ConfirmDialog
          title="Confirm Deletion"
          subMessage="Are you sure you wish to remove the entire imported file?"
          onClosePopup={() => {
            setIsShowConfirmDialog(false);
          }}
          onAsyncConfirm={handleDelete}
          isLoadingYes={isLoading}
        />
      )}
    </>
  );
});
