import { ChangeOfOwnerShipButton } from "@app/products/property/assessments/components/action-bar/buttons/change-of-ownership/_index";
import { CancelChangeOfOwnershipButton } from "@app/products/property/changes-of-ownership/list/components/action-bar/buttons/cancel-change-of-ownership/_index";
import { EditChangeOfOwnershipListButton } from "@app/products/property/changes-of-ownership/list/components/action-bar/buttons/edit-change-of-ownership/_index";
import { ChangeOfOwnershipContactTab } from "@app/products/property/changes-of-ownership/list/components/reference-sidebar/contact/_index";
import { ChangeOfOwnershipDetailTab } from "@app/products/property/changes-of-ownership/list/components/reference-sidebar/detail/_index";
import { colList } from "@app/products/property/changes-of-ownership/list/config";
import { PROPERTY_CHANGE_OF_OWNERSHIP_LIST_VIEW_URL } from "@app/products/property/changes-of-ownership/list/constant";
import { DetailComponent } from "@app/products/property/changes-of-ownership/list/detail/_index";
import { VO_Change_of_Ownership } from "@app/products/property/changes-of-ownership/list/model";
import { changesOfOwnershipRoute } from "@app/products/property/changes-of-ownership/route";
import { PropertyActionBarNavDropdown } from "@app/products/property/components/action-bar/nav-dropdown/_index";
import { eComponent } from "@app/products/property/components/associations/model";
import { PropertyHistoryTab } from "@app/products/property/components/reference-sidebar/property-history/_index";
import { propertyRoute } from "@app/products/property/route";
import { RECORDTYPE } from "@common/constants/recordtype";
import { nameOfFactory } from "@common/utils/common";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import React from "react";

const nameOf = nameOfFactory<VO_Change_of_Ownership>();

export default () => {
  useCCListViewActionBar({
    title: propertyRoute.name,
    leftComponents: [
      <PropertyActionBarNavDropdown category={changesOfOwnershipRoute.path} />,
    ],
    centerComponents: [
      <CCNavButton
        title={"Workflow"}
        type="sub-workflow"
        className="cc-workflow-button"
      >
        <ChangeOfOwnerShipButton
          title={"New change of ownership"}
          componentNumber={eComponent.Change_of_Ownership}
        />
        <EditChangeOfOwnershipListButton />
        <CancelChangeOfOwnershipButton />
      </CCNavButton>,
    ],
    rightComponents: [
      <ActionBarExportIcon />,
      <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
      //  Holding now but can use later
      // <ListViewBookmarkIcon
      //   url={PROPERTY_ASSESSMENT_ROUTE}
      //   detail={getBookmarkDetail}
      //   displayName={getBookmarkDisplayName}
      // />,
      <CCNavButton iconClass="fal fa-bookmark" title="Bookmark" />,
    ],
  });
  useReferenceSideBar({
    referenceComponents: [
      { title: "Details", component: <ChangeOfOwnershipDetailTab /> },

      { title: "Contacts", component: <ChangeOfOwnershipContactTab /> },
      {
        title: "History",
        component: (
          <PropertyHistoryTab
            //Hyperlink open Assessment -> Use Assessment
            primaryField={nameOf("Assessment_Id")}
            recordType={RECORDTYPE.CommunityProperty_Assessment}
          />
        ),
      },
    ],
  });

  return (
    <CCProductListView
      detail={DetailComponent}
      columnFields={colList}
      primaryField={nameOf("Id")}
      dataUrl={PROPERTY_CHANGE_OF_OWNERSHIP_LIST_VIEW_URL}
      state={{ sort: [{ field: nameOf("Id"), dir: "desc" }] }}
    />
  );
};
