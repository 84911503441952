import { addUniqueKey } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/change-of-ownership/components/form-elements/names/util";
import {
  COONotRetainOwnersTypes,
  eChangeOfOwnershipType,
} from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/change-of-ownership/components/form-elements/type/model";
import { useFormatNameStep } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/change-of-ownership/hooks/useChangeOfOwnership";
import {
  DTO_COO_Type,
  DTO_Entity_Details,
  DTO_Rebates,
  EKeysOfSteps,
} from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/change-of-ownership/model";
import { useChangeOfOwnershipDialogStore } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/change-of-ownership/store";
import { nameOfFactory } from "@common/utils/common";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCGrid } from "@components/cc-grid/_index";
import { FieldArray } from "@progress/kendo-react-form";
import { subDays } from "date-fns";
import { unionBy } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";
import "./_index.scss";
import { colTypeChangeOfOwnershipList } from "./config";

const validator = (values: any) => {
  if (!values?.Type) {
    return "Please selected one type!";
  }
  return undefined;
};
const nameOfTypeLovs = nameOfFactory<DTO_COO_Type>();
export const TypeFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray
      name={props.nameOf()}
      {...props}
      component={FormStepElement}
      validator={!props?.options?.isReadOnly ? validator : undefined}
    />
  );
};

const FormStepElement = observer(
  ({
    formRenderProps,
    nameOf,
    setStepsVisible,
    options = {
      isReadOnly: false,
    },
    resetAllStepsAfter,
  }: IFormStepElement) => {
    const { changeOfOwnershipLOVs, initWorkflowData } =
      useChangeOfOwnershipDialogStore();
    const { valueGetter, onChange } = formRenderProps;
    const getFieldValue = (name: string) => valueGetter(nameOf(name));
    const typeValue = getFieldValue("Type");
    const {
      handleChangeNamesGrid,
      calculatePercentage,
      handleSwitchRoleToOwner,
    } = useFormatNameStep(
      changeOfOwnershipLOVs,
      onChange,
      valueGetter,
      options?.setIsLoadingStep
    );

    const typeList = useMemo(() => {
      return changeOfOwnershipLOVs?.ChangeOfOwnershipTypes ?? [];
    }, [changeOfOwnershipLOVs]);

    const selectedType = useMemo(() => {
      return (
        typeList?.filter(
          (type: DTO_COO_Type) => type.Change_of_Ownership_Type === typeValue
        ) ?? []
      );
    }, [typeValue, typeList]);

    const handleGridSelectionChange = async (dataItem: DTO_COO_Type[]) => {
      const newSelected = dataItem?.[0];
      onChange(nameOf("Type"), {
        value: newSelected?.Change_of_Ownership_Type ?? null,
      });
      onChange(nameOf("_option.Type"), {
        value: newSelected ?? {},
      });

      const rebates = valueGetter(`${EKeysOfSteps.Rebates}.Rebates`) ?? [];
      const newSteps = resetAllStepsAfter();
      setStepsVisible(
        [
          {
            visible: newSelected?.COOT_Display_Rebates && rebates.length !== 0,
            key: EKeysOfSteps.Rebates,
            isClearData: true,
          },
          {
            visible:
              newSelected?.Change_of_Ownership_Type !==
              eChangeOfOwnershipType.PIC_Name_and_Address_Change,
            key: EKeysOfSteps.Property,
            isClearData: true,
          },
          {
            visible:
              newSelected?.Change_of_Ownership_Type ===
              eChangeOfOwnershipType.PIC_Name_and_Address_Change,
            key: EKeysOfSteps.PICDetails,
            isClearData: true,
          },
        ],
        newSteps
      );

      if (newSelected?.COOT_Clear_Rebates) {
        rebates.map((rebateItem: DTO_Rebates) => {
          rebateItem.is_InActive = true;
          if (rebateItem.Effective_Date) {
            rebateItem.Loss_Of_Eligibility_Date = subDays(
              rebateItem.Effective_Date,
              1
            );
          } else {
            rebateItem.Loss_Of_Eligibility_Date = new Date();
          }
          return rebateItem;
        });
      } else {
        rebates.map((rebateItem: DTO_Rebates) => {
          rebateItem.is_InActive = false;
          rebateItem.Loss_Of_Eligibility_Date = new Date();
          return rebateItem;
        });
      }
      onChange(`${EKeysOfSteps.Rebates}.Rebates`, { value: rebates });
      onChange(`${EKeysOfSteps.Details}.RetainAssociatedNames`, {
        value: newSelected?.COOT_Retain_AssociatedNames,
      });
      onChange(`${EKeysOfSteps.Details}.RetainRatePayerDetails`, {
        value: newSelected?.COOT_Retain_Occupier,
      });

      // Clear Owners in Names Grid if selected type is not retain owner
      const ownerNames = COONotRetainOwnersTypes.includes(
        newSelected?.Change_of_Ownership_Type
      )
        ? []
        : initWorkflowData?.WorkflowDetail?.OwnerDetails?.Contacts ?? [];

      // Clear Ratepayers in Names Grid if selected type is not retain ratepayers
      const ratePayerNames = newSelected?.COOT_Retain_Occupier
        ? initWorkflowData?.WorkflowDetail?.RatePayerDetails?.Contacts ?? []
        : [];

      // Clear Associated Names in Names Grid if selected type is not retain associated names
      const associatedNames = newSelected?.COOT_Retain_AssociatedNames
        ? initWorkflowData?.WorkflowDetail?.AssociatedNamesDetails ?? []
        : [];

      let allNames = [...ownerNames, ...ratePayerNames, ...associatedNames];

      allNames = unionBy(
        allNames,
        (name: DTO_Entity_Details) => `${name.Entity_Id}-${name.Role_Name}`
      );
      allNames = addUniqueKey(allNames);
      if (
        newSelected?.Change_of_Ownership_Type ===
        eChangeOfOwnershipType.Full_Transfer_of_Occupier
      ) {
        allNames = handleSwitchRoleToOwner(allNames);
      }
      const newNames = calculatePercentage(allNames);
      onChange(`${EKeysOfSteps.NamesGridData}`, {
        value: newNames,
      });
      handleChangeNamesGrid(newNames);
    };

    return (
      <section className="cc-field-group cc-change-of-ownership-type-step">
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <CCGrid
              data={typeList}
              selectedRows={selectedType}
              columnFields={colTypeChangeOfOwnershipList}
              itemPerPage={20}
              primaryField={nameOfTypeLovs("Change_of_Ownership_Type")}
              readOnly={options?.isReadOnly}
              selectableMode="single"
              isLimitDetailWidth
              onSelectionChange={(dataItem) => {
                handleGridSelectionChange(dataItem);
              }}
            />
          </div>
        </div>
      </section>
    );
  }
);
