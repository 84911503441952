import { CommunicationButtonListView } from "@app/core/communication/buttons-list-view/_index";
import { DeleteButton } from "@app/core/delete/buttons/_index";
import { MailMergeButtonListView } from "@app/core/new-mail-merge/buttons/_index";
import { MAILMERGEDATASET } from "@app/core/new-mail-merge/dialogs/model";
import { ViewOnMapByGISButton } from "@app/core/view-on-map/components/buttons/view-map-by-gis/_index";
import { CRMSEventContactTab } from "@app/products/crms/[id]/components/forms/components/reference-sidebar/contact/_index";
import { CRMSEventDetailsTab } from "@app/products/crms/[id]/components/forms/components/reference-sidebar/details/_index";
import { CRMSGuidanceTab } from "@app/products/crms/[id]/components/forms/components/reference-sidebar/guidance/_index";
import { CRMSHistoryTab } from "@app/products/crms/[id]/components/forms/components/reference-sidebar/history/_index";
import { CRMS_ROUTE } from "@app/products/crms/[id]/constant";
import { EventView } from "@app/products/crms/all-events/by-refno/model";
import { CrmsActionBarNavDropdown } from "@app/products/crms/components/action-bar/nav-dropdown/_index";
import { FormAction, FormIdentifier } from "@app/products/crms/model";
import { crmsRoute } from "@app/products/crms/route";
import { CRMS_UNRESOLVED_EVENTS_CS_ADMIN_VIEW_URL } from "@app/products/crms/unresolved-events/constant";
import {
  colCSAdmin,
  generateUnresolvedEventsCSAdminBySetting,
} from "@app/products/crms/unresolved-events/cs-admin/config";
import { CRMSCSAdminBookmark } from "@app/products/crms/unresolved-events/cs-admin/util";
import { unresolvedEventsRoute } from "@app/products/crms/unresolved-events/route";
import { CRMSBookmark } from "@app/products/crms/util";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { LoadingPermissionWrapper } from "@common/hocs/permissions/check-security-loading-wrapper";
import { useCheckPermissions } from "@common/hooks/permissions/useCheckPermissions";
import { useCommonCoreStore } from "@common/stores/core/store";
import { nameOfFactory } from "@common/utils/common";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { MapTab } from "@components/cc-reference-sidebar/components/Map/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import { observer } from "mobx-react-lite";
import React from "react";

const nameOf = nameOfFactory<EventView>();

export default observer(() => {
  const { settings } = useCommonCoreStore();
  const { checkPermissions, isLoadingPermission } = useCheckPermissions({
    formIdentifier: FormIdentifier.CUSTOMERSERVICE_Form_Event,
    productType: PRODUCT_TYPE_NUMBER.CustomerService,
  });

  useCCListViewActionBar({
    title: crmsRoute.name,
    leftComponents: [
      <CrmsActionBarNavDropdown category={unresolvedEventsRoute.path} />,
    ],
    centerComponents: [
      <MailMergeButtonListView
        productType={PRODUCT_TYPE_NUMBER.CustomerService}
        recordType={RECORDTYPE.CUSTOMERSERVICE_Event}
        dataSetFilter={MAILMERGEDATASET.CUSTOMERSERVICE_Event}
        isDisabled={!checkPermissions(FormAction.CORE_ALLOW_MAILMERGE)}
      />,
      <CommunicationButtonListView
        productType={PRODUCT_TYPE_NUMBER.CustomerService}
        recordType={RECORDTYPE.CUSTOMERSERVICE_Event}
        isDisabled={
          !checkPermissions(FormAction.CORE_TOOLBAR_ADD_COMMUNICATION)
        }
      />,
      <DeleteButton
        recordType={RECORDTYPE.CUSTOMERSERVICE_Event}
        disabled={!checkPermissions(FormAction.CORE_ALLOW_DELETE)}
      />,
      <CCNavButton title={"Tools"} type="more">
        <ViewOnMapByGISButton
          recordType={RECORDTYPE.CUSTOMERSERVICE_Event}
          isDisabled={!checkPermissions(FormAction.CRMS_TOOLS_VIEWONMAP)}
        />
      </CCNavButton>,
    ],
    rightComponents: [
      <ActionBarExportIcon
        disabled={!checkPermissions(FormAction.CORE_ALLOW_EXPORT)}
      />,
      <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
      <ListViewBookmarkIcon
        linkUrl={CRMS_ROUTE}
        productType={PRODUCT_TYPE.CustomerService}
        recordType={RECORDTYPE.CUSTOMERSERVICE_Event}
        detail={CRMSBookmark.getBookmarkDetail}
        displayName={CRMSBookmark.getBookmarkDisplayName}
        listViewDisplayName={CRMSCSAdminBookmark.getBookmarkListViewDisplayName}
        listViewDetail={CRMSCSAdminBookmark.getBookmarkListViewDetail}
      />,
    ],
  });

  useReferenceSideBar({
    referenceComponents: [
      { title: "Details", component: <CRMSEventDetailsTab /> },
      {
        title: "Map",
        component: <MapTab addressField={nameOf("Location")} zoom={18} />,
      },
      {
        title: "Contacts",
        component: <CRMSEventContactTab />,
      },
      {
        title: "Guidance",
        component: <CRMSGuidanceTab />,
      },
      {
        title: "History",
        component: <CRMSHistoryTab />,
      },
    ],
  });

  return (
    <LoadingPermissionWrapper isLoadingPermission={isLoadingPermission}>
      <CCProductListView
        columnFields={generateUnresolvedEventsCSAdminBySetting({
          settings,
          defaultConfig: colCSAdmin,
        })}
        primaryField={nameOf("ID")}
        dataUrl={CRMS_UNRESOLVED_EVENTS_CS_ADMIN_VIEW_URL}
        state={{
          sort: [{ field: nameOf("RefNo"), dir: "desc" }],
          group: [{ field: nameOf("Admin_Action"), dir: "asc" }],
        }}
      />
    </LoadingPermissionWrapper>
  );
});
