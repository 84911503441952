import { LOCAL_LAWS_MANAGE_ROUTE } from "@app/products/local-laws/[id]/constant";
import { CCNavButton } from "@components/cc-nav-button/_index";
import React from "react";
import { useHistory } from "react-router-dom";

export interface INewLocalLawsButton {
  isDisabled?: boolean;
}
export const NewLocalLawsButton = ({ isDisabled }: INewLocalLawsButton) => {
  const history = useHistory();
  return (
    <>
      <CCNavButton
        title="New Local Laws"
        onClick={() => {
          history.push(`${LOCAL_LAWS_MANAGE_ROUTE}/new`);
        }}
        disabled={isDisabled}
      />
    </>
  );
};
