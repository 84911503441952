import {
  PermitType,
  Svc_PermitTypeFees,
} from "@app/products/local-laws/system-admin/permit-types/[id]/model";

import { colPermitTypeFees } from "@app/products/local-laws/system-admin/permit-types/[id]/components/general/components/form-element/components/fee-section/config";
import { FEE_ITEM_COUNT } from "@app/products/local-laws/system-admin/permit-types/[id]/components/general/components/form-element/components/fee-section/constant";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import {
  getKeyFromKeyValuePacket,
  getValueFromKeyValuePacket,
  nameOfFactory,
} from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { CCDropDownList } from "@components/cc-drop-down-list/_index";
import { CCGrid } from "@components/cc-grid/_index";
import { DropDownEditCell } from "@components/cc-grid/components/grid-cells/components/drop-down-edit-cell/_index";
import { IColumnFields, ICustomEditCell } from "@components/cc-grid/model";
import { CCLabel } from "@components/cc-label/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { Field, FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useEffect, useMemo, useState } from "react";
import "./_index.scss";

interface IFeesSectionProps {
  formRenderProps: FormRenderProps;
}
const nameOfFees = nameOfFactory<Svc_PermitTypeFees>();
const nameOf = nameOfFactory<PermitType>();

export const FeesSection = observer(
  ({ formRenderProps }: IFeesSectionProps) => {
    const { valueGetter, onChange } = formRenderProps;
    const getValue = (nameOfField: keyof PermitType) =>
      valueGetter(nameOf(nameOfField));
    const onChangeField = (nameOfField: string, value: any) => {
      onChange(nameOf(nameOfField as keyof PermitType), { value: value });
    };

    const { dataForm } = useFlexibleFormStore();
    const permitTypeLOVs = dataForm?.GeneralFormLovs;

    const isFeesEnabled = valueGetter(nameOf("Flag_Fees"));

    const [feesData, setFeesData] = useState<Svc_PermitTypeFees[]>([]);

    useEffect(() => {
      if (permitTypeLOVs) {
        const feesData: Svc_PermitTypeFees[] = [];
        for (let i = 1; i <= FEE_ITEM_COUNT; i++) {
          feesData.push({
            ID: i,
            FeeDescription: getValue(`FeeDescription_${i}` as keyof PermitType),
            FeeAmount: getValue(`FeeAmount_${i}` as keyof PermitType),
            FeeDimension1: getValueFromKeyValuePacket(
              permitTypeLOVs.Multiplier,
              getValue(`FeeDimension${i}_1_ENUM` as keyof PermitType)
            ),
            FeeDimension2: getValueFromKeyValuePacket(
              permitTypeLOVs.Multiplier,
              getValue(`FeeDimension${i}_2_ENUM` as keyof PermitType)
            ),
            FeeMinAmount: getValue(`FeeMinAmount_${i}` as keyof PermitType),
            FeeMaxAmount: getValue(`FeeMaxAmount_${i}` as keyof PermitType),
          });
        }
        setFeesData(feesData);
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [permitTypeLOVs]);

    const handleDataChangeGrid = (
      dataRow: any,
      fieldChange: keyof Svc_PermitTypeFees
    ) => {
      switch (fieldChange) {
        case nameOfFees("FeeDescription"):
          onChangeField(
            `FeeDescription_${dataRow?.ID}`,
            dataRow?.FeeDescription
          );
          break;
        case nameOfFees("FeeAmount"):
          onChangeField(`FeeAmount_${dataRow?.ID}`, dataRow?.FeeAmount);
          break;
        case nameOfFees("FeeDimension1"):
          onChangeField(
            `FeeDimension${dataRow?.ID}_1_ENUM`,
            getKeyFromKeyValuePacket(
              permitTypeLOVs?.Multiplier ?? [],
              dataRow?.FeeDimension1
            )
          );
          break;
        case nameOfFees("FeeDimension2"):
          onChangeField(
            `FeeDimension${dataRow?.ID}_2_ENUM`,
            getKeyFromKeyValuePacket(
              permitTypeLOVs?.Multiplier ?? [],
              dataRow?.FeeDimension2
            )
          );
          break;
        case nameOfFees("FeeMinAmount"):
          onChangeField(`FeeMinAmount_${dataRow?.ID}`, dataRow?.FeeMinAmount);
          break;
        case nameOfFees("FeeMaxAmount"):
          onChangeField(`FeeMaxAmount_${dataRow?.ID}`, dataRow?.FeeMaxAmount);
          break;

        default:
          break;
      }
    };

    // Refactor column config to add dropdown list into grid's cell
    const colPermitTypeFeesRefactor = useMemo(() => {
      return colPermitTypeFees.map((col: IColumnFields) => {
        if (
          col.field === nameOfFees("FeeDimension1") ||
          col.field === nameOfFees("FeeDimension2")
        ) {
          return {
            ...col,
            editCell: (props: ICustomEditCell) => (
              <DropDownEditCell
                {...props}
                textField={"Value"}
                dataItemKey={"Key"}
                dropdownList={permitTypeLOVs?.Multiplier ?? []}
              />
            ),
            editable: true,
          };
        }
        return col;
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [permitTypeLOVs?.Multiplier]);

    return (
      <section className="cc-field-group">
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <CCLabel title="Fees?" isMandatory />
            <Field
              component={CCSwitch}
              name={nameOf("Flag_Fees")}
              checked={getValue("Flag_Fees")}
              validator={requiredValidator}
            />
          </div>
          {isFeesEnabled && (
            <div className="cc-field">
              <CCLabel title="Fees type" isMandatory />
              <Field
                component={CCDropDownList}
                name={nameOf("FeeType_ID")}
                validator={requiredValidator}
                textField="Value"
                dataItemKey="Key"
                data={permitTypeLOVs?.FeeTypes}
                isKeyValueDropdown
              />
            </div>
          )}
        </div>
        {isFeesEnabled && (
          <div className="cc-form-cols-1">
            <CCGrid
              className="cc-permit-type-fees"
              itemPerPage={FEE_ITEM_COUNT}
              primaryField={nameOfFees("ID")}
              columnFields={colPermitTypeFeesRefactor}
              data={feesData}
              editableMode="cell"
              onDataRowChange={(dataRow: any, fieldChange: string) => {
                handleDataChangeGrid(
                  dataRow,
                  fieldChange as keyof Svc_PermitTypeFees
                );
              }}
            />
          </div>
        )}
      </section>
    );
  }
);
