import { RequestAmalgamateAssessmentObj } from "@app/products/property/assessments/components/form-steps/amalgamate-assessment/model";
import { usePropertyWorkflowStore } from "@app/products/property/components/action-bar/property-workflow/store";
import { ECustomColNameProperty } from "@app/products/property/config";
import { WorkflowTypes } from "@app/products/property/model";
import { Label } from "@common/stores/products/config";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React from "react";

interface IAmalgamateAssessmentCommonButtonProps {
  requestAmalgamateObj: RequestAmalgamateAssessmentObj;
  isDisabled?: boolean;
}
export const AmalgamateAssessmentCommonButton = observer(
  ({
    requestAmalgamateObj,
    isDisabled,
  }: IAmalgamateAssessmentCommonButtonProps) => {
    const { setListDialog } = usePropertyWorkflowStore();

    //Get label
    const assessmentLabel = Label.CommunityProperty.getLabel(
      ECustomColNameProperty.Assessment
    );

    return (
      <CCNavButton
        title={`Amalgamate ${assessmentLabel}`}
        disabled={isDisabled}
        onClick={() => {
          setListDialog([
            {
              type: WorkflowTypes.Amalgamate_Assessment,
              data: {},
              props: {
                requestAmalgamateObj: requestAmalgamateObj,
              },
            },
          ]);
        }}
      />
    );
  }
);
