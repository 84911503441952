import { VO_Workflow_Draft } from "@app/products/property/actions/model";
import { listSubmitButton } from "@app/products/property/assessments/components/form-steps/new-assessment/config";
import { useConfirmCancelDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-cancel/store";
import { useConfirmCloseDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-close/store";
import { useConfirmFinishDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-finish/store";
import { useConfirmReallocateDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-reallocate/store";
import { useConfirmRejectDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-reject/store";
import { useConfirmSendBackDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-send-back/store";
import { CommentsFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/comments/_index";
import { DocumentsFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/documents/_index";
import { WorkflowStepFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/workflow/_index";
import {
  getSuffixTitle,
  secondaryWorkflowUtilProcessing,
} from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/workflow/util";
import { usePropertyWorkflow } from "@app/products/property/components/action-bar/property-workflow/component/hooks/useProprtyWorkflow/usePropertyWorkflow";
import { INewProcessWorkflow } from "@app/products/property/components/action-bar/property-workflow/model";
import { getTitleWorkflow } from "@app/products/property/components/action-bar/property-workflow/util";
import { officerUtilProcessing } from "@app/products/property/components/fields/officer-and-officer-region/util";
import { AssociationsLLSFormStep } from "@app/products/property/contacts-central-names/list/components/dialogs/components/form-elements/associations-lls/_index";
import { AttributesFormStep } from "@app/products/property/contacts-central-names/list/components/dialogs/components/form-elements/attributes/_index";
import { ConcessionCardsFormStep } from "@app/products/property/contacts-central-names/list/components/dialogs/components/form-elements/concession-cards/_index";
import { ContactDetailFormStep } from "@app/products/property/contacts-central-names/list/components/dialogs/components/form-elements/details/_index";
import { PersonFormStep } from "@app/products/property/contacts-central-names/list/components/dialogs/components/form-elements/person/_index";
import { processPersonData } from "@app/products/property/contacts-central-names/list/components/dialogs/components/form-elements/person/util";
import { PostalAndPhysicalAddressFormStep } from "@app/products/property/contacts-central-names/list/components/dialogs/components/form-elements/postal-and-physical-address/_index";
import {
  EAddressStepperMode,
  EDeliveryOptions,
} from "@app/products/property/contacts-central-names/list/components/dialogs/components/form-elements/postal-and-physical-address/config";
import {
  processAddressData,
  propertyFieldGroupedByDelivery,
} from "@app/products/property/contacts-central-names/list/components/dialogs/components/form-elements/postal-and-physical-address/util";
import { VotingFormStep } from "@app/products/property/contacts-central-names/list/components/dialogs/components/form-elements/voting/_index";
import {
  DTO_ConcessionCard,
  DTO_Create_Entity_LOVs,
  DTO_Entity_LOVs,
  DTO_Entity_PostalAddress,
} from "@app/products/property/contacts-central-names/list/components/dialogs/new-contact/model";
import {
  getInitialDataModifyContact,
  getWorkflowModifyContact,
  postProcessModifyContact,
} from "@app/products/property/contacts-central-names/list/components/dialogs/update-contact/api";
import {
  defaultAddress,
  defaultAddressLLS,
  defaultPerson,
} from "@app/products/property/contacts-central-names/list/components/dialogs/update-contact/config";
import {
  DTO_Entity_Concession_Card,
  DTO_Workflow_ModifyEntity,
  EKeysOfUpdateContactSteps,
  keysOfSendUpdateContactSteps,
} from "@app/products/property/contacts-central-names/list/components/dialogs/update-contact/model";
import {
  DTO_WorkflowHeader,
  EListSubmitButton,
  EWorkflowStatus,
  ResponseMessageWorkflow,
  WorkflowProcessMode,
  WorkflowTypes,
} from "@app/products/property/model";
import { isShowParkButton } from "@app/products/property/util";
import { APIResponse } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { RECORDTYPE } from "@common/constants/recordtype";
import { ResponsePacket } from "@common/models/identityPacket";
import { OrganisationMode } from "@common/stores/products/model";
import { useCommonProductStore } from "@common/stores/products/store";
import { nameOfFactory } from "@common/utils/common";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCDialog } from "@components/cc-dialog/_index";
import {
  CCFormStep,
  ICCFormStepNotificationHandle,
  ICCFormStepRender,
} from "@components/cc-form-step/_index";
import { IStep } from "@components/cc-form-step/model";
import { Button } from "@progress/kendo-react-buttons";
import { cloneDeep, head, isNil, pickBy } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo, useRef, useState } from "react";
import { useEffectOnce } from "react-use";

//#region <Props interface>
interface IUpdateContactDialogProps {
  onClose: () => void;
  onCloseSpecial?: () => void;
  handleSubmitDialog: (
    entity: DTO_Workflow_ModifyEntity,
    response: ResponseMessageWorkflow<DTO_Workflow_ModifyEntity>
  ) => void;
  currentEntity?: any;
  dataFromActionList?: VO_Workflow_Draft;
  isSaveOnNextStep?: boolean;
  prefixTitle?: string;
  suffixTitle?: string;
  entityId?: number;
  isShowNotificationFinish?: boolean;
  isUpdateFromChangeOfOwnership?: boolean;
}
//#endregion
const nameOfPostal = nameOfFactory<DTO_Entity_PostalAddress>();

export const UpdateContactDialog = observer(
  ({
    currentEntity = defaultPerson,
    dataFromActionList,
    isSaveOnNextStep = false,
    entityId,
    onClose,
    onCloseSpecial,
    handleSubmitDialog,
    prefixTitle,
    suffixTitle,
    isShowNotificationFinish = true,
    isUpdateFromChangeOfOwnership = false,
  }: IUpdateContactDialogProps) => {
    const {
      isReadOnly,
      isIncompleteMode,
      isShowCancelWorkflowButton,
      statusBadge,
      isFromActionList,
      isToBeApprovalMode,
    } = usePropertyWorkflow(dataFromActionList);

    //#region <Store>
    const { currentOrganisationMode, currentFormTitle } =
      useCommonProductStore();
    const { setDataForCloseDialog, setIsLoadingClose } =
      useConfirmCloseDialogStore();
    const { pushNotification } = useCCAppNotificationStore();
    const { setDataForCancelDialog } = useConfirmCancelDialogStore();
    const { setDataForFinishDialog } = useConfirmFinishDialogStore();
    const { setDataForSendBackDialog } = useConfirmSendBackDialogStore();
    const { setDataForRejectDialog } = useConfirmRejectDialogStore();
    const { setDataForReallocateDialog } = useConfirmReallocateDialogStore();

    //#endregion

    //#region <Get value>
    const isActro = currentOrganisationMode(OrganisationMode.ACTRO);
    const isLLS = currentOrganisationMode(OrganisationMode.LLS);
    const id = dataFromActionList?.Workflow_Draft_Id ?? entityId;
    //#endregion

    //#region <Local state>
    const notificationFormStepRef =
      useRef<ICCFormStepNotificationHandle | null>(null);
    const [isLoadingProcess, setIsLoadingProcess] = useState<
      WorkflowProcessMode | undefined
    >();
    const [isDisableDialog, setIsDisableDialog] = useState<boolean>(false);
    const [isFirstSave, setIsFirstSave] = useState<boolean>(true);
    const [modifyContactInitData, setModifyContactInitData] =
      useState<DTO_Workflow_ModifyEntity>();
    const [workflowDraftId, setWorkflowDraftId] = useState<number>(id ?? 0);
    const [workflowHeader, setWorkflowHeader] = useState<DTO_WorkflowHeader>({
      WorkflowDraft: { Workflow_Draft_Id: id ?? 0 },
      AvailableSecondaryWorkflows: [],
      WorkflowApprovals: [],
    });
    const [updateContactLOVs, setUpdateContactLOVs] =
      useState<DTO_Create_Entity_LOVs>();
    const concessionCardLOV = useMemo(() => {
      return updateContactLOVs?.ConcessionCards ?? [];
    }, [updateContactLOVs]);

    //ref get onChange of form
    const onChangeRef = useRef<any>();
    //#endregion

    //#region <Title header>
    /**
     * process title dialog
     */
    const titleHeader = useMemo(() => {
      const formId = workflowHeader?.WorkflowDraft?.WD_Form_Id;
      const title = currentFormTitle(formId ?? 0) || "Update Contact";

      return getTitleWorkflow(
        title,
        prefixTitle,
        getSuffixTitle(
          suffixTitle,
          isToBeApprovalMode,
          workflowHeader?.WorkflowApprovals
        )
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      workflowHeader,
      prefixTitle,
      suffixTitle,
      isToBeApprovalMode,
      workflowHeader?.WorkflowApprovals,
    ]);
    //#endregion

    //#region <Initial value>
    // Initial value for workflow
    const initialValue = useMemo(() => {
      const officerInitData = officerUtilProcessing.processDataInit(
        modifyContactInitData
      );
      let initDetails: any = {
        ...modifyContactInitData?.WorkflowDetail?.Details,
        ...officerInitData,
      };
      let initEntityDetails: any = {
        ...defaultPerson,
        ...currentEntity,
      };
      let initPostalAddress: any = {
        ...(isLLS ? defaultAddressLLS : defaultAddress),
      };
      let initPhysicalAddress: any = {
        ...(isLLS ? defaultAddressLLS : defaultAddress),
      };
      let initVoting: any = {};
      let initAttributes: any = {};
      let initAssociations: any = {};
      let initConcessionCard: any = {};
      const concessionCardLOVs = [...concessionCardLOV];
      const processConcessionCard = concessionCardLOVs?.map(
        (concessionCard: DTO_ConcessionCard) => {
          return {
            ...concessionCard,
            Is_Active: false,
            DatePresented: undefined,
            ConfirmationDate: undefined,
          };
        }
      ) as any;
      let concessionCards: any = [];
      let initSecondaryWorkflow: any = {};
      if (modifyContactInitData) {
        const workflowDetail = modifyContactInitData?.WorkflowDetail;
        concessionCards = cloneDeep(workflowDetail?.Concession_Cards ?? []);
        initEntityDetails = {
          ...initEntityDetails,
          ...workflowDetail?.EntityDetails,
          _option: {
            Job_Description: {
              Job_Description: workflowDetail?.EntityDetails?.Job_Description,
            },
          },
        };
        initPostalAddress = {
          ...initPostalAddress,
          ...workflowDetail?.PostalAddress,
          _option: {
            Locality: {
              Locality_Code: workflowDetail?.PostalAddress?.Locality,
            },
            Street_Name: {
              Street: workflowDetail?.PostalAddress?.Street_Name,
            },
          },
          [propertyFieldGroupedByDelivery[
            workflowDetail?.PostalAddress?.Delivery as EDeliveryOptions
          ]]: workflowDetail?.PostalAddress?.Property_Name,
        };
        initPhysicalAddress = {
          ...initPhysicalAddress,
          ...workflowDetail?.PhysicalAddress,
          _option: {
            Locality: {
              Locality_Code: workflowDetail?.PhysicalAddress?.Locality,
            },
            Street_Name: {
              Street: workflowDetail?.PhysicalAddress?.Street_Name,
            },
          },
          [propertyFieldGroupedByDelivery[
            workflowDetail?.PostalAddress?.Delivery as EDeliveryOptions
          ]]: workflowDetail?.PostalAddress?.Property_Name,
        };
        initAttributes = {
          ...workflowDetail?.Attributes,
          Id: workflowDetail?.EntityDetails?.Id,
          Changed_On: workflowDetail?.EntityDetails?.Changed_On,
          Changed_By: workflowDetail?.EntityDetails?.Changed_By,
          _option: {
            AlsoKnownAsNames:
              workflowDetail?.Attributes?.AlsoKnownAsNames?.join("\n") ?? "",
          },
        };
        initAssociations = {
          Associated_Entities: [...(workflowDetail?.Associated_Entities ?? [])],
        };
        if (concessionCards?.length) {
          concessionCards = concessionCards?.map(
            (item: DTO_Entity_Concession_Card) => {
              const indexItemLov = concessionCardLOVs.findIndex(
                (e: DTO_ConcessionCard) => e.Id === item.Id
              );
              return {
                ...concessionCardLOVs[indexItemLov],
                ...concessionCards[indexItemLov],
              };
            }
          );
        } else {
          concessionCards = processConcessionCard;
        }
        initVoting = {
          ...initVoting,
          ...(!isLLS ? workflowDetail?.VotingDetails : {}),
        };

        //---step Secondary Workflow---
        initSecondaryWorkflow = secondaryWorkflowUtilProcessing.processDataInit(
          modifyContactInitData
        );
      } else {
        concessionCards = processConcessionCard;
      }
      initConcessionCard = {
        Concession_Cards: concessionCards ?? [],
      };
      return {
        [EKeysOfUpdateContactSteps.Details]: initDetails,
        [EKeysOfUpdateContactSteps.Person]: initEntityDetails,
        [EKeysOfUpdateContactSteps.PostalAddress]: initPostalAddress,
        [EKeysOfUpdateContactSteps.PhysicalAddress]: initPhysicalAddress,
        [EKeysOfUpdateContactSteps.Attributes]: initAttributes,
        [EKeysOfUpdateContactSteps.Associations]: initAssociations,
        [EKeysOfUpdateContactSteps.ConcessionCards]: initConcessionCard,
        [EKeysOfUpdateContactSteps.Voting]: initVoting,
        [EKeysOfUpdateContactSteps.Comments]: {},
        [EKeysOfUpdateContactSteps.Documents]: {},
        [EKeysOfUpdateContactSteps.SecondaryWorkflow]: initSecondaryWorkflow,
      };
      // eslint-disable-next-line
    }, [concessionCardLOV, currentEntity, modifyContactInitData]);
    //#endregion

    //#region <Step>
    /**
     * Initial steps of workflow
     */
    const steps: IStep[] = [
      {
        label: "Details",
        initialValues: initialValue[EKeysOfUpdateContactSteps.Details],
        component: ContactDetailFormStep,
        visible: true,
        key: EKeysOfUpdateContactSteps.Details,
        options: {
          isReadOnly,
          isDisabled: workflowDraftId,
          contactLOVs: updateContactLOVs,
          isUpdate: true,
        },
      },
      {
        label: "Person",
        initialValues: initialValue.EntityDetails,
        component: PersonFormStep,
        visible: true,
        key: EKeysOfUpdateContactSteps.Person,
        options: {
          isReadOnly,
          isLLS,
          contactLOVs: updateContactLOVs,
          keyOfPostalStep: EKeysOfUpdateContactSteps.PostalAddress,
          onChangeRef,
        },
      },
      {
        label: "Postal Address",
        initialValues: initialValue[EKeysOfUpdateContactSteps.PostalAddress],
        component: PostalAndPhysicalAddressFormStep,
        visible: true,
        key: EKeysOfUpdateContactSteps.PostalAddress,
        options: {
          isReadOnly,
          isLLS,
          contactLOVs: updateContactLOVs,
          setIsDisableDialog,
          keyOfPersonStep: EKeysOfUpdateContactSteps.Person,
          keyOfRelatedAddressStep: EKeysOfUpdateContactSteps.PhysicalAddress,
          stepperMode: EAddressStepperMode.Postal,
          onChangeRef,
        },
      },
      {
        label: "Physical Address",
        initialValues: initialValue[EKeysOfUpdateContactSteps.PhysicalAddress],
        component: PostalAndPhysicalAddressFormStep,
        visible: true,
        key: EKeysOfUpdateContactSteps.PhysicalAddress,
        options: {
          isReadOnly,
          isLLS,
          contactLOVs: updateContactLOVs,
          setIsDisableDialog,
          keyOfPersonStep: EKeysOfUpdateContactSteps.Person,
          keyOfRelatedAddressStep: EKeysOfUpdateContactSteps.PostalAddress,
          stepperMode: EAddressStepperMode.Physical,
          onChangeRef,
        },
      },
      {
        label: "Attributes",
        initialValues: initialValue.Attributes,
        component: AttributesFormStep,
        visible: true,
        key: EKeysOfUpdateContactSteps.Attributes,
        options: {
          isReadOnly,
          isActro,
          isLLS,
          contactLOVs: updateContactLOVs,
          isUpdate: true,
        },
      },
      {
        label: "Related",
        initialValues: initialValue.Associated_Entities,
        component: AssociationsLLSFormStep,
        visible: true,
        key: EKeysOfUpdateContactSteps.Associations,
        options: {
          isReadOnly,
          contactLOVs: updateContactLOVs,
        },
      },
      {
        label: "Concession cards",
        initialValues: initialValue.Concession_Cards,
        component: ConcessionCardsFormStep,
        visible: !isLLS,
        key: EKeysOfUpdateContactSteps.ConcessionCards,
        options: {
          isReadOnly,
          onChangeRef,
        },
      },
      {
        label: "Voting",
        component: VotingFormStep,
        initialValues: initialValue.VotingDetails,
        visible: !isActro && !isLLS,
        key: EKeysOfUpdateContactSteps.Voting,
        options: {
          isReadOnly,
          workflowDraftId,
        },
      },
      {
        label: "Comments",
        initialValues: initialValue.Comments,
        component: CommentsFormStep,
        visible: true,
        key: EKeysOfUpdateContactSteps.Comments,
        options: {
          isReadOnly,
          workflowDraftId,
          recordType: RECORDTYPE.CommunityProperty_Entity,
        },
        customClassName: "cc-comment-step-fixed-height-grid",
      },
      {
        label: "Documents",
        component: DocumentsFormStep,
        initialValues: initialValue.Documents,
        visible: !isLLS,
        key: EKeysOfUpdateContactSteps.Documents,
        options: {
          isReadOnly,
          workflowDraftId,
          //Only use for modify workflow
          componentId: entityId,
          workflowType: WorkflowTypes.Modify_Entity,
        },
      },
      {
        label: "Workflow",
        initialValues: initialValue.SecondaryWorkflow,
        component: WorkflowStepFormStep,
        visible: true,
        key: EKeysOfUpdateContactSteps.SecondaryWorkflow,
        options: {
          isReadOnly,
          isFromActionList,
          dataFromActionList,
        },
      },
    ];
    //#endregion

    //#region <Process data payload>
    /**
     * @param data
     * @returns
     */
    const processData = (data: any) => {
      let workflowDetail: any = {};
      // process workflow header to send the WD_Assessment_Group_Id/ Officer Region Id
      const { WD_Assessment_Group_Id: assessmentGroupIdWD } =
        officerUtilProcessing.processData(
          data,
          EKeysOfUpdateContactSteps.Details
        );
      workflowHeader.WorkflowDraft.WD_Assessment_Group_Id = assessmentGroupIdWD;
      const sendSteps = pickBy(data, function (value, key) {
        if (
          keysOfSendUpdateContactSteps.includes(
            key as EKeysOfUpdateContactSteps
          )
        ) {
          return { [key]: value };
        }
      });
      for (const [key, value] of Object.entries(sendSteps)) {
        const dataStep: any = cloneDeep(value);
        if (dataStep && dataStep?._option) {
          delete dataStep._option;
        }

        workflowDetail[key as keyof DTO_Workflow_ModifyEntity] = dataStep;
        switch (key) {
          case EKeysOfUpdateContactSteps.Person:
            workflowDetail[key] = processPersonData(dataStep.Type, dataStep);
            break;
          case EKeysOfUpdateContactSteps.PostalAddress:
          case EKeysOfUpdateContactSteps.PhysicalAddress:
            workflowDetail[key] = processAddressData(
              dataStep?.[nameOfPostal("Delivery")],
              dataStep
            );
            break;
          case EKeysOfUpdateContactSteps.Associations:
            workflowDetail[key] = value.Associated_Entities;
            break;
          case EKeysOfUpdateContactSteps.ConcessionCards:
            if (isLLS) {
              //Concessioncard is hidden in LLS
              workflowDetail[key] = [];
            } else {
              workflowDetail[key] = value[key];
            }
            break;
          case EKeysOfUpdateContactSteps.SecondaryWorkflow:
            secondaryWorkflowUtilProcessing.processData(
              value,
              workflowHeader,
              workflowDetail
            );
            break;
        }
      }
      workflowDetail.EntityDetails.Preferred_Name =
        data?.Attributes?._option?.Preferred_Name;

      return {
        WorkflowHeader: workflowHeader,
        WorkflowDetail: workflowDetail,
      };
    };
    //#endregion

    //#region <Handle process workflow>
    /**
     * common function
     * handle calling api with multiple process
     * @param props
     */

    const handleProcessWorkflow = async (
      props: INewProcessWorkflow<DTO_Workflow_ModifyEntity>
    ) => {
      const {
        actionSuccess,
        actionFail,
        actionClose,
        defaultFailedMessage,
        setLoading,
        modeProcess,
        payload,
      } = props;
      const response = await postProcessModifyContact(modeProcess, payload);
      setLoading();
      if (isSuccessResponse(response) && response?.data?.IsSuccess) {
        actionSuccess(response?.data);
      } else {
        if (actionFail) actionFail(response);
        notificationFormStepRef?.current
          ?.getNotificationFormStep()
          ?.current?.pushNotification({
            title: response?.data?.ErrorMessage ?? defaultFailedMessage,
            type: "error",
            autoClose: false,
          });
      }
      if (actionClose) actionClose();
    };

    //#endregion

    /**
     * handle confirm send back workflow process
     * @param payload
     */
    const handleConfirmSendBackProcess = (
      payload: DTO_Workflow_ModifyEntity
    ) => {
      setDataForSendBackDialog({
        sendBackCallback: postProcessModifyContact,
        dataSendBack: payload,
        defaultSuccessMessage: "Contact approval sent back successfully",
        defaultErrorMessage: "Send contact back failed",
      });
    };

    /**
     * Handle reject process
     */
    const handleRejectButton = (data: any) => {
      if (isFromActionList || !isFirstSave) {
        setDataForRejectDialog({
          rejectCallback: postProcessModifyContact,
          dataReject: data,
          defaultSuccessMessage:
            "Update contact application was rejected successfully",
          defaultErrorMessage: "Update contact reject failed",
        });
      } else {
        onClose();
      }
    };

    //#region <Handle approve process>
    /**
     * @param payload
     */
    const handleApproveProcess = async (payload: DTO_Workflow_ModifyEntity) => {
      //set loading button
      setIsLoadingProcess(WorkflowProcessMode.Approve);

      //props send to process workflow
      const approveProps: INewProcessWorkflow<DTO_Workflow_ModifyEntity> = {
        payload: payload,
        actionSuccess: (e) => {
          onClose();
          pushNotification({
            title:
              e?.Notification ?? "Modify contact was approved successfully.",
            type: "success",
          });
        },
        setLoading: () => {
          setIsLoadingProcess(undefined);
        },
        defaultFailedMessage: "Modify contact could not be approved.",
        modeProcess: WorkflowProcessMode.Approve,
      };

      //calling api process workflow
      await handleProcessWorkflow(approveProps);
    };
    //#endregion

    //#region <Handle save and next>
    /**
     * Refresh workflow data
     * @param workflowDraftId
     */
    const refreshWorkflowData = async (workflowDraftId: number) => {
      const getWorkflowResponse = await getWorkflowModifyContact(
        workflowDraftId
      );
      if (isSuccessResponse(getWorkflowResponse) && getWorkflowResponse?.data) {
        setModifyContactInitData({
          WorkflowDetail: getWorkflowResponse.data?.WorkflowDetail,
          WorkflowHeader: getWorkflowResponse.data?.WorkflowHeader,
        });
      } else {
        notificationFormStepRef?.current?.setLoadFailedFormStep({
          onReload: () => refreshWorkflowData(workflowDraftId),
          responseError: {
            status: getWorkflowResponse.status,
            error: getWorkflowResponse.error ?? "Load workflow failed",
          },
        });
      }
    };

    /**
     * @param payload
     * @param keyStep
     * @returns Promise<boolean>
     */
    const handleSaveAndNext = async (
      payload: DTO_Workflow_ModifyEntity,
      keyStep?: string,
      isRefreshWorkflowData: boolean = false
    ): Promise<boolean> => {
      //check condition use for Save button
      setIsLoadingProcess(WorkflowProcessMode.Save);
      //Calling process Save at next button
      const response = await postProcessModifyContact(
        WorkflowProcessMode.Save,
        payload
      );
      //set default notification
      const defaultSuccessMessage = "Modify contact was saved successfully.";
      const defaultFailedMessage = "Modify contact could not be saved.";
      if (isSuccessResponse(response) && response?.data?.IsSuccess) {
        if (!isSaveOnNextStep) {
          onClose();
          pushNotification({
            title: response?.data?.Notification ?? defaultSuccessMessage,
            type: "success",
          });
        } else {
          // process onChange data to update the form specific step
          if (keyStep && onChangeRef?.current) {
            const { onChange, nameOf } = onChangeRef.current;
            switch (keyStep) {
              case EKeysOfUpdateContactSteps.Person:
                onChange(nameOf(""), {
                  value: {
                    ...response?.data?.ReturnData?.WorkflowDetail
                      ?.EntityDetails,
                    _option: {
                      Job_Description: {
                        Job_Description:
                          response?.data?.ReturnData?.WorkflowDetail
                            ?.EntityDetails?.Job_Description,
                      },
                    },
                  },
                });
                break;
              case EKeysOfUpdateContactSteps.PostalAddress:
              case EKeysOfUpdateContactSteps.PhysicalAddress:
                onChange(nameOf(""), {
                  value: {
                    ...payload.WorkflowDetail[keyStep],
                    _option: {
                      Locality: {
                        Locality_Code:
                          payload.WorkflowDetail[keyStep]?.Locality,
                      },
                      Street_Name: {
                        Street: payload.WorkflowDetail[keyStep]?.Street_Name,
                      },
                    },
                    [propertyFieldGroupedByDelivery[
                      payload.WorkflowDetail[keyStep]
                        ?.Delivery as EDeliveryOptions
                    ]]: payload.WorkflowDetail[keyStep]?.Property_Name,
                  },
                });
                break;
              case EKeysOfUpdateContactSteps.ConcessionCards:
                onChange(nameOf("Concession_Cards"), {
                  value: payload.WorkflowDetail.Concession_Cards,
                });
                onChange(nameOf(`_option.SelectedConcessionCardHeld`), {
                  value: undefined,
                });
                break;
            }
          }
          // check is the first saving
          if (isFirstSave) {
            setIsFirstSave(false);
            const workflowDraftId = response?.data?.ID ?? 0;
            //set current workflowDraft Id
            setWorkflowDraftId(workflowDraftId);
            // set payload to send
            setWorkflowHeader({
              ...workflowHeader,
              WorkflowDraft: {
                ...workflowHeader.WorkflowDraft,
                Workflow_Draft_Id: workflowDraftId,
              },
            });

            //Refresh data after saving officer region
            if (keyStep === EKeysOfUpdateContactSteps.Person) {
              refreshWorkflowData(workflowDraftId);
            }
          }
        }

        // TODO: Show notification after reloading the step -> enhance later
        if (isRefreshWorkflowData) {
          getWorkflowData().then(() => {
            notificationFormStepRef?.current
              ?.getNotificationFormStep()
              ?.current?.pushNotification({
                title: response?.data?.Notification ?? defaultSuccessMessage,
                type: "success",
              });
          });
        }
        setIsLoadingProcess(undefined);
        return true;
      } else {
        const showNotification = () => {
          notificationFormStepRef?.current
            ?.getNotificationFormStep()
            ?.current?.pushNotification({
              title:
                (isRefreshWorkflowData
                  ? head(response?.data?.Errors)
                  : response.data?.ErrorMessage) ?? defaultFailedMessage,
              type: "error",
              autoClose: false,
            });
        };
        if (isRefreshWorkflowData) {
          getWorkflowData().then(showNotification);
        } else {
          showNotification();
        }
        setIsLoadingProcess(undefined);
        return false;
      }
    };
    //#endregion

    //#region <Handle next button>
    /**
     * @param data
     * @param step
     * @param keyStep
     * @returns
     */
    const handleNextButton = async (
      data: DTO_Workflow_ModifyEntity,
      step: any,
      keyStep: string
    ) => {
      const newData = { ...data };
      const processPayload = processData(newData);
      //send data to call api save
      return handleSaveAndNext(processPayload, keyStep);
    };
    //#endregion

    //#region <Handle cancel button>
    /**
     * @param data
     */
    const handleCancelButton = async (data: DTO_Workflow_ModifyEntity) => {
      if (isFromActionList || !isFirstSave) {
        setDataForCancelDialog({
          cancelAPI: postProcessModifyContact,
          dataCancel: data,
          defaultSuccessMessage: "Modify contact was cancelled successfully.",
          defaultErrorMessage: "Modify contact could not be cancelled.",
        });
      } else {
        onClose();
      }
    };
    //#endregion

    //#region <Handle park process>
    /**
     * @param payload
     * @param mode
     */
    const handleParkProcess = async (
      payload: DTO_Workflow_ModifyEntity,
      mode?: EListSubmitButton
    ) => {
      //set loading button and dialog
      setIsLoadingProcess(WorkflowProcessMode.Park);
      mode === EListSubmitButton.ConfirmCloseYes && setIsLoadingClose(true);
      const actionCloseRetainDialog = () => {
        setIsLoadingClose(false);
        setDataForCloseDialog();
      };
      //props send to process workflow
      const parkProps: INewProcessWorkflow<DTO_Workflow_ModifyEntity> = {
        payload: payload,
        actionSuccess: (e) => {
          onClose();
          pushNotification({
            title: e?.Notification ?? "Modify contact was parked successfully.",
            type: "success",
          });
        },
        setLoading: () => {
          setIsLoadingProcess(undefined);
        },
        actionClose: () => {
          mode === EListSubmitButton.ConfirmCloseYes &&
            actionCloseRetainDialog();
        },
        defaultFailedMessage: "Modify contact could not be parked.",
        modeProcess: WorkflowProcessMode.Park,
      };

      //calling api process workflow
      await handleProcessWorkflow(parkProps);
    };
    //#endregion

    //#region <Handle close dialog>
    /**
     * @param renderProps
     */
    const handleCloseDialog = (renderProps: ICCFormStepRender) => {
      if (!isFromActionList && !isFirstSave) {
        //Store submit event
        setDataForCloseDialog({
          closeCallback: renderProps.submitButton.onClick,
        });
      } else if (
        isIncompleteMode &&
        dataFromActionList?.Workflow_Status_Name === EWorkflowStatus.Park
      ) {
        onClose();
      } else if (
        dataFromActionList?.Workflow_Status_Name ===
          EWorkflowStatus.Incomplete &&
        !isFirstSave
      ) {
        const newEvent = {
          currentTarget: { id: EListSubmitButton.Close },
        };
        renderProps.submitButton.onClick(newEvent);
      } else if (onCloseSpecial) {
        onCloseSpecial();
      } else {
        onClose();
      }
    };
    //#endregion

    //#region <Handle finish process>
    /**
     * @param payload
     */
    const handleFinishProcess = async (payload: DTO_Workflow_ModifyEntity) => {
      //set loading button
      setIsLoadingProcess(WorkflowProcessMode.Finish);
      //props send to process workflow
      const finishProps: INewProcessWorkflow<DTO_Workflow_ModifyEntity> = {
        payload: payload,
        actionSuccess: (e) => {
          onClose();
          isShowNotificationFinish &&
            pushNotification({
              title:
                e?.Notification ?? "Modify contact was updated successfully.",
              type: "success",
            });
          handleSubmitDialog && handleSubmitDialog(payload, e);
        },
        setLoading: () => {
          setIsLoadingProcess(undefined);
        },
        defaultFailedMessage: "Modify contact could not be updated.",
        modeProcess: WorkflowProcessMode.Finish,
      };
      //calling api process workflow
      await handleProcessWorkflow(finishProps);
    };
    //#endregion

    //#region <Handle confirm finish>
    const handleConfirmFinish = (payload: DTO_Workflow_ModifyEntity) => {
      const dataProcessed = processData(payload);
      const finishCallback = async () => {
        return await handleFinishProcess(dataProcessed);
      };
      setDataForFinishDialog({
        finishCallback,
        confirmMessage:
          "The contact will be updated based on the information provided. Are you sure you want to submit?",
      });
    };
    //#endregion

    //#region <Handle submit multiple button>
    const handleSubmit = async (
      data: DTO_Workflow_ModifyEntity,
      buttonId?: string
    ) => {
      switch (buttonId) {
        case EListSubmitButton.Approve:
          await handleApproveProcess(processData(data));
          break;
        case EListSubmitButton.Save:
          await handleSaveAndNext(processData(data));
          break;
        case EListSubmitButton.SaveWorkflow:
          await handleSaveAndNext(processData(data), undefined, true);
          break;
        case EListSubmitButton.Finish:
          handleConfirmFinish(data);
          break;
        case EListSubmitButton.Cancel:
        case EListSubmitButton.ConfirmCloseNo:
          handleCancelButton(processData(data));
          break;
        case EListSubmitButton.ConfirmCloseYes:
          await handleParkProcess(
            processData(data),
            EListSubmitButton.ConfirmCloseYes
          );
          break;
        case EListSubmitButton.Reject:
          handleRejectButton(processData(data));
          break;
        case EListSubmitButton.SendBack:
          handleConfirmSendBackProcess(processData(data));
          break;
        case EListSubmitButton.Reallocate:
          handleConfirmReallocateProcess(processData(data));
          break;
        case EListSubmitButton.Park:
        case EListSubmitButton.Close:
          await handleParkProcess(processData(data));
          break;
      }
    };
    //#endregion

    /**
     * handle confirm reallocate workflow process
     * @param payload
     */
    const handleConfirmReallocateProcess = (
      payload: DTO_Workflow_ModifyEntity
    ) => {
      setDataForReallocateDialog({
        reallocateCallback: postProcessModifyContact,
        dataReallocate: payload,
        defaultSuccessMessage: "Modify contact approved successfully",
        defaultErrorMessage: "Approve modify contact failed",
      });
    };

    //#region <Get workflow data>
    /**
     * load initValue for FormStep
     * call once time
     */
    const getWorkflowData = async () => {
      if (!id) return;
      notificationFormStepRef?.current?.setLoadingFormStep(true);
      await getInitialDataModifyContact(
        WorkflowTypes.Modify_Entity,
        id,
        isFromActionList,
        isUpdateFromChangeOfOwnership
      ).then((response) => {
        notificationFormStepRef?.current?.setLoadingFormStep(false);
        if (Array.isArray(response)) {
          const [lovsContact, workflowData] = response;
          if (
            isSuccessResponse(lovsContact) &&
            lovsContact?.data &&
            isSuccessResponse(workflowData) &&
            workflowData?.data
          ) {
            //set Lovs data Contact
            setUpdateContactLOVs(lovsContact?.data?.dtoEntity_LOVs);
            setModifyContactInitData({
              WorkflowDetail: workflowData.data?.WorkflowDetail,
              WorkflowHeader: workflowData.data?.WorkflowHeader,
            });
            if (workflowData.data?.WorkflowHeader) {
              setWorkflowHeader(workflowData.data?.WorkflowHeader);
              setWorkflowDraftId(
                workflowData.data?.WorkflowHeader?.WorkflowDraft
                  ?.Workflow_Draft_Id ?? 0
              );
            }
          } else {
            let responseError: APIResponse<
              DTO_Entity_LOVs | DTO_Workflow_ModifyEntity | ResponsePacket
            > = response[0];
            if (response[1] && !isSuccessResponse(response[1])) {
              responseError = response[1];
            }
            notificationFormStepRef?.current?.setLoadFailedFormStep({
              onReload: () => getWorkflowData(),
              responseError: {
                status: responseError.status,
                error:
                  (responseError.data as ResponsePacket)?.Errors ??
                  "Load workflow failed",
              },
            });
          }
        } else {
          const responseError = response as APIResponse;
          notificationFormStepRef?.current?.setLoadFailedFormStep({
            onReload: () => getWorkflowData(),
            responseError: {
              status: responseError.status,
              error: "Load workflow failed",
            },
          });
        }
      });
    };
    //#endregion

    //#region <Use Effect calling initial data>
    useEffectOnce(() => {
      getWorkflowData();
    });
    //#endregion

    //#region <Render UX>
    return (
      <>
        <CCFormStep
          ref={notificationFormStepRef}
          onSubmit={handleSubmit}
          listButtonId={listSubmitButton}
          initialSteps={steps}
          initialValues={initialValue}
          saveOnNextStep={isSaveOnNextStep ? handleNextButton : undefined}
          renderForm={(renderProps: ICCFormStepRender) => (
            <CCDialog
              maxWidth="60%"
              maxHeight="90%"
              titleHeader={titleHeader}
              disabled={isLoadingProcess !== undefined || isDisableDialog}
              onClose={() => handleCloseDialog(renderProps)}
              badge={statusBadge}
              bodyElement={renderProps.children}
              footerElement={
                <>
                  <div className={"cc-dialog-footer-actions-right"}>
                    {isShowParkButton(isFromActionList, isIncompleteMode) && (
                      <Button
                        iconClass={
                          isLoadingProcess === WorkflowProcessMode.Park
                            ? "fas fa-spinner fa-spin"
                            : ""
                        }
                        className={"cc-dialog-button"}
                        id={EListSubmitButton.Park}
                        onClick={renderProps.submitButton.onClick}
                        disabled={
                          renderProps.nextButton.disabled ||
                          isLoadingProcess === WorkflowProcessMode.Park
                        }
                      >
                        Park
                      </Button>
                    )}
                    {isShowCancelWorkflowButton && (
                      <Button
                        className={"cc-dialog-button"}
                        disabled={!isNil(isLoadingProcess)}
                        id={EListSubmitButton.Cancel}
                        onClick={renderProps.submitButton.onClick}
                      >
                        Cancel
                      </Button>
                    )}
                    {isToBeApprovalMode &&
                      workflowHeader?.OfficerCanApprove && (
                        <>
                          <Button
                            themeColor="primary"
                            id={EListSubmitButton.SendBack}
                            disabled={renderProps.nextButton.disabled}
                            className={"cc-dialog-button"}
                            onClick={renderProps.submitButton.onClick}
                          >
                            Send Back
                          </Button>
                          <Button
                            themeColor="primary"
                            id={EListSubmitButton.Reallocate}
                            disabled={renderProps.nextButton.disabled}
                            className={"cc-dialog-button"}
                            onClick={renderProps.submitButton.onClick}
                          >
                            Reallocate
                          </Button>
                          <Button
                            themeColor="primary"
                            id={EListSubmitButton.Approve}
                            disabled={
                              isLoadingProcess ===
                                WorkflowProcessMode.Approve ||
                              renderProps.nextButton.disabled
                            }
                            className={"cc-dialog-button"}
                            onClick={renderProps.submitButton.onClick}
                            iconClass={
                              isLoadingProcess === WorkflowProcessMode.Approve
                                ? "fas fa-spinner fa-spin"
                                : ""
                            }
                          >
                            Approve
                          </Button>
                          <Button
                            themeColor="primary"
                            id={EListSubmitButton.Reject}
                            disabled={renderProps.nextButton.disabled}
                            className={"cc-dialog-button"}
                            onClick={renderProps.submitButton.onClick}
                          >
                            Reject
                          </Button>
                        </>
                      )}
                    {!renderProps.prevButton.disabled ? (
                      <Button
                        className={"cc-dialog-button"}
                        themeColor="primary"
                        onClick={renderProps.prevButton.onClick}
                      >
                        Previous
                      </Button>
                    ) : null}
                    {isToBeApprovalMode || isReadOnly ? (
                      !renderProps.isLastStep && (
                        <Button
                          themeColor="primary"
                          id="cc-next-step-button"
                          disabled={
                            isLoadingProcess === WorkflowProcessMode.Save ||
                            renderProps.nextButton.disabled
                          }
                          className={"cc-dialog-button"}
                          iconClass={
                            isLoadingProcess === WorkflowProcessMode.Save
                              ? "fas fa-spinner fa-spin"
                              : ""
                          }
                          onClick={renderProps.nextButton.onClick}
                        >
                          {isLoadingProcess === WorkflowProcessMode.Save
                            ? "Saving"
                            : renderProps.nextButton.label}
                        </Button>
                      )
                    ) : (
                      <Button
                        themeColor="primary"
                        id={renderProps.nextButton.idButton}
                        disabled={
                          (isLoadingProcess === WorkflowProcessMode.Save &&
                            isSaveOnNextStep) ||
                          renderProps.nextButton.disabled
                        }
                        iconClass={
                          isLoadingProcess === WorkflowProcessMode.Save &&
                          isSaveOnNextStep
                            ? "fas fa-spinner fa-spin"
                            : ""
                        }
                        className={"cc-dialog-button"}
                        onClick={renderProps.nextButton.onClick}
                      >
                        {isLoadingProcess === WorkflowProcessMode.Save &&
                        isSaveOnNextStep
                          ? "Saving"
                          : renderProps.nextButton.label}
                      </Button>
                    )}
                  </div>
                </>
              }
            />
          )}
        />
      </>
    );
    //#endregion
  }
);
