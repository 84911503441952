import { eventEmitter } from "@/App";
import {
  odataCoreGetDocuments,
  odataCoreGetDocumentsView,
} from "@app/core/components/common/utils";
import { deleteRefUsageByIdAndRecordType } from "@app/core/delete/buttons/api";
import { ConfirmDelete } from "@app/core/delete/dialogs/_index";
import { IDeleteConfirmation } from "@app/core/delete/dialogs/model";
import {
  addToRecordsById,
  getDocumentById,
  getDocumentImageView,
  saveDocument,
  saveDocumentStatus,
} from "@app/core/documents/api";
import { ImageGalleryDialog } from "@app/core/documents/components/dialog/image-gallery/_index";
import { ManageDocumentDialog } from "@app/core/documents/components/dialog/manage-document/_index";
import { getDocumentContentDownload } from "@app/core/documents/components/dialog/manage-document/api";
import { colDocuments } from "@app/core/documents/config";
import {
  Attachment,
  DOCUMENTSTATUS,
  DocumentClassification,
  IDocuments,
  Svc_Attachment,
  eCheckType,
} from "@app/core/documents/model";
import { convertToImageInfo } from "@app/core/documents/util";
import { CCJournalEventType } from "@app/core/journal/util";
import { postCheckIsSuperAdmin } from "@app/core/notices/[id]/api";
import { TabTableEventType } from "@app/core/tab-table/constant";
import { useTabTableStore } from "@app/core/tab-table/store";
import { useCRMSEventStore } from "@app/products/crms/[id]/store";
import { PPREventType } from "@app/products/town-planning/ppr/constant";
import { APIResponse } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { RECORDSSYSTEM } from "@common/constants/enumerations";
import { RECORDTYPE } from "@common/constants/recordtype";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { IdentityPacketErrorStatus } from "@common/models/sysEnumerations";
import { useCommonCoreStore } from "@common/stores/core/store";
import { useGlobalStore } from "@common/stores/global/store";
import {
  getBoolValueSetting,
  getNumberValueSetting,
} from "@common/stores/products/util";
import { nameOfFactory } from "@common/utils/common";
import { customLogger } from "@common/utils/logger";
import { IAppNotificationItem } from "@components/cc-app-notification/components/notification-item/model";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCGrid } from "@components/cc-grid/_index";
import { CCGridEventType } from "@components/cc-grid/constant";
import { IColumnFields } from "@components/cc-grid/model";
import { Button } from "@progress/kendo-react-buttons";
import fileDownload from "js-file-download";
import { isNull } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";
import { ReactImageGalleryItem } from "react-image-gallery";
import { useEffectOnce } from "react-use";

interface IDocumentsProps {
  id?: number;
  recordType: RECORDTYPE;
  urlApi?: string;
  isUseSharepoint?: boolean;
  isAddToRecords?: boolean;
  isExistedFolderRecords?: boolean;
}

export const Documents = observer(
  ({
    id,
    recordType,
    urlApi,
    isUseSharepoint = false,
    isAddToRecords = false,
    isExistedFolderRecords = false,
  }: IDocumentsProps) => {
    const nameOf = nameOfFactory<IDocuments>();
    const { pushNotification, clearNotifications, clearErrorNotification } =
      useCCAppNotificationStore();
    const { currentUserInfo } = useGlobalStore();
    const { isDisabled } = useTabTableStore();
    const { settings } = useCommonCoreStore();
    const { crmsEventId, reLoadCRMS } = useCRMSEventStore();

    const [isUpdating, setIsUpdating] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [showManageDocumentDialog, setShowManageDocumentDialog] =
      useState<boolean>(false);
    const [showImageGalleryDialog, setShowImageGalleryDialog] =
      useState<boolean>(false);
    const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] =
      useState<boolean>(false);
    const [gridSelectedRows, setGridSelectedRows] = useState<IDocuments[]>([]);
    const [documentInfo, setDocumentInfo] = useState<Attachment>();
    const [imagesInfo, setImagesInfo] = useState<ReactImageGalleryItem[]>([]);
    const [notifications, setNotifications] = useState<IAppNotificationItem[]>(
      []
    );
    const [isVisibleAddToRecords, setIsVisibleAddToRecords] =
      useState<boolean>(false);

    const isUseImageGallery = getBoolValueSetting(
      settings[ECorporateSettingsField.CorporateSettings_UseImageGallery]
    );
    const isEnableFileNumberColumn = getBoolValueSetting(
      settings[
        ECorporateSettingsField.CorporateSettings_Records_EnableFileNumberColumn
      ]
    );
    const isEnableManageDocument = getBoolValueSetting(
      settings[ECorporateSettingsField.CorporateSettings_EnableManageDocuments]
    );
    const recordsSystem = getNumberValueSetting(
      settings[ECorporateSettingsField.CorporateSettings_RecordsSystem]
    );

    const columnFields = useMemo(
      () => {
        let convertColDocuments: IColumnFields[] = colDocuments;
        let isTitleSharepointColumn =
          isUseSharepoint && recordsSystem === RECORDSSYSTEM.SharePoint;
        const removeColumn = (colName: keyof IDocuments) => {
          const idFieldIndex = convertColDocuments.findIndex(
            (element) => element.field === nameOf(colName)
          );
          idFieldIndex !== -1 && convertColDocuments.splice(idFieldIndex, 1);
        };
        if (!isEnableFileNumberColumn) {
          removeColumn("DocumentID");
        }

        // DynamicTitle is the combine of Title and FileName, use for sharepoint cases
        if (isTitleSharepointColumn) {
          removeColumn("Title");
        } else {
          removeColumn("DynamicTitle");
        }
        convertColDocuments = convertColDocuments.map((col) => {
          if (
            col.field ===
            (isTitleSharepointColumn ? nameOf("DynamicTitle") : nameOf("Title"))
          ) {
            col.handleOnClick = (dataItem: IDocuments) => {
              if (isUseSharepoint && dataItem?.OpenURL) {
                window.open(`${dataItem?.OpenURL}`, "_blank");
              } else if (dataItem?.RecordType === eCheckType.WEB_LINK) {
                const url = "http://" + dataItem?.Title;
                window.open(`${url}`, "_blank");
              } else if (dataItem?.RecordType === eCheckType.FILE) {
                if (
                  dataItem.IsImage === eCheckType.IMAGE &&
                  isUseImageGallery
                ) {
                  setIsLoading(true);
                  setShowImageGalleryDialog(true);
                  getDocumentImageView(dataItem.ID).then((response) => {
                    setIsLoading(false);
                    customLogger("Core documents, getDocumentImageView").info(
                      response?.data
                    );
                    if (isSuccessResponse(response)) {
                      if (!response?.data) return;
                      const imgInfo = response?.data?.map((item) =>
                        convertToImageInfo(item)
                      );
                      setImagesInfo(imgInfo);
                    } else {
                      pushNotification({
                        title: `Images load failed`,
                        type: "error",
                      });
                    }
                  });
                } else {
                  if (!isEnableManageDocument) {
                    setIsLoading(true);
                    getDocumentById(dataItem.ID).then(async (response) => {
                      setIsLoading(false);
                      customLogger("Core documents, getDocumentById").info(
                        response?.data
                      );
                      if (isSuccessResponse(response)) {
                        setDocumentInfo(response.data);
                        if (response.data?.Attachment_ID) {
                          const responseDownloadFile =
                            await getDocumentContentDownload(
                              response.data?.Attachment_ID
                            );
                          customLogger(
                            "Core documents, getDocumentContentDownload"
                          ).info(response?.data);
                          if (isSuccessResponse(responseDownloadFile)) {
                            fileDownload(
                              responseDownloadFile.data,
                              response.data.FileEntry?.FileName ?? ""
                            );
                          } else {
                            const idError = response.data?.Attachment_ID
                              ? ` [${response.data?.Attachment_ID}]`
                              : "";
                            pushNotification({
                              title: `Unable to locate attachment${idError}. Refer to your legacy record management system to locate this document.`,
                              type: "error",
                              autoClose: false,
                            });
                          }
                        }
                      } else {
                        const idError = response.data?.Attachment_ID
                          ? ` [${response.data?.Attachment_ID}]`
                          : "";
                        pushNotification({
                          title: `Unable to locate attachment${idError}. Refer to your legacy record management system to locate this document.`,
                          type: "error",
                          autoClose: false,
                        });
                      }
                    });
                  } else {
                    setIsLoading(true);
                    getDocumentById(dataItem.ID).then((response) => {
                      setIsLoading(false);
                      setShowManageDocumentDialog(true);
                      customLogger("Core documents, getDocumentById").info(
                        response?.data
                      );
                      if (isSuccessResponse(response)) {
                        setDocumentInfo(response.data);
                      } else {
                        const idError = response.data?.Attachment_ID
                          ? ` [${response.data?.Attachment_ID}]`
                          : "";
                        pushNotification({
                          title: `Unable to locate attachment${idError}. Refer to your legacy record management system to locate this document.`,
                          type: "error",
                          autoClose: false,
                        });
                      }
                    });
                  }
                }
              }
            };
          }
          return col;
        });
        return convertColDocuments;
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    );

    const handleOnSubmit = async (
      documents: Attachment,
      onlyStatus?: boolean
    ) => {
      setIsUpdating(true);
      if (id && documents?.Attachment_ID) {
        let response: APIResponse;

        if (!onlyStatus) {
          const requestBody = {
            ParentID: id,
            ParentRecordType: recordType,
            AttachmentList: [documents],
            AddDateTimeToFileName: null,
          } as Svc_Attachment;
          response = await saveDocument(requestBody);
          customLogger("Core documents, saveDocument").info(response?.data);
        } else {
          const documentsStatus = documents.Status_ENUM ?? DOCUMENTSTATUS.Draft;
          const docClassification =
            documents.DocumentClassification_ENUM ??
            DocumentClassification.Unclassified;
          response = await saveDocumentStatus(
            id,
            documents.Attachment_ID,
            documentsStatus,
            docClassification
          );
          customLogger("Core documents, saveDocumentStatus").info(
            response?.data
          );
        }
        setIsUpdating(false);
        setShowManageDocumentDialog(false);
        if (isSuccessResponse(response)) {
          clearErrorNotification();
          pushNotification({
            title: "Document updated successfully",
            type: "success",
          });
          eventEmitter.emit(CCGridEventType.RefreshOData);
          eventEmitter.emit(CCJournalEventType.RefreshData);
          eventEmitter.emit(TabTableEventType.RefreshData);
          eventEmitter.emit(PPREventType.RefreshApplication);
        } else {
          pushNotification({
            autoClose: false,
            title: "The document could not be updated.",
            type: "error",
            description: response.error ?? response.statusText,
          });
        }
      }
    };

    const handleOnDelete = async (data: IDeleteConfirmation) => {
      setIsDeleting(true);
      const ids: number[] = gridSelectedRows?.map(
        (item: IDocuments) => item.ID
      );
      const response = await deleteRefUsageByIdAndRecordType(
        ids,
        contentDelete.recordType,
        data?.Reason
      );
      customLogger("Core documents, deleteRefUsageByIdAndRecordType").info(
        response?.data
      );
      setIsDeleting(false);

      if (isSuccessResponse(response)) {
        setGridSelectedRows([]);
        clearErrorNotification();
        setShowConfirmDeleteDialog(false);
        pushNotification({
          title: "Document deleted successfully",
          type: "success",
        });
        eventEmitter.emit(CCGridEventType.RefreshOData);
        eventEmitter.emit(CCJournalEventType.RefreshData);
        eventEmitter.emit(TabTableEventType.RefreshData);
        eventEmitter.emit(PPREventType.RefreshApplication);
      } else {
        setNotifications([
          {
            id: "1",
            autoClose: false,
            title: "Delete document failed",
            type: "error",
            description: response.data?.Errors,
          },
        ]);
      }
    };

    const handleOnAddToRecords = async () => {
      if (gridSelectedRows[0]?.ID) {
        setIsLoading(true);
        const response = await addToRecordsById(gridSelectedRows[0].ID);
        setIsLoading(false);
        if (
          isSuccessResponse(response) &&
          response.data?.ErrorStatus !== IdentityPacketErrorStatus.HasErrors
        ) {
          setGridSelectedRows([]);
          clearNotifications();
          setShowConfirmDeleteDialog(false);

          if (!isExistedFolderRecords && !isNull(crmsEventId)) {
            await reLoadCRMS(false);
          } else {
            eventEmitter.emit(CCGridEventType.RefreshOData);
            eventEmitter.emit(CCJournalEventType.RefreshData);
            eventEmitter.emit(TabTableEventType.RefreshData);
          }

          pushNotification({
            title: "Add to records successfully.",
            type: "success",
          });
        } else {
          pushNotification({
            autoClose: false,
            title: "Add to records failed.",
            type: "error",
            description: response?.data?.Errors,
          });
        }
      }
    };

    const isDisableDeleteButton: boolean = useMemo(() => {
      let isDisable = false;
      if (gridSelectedRows && gridSelectedRows?.length > 0) {
        gridSelectedRows.some((item: IDocuments) => {
          if (item?.FileNumber && isUseSharepoint) {
            isDisable = true;
            return true;
          }
          return false;
        });
      }
      return isDisable;
    }, [gridSelectedRows, isUseSharepoint]);

    const isDisableAddToRecordsButton: boolean = useMemo(() => {
      if (isDisabled || gridSelectedRows.length !== 1) return true;
      let isDisable = true;
      if (
        gridSelectedRows &&
        gridSelectedRows?.length === 1 &&
        gridSelectedRows[0]?.DocumentID
      ) {
        if (
          gridSelectedRows[0].DocumentID.length > 0 &&
          gridSelectedRows[0].DocumentID.includes("*")
        ) {
          isDisable = false;
        }
      }
      return isDisable;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gridSelectedRows]);

    const contentDelete = useMemo(() => {
      if (
        gridSelectedRows &&
        gridSelectedRows?.length === 1 &&
        gridSelectedRows[0]?.RecordType === eCheckType.WEB_LINK
      )
        return {
          title: "Web Link",
          recordType: RECORDTYPE.CORE_WebLink,
        };
      return {
        title: "Attachment",
        recordType: RECORDTYPE.CORE_Attachment,
      };
    }, [gridSelectedRows]);

    // keep comment for future reference, this is a new function
    // that didn't implement anywhere
    // const handleDataReceived = useCallback(
    //   async ({ result }: IDataReceivedParams) => {
    //     if (!result) return;
    //     return {
    //       ...result,
    //       data: result.data.map((dataItem: IDocuments) => ({
    //         ...dataItem,
    //         ID: dataItem.ID || parseInt(dataItem?.FileNumber ?? ""),
    //       })),
    //     };
    //   },
    //   []
    // );

    const checkIsSuperAdmin = async () => {
      if (currentUserInfo?.UserPKID) {
        const response = await postCheckIsSuperAdmin(
          parseInt(currentUserInfo.UserPKID)
        );
        if (isSuccessResponse(response)) {
          setIsVisibleAddToRecords(response.data ?? false);
        } else {
          pushNotification({
            autoClose: false,
            title: "Validate add to records permission failed.",
            type: "error",
            description: response?.error,
          });
        }
      }
    };

    useEffectOnce(() => {
      if (isAddToRecords) {
        checkIsSuperAdmin();
      }
    });

    return (
      <div className="cc-ppr-documents">
        <CCGrid
          toolbar={
            <div className="cc-grid-tools-bar">
              {isVisibleAddToRecords ? (
                <Button
                  type="button"
                  iconClass={
                    isLoading
                      ? "fas fa-spinner fa-spin"
                      : "far fa-arrow-alt-to-top text-success"
                  }
                  disabled={isDisableAddToRecordsButton || isLoading}
                  onClick={handleOnAddToRecords}
                ></Button>
              ) : null}
              <Button
                type="button"
                iconClass="fas fa-minus"
                title="Remove"
                disabled={
                  (isDisabled ? isDisabled : gridSelectedRows.length !== 1) ||
                  isDisableDeleteButton ||
                  isLoading
                }
                onClick={() => setShowConfirmDeleteDialog(true)}
              />
            </div>
          }
          selectableMode="multiple"
          dataUrl={
            isUseSharepoint
              ? odataCoreGetDocumentsView(id, recordType)
              : urlApi
              ? urlApi
              : odataCoreGetDocuments(id, recordType)
          }
          // keep comment for future reference
          // onDataReceived={handleDataReceived}
          primaryField={isUseSharepoint ? nameOf("DocumentID") : nameOf("ID")}
          selectedRows={gridSelectedRows}
          onSelectionChange={(dataItem: IDocuments[]) => {
            if (dataItem) setGridSelectedRows([...dataItem]);
          }}
          columnFields={columnFields}
          state={{
            sort: [{ field: nameOf("RecordedDate"), dir: "desc" }],
          }}
        />
        {showManageDocumentDialog && isEnableManageDocument && (
          <ManageDocumentDialog
            onClose={() => {
              setShowManageDocumentDialog(false);
            }}
            onSubmit={(document: Attachment, onlyStatus?: boolean) => {
              handleOnSubmit(document, onlyStatus);
            }}
            attachmentInfo={documentInfo}
            isUpdating={isUpdating}
            isLoading={isLoading}
          />
        )}
        {showImageGalleryDialog && (
          <ImageGalleryDialog
            onClose={() => {
              setShowImageGalleryDialog(false);
            }}
            imageInfo={imagesInfo}
            isLoading={isLoading}
          />
        )}
        {showConfirmDeleteDialog && (
          <ConfirmDelete
            onClose={() => setShowConfirmDeleteDialog(false)}
            handleSubmit={handleOnDelete}
            isDeleting={isDeleting}
            contentDelete={contentDelete.title}
            header={"Confirm Deletion"}
            notifications={notifications}
          />
        )}
      </div>
    );
  }
);
