import { eventEmitter } from "@/App";
import { history } from "@/AppRoutes";
import { TabTableEventType } from "@app/core/tab-table/constant";
import {
  batchCloseEvent,
  closeEvent,
  eventHandler,
  getCountLinkedSvcStandard,
  getCRMSEventLovs,
  getCRMSEventSummaryById,
  getCRMSNewSimpleEvent,
  getServiceStandardKB,
  postContactManagerFilter,
  postCRMSEvent,
  postCRMSSimpleEvent,
  postSetupRecategoriseFilter,
  rescheduleEvent,
  sendForAction,
  setupCloseEvent,
  setupReschedule,
  simpleEventHandler,
} from "@app/products/crms/[id]/api";
import {
  CloseEventRequest,
  EventClose,
  EventCloseLovs,
} from "@app/products/crms/[id]/components/forms/components/action-bar/dialogs/close-event/model";
import {
  EventReschedule,
  RescheduleMapObj,
} from "@app/products/crms/[id]/components/forms/components/action-bar/dialogs/details-reschedule/model";
import {
  EventBatchClose,
  EventBatchCloseRequest,
} from "@app/products/crms/[id]/components/forms/components/action-bar/dialogs/event-bactch-close/model";
import { EventLOVs } from "@app/products/crms/[id]/components/forms/components/child-screens/general/components/form-element/model";
import { ServiceStandardView } from "@app/products/crms/[id]/components/forms/components/reference-sidebar/details/model";
import { CRMS_ROUTE } from "@app/products/crms/[id]/constant";
import {
  CS_EventStatus,
  CS_IssueLocationType,
  ElementControl,
  Event,
  EventHandlerRequest,
  EventUIControl,
  EventUpdateTriggers,
  IEventsParentSection,
  ISafetyReason,
  PickCategoryFilter,
  ServiceStandardActionTypeLink,
  Svc_EventFormAction,
} from "@app/products/crms/[id]/model";
import { CRMS_ALL_EVENTS_BY_REF_NO_ROUTE } from "@app/products/crms/all-events/by-refno/constant";
import {
  CS_RequestSection,
  NewEventCloneParams,
} from "@app/products/crms/components/dialogs/clone-new-event/model";
import { FormIdentifier } from "@app/products/crms/model";
import { ServiceStandardPickListPacket } from "@app/products/crms/service-standards/[id]/model";
import {
  eventStatusAfterSendForAction,
  generateContactManagerDataURL,
  isFieldVisible,
} from "@app/products/crms/util";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { ACCESSRIGHTS } from "@common/constants/enumerations";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { ActionSubmitActions } from "@common/pages/actions/model";
import { commonCoreStore } from "@common/stores/core/store";
import { getBoolValueSetting } from "@common/stores/products/util";
import { ICCLocalNotificationHandle } from "@components/cc-app-notification/_index";
import {
  IAppNotificationItem,
  IAppNotificationItemAddProps,
} from "@components/cc-app-notification/components/notification-item/model";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { CCGridEventType } from "@components/cc-grid/constant";
import { isEmpty, isNil } from "lodash";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });
class CRMSEventStore {
  private _crmsEvent?: Event = undefined;
  private _crmsEventClose?: EventClose = undefined;
  private _crmsEventCloseLovs?: EventCloseLovs = undefined;
  private _isLoading: boolean = false;
  private _isLoadingKB: boolean = false;
  private _isLoadingCategoryFilter: boolean = false;
  private _isLoadingDetails: boolean = false;
  private _isLoadingSendForAction: boolean = false;
  private _notification?: IAppNotificationItemAddProps = undefined;
  private _formValid?: boolean = false;
  private _responseLoadError?: APIResponseError = undefined;
  private _isShowEventCategorisedAs: boolean = false;
  private _isShowCloseEvent: boolean = false;
  private _isShowEventVIP: boolean = false;
  private _crmsVIP: string = "Unassigned";
  private _crmsActionPerson: string = "Unassigned";
  private _isShowEventActionPerson: boolean = false;
  private _crmsEventLovs?: EventLOVs = undefined;
  private _parentSection?: IEventsParentSection = undefined;
  private _noLocationId?: number = undefined;
  private _isShowRescheduleDialog: boolean = false;
  private _isFormModified: boolean = false;
  private _isLoadingCloseEvent: boolean = false;
  private _isLoadingBatchCloseEvent: boolean = false;
  private _notAllowAccessMsg?: string = undefined;
  private _eventBatchClose?: EventBatchClose = undefined;
  private _isLoadingSubmitSimpleEvent = false;
  private _accessRights?: ACCESSRIGHTS = ACCESSRIGHTS.CanWrite;
  private _isHasVip?: boolean = false;
  private _isGetDescriptionFromEvent?: boolean = false;
  private _articleID?: number = undefined;
  private _lastServiceStandardId?: number = undefined;
  private _categoryFilter?: ServiceStandardPickListPacket = undefined;
  private _eventLitBannerMiddle?: ElementControl = undefined;
  private _isNewEventWithSameDetailOrIssue: boolean = false;

  // show dialog
  private _isShowNewSimpleEventDialog: boolean = false;
  private _isShowBatchCloseEventDialog: boolean = false;
  private _isShowSpecialInterestDialog: boolean = false;
  private _isShowSecurityDialog: boolean = false;
  private _isShowContactVIPDialog: boolean = false;
  private _showSafetyReasonDialog: ISafetyReason = {
    isShow: false,
    titleDialog: "",
    switchState: false,
  };
  private _isShowContactManagerDialog: boolean = false;
  private _isShowResolutionResponsesDialog: boolean = false;
  private _isShowRecategorisationCommentDialog: boolean = false;
  private _isShowReallocateOfficerCommentReDialog: boolean = false;
  private _isShowPickActionTypeDialog: boolean = false;
  private _isShowDuplicateCheckerDialog: boolean = false;

  private _isExpandedAdditionalDetails: boolean = false;

  private _onSubmit?: (event: React.SyntheticEvent<any>) => void = undefined;
  private _uiControl?: EventUIControl = undefined;
  private _eventReschedule?: EventReschedule = undefined;
  private _alerts?: IAppNotificationItemAddProps[] = undefined;
  private _contactManagerDataURL?: string = undefined;
  private _changeSystemToNormalServiceStd?: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  get onSubmit() {
    return this._onSubmit;
  }
  setOnSubmit = (onSubmit: (event: React.SyntheticEvent<any>) => void) => {
    runInAction(() => {
      this._onSubmit = onSubmit;
    });
  };

  get isExpandedAdditionalDetails() {
    return this._isExpandedAdditionalDetails;
  }
  setIsExpandedAdditionalDetails = (isExpandedAdditionalDetails: boolean) => {
    runInAction(() => {
      this._isExpandedAdditionalDetails = isExpandedAdditionalDetails;
    });
  };

  get parentSection() {
    return this._parentSection;
  }
  setParentSection = (parentSection: IEventsParentSection) => {
    runInAction(() => {
      this._parentSection = parentSection;
    });
  };

  get notification() {
    return this._notification;
  }
  setNotification = (
    notification: IAppNotificationItemAddProps | undefined
  ) => {
    runInAction(() => {
      this._notification = notification;
    });
  };

  get formValid() {
    return this._formValid;
  }
  setFormValid = (formValid: boolean) => {
    runInAction(() => {
      this._formValid = formValid;
    });
  };

  get crmsEventLovs() {
    return this._crmsEventLovs;
  }
  setCRMSEventLovs = (crmsEventLovs: EventLOVs | undefined) => {
    runInAction(() => {
      this._crmsEventLovs = crmsEventLovs;
    });
  };

  get noLocationId() {
    return this._noLocationId;
  }
  setNoLocationId = (noLocationId: number | undefined) => {
    runInAction(() => {
      this._noLocationId = noLocationId;
    });
  };

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  get crmsEvent() {
    return this._crmsEvent;
  }
  setCRMSEvent = (crmsEvent: Event | undefined) => {
    runInAction(() => {
      this._crmsEvent = crmsEvent;
    });
  };

  get crmsEventClose() {
    return this._crmsEventClose;
  }
  setCRMSEventClose = (crmsEventClose: EventClose | undefined) => {
    runInAction(() => {
      this._crmsEventClose = crmsEventClose;
    });
  };

  get crmsEventCloseLovs() {
    return this._crmsEventCloseLovs;
  }
  setCRMSEventCloseLovs = (crmsEventCloseLovs: EventCloseLovs | undefined) => {
    runInAction(() => {
      this._crmsEventCloseLovs = crmsEventCloseLovs;
    });
  };

  get eventId() {
    return toJS(this.crmsEvent?.Event_Id);
  }

  get isLoadingDetails() {
    return this._isLoadingDetails;
  }
  setIsLoadingDetails = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoadingDetails = isLoading;
    });
  };

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get isLoadingKB() {
    return this._isLoadingKB;
  }
  setIsLoadingKB = (isLoadingKB: boolean) => {
    runInAction(() => {
      this._isLoadingKB = isLoadingKB;
    });
  };

  get isNewEventWithSameDetailOrIssue() {
    return this._isNewEventWithSameDetailOrIssue;
  }
  setIsNewEventWithSameDetailOrIssue = (
    isNewEventWithSameDetailOrIssue: boolean
  ) => {
    runInAction(() => {
      this._isNewEventWithSameDetailOrIssue = isNewEventWithSameDetailOrIssue;
    });
  };

  get isLoadingCategoryFilter() {
    return this._isLoadingCategoryFilter;
  }
  setIsLoadingCategoryFilter = (isLoadingCategoryFilter: boolean) => {
    runInAction(() => {
      this._isLoadingCategoryFilter = isLoadingCategoryFilter;
    });
  };

  get crmsEventId() {
    return this._crmsEvent?.Event_Id;
  }

  get isShowEventCategorisedAs() {
    return this._isShowEventCategorisedAs;
  }
  setShowEventCategorisedAs = (isShowEventCategorisedAs: boolean) => {
    runInAction(() => {
      this._isShowEventCategorisedAs = isShowEventCategorisedAs;
    });
  };

  get isShowCloseEvent() {
    return this._isShowCloseEvent;
  }
  setIsShowCloseEvent = (isShowCloseEvent: boolean) => {
    runInAction(() => {
      this._isShowCloseEvent = isShowCloseEvent;
    });
  };

  get isShowEventVIP() {
    return this._isShowEventVIP;
  }
  setShowEventVIP = (isShowEventVIP: boolean) => {
    runInAction(() => {
      this._isShowEventVIP = isShowEventVIP;
    });
  };

  get crmsVIP() {
    return this._crmsVIP;
  }
  setEventVIP = (crmsVIP: string) => {
    runInAction(() => {
      this._crmsVIP = crmsVIP;
    });
  };

  get crmsActionPerson() {
    return this._crmsActionPerson;
  }
  setEventActionPerson = (crmsActionPerson: string) => {
    runInAction(() => {
      this._crmsActionPerson = crmsActionPerson;
    });
  };

  get isShowEventActionPerson() {
    return this._isShowEventActionPerson;
  }
  setShowEventActionPerson = (isShowEventActionPerson: boolean) => {
    runInAction(() => {
      this._isShowEventActionPerson = isShowEventActionPerson;
    });
  };

  get isFormModified() {
    return this._isFormModified;
  }
  setIsFormModified = (isFormModified: boolean) => {
    runInAction(() => {
      this._isFormModified = isFormModified;
    });
  };

  get uiControl() {
    return this._uiControl;
  }
  setUIControl = (uiControl?: EventUIControl) => {
    runInAction(() => {
      this._uiControl = uiControl;
    });
  };

  // get, set show Dialog
  get isShowSpecialInterestDialog() {
    return this._isShowSpecialInterestDialog;
  }
  setIsShowSpecialInterestDialog = (isShowSpecialInterestDialog: boolean) => {
    runInAction(() => {
      this._isShowSpecialInterestDialog = isShowSpecialInterestDialog;
    });
  };

  get isShowSecurityDialog() {
    return this._isShowSecurityDialog;
  }
  setIsShowSecurityDialog = (isShowSecurityDialog: boolean) => {
    runInAction(() => {
      this._isShowSecurityDialog = isShowSecurityDialog;
    });
  };

  get isShowContactVIPDialog() {
    return this._isShowContactVIPDialog;
  }
  setIsShowContactVIPDialog = (isShowContactVIPDialog: boolean) => {
    runInAction(() => {
      this._isShowContactVIPDialog = isShowContactVIPDialog;
    });
  };

  get isShowRescheduleDialog() {
    return this._isShowRescheduleDialog;
  }
  setIsShowRescheduleDialog = (isShowRescheduleDialog: boolean) => {
    runInAction(() => {
      this._isShowRescheduleDialog = isShowRescheduleDialog;
    });
  };

  get showSafetyReasonDialog() {
    return this._showSafetyReasonDialog;
  }
  setShowSafetyReasonDialog = (showSafetyReasonDialog: ISafetyReason) => {
    runInAction(() => {
      this._showSafetyReasonDialog = showSafetyReasonDialog;
    });
  };

  get isShowContactManagerDialog() {
    return this._isShowContactManagerDialog;
  }
  setIsShowContactManagerDialog = (isShowContactManagerDialog: boolean) => {
    runInAction(() => {
      this._isShowContactManagerDialog = isShowContactManagerDialog;
    });
  };

  get isShowReallocateOfficerCommentReDialog() {
    return this._isShowReallocateOfficerCommentReDialog;
  }
  setIsShowReallocateOfficerCommentReDialog = (
    isShowReallocateOfficerCommentReDialog: boolean
  ) => {
    runInAction(() => {
      this._isShowReallocateOfficerCommentReDialog =
        isShowReallocateOfficerCommentReDialog;
    });
  };

  get isShowRecategorisationCommentDialog() {
    return this._isShowRecategorisationCommentDialog;
  }
  setIsShowRecategorisationCommentDialog = (
    isShowRecategorisationCommentDialog: boolean
  ) => {
    runInAction(() => {
      this._isShowRecategorisationCommentDialog =
        isShowRecategorisationCommentDialog;
    });
  };

  get isShowDuplicateCheckerDialog() {
    return this._isShowDuplicateCheckerDialog;
  }
  setIsShowDuplicateCheckerDialog = (isShowDuplicateCheckerDialog: boolean) => {
    runInAction(() => {
      this._isShowDuplicateCheckerDialog = isShowDuplicateCheckerDialog;
    });
  };

  get eventReschedule() {
    return this._eventReschedule;
  }
  setEventReschedule = (eventReschedule: EventReschedule | undefined) => {
    runInAction(() => {
      this._eventReschedule = eventReschedule;
    });
  };

  get alerts() {
    return this._alerts;
  }
  setAlerts = (alerts: IAppNotificationItemAddProps[] | undefined) => {
    runInAction(() => {
      this._alerts = alerts;
    });
  };

  get isLoadingCloseEvent() {
    return this._isLoadingCloseEvent;
  }
  setIsLoadingCloseEvent = (isLoadingCloseEvent: boolean) => {
    runInAction(() => {
      this._isLoadingCloseEvent = isLoadingCloseEvent;
    });
  };

  get isLoadingBatchCloseEvent() {
    return this._isLoadingBatchCloseEvent;
  }
  setIsLoadingBatchCloseEvent = (isLoadingBatchCloseEvent: boolean) => {
    runInAction(() => {
      this._isLoadingBatchCloseEvent = isLoadingBatchCloseEvent;
    });
  };

  get isShowBatchCloseEventsDialog() {
    return this._isShowBatchCloseEventDialog;
  }
  setIsShowBatchCloseEventsDialog = (isShowBatchCloseEventsDialog: boolean) => {
    runInAction(() => {
      this._isShowBatchCloseEventDialog = isShowBatchCloseEventsDialog;
    });
  };

  get isShowNewSimpleEventDialog() {
    return this._isShowNewSimpleEventDialog;
  }
  setIsShowNewSimpleEventDialog = (isShowNewSimpleEventDialog: boolean) => {
    runInAction(() => {
      this._isShowNewSimpleEventDialog = isShowNewSimpleEventDialog;
    });
  };

  get isLoadingSubmitSimpleEvent() {
    return this._isLoadingSubmitSimpleEvent;
  }
  setIsLoadingSubmitSimpleEvent = (isLoadingSubmitSimpleEvent: boolean) => {
    runInAction(() => {
      this._isLoadingSubmitSimpleEvent = isLoadingSubmitSimpleEvent;
    });
  };

  get eventBatchClose() {
    return this._eventBatchClose;
  }
  setEventBatchClose = (eventBatchClose?: EventBatchClose) => {
    runInAction(() => {
      this._eventBatchClose = eventBatchClose;
    });
  };

  get notAllowAccessMsg() {
    return this._notAllowAccessMsg;
  }
  setNotAllowAccessMsg = (notAllowAccessMsg: string) => {
    runInAction(() => {
      this._notAllowAccessMsg = notAllowAccessMsg;
    });
  };

  get accessRights() {
    return this._accessRights;
  }
  setAccessRights = (accessRights: ACCESSRIGHTS) => {
    runInAction(() => {
      this._accessRights = accessRights;
    });
  };

  get isHasVip() {
    return this._isHasVip;
  }
  setIsHasVip = (isHasVip: boolean) => {
    runInAction(() => {
      this._isHasVip = isHasVip;
    });
  };

  get contactManagerDataURL() {
    return this._contactManagerDataURL;
  }
  setContactManagerDataURL = (contactManagerDataURL: string) => {
    runInAction(() => {
      this._contactManagerDataURL = contactManagerDataURL;
    });
  };

  get isShowResolutionResponsesDialog() {
    return this._isShowResolutionResponsesDialog;
  }
  setIsShowResolutionResponsesDialog = (
    isShowResolutionResponsesDialog: boolean
  ) => {
    runInAction(() => {
      this._isShowResolutionResponsesDialog = isShowResolutionResponsesDialog;
    });
  };

  get isGetDescriptionFromEvent() {
    return this._isGetDescriptionFromEvent;
  }
  setIsGetDescriptionFromEvent = (isGetDescriptionFromEvent: boolean) => {
    runInAction(() => {
      this._isGetDescriptionFromEvent = isGetDescriptionFromEvent;
    });
  };

  get articleID() {
    return this._articleID;
  }
  setArticleID = (articleID: number | undefined) => {
    runInAction(() => {
      this._articleID = articleID;
    });
  };

  get lastServiceStandardId() {
    return this._lastServiceStandardId;
  }
  setLastServiceStandardId = (lastServiceStandardId: number) => {
    runInAction(() => {
      this._lastServiceStandardId = lastServiceStandardId;
    });
  };

  get categoryFilter() {
    return toJS(this._categoryFilter);
  }
  setCategoryFilter = (categoryFilter: ServiceStandardPickListPacket) => {
    runInAction(() => {
      this._categoryFilter = categoryFilter;
    });
  };

  get isShowPickActionTypeDialog() {
    return this._isShowPickActionTypeDialog;
  }
  setIsShowPickActionTypeDialog = (isShowPickActionTypeDialog: boolean) => {
    runInAction(() => {
      this._isShowPickActionTypeDialog = isShowPickActionTypeDialog;
    });
  };

  get isLoadingSendForAction() {
    return this._isLoadingSendForAction;
  }
  setIsLoadingSendForAction = (isLoadingSendForAction: boolean) => {
    runInAction(() => {
      this._isLoadingSendForAction = isLoadingSendForAction;
    });
  };

  get eventLitBannerMiddle() {
    return this._eventLitBannerMiddle;
  }
  setEventLitBannerMiddle = (eventLitBannerMiddle?: ElementControl) => {
    runInAction(() => {
      this._eventLitBannerMiddle = eventLitBannerMiddle;
    });
  };

  get changeSystemToNormalServiceStd() {
    return this._changeSystemToNormalServiceStd;
  }
  setChangeSystemToNormalServiceStd = (
    changeSystemToNormalServiceStd: boolean
  ) => {
    runInAction(() => {
      this._changeSystemToNormalServiceStd = changeSystemToNormalServiceStd;
    });
  };

  //Action
  resetStore = () => {
    runInAction(() => {
      this._isLoading = false;
      this._crmsEvent = undefined;
      this._parentSection = undefined;
      this._onSubmit = undefined;
      this._responseLoadError = undefined;
      this._uiControl = undefined;
      this._isShowSpecialInterestDialog = false;
      this._isShowSecurityDialog = false;
      this._isLoadingCloseEvent = false;
      this._notAllowAccessMsg = undefined;
      this._accessRights = ACCESSRIGHTS.CanWrite;
      this._isHasVip = false;
      this._contactManagerDataURL = undefined;
      this._isShowContactManagerDialog = false;
      this._isShowReallocateOfficerCommentReDialog = false;
      this._isShowRecategorisationCommentDialog = false;
      this._isShowDuplicateCheckerDialog = false;
      this._isGetDescriptionFromEvent = false;
      this._articleID = undefined;
      this._isLoadingKB = false;
      this._lastServiceStandardId = undefined;
      this._categoryFilter = undefined;
      this._isLoadingCategoryFilter = false;
      this._isShowPickActionTypeDialog = false;
      this._isLoadingSendForAction = false;
      this._isNewEventWithSameDetailOrIssue = false;
      this._eventLitBannerMiddle = undefined;
      this._changeSystemToNormalServiceStd = false;
    });
  };

  loadCRMSEvent = async (
    crmsEventId: number,
    isNew: boolean = false,
    eventCloneParams?: NewEventCloneParams,
    mustRefreshNotification?: IAppNotificationItemAddProps
  ) => {
    this.setIsLoading(true);
    this.setIsLoadingDetails(true);
    this.setResponseLoadError(undefined);
    let crmsLovs = undefined;

    if (!isNew) {
      // Reload accordion
      eventEmitter.emit(CCGridEventType.RefreshOData);
      eventEmitter.emit(TabTableEventType.RefreshData);

      const [response, responseLovs] = await Promise.all([
        getCRMSEventSummaryById(crmsEventId),
        getCRMSEventLovs(),
      ]);

      if (
        isSuccessResponse(response) &&
        response.data?.ReturnObj &&
        isSuccessResponse(responseLovs) &&
        responseLovs.data
      ) {
        this.setAccessRights(response.data?.ReturnObj?.AccessRights);
        // Call event handler at the first time
        let params: EventHandlerRequest = {
          EventFormAction: Svc_EventFormAction.SystemInitialise,
          Event: response.data.ReturnObj.Event,
          EventArgs: {},
          isFirstTimeLoad: true,
        };
        const responseEventHandler = await eventHandler(params);
        if (
          isSuccessResponse(responseEventHandler) &&
          responseEventHandler.data?.ReturnObj
        ) {
          const returnObj = responseEventHandler.data.ReturnObj;
          this.setCRMSEvent(returnObj.Event);
          this.setUIControl(returnObj.UIControl);
          this.setEventLitBannerMiddle(returnObj.UIControl?.LitBannerMiddle);

          // push notification when add attachment
          if (mustRefreshNotification) {
            appNotificationStore.pushNotification(mustRefreshNotification);
          }

          // Customer Alert
          let alerts: IAppNotificationItemAddProps[] = [];
          if (returnObj.Event?.RequestedBy?.Contact?.ContactAlert) {
            alerts.push({
              title: `Requestor Alert: ${returnObj.Event?.RequestedBy?.Contact?.ContactAlert}`,
              type: "warning",
              autoClose: false,
            });
          }

          if (returnObj.Event?.OnBehalfOf?.Contact?.ContactAlert) {
            alerts.push({
              title: `On Behalf Of Alert: ${returnObj.Event?.OnBehalfOf?.Contact?.ContactAlert}`,
              type: "warning",
              autoClose: false,
            });
          }

          // Property Alert
          if (returnObj.Event?.IssueLocation?.LocationAddress?.PropertyAlert) {
            alerts.push({
              title: `Property Alert: ${returnObj.Event?.IssueLocation?.LocationAddress?.PropertyAlert}`,
              type: "warning",
              autoClose: false,
            });
          }

          // Acknowledgement Alert
          const isCorrespondenceRequired = getBoolValueSetting(
            commonCoreStore.settings?.[
              ECorporateSettingsField
                .CUSTOMERSERVICE_SourceType_CorrespondenceAcknowledgementRequired
            ]
          );
          const isDisplayAdviceText = isFieldVisible(
            this.uiControl?.DivEventDetailsAdviceText?.DisplayStatus
          );
          const isHaveAdviceText =
            !!this.uiControl?.LblEventDetailsAdviceText?.Value;
          const isHaveSource = !isNil(this.crmsEvent?.Source_ID);
          const isClosedStatus =
            this.crmsEvent?.EventStatus_ENUM !== CS_EventStatus.Closed;
          const isNotHaveDateAcknowledge = !this.crmsEvent?.DateAcknowledged;
          if (
            isDisplayAdviceText &&
            isHaveAdviceText &&
            isHaveSource &&
            isCorrespondenceRequired &&
            isClosedStatus &&
            isNotHaveDateAcknowledge
          ) {
            alerts.push({
              autoClose: false,
              title: this.uiControl?.LblEventDetailsAdviceText?.Value,
              type: "warning",
            });
          }

          // Set alerts
          if (alerts?.length > 0) {
            this.setAlerts(alerts);
          }
          if (responseEventHandler.data.Notifications.length > 0) {
            appNotificationStore.pushNotification({
              type: "warning",
              description: responseEventHandler.data.Notifications,
              autoClose: false,
            });
          }
        } else {
          this.setResponseLoadError({
            status: responseEventHandler.status,
            error:
              responseEventHandler.data?.Errors ?? responseEventHandler.error,
          });
        }
        crmsLovs = responseLovs.data;
      } else {
        const accessRights = response?.data?.ReturnObj
          ?.AccessRights as unknown as keyof typeof ACCESSRIGHTS;
        const isValidAccessRight =
          accessRights && ACCESSRIGHTS[accessRights] === ACCESSRIGHTS.Default;

        if (response.data && isValidAccessRight) {
          this.setAccessRights(ACCESSRIGHTS.Default);
          this.setNotAllowAccessMsg(response.data.Errors[0]);
        } else {
          this.setResponseLoadError({
            status: response.status ?? responseLovs.status,
            error:
              response.data?.Errors ?? responseLovs.error ?? response.error,
          });
        }
      }
      this.setCRMSEventLovs(crmsLovs);
      this.setIsLoading(false);
      this.setIsLoadingDetails(false);

      // Push notification
      if (this.parentSection?.notification) {
        this.parentSection?.notification.forEach(
          (notification: IAppNotificationItemAddProps) => {
            appNotificationStore.pushNotification(notification);
          }
        );
        this.setParentSection({ ...this._parentSection, notification: [] });
      }

      // Push notification
      if (this.notification) {
        appNotificationStore.pushNotification(this.notification);
        this.setNotification(undefined);
      }

      // Push alerts
      if (this.alerts && this.alerts.length > 0) {
        this.alerts.forEach((alert) => {
          appNotificationStore.pushNotification(alert);
        });
        this.setAlerts(undefined);
      }
    } else {
      let eventInit = undefined;
      let uiControl = undefined;

      //Prevent show "the form is not modified" message when create event same issue or same details.
      if (eventCloneParams) this.setIsFormModified(true);

      // handle logic to show event description in resolution and response dialog
      if (eventCloneParams?.sameIssue) {
        this.setIsGetDescriptionFromEvent(true);
      } else {
        this.setIsGetDescriptionFromEvent(false);
      }

      const [response, responseLovs] = await Promise.all(
        eventCloneParams && eventCloneParams.id
          ? [
              getCRMSEventSummaryById(eventCloneParams.id, eventCloneParams),
              getCRMSEventLovs(),
            ]
          : [getCRMSEventSummaryById(0), getCRMSEventLovs()]
      );
      if (
        isSuccessResponse(response) &&
        response.data?.ReturnObj &&
        isSuccessResponse(responseLovs) &&
        responseLovs.data
      ) {
        const processEventInfo = this.processFromParent(
          response.data.ReturnObj.Event
        );
        let params: EventHandlerRequest = {
          EventFormAction: Svc_EventFormAction.SystemInitialise,
          Event: processEventInfo,
          EventArgs: {},
          isFirstTimeLoad: true,
        };
        const responseEventHandler = await eventHandler(params);
        if (
          isSuccessResponse(responseEventHandler) &&
          responseEventHandler.data?.ReturnObj
        ) {
          uiControl = responseEventHandler.data.ReturnObj.UIControl;
          eventInit = responseEventHandler.data.ReturnObj.Event;
          crmsLovs = responseLovs.data;
          const noLocationId = crmsLovs?.LocationType?.find(
            (item) =>
              item.LookupTable_EnumeratedValue_ENUM ===
              CS_IssueLocationType.NoLocation
          )?.CustomerService_LookupTable_Id;
          this.setNoLocationId(noLocationId);

          if (this.articleID) {
            this.setupCreateEventFromKB(this.articleID);
          }

          if (
            responseEventHandler.data.ReturnObj?.Event &&
            eventCloneParams &&
            (eventCloneParams?.detailsToCopy?.includes(
              CS_RequestSection.SameCategory
            ) ||
              eventCloneParams?.sameIssue) &&
            (responseEventHandler.data.ReturnObj.Event?.ServiceStandard
              ?.Response1 ||
              responseEventHandler.data.ReturnObj.Event?.ServiceStandard
                ?.Response2 ||
              responseEventHandler.data.ReturnObj.Event?.ServiceStandard
                ?.Response3)
          ) {
            this.setIsShowResolutionResponsesDialog(true);
          }
        } else {
          this.setResponseLoadError({
            status: responseEventHandler.status,
            error:
              responseEventHandler.data?.Errors ?? responseEventHandler.error,
          });
        }
      } else {
        this.setResponseLoadError({
          status: response.status ?? responseLovs.status,
          error: response.data?.Errors ?? responseLovs.error ?? response.error,
        });
      }
      this.setUIControl(uiControl);
      this.setEventLitBannerMiddle(uiControl?.LitBannerMiddle);
      this.setCRMSEvent(eventInit);
      this.setCRMSEventLovs(crmsLovs);
      this.setIsLoading(false);
      this.setIsLoadingDetails(false);
    }
  };

  loadCRMSSimpleEvent = async () => {
    this.setIsLoading(true);
    this.setResponseLoadError(undefined);

    const [response, responseLovs] = await Promise.all([
      getCRMSNewSimpleEvent(),
      getCRMSEventLovs(),
    ]);

    let eventInit = undefined;
    let uiControl = undefined;
    let crmsLovs = undefined;

    if (
      isSuccessResponse(response) &&
      response.data?.ReturnObj &&
      isSuccessResponse(responseLovs) &&
      responseLovs.data
    ) {
      let params: EventHandlerRequest = {
        EventFormAction: Svc_EventFormAction.SystemInitialise,
        Event: response.data.ReturnObj,
        EventArgs: {},
        isFirstTimeLoad: true,
      };
      const responseEventHandler = await simpleEventHandler(params);
      if (
        isSuccessResponse(responseEventHandler) &&
        responseEventHandler.data?.ReturnObj
      ) {
        uiControl = responseEventHandler.data.ReturnObj.UIControl;
        eventInit = responseEventHandler.data.ReturnObj.Event;
        crmsLovs = responseLovs.data;
        const noLocationId = crmsLovs?.LocationType?.find(
          (item) =>
            item?.LookupTable_EnumeratedValue_ENUM ===
            CS_IssueLocationType.NoLocation
        )?.CustomerService_LookupTable_Id;
        this.setNoLocationId(noLocationId);
      } else {
        this.setResponseLoadError({
          status: responseEventHandler.status,
          error:
            responseEventHandler.data?.Errors ?? responseEventHandler.error,
        });
      }
    } else {
      this.setResponseLoadError({
        status: response.status ?? responseLovs.status,
        error: response.data?.Errors ?? responseLovs.error ?? response.error,
      });
    }
    this.setUIControl(uiControl);
    this.setCRMSEvent(eventInit);
    this.setCRMSEventLovs(crmsLovs);
    this.setIsLoading(false);
  };

  loadCRMSEventDetails = async (crmsEventId: number) => {
    this.setIsLoadingDetails(true);
    const response = await getCRMSEventSummaryById(crmsEventId);
    this.setIsLoadingDetails(false);
    if (isSuccessResponse(response) && response.data?.ReturnObj) {
      this.setCRMSEvent(response.data.ReturnObj.Event);
    } else {
      this.setResponseLoadError({
        status: response.status,
        error: response.data?.Errors ?? response.error,
      });
    }
  };

  reLoadCRMS = async (isNew: boolean) => {
    if (this.eventId) {
      await this.loadCRMSEvent(this.eventId, isNew);
    }
  };

  submitAction = async (
    crmsEvent: Event,
    isNew: boolean = false,
    action: ActionSubmitActions,
    loginUserID: string
  ) => {
    if (action === ActionSubmitActions.Save) {
      appNotificationStore.clearNotifications();
      this.setIsLoading(true);
      let newCRMS = undefined;
      const response = await postCRMSEvent(crmsEvent);
      this.setIsLoading(false);

      if (isSuccessResponse(response)) {
        newCRMS = response.data;
        if (this.isFormModified) this.setIsFormModified(false);
        if (isNew) {
          history.replace(CRMS_ROUTE + "/" + newCRMS?.ID.toString(), {
            notification: [
              {
                title: "Record successfully saved.",
                type: "success",
              },
            ],
          });
        } else {
          this.reLoadCRMS(isNew);
          this.setNotification({
            title: "Record successfully saved.",
            type: "success",
          });
        }
      } else {
        appNotificationStore.pushNotification({
          autoClose: false,
          title: "Event could not be saved.",
          type: "error",
          description: response.data?.Errors ?? response.error,
        });
      }
    }

    if (action === ActionSubmitActions.SendForAction) {
      appNotificationStore.clearNotifications();

      const isSendForActionFirstTime = !eventStatusAfterSendForAction.includes(
        crmsEvent.EventStatus_ENUM
      );

      const isEnableManualRemovalOfAutoAction = getBoolValueSetting(
        commonCoreStore.settings?.[
          ECorporateSettingsField
            .CUSTOMERSERVICE_Event_ManualRemovalOfAutoAction
        ]
      );
      const isCreateWorkflowActions = getBoolValueSetting(
        commonCoreStore.settings?.[
          ECorporateSettingsField
            .CUSTOMERSERVICE_SendForAction_CreateWorkflowActions
        ]
      );
      const isEnableAutoActions =
        crmsEvent?.ServiceStandard?.Flag_WorkflowCreateActions;
      const hasWorkflowActions =
        crmsEvent?.ServiceStandard?.WorkflowActions?.length > 0;

      const canAutoAction =
        isCreateWorkflowActions &&
        isSendForActionFirstTime &&
        isEnableAutoActions &&
        hasWorkflowActions;

      if (canAutoAction && isEnableManualRemovalOfAutoAction) {
        this.setIsShowPickActionTypeDialog(true);
        return;
      } else if (canAutoAction && !isEnableManualRemovalOfAutoAction) {
        const workflowActionIds =
          crmsEvent?.ServiceStandard?.WorkflowActions?.map(
            (item: ServiceStandardActionTypeLink) => item.ActionType_ID
          ) ?? [];
        const params: EventHandlerRequest = {
          EventFormAction: Svc_EventFormAction.Form_PickAutoAction,
          Event: crmsEvent,
          EventArgs: workflowActionIds,
          isFirstTimeLoad: false,
        };
        this.setIsLoading(true);
        const response = await eventHandler(params);
        this.setIsLoading(false);
        if (isSuccessResponse(response) && response.data?.ReturnObj) {
          this.sendForActionHandler(
            response.data.ReturnObj.Event,
            loginUserID,
            isNew
          );
        } else {
          appNotificationStore.pushNotification({
            autoClose: false,
            title: "Set workflow action failed.",
            type: "error",
            description: response.data?.Errors ?? response.error,
          });
        }
      } else {
        await this.sendForActionHandler(crmsEvent, loginUserID, isNew);
      }
    }

    if (action === ActionSubmitActions.CloseEvent) {
      this.setIsLoading(true);
      const response = await setupCloseEvent(crmsEvent);
      this.setIsLoading(false);
      if (isSuccessResponse(response) && response.data) {
        let closeEventObj = response.data.ReturnObj;
        if (
          (closeEventObj?.EventClose?.AcknowledgementRequired ||
            (closeEventObj?.EventClose?.CallReturned ?? false)) &&
          !this.crmsEventLovs?.AcknowledgedHow?.find(
            (item) =>
              item.CustomerService_LookupTable_Id ===
              closeEventObj?.EventClose?.AcknowledgedMethod_ID
          )
        ) {
          closeEventObj.EventClose.AcknowledgedMethod_ID = null;
        }
        this.setCRMSEventClose(closeEventObj.EventClose);
        this.setCRMSEventCloseLovs(closeEventObj.EventCloseLovs);
        this.setIsShowCloseEvent(true);
      } else {
        appNotificationStore.pushNotification({
          autoClose: false,
          title: "Setup close event failed.",
          type: "error",
          description: response.data?.Errors ?? response.error,
        });
      }
    }

    if (action === ActionSubmitActions.SpecialInterest) {
      this.setIsShowSpecialInterestDialog(true);
    }

    if (action === ActionSubmitActions.AccessControl) {
      this.setIsShowSecurityDialog(true);
    }

    if (action === ActionSubmitActions.RescheduleAction) {
      this.setIsLoading(true);
      const response = await setupReschedule(crmsEvent);
      this.setIsLoading(false);
      if (isSuccessResponse(response) && response.data) {
        this.setCRMSEvent(crmsEvent);
        this.setIsShowRescheduleDialog(true);
        this.setEventReschedule(response.data.ReturnObj);
      } else {
        appNotificationStore.pushNotification({
          autoClose: false,
          title: "Setup reschedule event failed.",
          type: "error",
          description: response.data?.Errors ?? response.error,
        });
      }
    }

    if (action === ActionSubmitActions.ChangeActionOfficer) {
      this.setIsLoading(true);
      const response = await postContactManagerFilter(crmsEvent);
      this.setIsLoading(false);
      if (
        isSuccessResponse(response) &&
        response.data &&
        response.data?._OrgStructureFilter
      ) {
        const filterString = generateContactManagerDataURL(
          response.data?._OrgStructureFilter
        );
        this.setContactManagerDataURL(filterString);
        this.setIsShowContactManagerDialog(true);
      } else {
        appNotificationStore.pushNotification({
          autoClose: false,
          title: "Setup reschedule event failed.",
          type: "error",
          description: response.error,
        });
      }
    }
  };

  sendForActionHandler = async (
    crmsEvent: Event,
    loginUserID: string,
    isNew: boolean = false
  ) => {
    this.setIsLoadingSendForAction(true);
    const response = await sendForAction(crmsEvent);
    this.setIsLoadingSendForAction(false);
    if (isSuccessResponse(response) && response.data) {
      if (
        parseInt(loginUserID) !== response.data.Event?.ActionOfficer_SiteUser_ID
      ) {
        history.replace(CRMS_ALL_EVENTS_BY_REF_NO_ROUTE);
        appNotificationStore.pushNotification({
          title: "Event successfully send for action.",
          type: "success",
        });
      } else {
        if (isNew) {
          history.replace(CRMS_ROUTE + "/" + response.data.ID);
          this.setNotification({
            title: "Event successfully send for action.",
            type: "success",
          });
        } else {
          this.setNotification({
            title: "Event successfully send for action.",
            type: "success",
          });
          this.reLoadCRMS(isNew);
        }
      }
    } else {
      const notificationFail: IAppNotificationItemAddProps = {
        autoClose: false,
        title: "Event send for action failed.",
        type: "error",
        description: response.data?.Errors ?? response.error,
      };
      if (response.data?.ID) {
        if (isNew) {
          history.replace(CRMS_ROUTE + "/" + response.data.ID);
          this.setNotification(notificationFail);
        } else {
          this.reLoadCRMS(isNew);
          this.setNotification(notificationFail);
        }
      } else {
        appNotificationStore.pushNotification(notificationFail);
      }
    }
  };

  closeEventHandler = async (
    closeEventInfo: CloseEventRequest,
    isNew: boolean
  ) => {
    this.setIsLoadingCloseEvent(true);
    const response = await closeEvent(closeEventInfo);
    this.setIsLoadingCloseEvent(false);
    if (isSuccessResponse(response) && response.data) {
      appNotificationStore.clearNotifications();
      this.setIsShowCloseEvent(false);
      history.replace(CRMS_ALL_EVENTS_BY_REF_NO_ROUTE);
      appNotificationStore.pushNotification({
        title: "Event successfully closed.",
        type: "success",
      });
    } else {
      const notificationFail: IAppNotificationItemAddProps = {
        autoClose: false,
        title: "Event closed failed.",
        type: "error",
        description: response.data?.Errors ?? response.error,
      };
      if (response.data?.ID) {
        if (isNew) {
          history.replace(CRMS_ROUTE + "/" + response.data.ID);
          this.setNotification(notificationFail);
        } else {
          this.reLoadCRMS(isNew);
          this.setNotification(notificationFail);
        }
      } else {
        this.setNotification(notificationFail);
      }
    }
  };

  batchCloseEventHandler = async (closeEventInfo: EventBatchCloseRequest) => {
    this.setIsLoadingBatchCloseEvent(true);
    const response = await batchCloseEvent(
      closeEventInfo,
      FormIdentifier.CUSTOMERSERVICE_Form_Event
    );

    this.setIsLoadingBatchCloseEvent(false);
    if (isSuccessResponse(response)) {
      appNotificationStore.clearNotifications();

      appNotificationStore.pushNotification({
        title: "Event(s) successfully closed.",
        type: "success",
      });
    } else {
      appNotificationStore.pushNotification({
        autoClose: false,
        title: "Event closed failed.",
        type: "error",
        description: response?.data?.Errors ?? response?.error,
      });
    }

    eventEmitter.emit(CCGridEventType.RefreshOData);
    this.setIsShowBatchCloseEventsDialog(false);
  };

  pickCRMSEventServiceStandard = async (
    serviceStandard: ServiceStandardView,
    isNew: boolean,
    isShowDialogCommentSetting: boolean
  ) => {
    this.setIsLoading(true);
    let params: EventHandlerRequest = {
      EventFormAction: Svc_EventFormAction.Form_CategoryChange,
      Event: this.crmsEvent!,
      EventArgs: {
        ServiceStandard_Id: serviceStandard.ID,
      },
      isFirstTimeLoad: false,
    };

    // set last service standard ID
    this.setLastServiceStandardId(
      this.crmsEvent ? this.crmsEvent?.ServiceStandard?.ServiceStandard_Id : 0
    );

    // Change system service standard to normal service standard
    if (
      this.crmsEvent?.ServiceStandard?.Flag_System &&
      serviceStandard?.Flag_System === "No"
    ) {
      this.setChangeSystemToNormalServiceStd(true);
    } else {
      this.setChangeSystemToNormalServiceStd(false);
    }

    const response = await eventHandler(params);
    if (isSuccessResponse(response) && response.data?.ReturnObj) {
      this.setIsFormModified(true);
      const returnObj = response.data.ReturnObj;
      this.setCRMSEvent(returnObj.Event);
      this.setUIControl(returnObj.UIControl);

      this.setIsShowDuplicateCheckerDialog(
        isFieldVisible(
          returnObj.UIControl?.DialogDuplicationEventCheck?.DisplayStatus
        ) && !isNil(returnObj.UIControl?.DialogDuplicationEventCheck?.Value)
      );

      if (
        returnObj.Event &&
        (returnObj.Event?.ServiceStandard?.Response1 ||
          returnObj.Event?.ServiceStandard?.Response2 ||
          returnObj.Event?.ServiceStandard?.Response3)
      ) {
        this.setIsShowResolutionResponsesDialog(true);
      }

      if (
        !isNew &&
        isShowDialogCommentSetting &&
        !this.isShowResolutionResponsesDialog &&
        this.lastServiceStandardId !==
          this.crmsEvent?.ServiceStandard?.ServiceStandard_Id
      ) {
        this.setIsShowRecategorisationCommentDialog(true);
      }
    } else {
      appNotificationStore.pushNotification({
        autoClose: false,
        title: "Can not pick service standard.",
        type: "error",
        description: response.data?.Errors || response.error,
      });
    }
    this.setIsLoading(false);
  };

  setMeAsRequestor = async (
    requestorID: number,
    requestorTypeID: number,
    event: Event
  ) => {
    this.setIsLoading(true);
    let requestorTypeParams: EventHandlerRequest = {
      EventFormAction: Svc_EventFormAction.Form_RequestorTypeChange,
      Event: event,
      EventArgs: {
        RequestorType_ID: requestorTypeID,
      },
      isFirstTimeLoad: false,
    };

    const requestorTypeResponse = await eventHandler(requestorTypeParams);

    if (
      isSuccessResponse(requestorTypeResponse) &&
      requestorTypeResponse.data
    ) {
      let requestorParams: EventHandlerRequest = {
        EventFormAction: Svc_EventFormAction.Form_RequestorChange,
        Event: requestorTypeResponse.data?.ReturnObj?.Event,
        EventArgs: {
          RequestedBy_SiteUser_ID: requestorID,
        },
        isFirstTimeLoad: false,
      };

      const requestorResponse = await eventHandler(requestorParams);

      if (
        isSuccessResponse(requestorResponse) &&
        requestorResponse.data?.ReturnObj
      ) {
        this.setIsFormModified(true);
        this.setCRMSEvent(requestorResponse.data.ReturnObj.Event);
        this.setUIControl(requestorResponse.data.ReturnObj.UIControl);
      } else {
        appNotificationStore.pushNotification({
          autoClose: false,
          title: "Set me as requestor failed.",
          type: "error",
          description:
            requestorResponse.data?.Errors || requestorResponse.error,
        });
      }
    } else {
      appNotificationStore.pushNotification({
        autoClose: false,
        title: "Set me as requestor failed.",
        type: "error",
        description:
          requestorTypeResponse.data?.Errors || requestorTypeResponse.error,
      });
    }
    this.setIsLoading(false);
  };

  eventChangeHandler = async (
    params: EventHandlerRequest,
    errorMsg: string,
    isNew?: boolean,
    isShowCommentDialogSetting?: boolean,
    isReallocateOfficer?: boolean,
    isAcknowledgementAlert?: boolean
  ) => {
    let result = false;
    this.setIsLoading(true);
    const response = await eventHandler(params);
    if (isSuccessResponse(response) && response.data?.ReturnObj) {
      this.setIsFormModified(true);
      const returnObj = response.data.ReturnObj;
      const dialogDuplicationEventCheck =
        returnObj.UIControl?.DialogDuplicationEventCheck;
      this.setCRMSEvent(returnObj.Event);
      this.setUIControl(returnObj.UIControl);

      this.setIsShowDuplicateCheckerDialog(
        isFieldVisible(dialogDuplicationEventCheck?.DisplayStatus) &&
          !isNil(dialogDuplicationEventCheck?.Value)
      );
      if (response.data.Notifications.length > 0) {
        appNotificationStore.pushNotification({
          type: "warning",
          description: response.data.Notifications,
          autoClose: false,
        });
      }
      result = true;
      if (
        !isNew &&
        isShowCommentDialogSetting &&
        isReallocateOfficer &&
        !isEmpty(params.Event.ActionOfficer_DisplayName)
      ) {
        this.setIsShowReallocateOfficerCommentReDialog(true);
      }

      // Acknowledgement Alert
      const isCorrespondenceRequired = getBoolValueSetting(
        commonCoreStore.settings?.[
          ECorporateSettingsField
            .CUSTOMERSERVICE_SourceType_CorrespondenceAcknowledgementRequired
        ]
      );
      const isDisplayAdviceText = isFieldVisible(
        this.uiControl?.DivEventDetailsAdviceText?.DisplayStatus
      );
      const isHaveAdviceText =
        !!this.uiControl?.LblEventDetailsAdviceText?.Value;
      const isHaveSource = !isNil(this.crmsEvent?.Source_ID);
      const isClosedStatus =
        this.crmsEvent?.EventStatus_ENUM !== CS_EventStatus.Closed;
      const isHaveDateAcknowledge = this.crmsEvent?.DateAcknowledged;

      if (
        isAcknowledgementAlert &&
        isDisplayAdviceText &&
        isHaveAdviceText &&
        isHaveSource &&
        isCorrespondenceRequired &&
        isClosedStatus
      ) {
        if (isHaveDateAcknowledge) {
          appNotificationStore.setNotifications(
            appNotificationStore.notifications.filter(
              (notification: IAppNotificationItem) =>
                notification.title !==
                this.uiControl?.LblEventDetailsAdviceText?.Value
            )
          );
        } else {
          appNotificationStore.pushNotification({
            autoClose: false,
            title: this.uiControl?.LblEventDetailsAdviceText?.Value,
            type: "warning",
          });
        }
      }
    } else {
      appNotificationStore.pushNotification({
        autoClose: false,
        title: errorMsg,
        type: "error",
        description: response.data?.Errors || response.error,
      });
      result = false;
    }
    this.setIsLoading(false);
    return result;
  };

  simpleEventChangeHandler = async (
    params: EventHandlerRequest,
    errorMsg: string,
    notificationRef?: React.RefObject<ICCLocalNotificationHandle | null>
  ) => {
    let result = false;
    this.setIsLoading(true);

    let notification = undefined;

    const response = await simpleEventHandler(params);
    if (isSuccessResponse(response) && response.data?.ReturnObj) {
      this.setIsFormModified(true);
      this.setCRMSEvent(response.data.ReturnObj?.Event);
      this.setUIControl(response.data.ReturnObj?.UIControl);

      if (response.data.Notifications.length > 0) {
        notification = {
          type: "warning",
          description: response.data.Notifications,
          autoClose: false,
        };
      }

      result = true;
    } else {
      notification = {
        autoClose: false,
        title: errorMsg,
        type: "error",
        description: response.data?.Errors || response.error,
      };

      result = false;
    }
    this.setIsLoading(false);

    if (notification) {
      if (notificationRef) {
        notificationRef.current?.pushNotification(
          notification as IAppNotificationItemAddProps
        );
      } else {
        appNotificationStore.pushNotification(
          notification as IAppNotificationItemAddProps
        );
      }
    }

    return result;
  };

  saveSimpleEvent = async (
    crmsEvent: Event,
    notificationRef?: React.RefObject<ICCLocalNotificationHandle | null>
  ) => {
    appNotificationStore.clearNotifications();
    this.setIsLoadingSubmitSimpleEvent(true);
    const response = await postCRMSSimpleEvent(crmsEvent);
    this.setIsLoadingSubmitSimpleEvent(false);

    if (isSuccessResponse(response)) {
      if (this.isFormModified) this.setIsFormModified(false);

      this.setIsShowNewSimpleEventDialog(false);
      appNotificationStore.pushNotification({
        type: "success",
        title: "Simple event created successfully.",
      });

      eventEmitter.emit(CCGridEventType.RefreshOData);
    } else {
      const notification: IAppNotificationItemAddProps = {
        autoClose: false,
        title: "Event could not be saved.",
        type: "error",
        description: response.data?.Errors ?? response.error,
      };

      if (notificationRef) {
        notificationRef.current?.pushNotification(notification);
      } else {
        appNotificationStore.pushNotification(notification);
      }
    }
  };

  rescheduleEventHandler = async (
    rescheduleMapObj: RescheduleMapObj,
    isNew: boolean = false
  ) => {
    appNotificationStore.clearNotifications();
    this.setIsLoading(true);
    const response = await rescheduleEvent(rescheduleMapObj);
    this.setIsLoading(false);
    if (isSuccessResponse(response) && response.data) {
      this.setIsShowRescheduleDialog(false);
      if (isNew) {
        history.replace(CRMS_ROUTE + "/" + response.data.ID);
        this.setNotification({
          title: "Record successfully saved.",
          type: "success",
        });
      } else {
        this.setNotification({
          title: "Record successfully saved.",
          type: "success",
        });
        this.reLoadCRMS(isNew);
      }
      this.setEventReschedule(undefined);
    } else {
      this.setNotification({
        autoClose: false,
        title: "Record could not be saved.",
        type: "error",
        description:
          response.data?.Errors ?? response.error ?? "Reschedule event failed.",
      });
    }
  };

  generateBadge = (initStatus: string) => {
    const listBadge = [initStatus];
    if (this.crmsEvent?.VIPIssue) {
      listBadge.push(CS_EventStatus[CS_EventStatus.VIP]);
    }
    if (this.crmsEvent?.RiskIssue) {
      listBadge.push(CS_EventStatus[CS_EventStatus.Risk]);
    }
    if (this.crmsEvent?.SafetyIssue) {
      listBadge.push(CS_EventStatus[CS_EventStatus.Safety]);
    }
    if (this.crmsEvent?.Urgency) {
      listBadge.push(CS_EventStatus[CS_EventStatus.Urgent]);
    }
    if (this.crmsEvent?.RequestedBy?.Contact?.ContactAlert) {
      listBadge.push(CS_EventStatus[CS_EventStatus.Alert]);
    }
    return listBadge;
  };

  setupCreateEventFromKB = async (articleId: number) => {
    appNotificationStore.clearNotifications();
    let svcStandardCount = undefined;
    this.setIsLoadingKB(true);
    const responseCountSvcStandard = await getCountLinkedSvcStandard(articleId);

    if (
      isSuccessResponse(responseCountSvcStandard) &&
      responseCountSvcStandard?.data
    ) {
      svcStandardCount = responseCountSvcStandard.data.ReturnObj;

      if (svcStandardCount === 0) {
        this.setArticleID(undefined);
        this.setShowEventCategorisedAs(true);
      } else if (svcStandardCount === 1) {
        const responseSvcStandard = await getServiceStandardKB(articleId);
        this.setShowEventCategorisedAs(false);
        if (
          isSuccessResponse(responseSvcStandard) &&
          responseSvcStandard?.data
        ) {
          const svcStandard = responseSvcStandard?.data?.value?.[0];
          const commentSetting = getBoolValueSetting(
            commonCoreStore.settings[
              ECorporateSettingsField
                .CUSTOMERSERVICE_Event_CommentOnRecategorise
            ]
          );
          this.pickCRMSEventServiceStandard(svcStandard, true, commentSetting);
        }
      } else {
        this.setShowEventCategorisedAs(true);
      }
      this.setIsLoadingKB(false);
    } else {
      this.setNotification({
        autoClose: false,
        title: "Create event failed.",
        type: "error",
        description:
          responseCountSvcStandard.data?.Errors ??
          responseCountSvcStandard.error ??
          "Create event failed.",
      });
    }
  };

  loadCategoryFilter = async (pickCategoryFilter: PickCategoryFilter) => {
    appNotificationStore.clearNotifications();
    this.setIsLoadingCategoryFilter(true);
    const responseSetupFilter = await postSetupRecategoriseFilter(
      pickCategoryFilter
    );
    this.setIsLoadingCategoryFilter(false);

    if (
      isSuccessResponse(responseSetupFilter) &&
      responseSetupFilter?.data?.ReturnObj
    ) {
      this.setCategoryFilter(responseSetupFilter.data?.ReturnObj);
    } else {
      this.setNotification({
        autoClose: false,
        title: "Get filter failed.",
        type: "error",
        description: responseSetupFilter.error ?? "Get filter failed.",
      });
    }
  };

  processFromParent = (eventInfo: Event) => {
    // Create event for animals registration
    if (
      this.parentSection?.isFromAnimals &&
      !isNil(this.parentSection?.animalsRegistrationData)
    ) {
      const animalsArgs = this.parentSection?.animalsRegistrationData;
      eventInfo.Animal_RegisterID = animalsArgs.Registration_ID;
      eventInfo.Animal_RegisterIDs = isEmpty(eventInfo.Animal_RegisterIDs)
        ? animalsArgs.Registration_ID.toString()
        : "|" + animalsArgs.Registration_ID.toString();
      eventInfo.Animal_Name = !isEmpty(animalsArgs.AnimalName)
        ? animalsArgs.AnimalName
        : "";
      if (!eventInfo.SaveTriggers.includes(EventUpdateTriggers.SaveEvent))
        eventInfo.SaveTriggers.push(EventUpdateTriggers.SaveEvent);

      eventInfo.NewEvent_DeferCategorising = true;
    }

    // Create event for animals kennel
    if (
      this.parentSection?.isFromKennel &&
      !isNil(this.parentSection?.kennelData)
    ) {
      const kennelsArg = this.parentSection?.kennelData;
      eventInfo.Kennel_RegisterIDs = isEmpty(eventInfo.Kennel_RegisterIDs)
        ? kennelsArg.Kennel_ID.toString()
        : "|" + kennelsArg.Kennel_ID.toString();
      if (!eventInfo.SaveTriggers.includes(EventUpdateTriggers.SaveEvent))
        eventInfo.SaveTriggers.push(EventUpdateTriggers.SaveEvent);

      eventInfo.NewEvent_DeferCategorising = true;
    }
    return eventInfo;
  };
}

const crmsEventStoreContext = createContext(new CRMSEventStore());
export const useCRMSEventStore = () => {
  return useContext(crmsEventStoreContext);
};
