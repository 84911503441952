import { VO_Workflow_Draft } from "@app/products/property/actions/model";
import {
  getInitialDataSplitAssessment,
  getSavedSplitAssessmentWorkflow,
  postProcessSplitAssessment,
} from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/split-assessment/api";
import { AssessmentToBeSplitFormStep } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/split-assessment/components/form-elements/assessment-to-be-split/_index";
import { GeneralFormStep } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/split-assessment/components/form-elements/general/_index";
import { ParcelsToBeSplitFormStep } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/split-assessment/components/form-elements/parcels-to-be-split/_index";
import {
  DTO_SplitAssessment,
  DTO_SplitAssessment_LOVs,
  DTO_Workflow_SplitAssessment,
  EKeysOfStepsSplitAssessment,
  keysOfSendStepsSplitAssessment,
} from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/split-assessment/model";
import { listSubmitButton } from "@app/products/property/assessments/components/form-steps/new-assessment/config";
import { Collection } from "@app/products/property/assessments/components/form-steps/new-assessment/model";
import { useConfirmCancelDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-cancel/store";
import { useConfirmCloseDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-close/store";
import { useConfirmFinishDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-finish/store";
import { useConfirmReallocateDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-reallocate/store";
import { useConfirmRejectDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-reject/store";
import { useConfirmSendBackDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-send-back/store";
import { CollectionFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/collection/collection/_index";
import { collectionUtilProcessing } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/collection/collection/util";
import { CommentsFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/comments/_index";
import { DocumentsFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/documents/_index";
import { WorkflowStepFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/workflow/_index";
import { secondaryWorkflowUtilProcessing } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/workflow/util";
import { usePropertyWorkflow } from "@app/products/property/components/action-bar/property-workflow/component/hooks/useProprtyWorkflow/usePropertyWorkflow";
import { INewProcessWorkflow } from "@app/products/property/components/action-bar/property-workflow/model";
import { getTitleWorkflow } from "@app/products/property/components/action-bar/property-workflow/util";
import { ECustomColNameProperty } from "@app/products/property/config";
import {
  DTO_WorkflowHeader,
  EListSubmitButton,
  EWorkflowStatus,
  WorkflowProcessMode,
  WorkflowTypes,
} from "@app/products/property/model";
import { isShowParkButton } from "@app/products/property/util";
import { APIResponse } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { RECORDTYPE } from "@common/constants/recordtype";
import { ResponsePacket } from "@common/models/identityPacket";
import { Label } from "@common/stores/products/config";
import { useCommonProductStore } from "@common/stores/products/store";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCDialog } from "@components/cc-dialog/_index";
import {
  CCFormStep,
  ICCFormStepNotificationHandle,
  ICCFormStepRender,
} from "@components/cc-form-step/_index";
import { IStep } from "@components/cc-form-step/model";
import { Button } from "@progress/kendo-react-buttons";
import { capitalize, head, isNil, pickBy, toLower } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo, useRef, useState } from "react";
import { useEffectOnce } from "react-use";

interface ISplitAssessmentDialog {
  onClose: () => void;
  onCloseSpecial?: () => void;
  onSubmit: (data: any) => void;
  assessmentId: number;
  statusId: number;
  dataFromActionList?: VO_Workflow_Draft;
  prefixTitle?: string;
  suffixTitle?: string;
  isSaveOnNextStep?: boolean;
}

export const SplitAssessmentDialog = observer(
  ({
    onClose,
    onCloseSpecial,
    assessmentId,
    statusId,
    dataFromActionList,
    prefixTitle,
    suffixTitle,
    isSaveOnNextStep = false,
  }: ISplitAssessmentDialog) => {
    //#region <HOOKS>
    //Use ref
    const notificationFormStepRef =
      useRef<ICCFormStepNotificationHandle | null>(null);

    //Use store
    const { currentFormTitle } = useCommonProductStore();
    const { pushNotification } = useCCAppNotificationStore();
    const { setDataForCloseDialog, setIsLoadingClose } =
      useConfirmCloseDialogStore();
    const { setDataForCancelDialog } = useConfirmCancelDialogStore();
    const { setDataForFinishDialog } = useConfirmFinishDialogStore();
    const { setDataForSendBackDialog } = useConfirmSendBackDialogStore();
    const { setDataForRejectDialog } = useConfirmRejectDialogStore();
    const { setDataForReallocateDialog } = useConfirmReallocateDialogStore();

    //Use state
    const [workflowName, setWorkflowName] = useState<string>("Split Holding");
    const [workflowInitialData, setWorkflowInitialData] =
      useState<DTO_Workflow_SplitAssessment>();
    const [workflowHeader, setWorkflowHeader] = useState<DTO_WorkflowHeader>({
      WorkflowDraft: { Workflow_Draft_Id: 0 },
      AvailableSecondaryWorkflows: [],
      WorkflowApprovals: [],
    });
    const [workflowDraftId, setWorkflowDraftId] = useState<number>(0);
    const [workflowLOVs, setWorkflowLOVs] =
      useState<DTO_SplitAssessment_LOVs>();
    const [isLoadingProcess, setIsLoadingProcess] = useState<
      WorkflowProcessMode | undefined
    >();
    const [isFirstSave, setIsFirstSave] = useState<boolean>(true);

    const {
      isFromActionList,
      isIncompleteMode,
      isReadOnly,
      isShowCancelWorkflowButton,
      isToBeApprovalMode,
      statusBadge,
    } = usePropertyWorkflow(dataFromActionList);

    //Get labels
    const splitAssessmentLabel = Label.CommunityProperty.getLabel(
      ECustomColNameProperty.Split_Assessment
    );

    //Use memo
    const titleHeader = useMemo(() => {
      const formId = workflowHeader?.WorkflowDraft?.WD_Form_Id;
      const title = currentFormTitle(formId ?? 0) ?? splitAssessmentLabel;
      setWorkflowName(capitalize(toLower(title)));
      return getTitleWorkflow(title, prefixTitle, suffixTitle);
      // eslint-disable-next-line
    }, [workflowHeader, prefixTitle, suffixTitle]);

    const initialValues = useMemo(() => {
      const workflowHeader = workflowInitialData?.WorkflowHeader;
      const workflowDetail = workflowInitialData?.WorkflowDetail;

      //General
      let initGeneral = {};
      if (workflowDetail?.Details) {
        initGeneral = {
          ...initGeneral,
          ...workflowDetail?.Details,
          WD_Assessment_Group_Id:
            workflowHeader?.WorkflowDraft?.WD_Assessment_Group_Id ??
            workflowHeader?.OfficerAssessmentGroups?.[0]?.Code ??
            null,
          Officer: workflowHeader?.Officer ?? "",
          OfficerAssessmentGroups:
            workflowHeader?.OfficerAssessmentGroups ?? [],
          Reason_Id: workflowDetail?.Details?.Reason_Id?.toString(),
        };
      }

      //Assessment to be split
      let initAssessmentToBeSplit = {};
      if (workflowDetail?.SplitAssessment) {
        initAssessmentToBeSplit = {
          ...initAssessmentToBeSplit,
          ...workflowDetail?.SplitAssessment,
        };
      }

      //Parcel to be split
      const initParcelToBeSplit = workflowDetail?.SplitParcel;

      //Item to clone
      let initItemToClone = {}; //@TODO: Waiting confirmation

      //---step Secondary Workflow---
      const initialWorkflow =
        secondaryWorkflowUtilProcessing.processDataInit(workflowInitialData);

      return {
        [EKeysOfStepsSplitAssessment.General]: initGeneral,
        [EKeysOfStepsSplitAssessment.AssessmentToBeSplit]:
          initAssessmentToBeSplit,
        [EKeysOfStepsSplitAssessment.ParcelsToBeSplit]: initParcelToBeSplit,
        [EKeysOfStepsSplitAssessment.ItemsToClone]: initItemToClone,
        [EKeysOfStepsSplitAssessment.Collection]:
          collectionUtilProcessing.processDataInit<Collection[]>(
            workflowInitialData
          ),
        [EKeysOfStepsSplitAssessment.Comments]: {},
        [EKeysOfStepsSplitAssessment.Documents]: {},
        [EKeysOfStepsSplitAssessment.Workflow]: initialWorkflow,
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [workflowInitialData]);

    //Use effect
    /**
     * get workflow data
     */
    const getWorkflowData = async () => {
      notificationFormStepRef?.current?.setLoadingFormStep(true);
      const payload: DTO_SplitAssessment = {
        Assessment_Id: assessmentId,
        StatusId: statusId,
      };

      const response = await getInitialDataSplitAssessment(
        payload,
        dataFromActionList?.Workflow_Draft_Id
      );
      notificationFormStepRef?.current?.setLoadingFormStep(false);
      const workflowData = response?.data;
      if (isSuccessResponse(response) && workflowData) {
        //Set workflow LOVs
        setWorkflowLOVs(workflowData.WorkflowDetail?.LOVs);
        //Set workflow initial data
        setWorkflowInitialData({
          WorkflowHeader: workflowData.WorkflowHeader,
          WorkflowDetail: workflowData.WorkflowDetail,
        });
        //Set workflow header and workflow draft ID
        if (workflowData?.WorkflowHeader) {
          setWorkflowHeader(workflowData.WorkflowHeader);
          setWorkflowDraftId(
            workflowData?.WorkflowHeader?.WorkflowDraft?.Workflow_Draft_Id ?? 0
          );
        }
      } else {
        const responseError =
          response as unknown as APIResponse<ResponsePacket>;
        notificationFormStepRef?.current?.setLoadFailedFormStep({
          onReload: () => getWorkflowData(),
          responseError: {
            status: responseError.status,
            error:
              (responseError.data as ResponsePacket)?.Errors ??
              "Load workflow failed",
          },
        });
      }
    };

    useEffectOnce(() => {
      getWorkflowData();
    });
    //#endregion

    //#region <SAVE and NEXT>
    /**
     * Refresh workflow data
     * @param workflowDraftId
     */
    const refreshWorkflowData = async (workflowDraftId: number) => {
      const response = await getSavedSplitAssessmentWorkflow(workflowDraftId);
      if (isSuccessResponse(response) && response?.data) {
        setWorkflowInitialData({
          WorkflowDetail: response.data?.WorkflowDetail,
          WorkflowHeader: response.data?.WorkflowHeader,
        });
      } else {
        notificationFormStepRef?.current?.setLoadFailedFormStep({
          onReload: () => refreshWorkflowData(workflowDraftId),
          responseError: {
            status: response.status,
            error: response.error ?? "Load workflow failed",
          },
        });
      }
    };

    /**
     * Handle Next button
     * @param data
     * @param step
     * @param keyStep
     */
    const handleNextButton = async (data: any, step: any, keyStep: string) => {
      const newData = { ...data };
      const processPayload = processData(newData);
      return sendSaveWorkflow(processPayload, false, false, keyStep);
    };

    /**
     * Send save workflow data (call API with 'Save' mode)
     * @param payload
     * @param isCloseDialog
     * @param isRefreshWorkflowData
     * @param keyStep
     */
    const sendSaveWorkflow = async (
      payload: DTO_Workflow_SplitAssessment,
      isCloseDialog: boolean = false,
      isRefreshWorkflowData: boolean = false,
      keyStep?: string
    ) => {
      setIsLoadingProcess(WorkflowProcessMode.Save);
      const response = await postProcessSplitAssessment(
        WorkflowProcessMode.Save,
        payload
      );

      const defaultSuccessMessage = `${workflowName} was saved successfully.`;
      const defaultFailedMessage = `${workflowName} could not be saved.`;
      if (isSuccessResponse(response)) {
        if (response?.data?.IsSuccess) {
          if (isCloseDialog) {
            onClose();
            pushNotification({
              title: response?.data?.SuccessMessage ?? defaultSuccessMessage,
              type: "success",
            });
          }
          if (isFirstSave) {
            setIsFirstSave(false);
            const workflowDraftId = response?.data?.ID ?? 0;
            setWorkflowDraftId(workflowDraftId);
            setWorkflowHeader({
              ...workflowHeader,
              WorkflowDraft: {
                ...workflowHeader.WorkflowDraft,
                Workflow_Draft_Id: workflowDraftId,
              },
            });

            if (
              !isFromActionList &&
              keyStep === EKeysOfStepsSplitAssessment.General
            ) {
              refreshWorkflowData(workflowDraftId);
            }
          }

          // TODO: Show notification after reloading the step -> enhance later
          if (isRefreshWorkflowData) {
            getWorkflowData().then(() => {
              notificationFormStepRef?.current
                ?.getNotificationFormStep()
                ?.current?.pushNotification({
                  title: response?.data?.Notification ?? defaultSuccessMessage,
                  type: "success",
                });
            });
          }
          setIsLoadingProcess(undefined);
          return true;
        } else {
          notificationFormStepRef?.current
            ?.getNotificationFormStep()
            ?.current?.pushNotification({
              title: response.data?.ErrorMessage ?? defaultFailedMessage,
              type: "error",
              autoClose: false,
            });
          return false;
        }
      } else {
        const showNotification = () => {
          notificationFormStepRef?.current
            ?.getNotificationFormStep()
            ?.current?.pushNotification({
              title:
                (isRefreshWorkflowData
                  ? head(response?.data?.Errors)
                  : response.data?.ErrorMessage) ?? defaultFailedMessage,
              type: "error",
              autoClose: false,
            });
        };
        if (isRefreshWorkflowData) {
          getWorkflowData().then(showNotification);
        } else {
          showNotification();
        }
        setIsLoadingProcess(undefined);
        return false;
      }
    };
    //#endregion

    //#region <PARK>
    /**
     * Handle park process
     * @param payload
     * @param mode
     */
    const handleParkProcess = async (
      payload: DTO_Workflow_SplitAssessment,
      mode?: EListSubmitButton
    ) => {
      //set loading button and dialog
      setIsLoadingProcess(WorkflowProcessMode.Park);
      mode === EListSubmitButton.ConfirmCloseYes && setIsLoadingClose(true);
      const actionCloseRetainDialog = () => {
        setIsLoadingClose(false);
        setDataForCloseDialog();
      };
      //props send to process workflow
      const parkProps: INewProcessWorkflow<DTO_Workflow_SplitAssessment> = {
        payload: payload,
        actionSuccess: (e) => {
          onClose();
          pushNotification({
            title:
              e?.Notification ?? `${workflowName} was parked successfully.`,
            type: "success",
          });
        },
        setLoading: () => {
          setIsLoadingProcess(undefined);
        },
        actionClose: () => {
          mode === EListSubmitButton.ConfirmCloseYes &&
            actionCloseRetainDialog();
        },
        defaultFailedMessage: `${workflowName} could not be parked.`,
        modeProcess: WorkflowProcessMode.Park,
      };

      //calling api process workflow
      await handleProcessWorkflow(parkProps);
    };
    //#endregion

    //#region <CANCEL>
    /**
     * Handle Cancel button
     * @param data
     */
    const handleCancelButton = (data: any) => {
      if (isFromActionList || !isFirstSave) {
        setDataForCancelDialog({
          cancelAPI: postProcessSplitAssessment,
          dataCancel: data,
          defaultSuccessMessage: `${workflowName} was cancelled successfully.`,
          defaultErrorMessage: `${workflowName} could not be cancelled.`,
        });
      } else {
        onClose();
      }
    };
    //#endregion

    //#region <FINISH>
    /**
     * handle confirm finish workflow process
     * @param payload
     */
    const handleConfirmFinishProcess = (
      payload: DTO_Workflow_SplitAssessment
    ) => {
      setDataForFinishDialog({
        finishCallback: async () =>
          await handleFinishProcess(processData(payload)),
        confirmMessage: `${workflowName} will be created based on the information provided. Are you sure you want to submit?`,
      });
    };

    /**
     * handle finish process
     * @param payload
     */
    const handleFinishProcess = async (
      payload: DTO_Workflow_SplitAssessment
    ) => {
      //props send to process workflow
      const finishProps: INewProcessWorkflow<DTO_Workflow_SplitAssessment> = {
        payload: payload,
        actionSuccess: (e) => {
          onClose();
          pushNotification({
            title:
              e?.Notification ?? `${workflowName} was completed successfully.`,
            type: "success",
          });
        },
        setLoading: () => {
          setIsLoadingProcess(undefined);
        },
        defaultFailedMessage: `${workflowName} could not be completed.`,
        modeProcess: WorkflowProcessMode.Finish,
      };

      //calling API process workflow
      await handleProcessWorkflow(finishProps);
    };
    //#endregion

    //#region <CLOSE>
    /**
     * @param renderProps
     */
    const handleCloseDialog = (renderProps: ICCFormStepRender) => {
      if (!isFromActionList && !isFirstSave) {
        //Store submit event
        setDataForCloseDialog({
          closeCallback: renderProps.submitButton.onClick,
        });
      } else if (
        isIncompleteMode &&
        dataFromActionList?.Workflow_Status_Name === EWorkflowStatus.Park
      ) {
        onClose();
      } else if (
        dataFromActionList?.Workflow_Status_Name ===
          EWorkflowStatus.Incomplete &&
        !isFirstSave
      ) {
        const newEvent = {
          currentTarget: { id: EListSubmitButton.Close },
        };
        renderProps.submitButton.onClick(newEvent);
      } else if (onCloseSpecial) {
        onCloseSpecial();
      } else {
        onClose();
      }
    };
    //#endregion

    //#region <APPROVE>
    /**
     * @param payload
     */
    const handleApproveProcess = async (
      payload: DTO_Workflow_SplitAssessment
    ) => {
      //set loading button
      setIsLoadingProcess(WorkflowProcessMode.Approve);

      //props send to process workflow
      const approveProps: INewProcessWorkflow<DTO_Workflow_SplitAssessment> = {
        payload: payload,
        actionSuccess: (e) => {
          onClose();
          pushNotification({
            title:
              e?.Notification ?? `${workflowName} was approved successfully.`,
            type: "success",
          });
        },
        setLoading: () => {
          setIsLoadingProcess(undefined);
        },
        defaultFailedMessage: `${workflowName} could not be approved.`,
        modeProcess: WorkflowProcessMode.Approve,
      };

      //calling api process workflow
      await handleProcessWorkflow(approveProps);
    };
    //#endregion

    //#region <SEND BACK>
    /**
     * handle confirm send back workflow process
     * @param payload
     */
    const handleConfirmSendBackProcess = (
      payload: DTO_Workflow_SplitAssessment
    ) => {
      setDataForSendBackDialog({
        sendBackCallback: postProcessSplitAssessment,
        dataSendBack: payload,
        defaultSuccessMessage: `${workflowName} was sent back successfully.`,
        defaultErrorMessage: `${workflowName} could not be sent back.`,
      });
    };
    //#endregion

    //#region <SEND BACK>
    /**
     * handle confirm reallocate workflow process
     * @param payload
     */
    const handleConfirmReallocateProcess = (
      payload: DTO_Workflow_SplitAssessment
    ) => {
      setDataForReallocateDialog({
        reallocateCallback: postProcessSplitAssessment,
        dataReallocate: payload,
        defaultSuccessMessage: `${workflowName} was reallocated successfully.`,
        defaultErrorMessage: `${workflowName} could not be reallocated.`,
      });
    };
    //#endregion

    //#region <REJECT>
    /**
     * Handle reject process
     * @param payload
     */
    const handleRejectButton = (payload: DTO_Workflow_SplitAssessment) => {
      if (isFromActionList || !isFirstSave) {
        setDataForRejectDialog({
          rejectCallback: postProcessSplitAssessment,
          dataReject: payload,
          defaultSuccessMessage: `${workflowName} was rejected successfully.`,
          defaultErrorMessage: `${workflowName} could not be rejected.`,
        });
      } else {
        onClose();
      }
    };
    //#endregion

    //#region <COMMON FUNCTIONS>
    /**
     * Handle submit to control all buttons in dialog
     * @param events
     * @param buttonId
     */
    const handleSubmit = async (data: any, buttonId?: string) => {
      switch (buttonId) {
        case EListSubmitButton.Approve:
          await handleApproveProcess(processData(data));
          break;
        case EListSubmitButton.Save:
          await sendSaveWorkflow(processData(data), false, false, undefined);
          break;
        case EListSubmitButton.SaveWorkflow:
          await sendSaveWorkflow(processData(data), false, true, undefined);
          break;
        case EListSubmitButton.Finish:
          handleConfirmFinishProcess(data);
          break;
        case EListSubmitButton.Cancel:
        case EListSubmitButton.ConfirmCloseNo:
          handleCancelButton(processData(data));
          break;
        case EListSubmitButton.ConfirmCloseYes:
          await handleParkProcess(
            processData(data),
            EListSubmitButton.ConfirmCloseYes
          );
          break;
        case EListSubmitButton.Reject:
          handleRejectButton(processData(data));
          break;
        case EListSubmitButton.SendBack:
          handleConfirmSendBackProcess(processData(data));
          break;
        case EListSubmitButton.Reallocate:
          handleConfirmReallocateProcess(processData(data));
          break;
        case EListSubmitButton.Park:
        case EListSubmitButton.Close:
          await handleParkProcess(processData(data));
          break;
        default:
          break;
      }
    };

    /**
     * Process data before sending to API
     * @param data
     */
    const processData = (data: any) => {
      let workflowDetail: any = {};
      // process workflow header to send the WD_Assessment_Group_Id/ Officer Region Id
      workflowHeader.WorkflowDraft.WD_Assessment_Group_Id =
        +data?.[EKeysOfStepsSplitAssessment.General]?.WD_Assessment_Group_Id;

      const sendSteps = pickBy(data, function (value, key) {
        if (
          keysOfSendStepsSplitAssessment.includes(
            key as EKeysOfStepsSplitAssessment
          )
        ) {
          return { [key]: value };
        }
      });
      workflowDetail.Assessment_Id =
        workflowInitialData?.WorkflowDetail?.Assessment_Id;

      if (sendSteps) {
        for (const [key, value] of Object.entries(sendSteps)) {
          const dataStep = { ...value };
          if (dataStep && dataStep?._option) {
            delete dataStep._option;
          }
          workflowDetail[key] = dataStep;
          if (key === EKeysOfStepsSplitAssessment.Workflow) {
            secondaryWorkflowUtilProcessing.processData(
              value,
              workflowHeader,
              workflowDetail
            );
          }
        }
      }
      return {
        WorkflowHeader: workflowHeader,
        WorkflowDetail: workflowDetail,
      };
    };

    /**
     * common function
     * handle calling api with multiple process
     * @param props
     */
    const handleProcessWorkflow = async (
      props: INewProcessWorkflow<DTO_Workflow_SplitAssessment>
    ) => {
      const {
        actionSuccess,
        defaultFailedMessage,
        setLoading,
        modeProcess,
        payload,
      } = props;
      const response = await postProcessSplitAssessment(modeProcess, payload);

      setLoading();
      if (isSuccessResponse(response)) {
        if (response?.data?.IsSuccess) {
          actionSuccess(response?.data);
        } else {
          if (props?.actionFail) props?.actionFail(response);
          notificationFormStepRef?.current
            ?.getNotificationFormStep()
            ?.current?.pushNotification({
              title: response.data?.ErrorMessage ?? defaultFailedMessage,
              type: "error",
              autoClose: false,
            });
        }
      } else {
        if (props?.actionFail) props?.actionFail(response);
        notificationFormStepRef?.current
          ?.getNotificationFormStep()
          ?.current?.pushNotification({
            title: response?.data?.ErrorMessage ?? defaultFailedMessage,
            type: "error",
            autoClose: false,
          });
      }
      if (props?.actionClose) props.actionClose();
    };
    //#endregion

    const steps: IStep[] = [
      {
        label: "General",
        initialValues: initialValues[EKeysOfStepsSplitAssessment.General],
        component: GeneralFormStep,
        visible: true,
        key: EKeysOfStepsSplitAssessment.General,
        options: {
          isReadOnly,
          workflowLOVs,
          workflowDraftId,
        },
      },
      {
        label: "Holding to be split",
        initialValues:
          initialValues[EKeysOfStepsSplitAssessment.AssessmentToBeSplit],
        component: AssessmentToBeSplitFormStep,
        visible: true,
        key: EKeysOfStepsSplitAssessment.AssessmentToBeSplit,
      },
      {
        label: "Parcels to be split",
        initialValues:
          initialValues[EKeysOfStepsSplitAssessment.ParcelsToBeSplit],
        component: ParcelsToBeSplitFormStep,
        visible: true,
        key: EKeysOfStepsSplitAssessment.ParcelsToBeSplit,
        options: {
          isReadOnly,
        },
      },
      // @TODO: Hide this step for now, will confirm and handle later
      // {
      //   label: "Items to clone",
      //   initialValues: initialValues[EKeysOfStepsSplitAssessment.ItemsToClone],
      //   component: ItemToCloneFormStep,
      //   visible: true,
      //   key: EKeysOfStepsSplitAssessment.ItemsToClone,
      // },
      {
        label: "Collections",
        component: CollectionFormStep,
        visible: true,
        key: EKeysOfStepsSplitAssessment.Collection,
        initialValues: initialValues[EKeysOfStepsSplitAssessment.Collection],
        options: {
          isReadOnly,
        },
      },
      {
        label: "Comments",
        initialValues: initialValues[EKeysOfStepsSplitAssessment.Comments],
        component: CommentsFormStep,
        visible: true,
        key: EKeysOfStepsSplitAssessment.Comments,
        options: {
          isReadOnly,
          workflowDraftId,
          recordType: RECORDTYPE.CommunityProperty_Assessment,
        },
        customClassName: "cc-comment-step-fixed-height-grid",
      },
      {
        label: "Documents",
        component: DocumentsFormStep,
        initialValues: initialValues[EKeysOfStepsSplitAssessment.Documents],
        visible: true,
        key: EKeysOfStepsSplitAssessment.Documents,
        options: {
          isReadOnly,
          workflowDraftId,
          workflowType: WorkflowTypes.Split_Assessment,
        },
      },
      {
        label: "Workflow",
        initialValues: initialValues[EKeysOfStepsSplitAssessment.Workflow],
        component: WorkflowStepFormStep,
        visible: true,
        key: EKeysOfStepsSplitAssessment.Workflow,
        options: {
          isReadOnly,
          isFromActionList,
          dataFromActionList,
        },
      },
    ];

    return (
      <CCFormStep
        ref={notificationFormStepRef}
        onSubmit={handleSubmit}
        listButtonId={listSubmitButton}
        initialSteps={steps}
        initialValues={initialValues}
        saveOnNextStep={isSaveOnNextStep ? handleNextButton : undefined}
        renderForm={(renderProps: ICCFormStepRender) => (
          <CCDialog
            maxWidth="65%"
            maxHeight="90%"
            badge={statusBadge}
            titleHeader={titleHeader}
            disabled={isLoadingProcess !== undefined}
            onClose={() => handleCloseDialog(renderProps)}
            bodyElement={renderProps.children}
            footerElement={
              <div className={"cc-dialog-footer-actions-right"}>
                {isShowParkButton(isFromActionList, isIncompleteMode) && (
                  <Button
                    iconClass={
                      isLoadingProcess === WorkflowProcessMode.Park
                        ? "fas fa-spinner fa-spin"
                        : ""
                    }
                    className={"cc-dialog-button"}
                    id={EListSubmitButton.Park}
                    onClick={renderProps.submitButton.onClick}
                    disabled={
                      renderProps.nextButton.disabled ||
                      isLoadingProcess === WorkflowProcessMode.Park
                    }
                  >
                    Park
                  </Button>
                )}
                {isShowCancelWorkflowButton && (
                  <Button
                    className={"cc-dialog-button"}
                    disabled={!isNil(isLoadingProcess)}
                    id={EListSubmitButton.Cancel}
                    onClick={renderProps.submitButton.onClick}
                  >
                    Cancel
                  </Button>
                )}
                {isToBeApprovalMode && workflowHeader?.OfficerCanApprove && (
                  <>
                    <Button
                      themeColor="primary"
                      id={EListSubmitButton.SendBack}
                      disabled={renderProps.nextButton.disabled}
                      className={"cc-dialog-button"}
                      onClick={renderProps.submitButton.onClick}
                    >
                      Send Back
                    </Button>
                    <Button
                      themeColor="primary"
                      id={EListSubmitButton.Reallocate}
                      disabled={renderProps.nextButton.disabled}
                      className={"cc-dialog-button"}
                      onClick={renderProps.submitButton.onClick}
                    >
                      Reallocate
                    </Button>
                    <Button
                      themeColor="primary"
                      id={EListSubmitButton.Approve}
                      disabled={
                        isLoadingProcess === WorkflowProcessMode.Approve ||
                        renderProps.nextButton.disabled
                      }
                      className={"cc-dialog-button"}
                      onClick={renderProps.submitButton.onClick}
                      iconClass={
                        isLoadingProcess === WorkflowProcessMode.Approve
                          ? "fas fa-spinner fa-spin"
                          : ""
                      }
                    >
                      Approve
                    </Button>
                    <Button
                      themeColor="primary"
                      id={EListSubmitButton.Reject}
                      disabled={renderProps.nextButton.disabled}
                      className={"cc-dialog-button"}
                      onClick={renderProps.submitButton.onClick}
                    >
                      Reject
                    </Button>
                  </>
                )}
                {!renderProps.prevButton.disabled ? (
                  <Button
                    className={"cc-dialog-button"}
                    themeColor="primary"
                    onClick={renderProps.prevButton.onClick}
                  >
                    Previous
                  </Button>
                ) : null}
                {isToBeApprovalMode || isReadOnly ? (
                  !renderProps.isLastStep && (
                    <Button
                      themeColor="primary"
                      id="cc-next-step-button"
                      disabled={
                        isLoadingProcess === WorkflowProcessMode.Save ||
                        renderProps.nextButton.disabled
                      }
                      className={"cc-dialog-button"}
                      iconClass={
                        isLoadingProcess === WorkflowProcessMode.Save
                          ? "fas fa-spinner fa-spin"
                          : ""
                      }
                      onClick={renderProps.nextButton.onClick}
                    >
                      {isLoadingProcess === WorkflowProcessMode.Save
                        ? "Saving"
                        : renderProps.nextButton.label}
                    </Button>
                  )
                ) : (
                  <Button
                    themeColor="primary"
                    id={renderProps.nextButton.idButton}
                    disabled={
                      (isLoadingProcess === WorkflowProcessMode.Save &&
                        isSaveOnNextStep) ||
                      renderProps.nextButton.disabled
                    }
                    iconClass={
                      isLoadingProcess === WorkflowProcessMode.Save &&
                      isSaveOnNextStep
                        ? "fas fa-spinner fa-spin"
                        : ""
                    }
                    className={"cc-dialog-button"}
                    onClick={renderProps.nextButton.onClick}
                  >
                    {isLoadingProcess === WorkflowProcessMode.Save &&
                    isSaveOnNextStep
                      ? "Saving"
                      : renderProps.nextButton.label}
                  </Button>
                )}
              </div>
            }
          />
        )}
      />
    );
  }
);
