import { hiddenSomeInputsTypes } from "@app/products/property/changes-of-ownership/notice-of-sales/components/form-steps/update-notice-of-sale/components/form-elements/detail/config";
import {
  DTO_ChangeOfOwnerShip_Detail,
  EKeysOfStepsCOONoS,
} from "@app/products/property/changes-of-ownership/notice-of-sales/components/form-steps/update-notice-of-sale/model";
import { DATE_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { validatorDateValueWithMinMax } from "@common/utils/field-validators";
import { CCCurrencyInput } from "@components/cc-currency-input/_index";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { Field, FieldArray } from "@progress/kendo-react-form";
import React, { useCallback } from "react";

export const DetailsFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};

const nameOfCOODetails = nameOfFactory<DTO_ChangeOfOwnerShip_Detail>();
const FormStepElement = ({
  formRenderProps,
  nameOf,
  options = {
    isReadOnly: false,
    isLLS: false,
  },
}: IFormStepElement) => {
  const getNameOfCOODetails = (
    name: keyof DTO_ChangeOfOwnerShip_Detail
  ): string => {
    return nameOf(nameOfCOODetails(name));
  };
  const { valueGetter } = formRenderProps;
  const changeOfOwnershipSelectedType = valueGetter(
    `${EKeysOfStepsCOONoS.Type}.Type`
  );

  const validateContractDate = useCallback((value: Date) => {
    return validatorDateValueWithMinMax(value, null, new Date());
  }, []);

  return (
    <section className="cc-field-group">
      <div className="cc-form-cols-2">
        <div className="cc-field cc-col-span-2">
          <CCLabel title="Reference" />
          <Field
            name={getNameOfCOODetails("Reference")}
            component={CCInput}
            placeholder="Reference"
            readOnly={options?.isReadOnly}
          />
        </div>
        <div className="cc-field">
          <CCLabel title="Effective date" />
          <Field
            name={getNameOfCOODetails("EffectiveDate")}
            format={DATE_FORMAT.DATE_CONTROL}
            component={CCDatePicker}
            disabled={options?.isReadOnly}
          />
        </div>
        {!hiddenSomeInputsTypes.includes(changeOfOwnershipSelectedType) && (
          <>
            <div className="cc-field">
              <CCLabel title="Sale price" />
              <Field
                name={getNameOfCOODetails("SalePrice")}
                component={CCCurrencyInput}
                disabled={options?.isReadOnly}
                min={0}
              />
            </div>

            <div className="cc-field">
              <CCLabel title="Contract date" />
              <Field
                name={getNameOfCOODetails("ContractDate")}
                format={DATE_FORMAT.DATE_CONTROL}
                component={CCDatePicker}
                max={new Date()}
                disabled={options?.isReadOnly}
                validator={validateContractDate}
              />
            </div>
            <div className="cc-field">
              <CCLabel title="Chattels" />
              <Field
                name={getNameOfCOODetails("Chattels")}
                component={CCCurrencyInput}
                disabled={options?.isReadOnly}
                min={0}
              />
            </div>
          </>
        )}
        <div className="cc-field cc-col-span-2">
          <CCLabel title="Comment" />
          <Field
            name={getNameOfCOODetails("Comment")}
            component={CCTextArea}
            rows={3}
            placeholder="Comment"
            readOnly={options?.isReadOnly}
          />
        </div>
      </div>
    </section>
  );
};
