import { decryptionHtmlForEditor } from "@app/core/communication/dialogs/components/form-elememts/message-template/components/cc-edit-html-tool/util";
import { sanitizeHtmlWithConfigs } from "@common/utils/sanitized-parser";
import { CCShadowRootWrap } from "@components/cc-shadow-root-wrap/_index";
import DOMPurify from "dompurify";
import React from "react";

interface IGuidanceContentIframeProps {
  content: string;
}
export const GuidanceContentIframe = ({
  content,
}: IGuidanceContentIframeProps) => {
  const sanitizeGuidanceContent = DOMPurify.sanitize(
    decryptionHtmlForEditor(content ?? ""),
    {
      FORCE_BODY: true,
      FORBID_TAGS: ["script"],
    }
  );
  return (
    <CCShadowRootWrap>
      {sanitizeHtmlWithConfigs(sanitizeGuidanceContent, { FORCE_BODY: true })}
    </CCShadowRootWrap>
  );
};
