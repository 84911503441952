import { eventEmitter } from "@/App";
import { history } from "@/AppRoutes";
import { saveComment } from "@app/core/comments/api";
import { COMMENT_ACCORDION_GRID_ID } from "@app/core/comments/constant";
import { Comments } from "@app/core/comments/model";
import { KeyValuePair } from "@app/core/documents/model";
import { TabTableEventType } from "@app/core/tab-table/constant";
import { EventClose } from "@app/products/crms/[id]/components/forms/components/action-bar/dialogs/close-event/model";
import { CRMS_ROUTE } from "@app/products/crms/[id]/constant";
import { CS_RequestorType } from "@app/products/crms/[id]/model";
import { CRMSViews } from "@app/products/crms/model";
import {
  convertToEvent,
  eventHandler,
  getCRMSPhoneMessageSummaryById,
  getPhoneMsgEventNumber,
  getPhoneMsgLovs,
  postCompleteMessage,
  postSentToActionOfficer,
  savePhoneMessage,
  sendPhoneMessage,
  setupCompleteMessage,
} from "@app/products/crms/phone-messages/[id]/api";
import { CRMS_PHONE_MESSAGE_FLAGS } from "@app/products/crms/phone-messages/[id]/components/forms/components/child-screens/general/components/form-element/constant";
import { CompleteMessageRequest } from "@app/products/crms/phone-messages/[id]/components/forms/components/child-screens/general/components/form-element/model";
import { CRMS_PHONE_MESSAGE_ROUTE } from "@app/products/crms/phone-messages/[id]/constant";
import {
  PhoneMessage,
  PhoneMessageEventHandlerRequest,
  PhoneMessageLOVs,
  PhoneMessageUIControl,
  SendToActionOfficerRequest,
  Svc_PhoneMessageFormAction,
} from "@app/products/crms/phone-messages/[id]/model";
import { PHONE_MESSAGE_GRID_ID } from "@app/products/crms/phone-messages/constant";
import { BubbleUpType } from "@app/products/waste-water/[id]/model";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { RECORDTYPE } from "@common/constants/recordtype";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { commonCoreStore } from "@common/stores/core/store";
import { getStringValueSetting } from "@common/stores/products/util";
import { ICCLocalNotificationHandle } from "@components/cc-app-notification/_index";
import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { CCGridEventType } from "@components/cc-grid/constant";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });

class CRMSPhoneMessageStore {
  private _crmsPhoneMessage?: PhoneMessage = undefined;
  private _existCrmsPhoneMessage?: PhoneMessage = undefined;
  private _detailsCrmsPhoneMessage?: PhoneMessage = undefined;
  private _isLoading: boolean = false;
  private _isLoadingExited: boolean = false;
  private _responseLoadError?: APIResponseError = undefined;
  private _eventNumberObj?: KeyValuePair<number, string> = undefined;
  private _phoneMsgLovs?: PhoneMessageLOVs = undefined;
  private _uiControl?: PhoneMessageUIControl = undefined;
  private _uiControlExisted?: PhoneMessageUIControl = undefined;
  private _isLoadingDetails: boolean = false;
  private _isShowNewPhoneMsgDialog: boolean = false;
  private _isSubmitting: boolean = false;
  private _isLoadingEventNumber: boolean = false;
  private _notification?: IAppNotificationItemAddProps = undefined;
  private _onSubmit?: (event: React.SyntheticEvent<any>) => void = undefined;
  private _isShowConvertToEventDialog?: boolean = false;
  private _isShowCloseEventDialog?: boolean = false;
  private _crmsEventClose?: EventClose = undefined;
  private _isLoadingCloseEvent: boolean = false;
  private _isShowForwardOnDialog?: boolean = false;
  private _isLoadingSendToActionOfficer?: boolean = false;
  private _isShowCommentDialog?: boolean = false;
  private _isLoadingGetContact?: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  get crmsPhoneMessage() {
    return this._crmsPhoneMessage;
  }
  setCRMSPhoneMessage = (crmsPhoneMessage: PhoneMessage | undefined) => {
    runInAction(() => {
      this._crmsPhoneMessage = crmsPhoneMessage;
    });
  };

  get existCrmsPhoneMessage() {
    return this._existCrmsPhoneMessage;
  }
  setExistCrmsPhoneMessage = (
    existCrmsPhoneMessage: PhoneMessage | undefined
  ) => {
    runInAction(() => {
      this._existCrmsPhoneMessage = existCrmsPhoneMessage;
    });
  };

  get crmsPhoneMessageDetails() {
    return this._detailsCrmsPhoneMessage;
  }
  setCrmsPhoneMessageDetails = (
    crmsPhoneMessageDetails: PhoneMessage | undefined
  ) => {
    runInAction(() => {
      this._detailsCrmsPhoneMessage = crmsPhoneMessageDetails;
    });
  };

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get isLoadingExisted() {
    return this._isLoadingExited;
  }
  setIsLoadingExisted = (isLoadingExisted: boolean) => {
    runInAction(() => {
      this._isLoadingExited = isLoadingExisted;
    });
  };

  get isLoadingDetails() {
    return this._isLoadingDetails;
  }
  setIsLoadingDetails = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoadingDetails = isLoading;
    });
  };

  get crmsPhoneMessageId() {
    if (this._crmsPhoneMessage) return this._crmsPhoneMessage?.PhoneMessage_ID;
    if (this._existCrmsPhoneMessage)
      return this._existCrmsPhoneMessage?.PhoneMessage_ID;
    if (this._detailsCrmsPhoneMessage)
      return this._detailsCrmsPhoneMessage?.PhoneMessage_ID;
    return 0;
  }

  get eventNumberObj() {
    return this._eventNumberObj;
  }
  setEventNumberObj = (eventNumberObj: KeyValuePair<number, string>) => {
    runInAction(() => {
      this._eventNumberObj = eventNumberObj;
    });
  };

  get phoneMsgLovs() {
    return this._phoneMsgLovs;
  }
  setPhoneMsgLovs = (phoneMsgLovs: PhoneMessageLOVs) => {
    runInAction(() => {
      this._phoneMsgLovs = phoneMsgLovs;
    });
  };

  get uiControl() {
    return this._uiControl;
  }
  setUIControl = (uiControl: PhoneMessageUIControl) => {
    runInAction(() => {
      this._uiControl = uiControl;
    });
  };

  get uiControlExisted() {
    return this._uiControlExisted;
  }
  setUIControlExisted = (uiControlExisted: PhoneMessageUIControl) => {
    runInAction(() => {
      this._uiControlExisted = uiControlExisted;
    });
  };

  get isShowNewPhoneMsgDialog() {
    return this._isShowNewPhoneMsgDialog;
  }
  setIsShowNewPhoneMsgDialog = (isShowNewPhoneMsgDialog: boolean) => {
    runInAction(() => {
      this._isShowNewPhoneMsgDialog = isShowNewPhoneMsgDialog;
    });
  };

  get isSubmitting() {
    return this._isSubmitting;
  }
  setIsSubmitting = (isSubmitting: boolean) => {
    runInAction(() => {
      this._isSubmitting = isSubmitting;
    });
  };

  get isLoadingEventNumber() {
    return this._isLoadingEventNumber;
  }
  setIsLoadingEventNumber = (isLoadingEventNumber: boolean) => {
    runInAction(() => {
      this._isLoadingEventNumber = isLoadingEventNumber;
    });
  };

  get notification() {
    return this._notification;
  }
  setNotification = (
    notification: IAppNotificationItemAddProps | undefined
  ) => {
    runInAction(() => {
      this._notification = notification;
    });
  };

  get onSubmit() {
    return this._onSubmit;
  }
  setOnSubmit = (onSubmit: (event: React.SyntheticEvent<any>) => void) => {
    runInAction(() => {
      this._onSubmit = onSubmit;
    });
  };

  get isShowConvertToEventDialog() {
    return this._isShowConvertToEventDialog;
  }
  setIsShowConvertToEventDialog = (isShowConvertToEventDialog: boolean) => {
    runInAction(() => {
      this._isShowConvertToEventDialog = isShowConvertToEventDialog;
    });
  };

  get isShowCloseEventDialog() {
    return this._isShowCloseEventDialog;
  }
  setIsShowCloseEventDialog = (isShowCloseEventDialog: boolean) => {
    runInAction(() => {
      this._isShowCloseEventDialog = isShowCloseEventDialog;
    });
  };

  get crmsEventClose() {
    return this._crmsEventClose;
  }
  setCrmsEventClose = (crmsEventClose: EventClose) => {
    runInAction(() => {
      this._crmsEventClose = crmsEventClose;
    });
  };

  get isLoadingCloseEvent() {
    return this._isLoadingCloseEvent;
  }
  setIsLoadingCloseEvent = (isLoadingCloseEvent: boolean) => {
    runInAction(() => {
      this._isLoadingCloseEvent = isLoadingCloseEvent;
    });
  };

  get isShowForwardOnDialog() {
    return this._isShowForwardOnDialog;
  }
  setIsShowForwardOnDialog = (isShowForwardOnDialog: boolean) => {
    runInAction(() => {
      this._isShowForwardOnDialog = isShowForwardOnDialog;
    });
  };

  get isLoadingSendToActionOfficer() {
    return this._isLoadingSendToActionOfficer;
  }
  setIsLoadingSendToActionOfficer = (isLoadingSendToActionOfficer: boolean) => {
    runInAction(() => {
      this._isLoadingSendToActionOfficer = isLoadingSendToActionOfficer;
    });
  };

  get isShowCommentDialog() {
    return this._isShowCommentDialog;
  }
  setIsShowCommentDialog = (isShowCommentDialog: boolean) => {
    runInAction(() => {
      this._isShowCommentDialog = isShowCommentDialog;
    });
  };

  get isLoadingGetContact() {
    return this._isLoadingGetContact;
  }
  setIsLoadingGetContact = (isLoadingGetContact: boolean) => {
    runInAction(() => {
      this._isLoadingGetContact = isLoadingGetContact;
    });
  };

  //Action
  resetStore = (isExisted: boolean) => {
    runInAction(() => {
      this._crmsPhoneMessage = undefined;
      this._isLoading = false;
      this._responseLoadError = undefined;
      this._eventNumberObj = undefined;
      this._uiControl = undefined;
      this._isShowNewPhoneMsgDialog = false;
      this._isSubmitting = false;
      this._isLoadingEventNumber = false;
      this._notification = undefined;
      this._isLoadingGetContact = false;
      if (isExisted) {
        this._uiControlExisted = undefined;
        this._isLoadingExited = false;
        this._existCrmsPhoneMessage = undefined;
        this._phoneMsgLovs = undefined;
        this._isShowConvertToEventDialog = false;
        this._isShowCloseEventDialog = false;
        this._crmsEventClose = undefined;
        this._isLoadingCloseEvent = false;
        this._isLoadingSendToActionOfficer = false;
        this._isShowCommentDialog = false;
      }
    });
  };

  resetDetailsCrmsPhoneMessage = () => {
    runInAction(() => {
      this._detailsCrmsPhoneMessage = undefined;
    });
  };

  loadCRMSPhoneMessage = async (
    crmsPhoneMessageId: number,
    isNew: boolean = false,
    notification?: IAppNotificationItemAddProps
  ) => {
    let errorResponse = undefined;
    if (!isNew) {
      this.setIsLoadingExisted(true);
      const [response, responseLovs] = await Promise.all([
        getCRMSPhoneMessageSummaryById(crmsPhoneMessageId),
        getPhoneMsgLovs(),
      ]);
      if (
        isSuccessResponse(response) &&
        response.data &&
        isSuccessResponse(responseLovs) &&
        responseLovs?.data
      ) {
        let params: PhoneMessageEventHandlerRequest = {
          PhoneMessageFormAction: Svc_PhoneMessageFormAction.SystemInitialise,
          PhoneMessage: response.data,
          EventArgs: {},
          isFirstTimeLoad: true,
        };
        const responseEventHandler = await eventHandler(params);
        if (
          isSuccessResponse(responseEventHandler) &&
          responseEventHandler.data?.ReturnObj
        ) {
          const phoneMessageObj =
            responseEventHandler.data.ReturnObj.PhoneMessage;
          const flags = phoneMessageObj?.MessageFlag_IDs?.split("|");
          let flagsObj = {};
          for (let flag of flags) {
            flagsObj = Object.assign(flagsObj, { [`MsgFlag_${flag}`]: true });
          }
          this.setUIControlExisted(
            responseEventHandler.data.ReturnObj.UIControl
          );
          this.setPhoneMsgLovs(responseLovs.data);
          this.setExistCrmsPhoneMessage(
            Object.assign(responseEventHandler.data.ReturnObj.PhoneMessage, {
              [`${CRMS_PHONE_MESSAGE_FLAGS}`]: flagsObj,
            })
          );
          // Push contact alert
          if (
            phoneMessageObj?.ContactType_ENUM ===
              CS_RequestorType.ExternalPerson &&
            phoneMessageObj?.Contact_External?.Contact?.ContactAlert
          ) {
            appNotificationStore.pushNotification({
              type: "warning",
              description: `Contact alert: ${phoneMessageObj.Contact_External.Contact.ContactAlert}`,
              autoClose: false,
            });
          }
          // Push notification
          if (notification) {
            appNotificationStore.pushNotification(notification);
          }
        } else {
          errorResponse = {
            status: responseEventHandler.status,
            error: responseEventHandler.error,
          };
        }
      } else {
        errorResponse = {
          status: response.status,
          error: response.error,
        };
      }
      this.setIsLoadingExisted(false);
      // Push notification
      if (this.notification) {
        appNotificationStore.pushNotification(this.notification);
        this.setNotification(undefined);
      }
    } else {
      this.setIsLoading(true);
      const [response, responseLovs] = await Promise.all([
        getCRMSPhoneMessageSummaryById(0),
        getPhoneMsgLovs(),
      ]);
      if (
        isSuccessResponse(response) &&
        response?.data &&
        isSuccessResponse(responseLovs) &&
        responseLovs?.data
      ) {
        let params: PhoneMessageEventHandlerRequest = {
          PhoneMessageFormAction: Svc_PhoneMessageFormAction.SystemInitialise,
          PhoneMessage: response.data,
          EventArgs: {},
          isFirstTimeLoad: true,
        };
        const responseEventHandler = await eventHandler(params);
        if (
          isSuccessResponse(responseEventHandler) &&
          responseEventHandler.data?.ReturnObj
        ) {
          this.setUIControl(responseEventHandler.data.ReturnObj.UIControl);
          this.setPhoneMsgLovs(responseLovs.data);
          this.setCRMSPhoneMessage(
            responseEventHandler.data.ReturnObj.PhoneMessage
          );
        } else {
          errorResponse = {
            status: responseEventHandler.status,
            error: responseEventHandler.error,
          };
        }
      } else {
        errorResponse = {
          status: response.status ?? responseLovs.status,
          error: response.error ?? responseLovs.error,
        };
      }
      this.setIsLoading(false);
    }
    this.setResponseLoadError(errorResponse);
  };

  getNextEventNumber = async (phoneMessage: PhoneMessage) => {
    this.setIsLoadingEventNumber(true);
    const response = await getPhoneMsgEventNumber();
    this.setIsLoadingEventNumber(false);
    if (isSuccessResponse(response) && response.data) {
      this.setEventNumberObj(response.data);
    } else {
      appNotificationStore.pushNotification({
        autoClose: false,
        title: "Get event number fail.",
        type: "error",
        description: response.error,
      });
    }
  };

  eventChangeHandler = async (
    params: PhoneMessageEventHandlerRequest,
    errorMsg: string,
    notificationRef?: React.RefObject<ICCLocalNotificationHandle | null>
  ) => {
    this.setIsLoading(true);
    const response = await eventHandler(params);
    if (isSuccessResponse(response) && response.data?.ReturnObj) {
      this.setCRMSPhoneMessage(response.data.ReturnObj.PhoneMessage);
      this.setUIControl(response.data.ReturnObj.UIControl);
    } else {
      let notification: IAppNotificationItemAddProps = {
        autoClose: false,
        title: errorMsg,
        type: "error",
        description:
          response.data?.Errors || response.error || "Change value fail.",
      };
      if (notificationRef) {
        notificationRef.current?.pushNotification(notification);
      } else {
        appNotificationStore.pushNotification(notification);
      }
    }
    this.setIsLoading(false);
  };

  loadCRMSPhoneMessageDetails = async (crmsPhoneMessageId: number) => {
    let errorResponse = undefined;
    this.setIsLoadingDetails(true);
    const response = await getCRMSPhoneMessageSummaryById(crmsPhoneMessageId);
    this.setIsLoadingDetails(false);

    if (isSuccessResponse(response)) {
      this.setCrmsPhoneMessageDetails(response.data);
    } else {
      errorResponse = {
        status: response.status,
        error: response.error,
      };
    }

    this.setResponseLoadError(errorResponse);
  };

  sendCRMSPhoneMessage = async (
    phoneMessage: PhoneMessage,
    notificationRef?: React.RefObject<ICCLocalNotificationHandle | null>
  ) => {
    this.setIsSubmitting(true);
    const response = await sendPhoneMessage(phoneMessage);
    this.setIsSubmitting(false);

    if (isSuccessResponse(response) && response.data) {
      this.setIsShowNewPhoneMsgDialog(false);
      eventEmitter.emit(CCGridEventType.RefreshOData, {
        gridIds: [PHONE_MESSAGE_GRID_ID],
      });
      appNotificationStore.pushNotification({
        title: "The CRMS phone message was sent successfully.",
        type: "success",
      });
    } else {
      let notification: IAppNotificationItemAddProps = {
        autoClose: false,
        type: "error",
        description:
          response.data?.Errors ||
          response.error ||
          "The CRMS phone message send fail.",
      };
      if (notificationRef) {
        notificationRef.current?.pushNotification(notification);
      } else {
        appNotificationStore.pushNotification(notification);
      }
    }
  };

  saveCRMSPhoneMessage = async (phoneMessage: PhoneMessage) => {
    this.setIsLoadingExisted(true);
    const response = await savePhoneMessage(phoneMessage);
    this.setIsLoadingExisted(false);

    if (isSuccessResponse(response) && response.data) {
      this.loadCRMSPhoneMessage(response.data.ID, false);
      this.setNotification({
        title: "Phone message successfully saved.",
        type: "success",
      });
    } else {
      appNotificationStore.pushNotification({
        autoClose: false,
        type: "error",
        description:
          response.data?.Errors || response.error || "Phone message save fail.",
      });
    }
  };

  convertToEventHandler = async (phoneMessage: PhoneMessage) => {
    this.setIsLoadingExisted(true);
    const response = await convertToEvent(phoneMessage);
    this.setIsShowConvertToEventDialog(false);
    this.setIsLoadingExisted(false);

    if (isSuccessResponse(response) && response.data) {
      if (response.data.ID === phoneMessage.PhoneMessage_ID) {
        this.loadCRMSPhoneMessage(response.data.ID, false);
      } else {
        history.replace(CRMS_ROUTE + "/" + response.data.ID);
      }
    } else {
      appNotificationStore.pushNotification({
        autoClose: false,
        type: "error",
        description:
          response.data?.Errors || response.error || "Covert to event fail.",
      });
    }
  };

  completeMessageHandler = async (
    completeMessageRequest: CompleteMessageRequest
  ) => {
    this.setIsLoadingCloseEvent(true);
    const response = await postCompleteMessage(completeMessageRequest);
    this.setIsLoadingCloseEvent(false);

    if (isSuccessResponse(response) && response.data) {
      this.setIsShowCloseEventDialog(false);
      const notification: IAppNotificationItemAddProps = {
        title: "Phone message successfully completed.",
        type: "success",
      };
      const phoneMsgViewsSetting = getStringValueSetting(
        commonCoreStore.settings?.[
          ECorporateSettingsField.CUSTOMERSERVICE_Views_PhoneMessages
        ]
      );
      const listPhoneMessageView = phoneMsgViewsSetting
        ?.split("|")
        .filter((item) => item.trim() !== "");

      if (listPhoneMessageView && listPhoneMessageView?.length > 0) {
        const defaultRoute = CRMS_PHONE_MESSAGE_ROUTE + "/all-messages";
        const route = listPhoneMessageView.includes(
          CRMSViews.PhoneMessages_Open.toString()
        )
          ? CRMS_PHONE_MESSAGE_ROUTE + "/open-messages"
          : defaultRoute;
        history.replace(route);
        appNotificationStore.pushNotification(notification);
      } else {
        this.loadCRMSPhoneMessage(response.data.ID, false, notification);
      }
    } else {
      this.setNotification({
        autoClose: false,
        type: "error",
        description:
          response.data?.Errors || response.error || "Complete message fail.",
      });
    }
  };

  setupCompleteMessageHandler = async (phoneMessage: PhoneMessage) => {
    this.setIsLoadingExisted(true);
    const response = await setupCompleteMessage(phoneMessage);
    this.setIsLoadingExisted(false);

    if (isSuccessResponse(response) && response.data) {
      this.setCrmsEventClose(response.data.ReturnObj);
    } else {
      appNotificationStore.pushNotification({
        autoClose: false,
        type: "error",
        description:
          response.data?.Errors ||
          response.error ||
          "Setup complete message fail.",
      });
    }
  };

  sendToActionOfficerHandler = async (
    sendToActionOfficerRequest: SendToActionOfficerRequest,
    id: string
  ) => {
    const requestBody: Comments = {
      ID: 0,
      Comment: sendToActionOfficerRequest?.CommentMessage ?? "",
      Title: "Comment",
      BubbleUps: [
        {
          PKID: 0,
          SourceIdentifier: {
            _RecordSource_ID: id ? +id : 0,
            _RecordSourceType_ENUM:
              RECORDTYPE.CUSTOMERSERVICE_PhoneMessage.toString(),
            _RecordSource_ColumnName: "",
            _RecordSource_StringID: "",
          },
          BubbleUpType_ENUM: BubbleUpType.Ancestor,
          BubbleUpType_Name: BubbleUpType.Ancestor.toString(),
          Sys_CreatedDate: new Date(),
          Sys_DeactivationDate: null,
        },
        {
          PKID: 0,
          SourceIdentifier: {
            _RecordSource_ID: id ? +id : 0,
            _RecordSourceType_ENUM:
              RECORDTYPE.CUSTOMERSERVICE_PhoneMessage.toString(),
            _RecordSource_ColumnName: "",
            _RecordSource_StringID: "",
          },
          BubbleUpType_ENUM: BubbleUpType.Parent,
          BubbleUpType_Name: BubbleUpType.Parent.toString(),
          Sys_CreatedDate: new Date(),
          Sys_DeactivationDate: null,
        },
      ],
      workFlowDraftID: null,
    };

    this.setIsLoadingSendToActionOfficer(true);
    const response = await postSentToActionOfficer(sendToActionOfficerRequest);
    let responseComment = undefined;
    let errorNotification = undefined;
    let successNotification = undefined;
    if (sendToActionOfficerRequest?.CommentMessage) {
      responseComment = await saveComment(
        requestBody as Comments,
        RECORDTYPE.CUSTOMERSERVICE_PhoneMessage
      );
    }

    this.setIsLoadingSendToActionOfficer(false);

    if (!sendToActionOfficerRequest?.CommentMessage) {
      if (isSuccessResponse(response) && response?.data) {
        this.loadCRMSPhoneMessage(response.data.ID, false);
        successNotification = {
          title: "Record saved successfully.",
          type: "success",
        };
        this.setIsShowCommentDialog(false);
      } else {
        errorNotification = {
          autoClose: false,
          type: "error",
          description:
            response.data?.Errors ||
            response.error ||
            "Send to action officer fail.",
        };
      }
    } else {
      if (
        isSuccessResponse(response) &&
        response?.data &&
        isSuccessResponse(responseComment) &&
        responseComment?.data
      ) {
        this.loadCRMSPhoneMessage(response.data.ID, false);
        successNotification = {
          title: "Record saved successfully.",
          type: "success",
        };
        // Refresh comments accordion
        eventEmitter.emit(CCGridEventType.RefreshOData, {
          gridIds: [COMMENT_ACCORDION_GRID_ID],
        });
        eventEmitter.emit(TabTableEventType.RefreshData);
        this.setIsShowCommentDialog(false);
      } else {
        errorNotification = {
          autoClose: false,
          type: "error",
          description:
            response.data?.Errors ||
            response.error ||
            responseComment?.data?.Errors ||
            responseComment?.error ||
            "Send to action officer fail.",
        };
      }
    }
    // Set success notification
    if (successNotification)
      this.setNotification(successNotification as IAppNotificationItemAddProps);
    //Set error notification
    if (errorNotification)
      this.setNotification(errorNotification as IAppNotificationItemAddProps);
  };
}

const crmsPhoneMessageStoreContext = createContext(new CRMSPhoneMessageStore());
export const useCRMSPhoneMessageStore = () => {
  return useContext(crmsPhoneMessageStoreContext);
};
