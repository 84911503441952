import {
  apiCoreDocumentsAddToRecordsById,
  apiCoreDocumentsAttachment,
  apiCoreDocumentsAttachmentById,
  apiCoreDocumentsImages,
  apiCoreDocumentsStatus,
  apiCoreDocumentsWebLink,
} from "@app/core/components/common/utils";
import {
  Attachment,
  DOCUMENTSTATUS,
  DocumentClassification,
  IImage,
  Svc_Attachment,
  WebLink,
} from "@app/core/documents/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { RECORDTYPE } from "@common/constants/recordtype";
import { IdentityPacket } from "@common/models/identityPacket";

export const getDocumentById = async (
  id: number,
  attachment?: boolean | null
): Promise<APIResponse<Attachment>> => {
  try {
    return await CoreAPIService.getClient().get<Attachment>(
      apiCoreDocumentsAttachmentById(id),
      { params: { attachment: attachment } }
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const saveDocumentStatus = async (
  id: number,
  attachmentId: number,
  status?: DOCUMENTSTATUS | null,
  docClassification?: DocumentClassification | null
): Promise<APIResponse<any>> => {
  try {
    return await CoreAPIService.getClient().put(
      apiCoreDocumentsStatus(id),
      {},
      {
        params: {
          Attachment_ID: attachmentId,
          status: status,
          docClassification: docClassification,
        },
      }
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const saveDocument = async (
  attachment: Svc_Attachment | any
): Promise<APIResponse<any>> => {
  try {
    return await CoreAPIService.getClient().post(
      apiCoreDocumentsAttachment(),
      attachment
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const saveDocumentAsWebLink = async (
  parentId: number,
  recordType: RECORDTYPE,
  requestBody: WebLink | any
): Promise<APIResponse<IdentityPacket>> => {
  try {
    return await CoreAPIService.getClient().post(
      apiCoreDocumentsWebLink(),
      requestBody,
      {
        params: {
          id: parentId,
          recordType: recordType,
        },
      }
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getDocumentImageView = async (
  attachmentId?: number,
  recordSourceId?: number,
  blobStoreId?: number
): Promise<APIResponse<IImage[]>> => {
  try {
    return await CoreAPIService.getClient().get<IImage[]>(
      apiCoreDocumentsImages(),
      {
        params: {
          attachmentId: attachmentId,
          recordSourceId: recordSourceId,
          blobStoreId: blobStoreId,
        },
      }
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const addToRecordsById = async (
  id: number
): Promise<APIResponse<IdentityPacket>> => {
  try {
    return await CoreAPIService.getClient().get<IdentityPacket>(
      apiCoreDocumentsAddToRecordsById(id)
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
