import { Svc_LLPermit } from "@app/products/local-laws/permits/model";
import { SettingsMap } from "@app/products/property/system-admin/settings/model";
import { DATE_FORMAT } from "@common/constants/common-format";
import { PROPERTYSYSTEM, RECORDSSYSTEM } from "@common/constants/enumerations";
import { configGrid, configGridCustom } from "@common/models/configGrid";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import {
  getBoolValueSetting,
  getNumberValueSetting,
  getStringValueSetting,
} from "@common/stores/products/util";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";
import { cloneDeep } from "lodash";

const nameOf = nameOfFactory<Svc_LLPermit>();

export const localLawsPermitsRegister: configGrid = {
  columns: [
    {
      field: nameOf("PermitNumber"),
      title: "App Number",
      locked: true,
      /* Update this after finishing the manage page of this list */
      // linkTo: (dataItem) => ({
      //   pathname: `${LOCAL_LAWS_MANAGE_ROUTE}/` + dataItem.ID,
      // }),
    },
    {
      field: nameOf("DebtorNo"),
      title: "Debtor Number",
    },
    {
      field: nameOf("AssessmentNo"),
      title: "Assess Number",
    },
    {
      field: nameOf("FileNo"),
      title: "File Number",
    },
    {
      field: nameOf("Applicant"),
      title: "Permit Holder",
    },
    { field: nameOf("SiteAddress"), title: "Site Address" },
    { field: nameOf("Locality"), title: "Locality" },
    { field: nameOf("StatusDueStatus"), title: "Status" },
    { field: nameOf("Officer"), title: "Officer" },
    { field: nameOf("Type"), title: "Permit Type" },
    { field: nameOf("Drescription"), title: "Dev Description" },
    {
      field: nameOf("EffectiveFrom"),
      title: "Effective From",
      format: DATE_FORMAT.DATE,
    },
    {
      field: nameOf("EffectiveTo"),
      title: "Effective To",
      format: DATE_FORMAT.DATE,
    },
    {
      field: nameOf("UsePermitEventName"),
      title: "Event Name",
    },
    { field: nameOf("Area"), title: "Area" },
  ],
};

export const localLawsPermitsRegisterCustom: configGridCustom = (
  settings: SettingsMap
) => {
  const isRegisterDisplayDebtorNumber =
    getBoolValueSetting(
      settings[
        ECorporateSettingsField.CorporateSettings_RegisterDisplayDebtorNumber
      ]
    ) ?? false;
  const isLLPUsePermitEventName =
    getBoolValueSetting(
      settings[ECorporateSettingsField.LLP_UsePermitEventName]
    ) ?? false;
  const debtorNumberLabel = getStringValueSetting(
    settings[ECorporateSettingsField.CorporateSettings_DebtorNumberLabel]
  );
  const propertySystem = getNumberValueSetting(
    settings[ECorporateSettingsField.CorporateSettings_PropertySystem]
  );
  const recordsSystem = getNumberValueSetting(
    settings[ECorporateSettingsField.CorporateSettings_RecordsSystem]
  );

  const fieldConditions = {
    [nameOf("DebtorNo")]: () =>
      debtorNumberLabel && isRegisterDisplayDebtorNumber,
    [nameOf("AssessmentNo")]: () => propertySystem !== PROPERTYSYSTEM.None,
    [nameOf("FileNo")]: () => recordsSystem !== RECORDSSYSTEM.None,
    [nameOf("UsePermitEventName")]: () => isLLPUsePermitEventName,
  };
  const columnsClone: IColumnFields[] = cloneDeep(
    localLawsPermitsRegister.columns
  );
  const newColumns = columnsClone.filter((col) => {
    /* ====  Change Label here  ==== */
    if (col.field === nameOf("DebtorNo") && debtorNumberLabel) {
      col.title = debtorNumberLabel;
    }
    /* ====  / Change Label here  ==== */

    const conditionCheck =
      fieldConditions[col.field as keyof typeof fieldConditions];
    return conditionCheck ? conditionCheck() : true;
  });
  return { columns: newColumns };
};
