export enum RECORDTYPE {
  SystemInitialise = 0,
  CONTACTMANAGER_ORGANISATION = 1,
  CONTACTMANAGER_PERSON = 2,
  CORE_InspectionType = 10,
  CORE_FeeType = 11,
  CORE_SampleType = 12,
  CORE_ActionType = 13,
  CORE_SecurityTemplate = 14,
  CORE_SiteUser_CorporateAuthorisation = 15,
  CORE_ComplaintType = 16,
  CORE_PrintQueue = 17,
  CORE_Log = 18,
  CORE_NoticeType = 19,
  CORE_AdvertisingType = 20,
  CORE_Advertising = 21,
  CORE_FurtherInformationRequest = 22,
  CORE_Appeal = 23,
  CORE_AppealHearing = 24,
  CORE_Contact = 101,
  CORE_ContactRelation = 102,
  CORE_ContactBubble = 103,
  CORE_Address = 104,
  CORE_Document = 111,
  CORE_Attachment = 112,
  CORE_Report = 113,
  CORE_Keyword = 114,
  CORE_GlobalSettings = 115,
  CORE_UserRole = 116,
  CORE_TrimSettings = 117,
  CORE_OrgStructure = 118,
  CORE_Comment = 119,
  CORE_Sample = 120,
  CORE_Fees = 121,
  CORE_Inspection = 122,
  CORE_Action = 123,
  CORE_Payment = 124,
  CORE_Notice = 125,
  CORE_Prosecution = 126,
  CORE_Complaint = 127,
  CORE_Journal = 128,
  CORE_Suburb = 129,
  CORE_CorporateSetting = 130,
  CORE_Group = 131,
  CORE_LoginTracking = 132,
  CORE_ProductListing = 133,
  CORE_ClientListing = 134,
  CORE_OrgUnit = 135,
  CORE_NavigationStore = 136,
  CORE_NavigationMessage = 137,
  CORE_ReferenceNumberUsage = 138,
  CORE_NCItem = 139,
  CORE_NCItemResult = 140,
  CORE_FeeSetup = 141,
  CORE_AssessmentResult = 142,
  CORE_ActionPlan = 143,
  CORE_Quote = 144,
  CORE_QuoteItem = 145,
  CORE_VCATReview = 146,
  CORE_ProsecutionCharge = 147,
  CORE_SiteUser_Credential = 148,
  CORE_ResetEffectiveDate = 149,
  CORE_CommunicationQueue = 150,
  CORE_LookUpDate = 151,
  CORE_AFSAResult = 152,
  CORE_AFSAResultComment = 153,
  CORE_CommunicationTemplate = 154,
  CORE_Invoice = 155,
  CORE_DialogDate = 156,
  CORE_AccessControl = 157,
  EXTERNAL_infringe = 158,
  CORE_SampleSurvey = 159,
  CORE_SampleTest = 160,
  CORE_Recall = 161,
  CORE_StatClock = 162,
  CORE_ChecklistQuestion = 163,
  CORE_ChecklistResult = 164,
  CORE_Checklist = 165,
  CORE_AFSAComment = 166,
  CORE_Refund = 167,
  CORE_Register = 168,
  CORE_PaymentGateway = 169,
  CORE_ConditionReference = 170,
  CORE_Condition = 171,
  CORE_WebLink = 172,
  CORE_Related = 173,
  CORE_PortalLink = 174,
  CORE_Register_StreetProtection = 175,
  CORE_TemperatureRecorded = 176,
  CORE_FSPRecord = 177,
  CORE_Roster = 178,
  CORE_PropertyAlert = 179,
  CORE_PaymentTransaction = 180,
  Core_Appointment = 181,
  Core_AppointmentType = 182,
  Core_AvailableAppointment = 183,
  Core_AppointmentCalendar = 184,
  Core_AppointmentShopCalendar = 185,
  Core_HomePageFunction = 190,
  Core_HomePageView = 191,
  Core_SequenceNumber = 192,
  CORE_NonCompliance = 193,
  CORE_IntegratedReport = 194,
  Core_Project = 195,
  CommunityProfile = 196,
  Core_InspectionPlanner = 197,
  CORE_Link = 198,
  CORE_Council = 199,
  HealthManager_PremisesType = 200,
  HealthManager_Premises = 201,
  HealthManager_PremisesClassification = 202,
  HealthManager_RiskClassification = 203,
  HealthManager_Recall = 204,
  HealthManager_FSRLog = 205,
  HealthManager_RegistrationNumber = 206,
  HealthManager_VolumeScore = 207,
  HealthManager_PremisesBusinessActivity = 208,
  HealthManager_PremisesHealthCare = 209,
  HealthManager_Complaints = 210,
  HealthManager_ActivityReport = 211,
  CRS_Registration = 401,
  CRS_Centres = 402,
  CRS_CentreBasedCare = 403,
  CRS_HomeBasedCare = 404,
  CRS_ThreeYOKinder = 405,
  CRS_FourYOKinder = 406,
  CRS_FourYOKinderInvoice = 407,
  CRS_FYOKinderTerm1Invoice = 408,
  CRS_FYOKinderTerm2Invoice = 409,
  CRS_FYOKinderTerm3Invoice = 410,
  CRS_FYOKinderTerm4Invoice = 411,
  CRS_FYOKinderPlacementInvoice = 412,
  CRS_FourYOKinder_Offer = 413,
  CRS_ThreeYOKinder_Offer = 414,
  CRS_Rooms = 425,
  CRS_Group = 430,
  CRS_Session = 431,
  CRS_Characteristic = 432,
  CRS_Category = 433,
  CRS_Allocation = 434,
  CRS_AllocationGroup = 435,
  CRS_Question = 437,
  CRS_AllocationAudit = 438,
  CRS_Comment = 439,
  CRS_QuestionCategory = 440,
  CRS_Search = 443,
  CRS_CBCGroup = 444,
  CRS_HBCGroup = 445,
  CRS_TYOGroup = 446,
  CRS_FYOGroup = 447,
  CRS_ProcessChange = 460,
  CRS_PortalAttachment = 461,
  CRS_PortalPayment = 462,
  CRS_SearchParent = 463,
  CRS_LinkedFeeService = 464,
  CRS_PaymentImported = 465,
  CRS_ThreeYOKinderInvoice = 466,
  CRS_CENTRE_ACCESS_Comment = 480,
  CUSTOMERSERVICE_ServiceStandard = 501,
  CUSTOMERSERVICE_ServiceStandardMapper = 502,
  CUSTOMERSERVICE_Event = 503,
  CUSTOMERSERVICE_ServiceStandardCategory = 504,
  CUSTOMERSERVICE_ServiceStandardSubCategory = 505,
  CUSTOMERSERVICE_LookupTable = 506,
  CUSTOMERSERVICE_EventLookupTable_LNK = 507,
  CUSTOMERSERVICE_BulletinBoard = 508,
  CUSTOMERSERVICE_BulletinBoardServiceStandard_LNK = 509,
  CUSTOMERSERVICE_StandardDocument = 510,
  CUSTOMERSERVICE_KBTopic = 511,
  CUSTOMERSERVICE_KBArticleTopicLink = 512,
  CUSTOMERSERVICE_KBAlert = 513,
  CUSTOMERSERVICE_KBAlertServiceStandardLink = 514,
  CUSTOMERSERVICE_KBAlertTerm = 515,
  CUSTOMERSERVICE_PhoneMessage = 516,
  CUSTOMERSERVICE_ServiceStandardActionType_LNK = 517,
  CUSTOMERSERVICE_EventActionType_LNK = 518,
  CUSTOMERSERVICE_BulletinBoardEvent_LNK = 519,
  TelephoneActivity = 520,
  CUSTOMERSERVICE_RelatedEvent = 521,
  CUSTOMERSERVICE_SpamEmails = 522,
  CUSTOMERSERVICE_CC_ServiceProvider_PortalUserProfile = 531,
  CUSTOMERSERVICE_CC_Vaccine = 532,
  CUSTOMERSERVICE_CC_Breach = 533,
  CUSTOMERSERVICE_CC_BreachDetails = 534,
  CUSTOMERSERVICE_CC_BreachAdvice = 535,
  CUSTOMERSERVICE_CC_TemperatureDurationRange = 536,
  CUSTOMERSERVICE_CC_VaccineAction = 537,
  CUSTOMERSERVICE_CC_GPDivision = 538,
  CUSTOMERSERVICE_CD_IncidentCheckListFlood = 550,
  CUSTOMERSERVICE_CD_IncidentCheckListStorm = 551,
  CUSTOMERSERVICE_CD_TreeManagement = 552,
  CUSTOMERSERVICE_HardRubbishCollection = 553,
  CUSTOMERSERVICE_CD_AnimalAttacks = 554,
  CUSTOMERSERVICE_CD_PublicLiabilitySimple = 555,
  CUSTOMERSERVICE_CD_VehicleImpoundChecklist = 556,
  CUSTOMERSERVICE_CD_AnimalsWandering = 557,
  CUSTOMERSERVICE_CD_Bins = 558,
  CUSTOMERSERVICE_CD_CatTrap = 559,
  CUSTOMERSERVICE_CD_SyringesAndSharps = 560,
  CUSTOMERSERVICE_CD_EnvironmentalHealth = 561,
  CUSTOMERSERVICE_CD_MyPlaceYouthCentre = 562,
  CUSTOMERSERVICE_CD_ComplaintsHandling = 563,
  CUSTOMERSERVICE_CD_PublicLiabilityComprehensive = 564,
  CUSTOMERSERVICE_CD_DrinkingWater = 570,
  CUSTOMERSERVICE_CD_DisabilityWorker = 580,
  CUST_EventIssue = 581,
  DisRes_Permit = 601,
  DisRes_ResidentParkingAreaDefinition = 602,
  DisRes_StaffParkingAreaDefinition = 603,
  DisRes_PermitType = 604,
  DisRes_PropertyException = 605,
  DisRes_PropertyRestriction = 606,
  DisRes_PermitLabel = 607,
  DisRes_WorkStats = 608,
  DisRes_Portal_DocumentChecklist = 609,
  DisRes_Portal_Fee = 610,
  CM_Cemetery = 701,
  CM_Section = 702,
  CM_Subsection = 703,
  CM_Site = 704,
  CM_Licence = 705,
  CM_LicenceGroup = 706,
  CM_Interment = 707,
  CM_CrematedRemains = 708,
  CM_Monument = 709,
  CM_Inventory = 710,
  CM_Booking = 711,
  CM_Appointment = 712,
  CM_Consultation = 713,
  CM_FuneralPlan = 714,
  CM_RoseRegister = 715,
  CM_RoseVariety = 716,
  CM_SubsectionInventory = 717,
  CM_LicenceGroupLicence = 718,
  CM_BCI = 721,
  CM_BCIItem = 722,
  CM_BIO = 723,
  CM_BIOItem = 724,
  CM_BookingDeceasedPerson = 725,
  CM_CreateLicence = 726,
  CM_CO = 727,
  CM_COItem = 728,
  CM_CRIO = 729,
  CM_CRIOItem = 730,
  CM_CRIOMonument = 731,
  CM_CRRO = 732,
  CM_CRROItem = 733,
  CM_EO = 734,
  CM_MWIO = 735,
  CM_MWIODeceasedPerson = 736,
  CM_MWO = 737,
  CM_MWOItem = 738,
  CM_MWOItemDeceasedPerson = 739,
  CM_MWOItemLicence = 740,
  CM_RenewLicence = 741,
  CM_ROCR = 742,
  CM_SurrenderLicence = 743,
  CM_SurrenderLicenceInterment = 744,
  CM_SurrenderLicenceMonument = 745,
  CM_TransferLicence = 746,
  CM_ConsultationLicence = 747,
  CM_ConsultationDeceasedPerson = 748,
  CM_OptionMaster = 749,
  CM_BookingOption = 750,
  CM_Neighbour = 751,
  CM_MonumentPart = 752,
  CM_SubsectionMonumentPart = 753,
  CM_ConsultationConsultedPerson = 754,
  CM_MWOPermit = 755,
  CM_AppointmentDeceasedPerson = 756,
  CM_CPUser = 758,
  CM_MonumentDeceasedPerson = 759,
  CM_Transaction = 760,
  CM_MWOItemMonument = 761,
  CM_LicenceCategory = 762,
  CM_POS_LineItem = 763,
  CM_POS_Transaction = 764,
  CM_POS_CashPayment = 765,
  CM_POS_Payment = 766,
  CM_Headstone = 767,
  CM_Plaque = 768,
  CM_Other = 769,
  CM_POS_PaymentLineItem = 770,
  CM_Booking_BookableResource = 771,
  CM_MemorialService = 772,
  CM_MemorialServiceDeceasedPerson = 773,
  CM_CRSO = 774,
  CM_CRSOItem = 775,
  CM_BulkSites = 776,
  CM_FuneralPlanOption = 777,
  CM_PrepaidQuoteItem = 778,
  CM_PrepaidFeeType = 779,
  CM_CalendarNote = 780,
  CM_AdditionalFeeType = 781,
  CM_AdditionalQuoteItem = 782,
  CM_ARFeeType = 783,
  CM_ARQuoteItem = 784,
  EPLAN_Application = 900,
  EPLAN_Enquiry = 901,
  EPLAN_Objection = 902,
  EPLAN_FurtherInformation = 903,
  EPLAN_OtherDocuments = 904,
  EPLAN_Reference_Category = 920,
  EPLAN_Reference_Document = 921,
  EPLAN_Reference_Schedule = 922,
  ELFPortal_Application = 1100,
  ELFPortal_Renewal = 1101,
  ELFPortal_AnnualReturn = 1102,
  ELFPortal_Site = 1103,
  ELFPortal_Vehicle = 1104,
  ELFPortal_Activity = 1105,
  ELFPortal_Certificates = 1106,
  ELFPortal_DevelopmentApprovals = 1107,
  ELFPortal_WasteActivities = 1108,
  ELFPortal_PortalContact = 1109,
  GENI_RADPortal_Application = 1150,
  GENI_PortalContact = 1151,
  GENI_Radionuclide = 1152,
  GENI_RECPortal_Application = 1153,
  GENI_SuperCollector = 1154,
  GENI_WorkDay = 1155,
  GENI_BeverageContainer = 1156,
  GENI_SealedSource = 1157,
  GENI_Premises = 1158,
  CC_Portal_Breach = 1200,
  CC_Portal_AffectedVaccine = 1201,
  TP_Application = 1300,
  TP_ApplicationCategory = 1301,
  TP_ConditionReference = 1302,
  TP_ApplicationCondition = 1303,
  TP_Application_Contact_MAILMERGEONLY = 1304,
  TP_CalculatedFeeSetup = 1305,
  TP_Subdivision = 1306,
  TP_BuildingApplication = 1307,
  TP_DevAppsApplication = 1308,
  TP_FeeSchedule = 1309,
  TP_PSA_Application = 1310,
  TP_PrescribeEvents = 1311,
  TP_ExtensionOfTimeActivity = 1388,
  TP_SecondaryConsentActivity = 1389,
  TP_ObjectionActivity = 1390,
  TP_EnquiryActivity = 1391,
  TP_OtherDocumentsActivity = 1392,
  TP_FurtherInformationActivity = 1393,
  TP_EplanningFee = 1394,
  TP_PermitType = 1395,
  TP_OtherApplication = 1396,
  TP_SPEAR_Application = 1397,
  TP_PPRResponsibleAuthority = 1398,
  TP_PPRApplication = 1399,
  TP_PPRPlanningRegion = 1400,
  TP_Application_AssessmentDetails = 1312,
  TP_PNFApplication = 1313,
  TP_PNFDueDiligence = 1314,
  TP_PNFOperations = 1315,
  TP_PNFTSData = 1316,
  TP_PNF_MailMergeCategory = 1317,
  TP_PNFFieldObservation = 1316,
  TP_PNFDetails = 1317,
  TP_PNFAssessement = 1318,
  TP_PNFApplication_Lots = 1319,
  TP_PNFApplication_LGAs = 1320,
  TP_PNFApplication_Regions = 1321,
  TP_EPlanning_ApplicationCategory = 1322,
  TP_EPlanning_NatureOfWork = 1323,
  TP_EPlanning_DocumentChecklist = 1324,
  TP_LMApplication = 1325,
  TP_LMRegister = 1326,
  TP_BUILD_Portal_DocumentChecklist = 1327,
  MCH_Registration = 1401,
  MCH_Centre = 1402,
  MCH_AppointmentType = 1403,
  MCH_Appointment = 1404,
  LLP_PermitType = 1501,
  LLP_Permit = 1502,
  LLP_PermitDetail = 1503,
  Tender = 1504,
  Permit = 1505,
  Animals_AnimalType = 1601,
  Animals_Registration = 1602,
  Animals_Notices = 1603,
  Animals_PoundRegister = 1604,
  Animals_Kennel = 1605,
  CRSPortal_RegistrationLoginDetails = 1701,
  CRSPortal_Application = 1702,
  CRSPortal_Registration = 1703,
  CRSPortal_Service = 1704,
  CRSPortal_PortalContact = 1705,
  CRSPortal_QuestionResponse = 1706,
  CRSPortal_Question = 1707,
  CRSPortal_Centre = 1708,
  CRSPortal_Group = 1709,
  CRSPortal_Offer = 1710,
  CRSPortal_HoldingBayNewProfileDetails = 1711,
  CRSPortal_HoldingBayChangeProfileDetails = 1712,
  WW_SystemType = 1801,
  WW_System = 1802,
  WW_Scheme = 1803,
  WW_Installation = 1804,
  WW_Product = 1805,
  CSM_LicenceCategory = 1901,
  CSM_Licence = 1902,
  CSM_LicenceSubstanceDetail = 1903,
  CSM_PoisonRegister = 1904,
  CSM_Poisons_PestObservation = 1905,
  CSM_Poison_ProductType = 1906,
  CSM_Poison_RiskAssessment = 1907,
  CSM_Poison_Indemnity = 1908,
  CSM_Poison_PestSpeciesType = 1909,
  CSM_Poison_PestAnimalProgram = 1910,
  CSM_Poison_IssuePoison = 1911,
  CSM_Poisons_PestAnimalActivity = 1912,
  CSM_Poison_FieldDataSheet = 1913,
  CSM_Poison_Transaction = 1914,
  CSM_Poisons_AerialBaiting = 1915,
  CSM_Poison_TransactionACO = 1916,
  EHealth_Application = 2001,
  EHealth_PortalUser = 2002,
  EHealth_Premises = 2003,
  EHealth_PortalContact = 2004,
  AMS_Department = 2101,
  AMS_AppointmentType = 2102,
  AMS_Appointment = 2103,
  AMS_AppointmentPlan = 2104,
  AMS_AppointmentPlanItem = 2105,
  EMS_Facility = 2201,
  EMS_FacilityBookingPlan = 2202,
  EMS_FacilityBookingPlanItem = 2203,
  EMS_EventBooking = 2204,
  EMS_FacilityPhoto = 2205,
  EMS_BookingDate = 2206,
  EMS_EventType = 2207,
  EMS_GroupEventBooking = 2208,
  EMS_FacilityBooking = 2209,
  EMS_FacilityFeature = 2210,
  Portal_Application = 2301,
  Portal_SiteUser = 2302,
  ABS_Application = 2401,
  ABS_PortalUser = 2402,
  ABS_Booking = 2403,
  EBS_Application = 2501,
  EBS_PortalUser = 2502,
  EBS_Booking = 2503,
  EBS_FacilityPhoto = 2504,
  EBS_BookingDate = 2505,
  EBS_FacilityBooking = 2506,
  TM_CourseType = 2601,
  TM_Competency = 2602,
  TM_CourseOutline = 2603,
  TM_CourseSchedule = 2604,
  TM_TrainingRequest = 2605,
  TM_TrainingEvaluation = 2606,
  TM_DevelopmentPlan = 2607,
  TM_DevelopmentPlanItem = 2608,
  TM_RecordType = 2609,
  TM_SkillAndCompliance = 2610,
  NSP_OrganisationOutlet = 2701,
  NSP_Register = 2702,
  NSP_Training = 2703,
  NSP_PrevAddr = 2704,
  NSP_PrevReg = 2705,
  RAD_Register_Licence = 2801,
  RAD_Register_Source = 2802,
  RAD_Register_Place = 2803,
  RAD_Register_Accreditation = 2804,
  RAD_Register_UseLicence = 2805,
  RAD_Register_ManagementLicence = 2806,
  RAD_Register_FacilityConstructionLicence = 2807,
  RAD_Authorisation = 2808,
  RAD_AuthorisationBusinessRule = 2809,
  RAD_Register_ApprovedTester = 2810,
  RAD_Register_ApprovedAssessor = 2811,
  RAD_OccupationBusinessRule = 2812,
  RAD_AuthorisationPractice = 2813,
  RAD_AuthorisationSource = 2814,
  RAD_Site = 2815,
  RAD_FeesConfiguration = 2816,
  RAD_MakeModelBusinessRule = 2817,
  RAD_Radionuclide = 2818,
  RAD_AuthorisationAcquisition = 2819,
  RAD_AuthorisationCompliance = 2820,
  RAD_Incident = 2821,
  RAD_ResearchProject = 2822,
  RAD_Refund = 2823,
  RAD_OccupationConditionBusinessRule = 2824,
  RAD_SmartForms_Staging = 2825,
  RAD_AuthorisationDisposalPathway = 2826,
  RAD_AuthorisationAcquisitionProcess = 2827,
  RAD_RegisterTransfer = 2828,
  RAD_AuthorisationAcquisitionApparatus = 2829,
  CORE_WorkFlowType = 2901,
  CORE_WorkFlowProcessType = 2902,
  CORE_StateRoad = 2903,
  CORE_Region = 2904,
  CORE_Division = 2905,
  CORE_InterviewType = 2906,
  CORE_Interview = 2907,
  CORE_InterviewQuestion = 2908,
  CORE_DynamicQuestionList = 2909,
  CORE_DynamicQuestionResponse = 2910,
  Core_Undertaking = 2911,
  Core_Status = 2912,
  CORE_Investigation = 2913,
  CORE_NationalPoliceCheck = 2914,
  CORE_SearchWarrant = 2915,
  Core_Education = 2916,
  Core_EducationProvider = 2917,
  CORE_Allegation = 2918,
  CORE_IntegrationLog = 2919,
  CORE_ContactExtensionDriverLicence = 2920,
  CORE_Basket = 2921,
  CORE_BasketItem = 2922,
  CORE_SubsidyType = 2923,
  CORE_Subsidy = 2924,
  Core_EducationApproval = 2925,
  SRU_Organisation = 3001,
  SRU_Registration = 3002,
  SRU_Condition = 3003,
  SRU_Audit = 3004,
  SRU_Issue = 3005,
  SRU_Application = 3006,
  SRU_IndependentReviewBody = 3007,
  SRU_Location = 3008,
  SRU_RegistrationSanction = 3009,
  SRU_RegistrationStatement = 3010,
  SRU_SAVVIExpenditure = 3012,
  SRU_RegistrationClosure = 3013,
  SRU_RegistrationAdministration = 3014,
  SRU_Seizure = 3015,
  SRU_ReportableIncident = 3016,
  SRU_ReportableTransaction = 3017,
  SRU_Amendment = 3018,
  SRU_Risk = 3019,
  SRU_SAVVIFundingPool = 3020,
  SRU_SAVVIPartnership = 3021,
  SRU_Facility = 3022,
  SRU_Notice = 3023,
  SRU_SuspendAdmission = 3024,
  SRU_EnforcementPlan = 3025,
  SRU_Infringement = 3026,
  SSMS_ChangeRequest = 3101,
  SSMS_SpeedSign = 3102,
  SSMS_Moc = 3103,
  SSMS_Repository = 3104,
  SSMS_LGARegion = 3105,
  SSMS_Suburb = 3106,
  SSMS_Region = 3107,
  SSMS_Document = 3108,
  DWCMS_Worker = 3201,
  DWCMS_Registration = 3202,
  DWCMS_Application = 3203,
  DWCMS_CriminalHistory = 3204,
  DWCMS_Employment = 3206,
  DWCMS_Impairment = 3207,
  DWCMS_Insurance = 3208,
  DWCMS_DisciplinaryAction = 3209,
  DWCMS_RegistrationType = 3210,
  DWCMS_Application_Registration = 3211,
  DWCMS_LimitedSPQualification = 3212,
  DWCMS_Division = 3213,
  DWCMS_ServiceProvider = 3214,
  DWCMS_Address_History = 3216,
  DWCMS_OtherNamesPracticed = 3217,
  DWCMS_ProfessionalRegistration = 3218,
  DWCMS_ProfessionalCompetence = 3219,
  DWCMS_PrimaryPlaceOfPractice = 3220,
  DWCMS_RequestInfo = 3221,
  DWCMS_SuspendWorker = 3222,
  INFRINGEMENTS_InfringementTicket = 3301,
  INFRINGEMENTS_InfringementCategory = 3302,
  INFRINGEMENTS_InfringementOffence = 3303,
  INFRINGEMENTS_InfringementAnimals = 3304,
  CEM_Registration = 3401,
  CEM_Entry = 3402,
  CORE_FileNumber = 3501,
  CORE_PaymentPlan = 3502,
  CORE_Species = 3503,
  Core_Bookmark = 3504,
  CORE_Quartz = 3507,
  FLEET_Vehicle = 3601,
  FLEET_VehicleBookingPlan = 3602,
  FLEET_VehicleBookingPlanItem = 3603,
  FLEET_EventBooking = 3604,
  FLEET_VehiclePhoto = 3605,
  FLEET_BookingDate = 3606,
  FLEET_EventType = 3607,
  FLEET_GroupEventBooking = 3608,
  FLEET_VehicleBooking = 3609,
  FLEET_VehicleFeature = 3610,
  AssetManager_AssetRegister = 3701,
  AssetManager_WorkOrder = 3702,
  CommunityProperty_Unknown = 3801,
  CommunityProperty_Assessment = 3802,
  CommunityProperty_Parcel = 3803,
  CommunityProperty_Title = 3804,
  CommunityProperty_Assessment_Transaction = 3805,
  CommunityProperty_Certificate = 3806,
  CommunityProperty_Register_Account = 3807,
  CommunityProperty_Supplementary = 3808,
  CommunityProperty_Meter = 3809,
  CommunityProperty_Scheme_Account = 3810,
  CommunityProperty_Certificate_Request = 3811,
  CommunityProperty_Charge = 3812,
  CommunityProperty_Charge_Balances = 3813,
  CommunityProperty_Notice_Group = 3814,
  CommunityProperty_Street_Locality = 3815,
  CommunityProperty_GIS_Selection = 3816,
  CommunityProperty_Certificates_Type = 3817,
  CommunityProperty_Active_Certificates = 3818,
  CommunityProperty_Active_Certificates_for_Responsible_Area = 3819,
  CommunityProperty_Register = 3820,
  CommunityProperty_Master_Property = 3821,
  CommunityProperty_Scheme = 3822,
  CommunityProperty_Meter_Reading_Route = 3823,
  CommunityProperty_Meter_Reading_Device = 3824,
  CommunityProperty_Utility = 3825,
  CommunityProperty_Scheme_Account_Transaction = 3826,
  CommunityProperty_Meter_Installation = 3827,
  CommunityProperty_Register_Account_Transaction = 3828,
  CommunityProperty_Land_Tax_Exemption = 3829,
  CommunityProperty_ACT_Lease_Transfer = 3830,
  CommunityProperty_Deferred_Duty_Account = 3831,
  CommunityProperty_Deferred_Duty_Scheme = 3832,
  CommunityProperty_Deferred_Duty_Transaction = 3833,
  CommunityProperty_Deferred_Duty_Instalments = 3834,
  CommunityProperty_Entity = 3835,
  CommunityProperty_Entity_Name_and_Address = 3836,
  CommunityProperty_Assessment_Levy_Id = 3837,
  CommunityProperty_Tasks_Run_Id = 3838,
  CommunityProperty_Tasks_Id = 3839,
  CommunityProperty_Collection = 3840,
  CommunityProperty_MenuItem = 3841,
  CommunityProperty_Journal = 3842,
  CommunityProperty_Advanced_Search = 3843,
  CommunityProperty_Report = 3844,
  CommunityProperty_Home = 3845,
  CommunityProperty_Charge_Instalment = 3846,
  CommunityProperty_Charge_Levy = 3847,
  CommunityProperty_Application_Search_No_Results = 3848,
  CommunityProperty_Session = 3849,
  CommunityProperty_Associated_Name = 3850,
  CommunityProperty_Lease = 3851,
  CommunityProperty_Debt_Recovery = 3852,
  CommunityProperty_Assessment_Rebate_Entitlement = 3853,
  CommunityProperty_Arrangement = 3854,
  CommunityProperty_Change_of_Ownership = 3855,
  CommunityProperty_Supplementary_Assessment = 3856,
  CommunityProperty_Arrangement_Letter = 3857,
  CommunityProperty_Name_and_Address = 3858,
  CommunityProperty_Debt_Recovery_Action = 3859,
  CommunityProperty_Debt_Recovery_PaymentsReceived = 3860,
  CommunityProperty_Debt_Recovery_Exemption = 3861,
  CommunityProperty_Mail_Box = 3862,
  CommunityProperty_Financial_Summary = 3863,
  CommunityProperty_Rebate_Claim = 3864,
  CommunityProperty_Concession_Card = 3865,
  CommunityProperty_Valuation = 3866,
  CommunityProperty_Address = 3867,
  CommunityProperty_CommunityCentral_Town_Planning_Application = 3868,
  CommunityProperty_CommunityCentral_Health_Manager_Premise = 3869,
  CommunityProperty_CommunityCentral_Animal_Registration = 3870,
  CommunityProperty_CommunityCentral_Building_Application = 3871,
  CommunityProperty_CommunityCentral_Local_Laws_Permit = 3872,
  CommunityProperty_CommunityCentral_Events_Management_System_Booking = 3873,
  CommunityProperty_CommunityCentral_Waste_Water_Permit = 3874,
  CommunityProperty_HazardId_Fire_Prevention = 3875,
  CommunityProperty_HazardId_Noxious_Weed = 3876,
  CommunityProperty_CommunityFinance_Customer = 3877,
  CommunityProperty_Charge_Run = 3878,
  CommunityProperty_Notice_Run_Assessment = 3879,
  CommunityProperty_CommunityCentral_DevelopmentApplication = 3880,
  CommunityProperty_CommunityCentral_StreetProtection = 3881,
  CommunityProperty_CommunityCentral_Other = 3882,
  CommunityProperty_Compliance = 3883,
  CommunityProperty_Attachment = 3884,
  CommunityProperty_CommunicationTemplate = 3885,
  CommunityProperty_Workflow = 3886,
  CommunityProperty_TaskRun = 3887,
  CommunityProperty_InterestRun_Assessment = 3888,
  CommunityProperty_InterestRun_DeferredDuty = 3889,
  CommunityProperty_Change_of_Ownership_Document = 3890,
  CommunityProperty_Notice_Output_Run = 3891,
  CommunityProperty_PIC = 3892,
  CommunityProperty_Charge_Run_PIC = 3893,
  //recheck later
  BUILDING = 8000,
}

//@TOTO: remove when api changed
export const mapRecordType: {
  [key: string]: RECORDTYPE | undefined;
} = {
  SystemInitialise: RECORDTYPE.SystemInitialise,
  CONTACTMANAGER_ORGANISATION: RECORDTYPE.CONTACTMANAGER_ORGANISATION,
  CONTACTMANAGER_PERSON: RECORDTYPE.CONTACTMANAGER_PERSON,
  CORE_InspectionType: RECORDTYPE.CORE_InspectionType,
  CORE_FeeType: RECORDTYPE.CORE_FeeType,
  CORE_SampleType: RECORDTYPE.CORE_SampleType,
  CORE_ActionType: RECORDTYPE.CORE_ActionType,
  CORE_SecurityTemplate: RECORDTYPE.CORE_SecurityTemplate,
  CORE_SiteUser_CorporateAuthorisation:
    RECORDTYPE.CORE_SiteUser_CorporateAuthorisation,
  CORE_ComplaintType: RECORDTYPE.CORE_ComplaintType,
  CORE_PrintQueue: RECORDTYPE.CORE_PrintQueue,
  CORE_Log: RECORDTYPE.CORE_Log,
  CORE_NoticeType: RECORDTYPE.CORE_NoticeType,
  CORE_AdvertisingType: RECORDTYPE.CORE_AdvertisingType,
  CORE_Advertising: RECORDTYPE.CORE_Advertising,
  CORE_FurtherInformationRequest: RECORDTYPE.CORE_FurtherInformationRequest,
  CORE_Appeal: RECORDTYPE.CORE_Appeal,
  CORE_AppealHearing: RECORDTYPE.CORE_AppealHearing,
  CORE_Contact: RECORDTYPE.CORE_Contact,
  CORE_ContactRelation: RECORDTYPE.CORE_ContactRelation,
  CORE_ContactBubble: RECORDTYPE.CORE_ContactBubble,
  CORE_Address: RECORDTYPE.CORE_Address,
  CORE_Document: RECORDTYPE.CORE_Document,
  CORE_Attachment: RECORDTYPE.CORE_Attachment,
  CORE_Report: RECORDTYPE.CORE_Report,
  CORE_Keyword: RECORDTYPE.CORE_Keyword,
  CORE_GlobalSettings: RECORDTYPE.CORE_GlobalSettings,
  CORE_UserRole: RECORDTYPE.CORE_UserRole,
  CORE_TrimSettings: RECORDTYPE.CORE_TrimSettings,
  CORE_OrgStructure: RECORDTYPE.CORE_OrgStructure,
  CORE_Comment: RECORDTYPE.CORE_Comment,
  CORE_Sample: RECORDTYPE.CORE_Sample,
  CORE_Fees: RECORDTYPE.CORE_Fees,
  CORE_Inspection: RECORDTYPE.CORE_Inspection,
  CORE_Action: RECORDTYPE.CORE_Action,
  CORE_Payment: RECORDTYPE.CORE_Payment,
  CORE_Notice: RECORDTYPE.CORE_Notice,
  CORE_Prosecution: RECORDTYPE.CORE_Prosecution,
  CORE_Complaint: RECORDTYPE.CORE_Complaint,
  CORE_Journal: RECORDTYPE.CORE_Journal,
  CORE_Suburb: RECORDTYPE.CORE_Suburb,
  CORE_CorporateSetting: RECORDTYPE.CORE_CorporateSetting,
  CORE_Group: RECORDTYPE.CORE_Group,
  CORE_LoginTracking: RECORDTYPE.CORE_LoginTracking,
  CORE_ProductListing: RECORDTYPE.CORE_ProductListing,
  CORE_ClientListing: RECORDTYPE.CORE_ClientListing,
  CORE_OrgUnit: RECORDTYPE.CORE_OrgUnit,
  CORE_NavigationStore: RECORDTYPE.CORE_NavigationStore,
  CORE_NavigationMessage: RECORDTYPE.CORE_NavigationMessage,
  CORE_ReferenceNumberUsage: RECORDTYPE.CORE_ReferenceNumberUsage,
  CORE_NCItem: RECORDTYPE.CORE_NCItem,
  CORE_NCItemResult: RECORDTYPE.CORE_NCItemResult,
  CORE_FeeSetup: RECORDTYPE.CORE_FeeSetup,
  CORE_AssessmentResult: RECORDTYPE.CORE_AssessmentResult,
  CORE_ActionPlan: RECORDTYPE.CORE_ActionPlan,
  CORE_Quote: RECORDTYPE.CORE_Quote,
  CORE_QuoteItem: RECORDTYPE.CORE_QuoteItem,
  CORE_VCATReview: RECORDTYPE.CORE_VCATReview,
  CORE_ProsecutionCharge: RECORDTYPE.CORE_ProsecutionCharge,
  CORE_SiteUser_Credential: RECORDTYPE.CORE_SiteUser_Credential,
  CORE_ResetEffectiveDate: RECORDTYPE.CORE_ResetEffectiveDate,
  CORE_CommunicationQueue: RECORDTYPE.CORE_CommunicationQueue,
  CORE_LookUpDate: RECORDTYPE.CORE_LookUpDate,
  CORE_AFSAResult: RECORDTYPE.CORE_AFSAResult,
  CORE_AFSAResultComment: RECORDTYPE.CORE_AFSAResultComment,
  CORE_CommunicationTemplate: RECORDTYPE.CORE_CommunicationTemplate,
  CORE_Invoice: RECORDTYPE.CORE_Invoice,
  CORE_DialogDate: RECORDTYPE.CORE_DialogDate,
  CORE_AccessControl: RECORDTYPE.CORE_AccessControl,
  EXTERNAL_infringe: RECORDTYPE.EXTERNAL_infringe,
  CORE_SampleSurvey: RECORDTYPE.CORE_SampleSurvey,
  CORE_SampleTest: RECORDTYPE.CORE_SampleTest,
  CORE_Recall: RECORDTYPE.CORE_Recall,
  CORE_StatClock: RECORDTYPE.CORE_StatClock,
  CORE_ChecklistQuestion: RECORDTYPE.CORE_ChecklistQuestion,
  CORE_ChecklistResult: RECORDTYPE.CORE_ChecklistResult,
  CORE_Checklist: RECORDTYPE.CORE_Checklist,
  CORE_AFSAComment: RECORDTYPE.CORE_AFSAComment,
  CORE_Refund: RECORDTYPE.CORE_Refund,
  CORE_Register: RECORDTYPE.CORE_Register,
  CORE_PaymentGateway: RECORDTYPE.CORE_PaymentGateway,
  CORE_ConditionReference: RECORDTYPE.CORE_ConditionReference,
  CORE_Condition: RECORDTYPE.CORE_Condition,
  CORE_WebLink: RECORDTYPE.CORE_WebLink,
  CORE_Related: RECORDTYPE.CORE_Related,
  CORE_PortalLink: RECORDTYPE.CORE_PortalLink,
  CORE_Register_StreetProtection: RECORDTYPE.CORE_Register_StreetProtection,
  CORE_TemperatureRecorded: RECORDTYPE.CORE_TemperatureRecorded,
  CORE_FSPRecord: RECORDTYPE.CORE_FSPRecord,
  CORE_Roster: RECORDTYPE.CORE_Roster,
  CORE_PropertyAlert: RECORDTYPE.CORE_PropertyAlert,
  CORE_PaymentTransaction: RECORDTYPE.CORE_PaymentTransaction,
  Core_Appointment: RECORDTYPE.Core_Appointment,
  Core_AppointmentType: RECORDTYPE.Core_AppointmentType,
  Core_AvailableAppointment: RECORDTYPE.Core_AvailableAppointment,
  Core_AppointmentCalendar: RECORDTYPE.Core_AppointmentCalendar,
  Core_AppointmentShopCalendar: RECORDTYPE.Core_AppointmentShopCalendar,
  Core_HomePageFunction: RECORDTYPE.Core_HomePageFunction,
  Core_HomePageView: RECORDTYPE.Core_HomePageView,
  Core_SequenceNumber: RECORDTYPE.Core_SequenceNumber,
  CORE_NonCompliance: RECORDTYPE.CORE_NonCompliance,
  CORE_IntegratedReport: RECORDTYPE.CORE_IntegratedReport,
  Core_Project: RECORDTYPE.Core_Project,
  CommunityProfile: RECORDTYPE.CommunityProfile,
  Core_InspectionPlanner: RECORDTYPE.Core_InspectionPlanner,
  CORE_Link: RECORDTYPE.CORE_Link,
  CORE_Council: RECORDTYPE.CORE_Council,
  HealthManager_PremisesType: RECORDTYPE.HealthManager_PremisesType,
  HealthManager_Premises: RECORDTYPE.HealthManager_Premises,
  HealthManager_PremisesClassification:
    RECORDTYPE.HealthManager_PremisesClassification,
  HealthManager_RiskClassification: RECORDTYPE.HealthManager_RiskClassification,
  HealthManager_Recall: RECORDTYPE.HealthManager_Recall,
  HealthManager_FSRLog: RECORDTYPE.HealthManager_FSRLog,
  HealthManager_RegistrationNumber: RECORDTYPE.HealthManager_RegistrationNumber,
  HealthManager_VolumeScore: RECORDTYPE.HealthManager_VolumeScore,
  HealthManager_PremisesBusinessActivity:
    RECORDTYPE.HealthManager_PremisesBusinessActivity,
  HealthManager_PremisesHealthCare: RECORDTYPE.HealthManager_PremisesHealthCare,
  HealthManager_Complaints: RECORDTYPE.HealthManager_Complaints,
  HealthManager_ActivityReport: RECORDTYPE.HealthManager_ActivityReport,
  CRS_Registration: RECORDTYPE.CRS_Registration,
  CRS_Centres: RECORDTYPE.CRS_Centres,
  CRS_CentreBasedCare: RECORDTYPE.CRS_CentreBasedCare,
  CRS_HomeBasedCare: RECORDTYPE.CRS_HomeBasedCare,
  CRS_ThreeYOKinder: RECORDTYPE.CRS_ThreeYOKinder,
  CRS_FourYOKinder: RECORDTYPE.CRS_FourYOKinder,
  CRS_FourYOKinderInvoice: RECORDTYPE.CRS_FourYOKinderInvoice,
  CRS_FYOKinderTerm1Invoice: RECORDTYPE.CRS_FYOKinderTerm1Invoice,
  CRS_FYOKinderTerm2Invoice: RECORDTYPE.CRS_FYOKinderTerm2Invoice,
  CRS_FYOKinderTerm3Invoice: RECORDTYPE.CRS_FYOKinderTerm3Invoice,
  CRS_FYOKinderTerm4Invoice: RECORDTYPE.CRS_FYOKinderTerm4Invoice,
  CRS_FYOKinderPlacementInvoice: RECORDTYPE.CRS_FYOKinderPlacementInvoice,
  CRS_FourYOKinder_Offer: RECORDTYPE.CRS_FourYOKinder_Offer,
  CRS_ThreeYOKinder_Offer: RECORDTYPE.CRS_ThreeYOKinder_Offer,
  CRS_Rooms: RECORDTYPE.CRS_Rooms,
  CRS_Group: RECORDTYPE.CRS_Group,
  CRS_Session: RECORDTYPE.CRS_Session,
  CRS_Characteristic: RECORDTYPE.CRS_Characteristic,
  CRS_Category: RECORDTYPE.CRS_Category,
  CRS_Allocation: RECORDTYPE.CRS_Allocation,
  CRS_AllocationGroup: RECORDTYPE.CRS_AllocationGroup,
  CRS_Question: RECORDTYPE.CRS_Question,
  CRS_AllocationAudit: RECORDTYPE.CRS_AllocationAudit,
  CRS_Comment: RECORDTYPE.CRS_Comment,
  CRS_QuestionCategory: RECORDTYPE.CRS_QuestionCategory,
  CRS_Search: RECORDTYPE.CRS_Search,
  CRS_CBCGroup: RECORDTYPE.CRS_CBCGroup,
  CRS_HBCGroup: RECORDTYPE.CRS_HBCGroup,
  CRS_TYOGroup: RECORDTYPE.CRS_TYOGroup,
  CRS_FYOGroup: RECORDTYPE.CRS_FYOGroup,
  CRS_ProcessChange: RECORDTYPE.CRS_ProcessChange,
  CRS_PortalAttachment: RECORDTYPE.CRS_PortalAttachment,
  CRS_PortalPayment: RECORDTYPE.CRS_PortalPayment,
  CRS_SearchParent: RECORDTYPE.CRS_SearchParent,
  CRS_LinkedFeeService: RECORDTYPE.CRS_LinkedFeeService,
  CRS_PaymentImported: RECORDTYPE.CRS_PaymentImported,
  CRS_ThreeYOKinderInvoice: RECORDTYPE.CRS_ThreeYOKinderInvoice,
  CRS_CENTRE_ACCESS_Comment: RECORDTYPE.CRS_CENTRE_ACCESS_Comment,
  CUSTOMERSERVICE_ServiceStandard: RECORDTYPE.CUSTOMERSERVICE_ServiceStandard,
  CUSTOMERSERVICE_ServiceStandardMapper:
    RECORDTYPE.CUSTOMERSERVICE_ServiceStandardMapper,
  CUSTOMERSERVICE_Event: RECORDTYPE.CUSTOMERSERVICE_Event,
  CUSTOMERSERVICE_ServiceStandardCategory:
    RECORDTYPE.CUSTOMERSERVICE_ServiceStandardCategory,
  CUSTOMERSERVICE_ServiceStandardSubCategory:
    RECORDTYPE.CUSTOMERSERVICE_ServiceStandardSubCategory,
  CUSTOMERSERVICE_LookupTable: RECORDTYPE.CUSTOMERSERVICE_LookupTable,
  CUSTOMERSERVICE_EventLookupTable_LNK:
    RECORDTYPE.CUSTOMERSERVICE_EventLookupTable_LNK,
  CUSTOMERSERVICE_BulletinBoard: RECORDTYPE.CUSTOMERSERVICE_BulletinBoard,
  CUSTOMERSERVICE_BulletinBoardServiceStandard_LNK:
    RECORDTYPE.CUSTOMERSERVICE_BulletinBoardServiceStandard_LNK,
  CUSTOMERSERVICE_StandardDocument: RECORDTYPE.CUSTOMERSERVICE_StandardDocument,
  CUSTOMERSERVICE_KBTopic: RECORDTYPE.CUSTOMERSERVICE_KBTopic,
  CUSTOMERSERVICE_KBArticleTopicLink:
    RECORDTYPE.CUSTOMERSERVICE_KBArticleTopicLink,
  CUSTOMERSERVICE_KBAlert: RECORDTYPE.CUSTOMERSERVICE_KBAlert,
  CUSTOMERSERVICE_KBAlertServiceStandardLink:
    RECORDTYPE.CUSTOMERSERVICE_KBAlertServiceStandardLink,
  CUSTOMERSERVICE_KBAlertTerm: RECORDTYPE.CUSTOMERSERVICE_KBAlertTerm,
  CUSTOMERSERVICE_PhoneMessage: RECORDTYPE.CUSTOMERSERVICE_PhoneMessage,
  CUSTOMERSERVICE_ServiceStandardActionType_LNK:
    RECORDTYPE.CUSTOMERSERVICE_ServiceStandardActionType_LNK,
  CUSTOMERSERVICE_EventActionType_LNK:
    RECORDTYPE.CUSTOMERSERVICE_EventActionType_LNK,
  CUSTOMERSERVICE_BulletinBoardEvent_LNK:
    RECORDTYPE.CUSTOMERSERVICE_BulletinBoardEvent_LNK,
  TelephoneActivity: RECORDTYPE.TelephoneActivity,
  CUSTOMERSERVICE_RelatedEvent: RECORDTYPE.CUSTOMERSERVICE_RelatedEvent,
  CUSTOMERSERVICE_SpamEmails: RECORDTYPE.CUSTOMERSERVICE_SpamEmails,
  CUSTOMERSERVICE_CC_ServiceProvider_PortalUserProfile:
    RECORDTYPE.CUSTOMERSERVICE_CC_ServiceProvider_PortalUserProfile,
  CUSTOMERSERVICE_CC_Vaccine: RECORDTYPE.CUSTOMERSERVICE_CC_Vaccine,
  CUSTOMERSERVICE_CC_Breach: RECORDTYPE.CUSTOMERSERVICE_CC_Breach,
  CUSTOMERSERVICE_CC_BreachDetails: RECORDTYPE.CUSTOMERSERVICE_CC_BreachDetails,
  CUSTOMERSERVICE_CC_BreachAdvice: RECORDTYPE.CUSTOMERSERVICE_CC_BreachAdvice,
  CUSTOMERSERVICE_CC_TemperatureDurationRange:
    RECORDTYPE.CUSTOMERSERVICE_CC_TemperatureDurationRange,
  CUSTOMERSERVICE_CC_VaccineAction: RECORDTYPE.CUSTOMERSERVICE_CC_VaccineAction,
  CUSTOMERSERVICE_CC_GPDivision: RECORDTYPE.CUSTOMERSERVICE_CC_GPDivision,
  CUSTOMERSERVICE_CD_IncidentCheckListFlood:
    RECORDTYPE.CUSTOMERSERVICE_CD_IncidentCheckListFlood,
  CUSTOMERSERVICE_CD_IncidentCheckListStorm:
    RECORDTYPE.CUSTOMERSERVICE_CD_IncidentCheckListStorm,
  CUSTOMERSERVICE_CD_TreeManagement:
    RECORDTYPE.CUSTOMERSERVICE_CD_TreeManagement,
  CUSTOMERSERVICE_HardRubbishCollection:
    RECORDTYPE.CUSTOMERSERVICE_HardRubbishCollection,
  CUSTOMERSERVICE_CD_AnimalAttacks: RECORDTYPE.CUSTOMERSERVICE_CD_AnimalAttacks,
  CUSTOMERSERVICE_CD_PublicLiabilitySimple:
    RECORDTYPE.CUSTOMERSERVICE_CD_PublicLiabilitySimple,
  CUSTOMERSERVICE_CD_VehicleImpoundChecklist:
    RECORDTYPE.CUSTOMERSERVICE_CD_VehicleImpoundChecklist,
  CUSTOMERSERVICE_CD_AnimalsWandering:
    RECORDTYPE.CUSTOMERSERVICE_CD_AnimalsWandering,
  CUSTOMERSERVICE_CD_Bins: RECORDTYPE.CUSTOMERSERVICE_CD_Bins,
  CUSTOMERSERVICE_CD_CatTrap: RECORDTYPE.CUSTOMERSERVICE_CD_CatTrap,
  CUSTOMERSERVICE_CD_SyringesAndSharps:
    RECORDTYPE.CUSTOMERSERVICE_CD_SyringesAndSharps,
  CUSTOMERSERVICE_CD_EnvironmentalHealth:
    RECORDTYPE.CUSTOMERSERVICE_CD_EnvironmentalHealth,
  CUSTOMERSERVICE_CD_MyPlaceYouthCentre:
    RECORDTYPE.CUSTOMERSERVICE_CD_MyPlaceYouthCentre,
  CUSTOMERSERVICE_CD_ComplaintsHandling:
    RECORDTYPE.CUSTOMERSERVICE_CD_ComplaintsHandling,
  CUSTOMERSERVICE_CD_PublicLiabilityComprehensive:
    RECORDTYPE.CUSTOMERSERVICE_CD_PublicLiabilityComprehensive,
  CUSTOMERSERVICE_CD_DrinkingWater: RECORDTYPE.CUSTOMERSERVICE_CD_DrinkingWater,
  CUSTOMERSERVICE_CD_DisabilityWorker:
    RECORDTYPE.CUSTOMERSERVICE_CD_DisabilityWorker,
  CUST_EventIssue: RECORDTYPE.CUST_EventIssue,
  DisRes_Permit: RECORDTYPE.DisRes_Permit,
  DisRes_ResidentParkingAreaDefinition:
    RECORDTYPE.DisRes_ResidentParkingAreaDefinition,
  DisRes_StaffParkingAreaDefinition:
    RECORDTYPE.DisRes_StaffParkingAreaDefinition,
  DisRes_PermitType: RECORDTYPE.DisRes_PermitType,
  DisRes_PropertyException: RECORDTYPE.DisRes_PropertyException,
  DisRes_PropertyRestriction: RECORDTYPE.DisRes_PropertyRestriction,
  DisRes_PermitLabel: RECORDTYPE.DisRes_PermitLabel,
  DisRes_WorkStats: RECORDTYPE.DisRes_WorkStats,
  DisRes_Portal_DocumentChecklist: RECORDTYPE.DisRes_Portal_DocumentChecklist,
  DisRes_Portal_Fee: RECORDTYPE.DisRes_Portal_Fee,
  CM_Cemetery: RECORDTYPE.CM_Cemetery,
  CM_Section: RECORDTYPE.CM_Section,
  CM_Subsection: RECORDTYPE.CM_Subsection,
  CM_Site: RECORDTYPE.CM_Site,
  CM_Licence: RECORDTYPE.CM_Licence,
  CM_LicenceGroup: RECORDTYPE.CM_LicenceGroup,
  CM_Interment: RECORDTYPE.CM_Interment,
  CM_CrematedRemains: RECORDTYPE.CM_CrematedRemains,
  CM_Monument: RECORDTYPE.CM_Monument,
  CM_Inventory: RECORDTYPE.CM_Inventory,
  CM_Booking: RECORDTYPE.CM_Booking,
  CM_Appointment: RECORDTYPE.CM_Appointment,
  CM_Consultation: RECORDTYPE.CM_Consultation,
  CM_FuneralPlan: RECORDTYPE.CM_FuneralPlan,
  CM_RoseRegister: RECORDTYPE.CM_RoseRegister,
  CM_RoseVariety: RECORDTYPE.CM_RoseVariety,
  CM_SubsectionInventory: RECORDTYPE.CM_SubsectionInventory,
  CM_LicenceGroupLicence: RECORDTYPE.CM_LicenceGroupLicence,
  CM_BCI: RECORDTYPE.CM_BCI,
  CM_BCIItem: RECORDTYPE.CM_BCIItem,
  CM_BIO: RECORDTYPE.CM_BIO,
  CM_BIOItem: RECORDTYPE.CM_BIOItem,
  CM_BookingDeceasedPerson: RECORDTYPE.CM_BookingDeceasedPerson,
  CM_CreateLicence: RECORDTYPE.CM_CreateLicence,
  CM_CO: RECORDTYPE.CM_CO,
  CM_COItem: RECORDTYPE.CM_COItem,
  CM_CRIO: RECORDTYPE.CM_CRIO,
  CM_CRIOItem: RECORDTYPE.CM_CRIOItem,
  CM_CRIOMonument: RECORDTYPE.CM_CRIOMonument,
  CM_CRRO: RECORDTYPE.CM_CRRO,
  CM_CRROItem: RECORDTYPE.CM_CRROItem,
  CM_EO: RECORDTYPE.CM_EO,
  CM_MWIO: RECORDTYPE.CM_MWIO,
  CM_MWIODeceasedPerson: RECORDTYPE.CM_MWIODeceasedPerson,
  CM_MWO: RECORDTYPE.CM_MWO,
  CM_MWOItem: RECORDTYPE.CM_MWOItem,
  CM_MWOItemDeceasedPerson: RECORDTYPE.CM_MWOItemDeceasedPerson,
  CM_MWOItemLicence: RECORDTYPE.CM_MWOItemLicence,
  CM_RenewLicence: RECORDTYPE.CM_RenewLicence,
  CM_ROCR: RECORDTYPE.CM_ROCR,
  CM_SurrenderLicence: RECORDTYPE.CM_SurrenderLicence,
  CM_SurrenderLicenceInterment: RECORDTYPE.CM_SurrenderLicenceInterment,
  CM_SurrenderLicenceMonument: RECORDTYPE.CM_SurrenderLicenceMonument,
  CM_TransferLicence: RECORDTYPE.CM_TransferLicence,
  CM_ConsultationLicence: RECORDTYPE.CM_ConsultationLicence,
  CM_ConsultationDeceasedPerson: RECORDTYPE.CM_ConsultationDeceasedPerson,
  CM_OptionMaster: RECORDTYPE.CM_OptionMaster,
  CM_BookingOption: RECORDTYPE.CM_BookingOption,
  CM_Neighbour: RECORDTYPE.CM_Neighbour,
  CM_MonumentPart: RECORDTYPE.CM_MonumentPart,
  CM_SubsectionMonumentPart: RECORDTYPE.CM_SubsectionMonumentPart,
  CM_ConsultationConsultedPerson: RECORDTYPE.CM_ConsultationConsultedPerson,
  CM_MWOPermit: RECORDTYPE.CM_MWOPermit,
  CM_AppointmentDeceasedPerson: RECORDTYPE.CM_AppointmentDeceasedPerson,
  CM_CPUser: RECORDTYPE.CM_CPUser,
  CM_MonumentDeceasedPerson: RECORDTYPE.CM_MonumentDeceasedPerson,
  CM_Transaction: RECORDTYPE.CM_Transaction,
  CM_MWOItemMonument: RECORDTYPE.CM_MWOItemMonument,
  CM_LicenceCategory: RECORDTYPE.CM_LicenceCategory,
  CM_POS_LineItem: RECORDTYPE.CM_POS_LineItem,
  CM_POS_Transaction: RECORDTYPE.CM_POS_Transaction,
  CM_POS_CashPayment: RECORDTYPE.CM_POS_CashPayment,
  CM_POS_Payment: RECORDTYPE.CM_POS_Payment,
  CM_Headstone: RECORDTYPE.CM_Headstone,
  CM_Plaque: RECORDTYPE.CM_Plaque,
  CM_Other: RECORDTYPE.CM_Other,
  CM_POS_PaymentLineItem: RECORDTYPE.CM_POS_PaymentLineItem,
  CM_Booking_BookableResource: RECORDTYPE.CM_Booking_BookableResource,
  CM_MemorialService: RECORDTYPE.CM_MemorialService,
  CM_MemorialServiceDeceasedPerson: RECORDTYPE.CM_MemorialServiceDeceasedPerson,
  CM_CRSO: RECORDTYPE.CM_CRSO,
  CM_CRSOItem: RECORDTYPE.CM_CRSOItem,
  CM_BulkSites: RECORDTYPE.CM_BulkSites,
  CM_FuneralPlanOption: RECORDTYPE.CM_FuneralPlanOption,
  CM_PrepaidQuoteItem: RECORDTYPE.CM_PrepaidQuoteItem,
  CM_PrepaidFeeType: RECORDTYPE.CM_PrepaidFeeType,
  CM_CalendarNote: RECORDTYPE.CM_CalendarNote,
  CM_AdditionalFeeType: RECORDTYPE.CM_AdditionalFeeType,
  CM_AdditionalQuoteItem: RECORDTYPE.CM_AdditionalQuoteItem,
  CM_ARFeeType: RECORDTYPE.CM_ARFeeType,
  CM_ARQuoteItem: RECORDTYPE.CM_ARQuoteItem,
  EPLAN_Application: RECORDTYPE.EPLAN_Application,
  EPLAN_Enquiry: RECORDTYPE.EPLAN_Enquiry,
  EPLAN_Objection: RECORDTYPE.EPLAN_Objection,
  EPLAN_FurtherInformation: RECORDTYPE.EPLAN_FurtherInformation,
  EPLAN_OtherDocuments: RECORDTYPE.EPLAN_OtherDocuments,
  EPLAN_Reference_Category: RECORDTYPE.EPLAN_Reference_Category,
  EPLAN_Reference_Document: RECORDTYPE.EPLAN_Reference_Document,
  EPLAN_Reference_Schedule: RECORDTYPE.EPLAN_Reference_Schedule,
  ELFPortal_Application: RECORDTYPE.ELFPortal_Application,
  ELFPortal_Renewal: RECORDTYPE.ELFPortal_Renewal,
  ELFPortal_AnnualReturn: RECORDTYPE.ELFPortal_AnnualReturn,
  ELFPortal_Site: RECORDTYPE.ELFPortal_Site,
  ELFPortal_Vehicle: RECORDTYPE.ELFPortal_Vehicle,
  ELFPortal_Activity: RECORDTYPE.ELFPortal_Activity,
  ELFPortal_Certificates: RECORDTYPE.ELFPortal_Certificates,
  ELFPortal_DevelopmentApprovals: RECORDTYPE.ELFPortal_DevelopmentApprovals,
  ELFPortal_WasteActivities: RECORDTYPE.ELFPortal_WasteActivities,
  ELFPortal_PortalContact: RECORDTYPE.ELFPortal_PortalContact,
  GENI_RADPortal_Application: RECORDTYPE.GENI_RADPortal_Application,
  GENI_PortalContact: RECORDTYPE.GENI_PortalContact,
  GENI_Radionuclide: RECORDTYPE.GENI_Radionuclide,
  GENI_RECPortal_Application: RECORDTYPE.GENI_RECPortal_Application,
  GENI_SuperCollector: RECORDTYPE.GENI_SuperCollector,
  GENI_WorkDay: RECORDTYPE.GENI_WorkDay,
  GENI_BeverageContainer: RECORDTYPE.GENI_BeverageContainer,
  GENI_SealedSource: RECORDTYPE.GENI_SealedSource,
  GENI_Premises: RECORDTYPE.GENI_Premises,
  CC_Portal_Breach: RECORDTYPE.CC_Portal_Breach,
  CC_Portal_AffectedVaccine: RECORDTYPE.CC_Portal_AffectedVaccine,
  TP_Application: RECORDTYPE.TP_Application,
  TP_ApplicationCategory: RECORDTYPE.TP_ApplicationCategory,
  TP_ConditionReference: RECORDTYPE.TP_ConditionReference,
  TP_ApplicationCondition: RECORDTYPE.TP_ApplicationCondition,
  TP_Application_Contact_MAILMERGEONLY:
    RECORDTYPE.TP_Application_Contact_MAILMERGEONLY,
  TP_CalculatedFeeSetup: RECORDTYPE.TP_CalculatedFeeSetup,
  TP_Subdivision: RECORDTYPE.TP_Subdivision,
  TP_BuildingApplication: RECORDTYPE.TP_BuildingApplication,
  TP_DevAppsApplication: RECORDTYPE.TP_DevAppsApplication,
  TP_FeeSchedule: RECORDTYPE.TP_FeeSchedule,
  TP_PSA_Application: RECORDTYPE.TP_PSA_Application,
  TP_PrescribeEvents: RECORDTYPE.TP_PrescribeEvents,
  TP_ExtensionOfTimeActivity: RECORDTYPE.TP_ExtensionOfTimeActivity,
  TP_SecondaryConsentActivity: RECORDTYPE.TP_SecondaryConsentActivity,
  TP_ObjectionActivity: RECORDTYPE.TP_ObjectionActivity,
  TP_EnquiryActivity: RECORDTYPE.TP_EnquiryActivity,
  TP_OtherDocumentsActivity: RECORDTYPE.TP_OtherDocumentsActivity,
  TP_FurtherInformationActivity: RECORDTYPE.TP_FurtherInformationActivity,
  TP_EplanningFee: RECORDTYPE.TP_EplanningFee,
  TP_PermitType: RECORDTYPE.TP_PermitType,
  TP_OtherApplication: RECORDTYPE.TP_OtherApplication,
  TP_SPEAR_Application: RECORDTYPE.TP_SPEAR_Application,
  TP_PPRResponsibleAuthority: RECORDTYPE.TP_PPRResponsibleAuthority,
  TP_PPRApplication: RECORDTYPE.TP_PPRApplication,
  TP_PPRPlanningRegion: RECORDTYPE.TP_PPRPlanningRegion,
  TP_Application_AssessmentDetails: RECORDTYPE.TP_Application_AssessmentDetails,
  TP_PNFApplication: RECORDTYPE.TP_PNFApplication,
  TP_PNFDueDiligence: RECORDTYPE.TP_PNFDueDiligence,
  TP_PNFOperations: RECORDTYPE.TP_PNFOperations,
  TP_PNFTSData: RECORDTYPE.TP_PNFTSData,
  TP_PNF_MailMergeCategory: RECORDTYPE.TP_PNF_MailMergeCategory,
  TP_PNFFieldObservation: RECORDTYPE.TP_PNFFieldObservation,
  TP_PNFDetails: RECORDTYPE.TP_PNFDetails,
  TP_PNFAssessement: RECORDTYPE.TP_PNFAssessement,
  TP_PNFApplication_Lots: RECORDTYPE.TP_PNFApplication_Lots,
  TP_PNFApplication_LGAs: RECORDTYPE.TP_PNFApplication_LGAs,
  TP_PNFApplication_Regions: RECORDTYPE.TP_PNFApplication_Regions,
  TP_EPlanning_ApplicationCategory: RECORDTYPE.TP_EPlanning_ApplicationCategory,
  TP_EPlanning_NatureOfWork: RECORDTYPE.TP_EPlanning_NatureOfWork,
  TP_EPlanning_DocumentChecklist: RECORDTYPE.TP_EPlanning_DocumentChecklist,
  TP_LMApplication: RECORDTYPE.TP_LMApplication,
  TP_LMRegister: RECORDTYPE.TP_LMRegister,
  TP_BUILD_Portal_DocumentChecklist:
    RECORDTYPE.TP_BUILD_Portal_DocumentChecklist,
  MCH_Registration: RECORDTYPE.MCH_Registration,
  MCH_Centre: RECORDTYPE.MCH_Centre,
  MCH_AppointmentType: RECORDTYPE.MCH_AppointmentType,
  MCH_Appointment: RECORDTYPE.MCH_Appointment,
  LLP_PermitType: RECORDTYPE.LLP_PermitType,
  LLP_Permit: RECORDTYPE.LLP_Permit,
  Animals_AnimalType: RECORDTYPE.Animals_AnimalType,
  Animals_Registration: RECORDTYPE.Animals_Registration,
  Animals_Notices: RECORDTYPE.Animals_Notices,
  Animals_PoundRegister: RECORDTYPE.Animals_PoundRegister,
  Animals_Kennel: RECORDTYPE.Animals_Kennel,
  CRSPortal_RegistrationLoginDetails:
    RECORDTYPE.CRSPortal_RegistrationLoginDetails,
  CRSPortal_Application: RECORDTYPE.CRSPortal_Application,
  CRSPortal_Registration: RECORDTYPE.CRSPortal_Registration,
  CRSPortal_Service: RECORDTYPE.CRSPortal_Service,
  CRSPortal_PortalContact: RECORDTYPE.CRSPortal_PortalContact,
  CRSPortal_QuestionResponse: RECORDTYPE.CRSPortal_QuestionResponse,
  CRSPortal_Question: RECORDTYPE.CRSPortal_Question,
  CRSPortal_Centre: RECORDTYPE.CRSPortal_Centre,
  CRSPortal_Group: RECORDTYPE.CRSPortal_Group,
  CRSPortal_Offer: RECORDTYPE.CRSPortal_Offer,
  CRSPortal_HoldingBayNewProfileDetails:
    RECORDTYPE.CRSPortal_HoldingBayNewProfileDetails,
  CRSPortal_HoldingBayChangeProfileDetails:
    RECORDTYPE.CRSPortal_HoldingBayChangeProfileDetails,
  WW_SystemType: RECORDTYPE.WW_SystemType,
  WW_System: RECORDTYPE.WW_System,
  WW_Scheme: RECORDTYPE.WW_Scheme,
  WW_Installation: RECORDTYPE.WW_Installation,
  WW_Product: RECORDTYPE.WW_Product,
  CSM_LicenceCategory: RECORDTYPE.CSM_LicenceCategory,
  CSM_Licence: RECORDTYPE.CSM_Licence,
  CSM_LicenceSubstanceDetail: RECORDTYPE.CSM_LicenceSubstanceDetail,
  CSM_PoisonRegister: RECORDTYPE.CSM_PoisonRegister,
  CSM_PestObservation: RECORDTYPE.CSM_Poisons_PestObservation,
  CSM_Poison_ProductType: RECORDTYPE.CSM_Poison_ProductType,
  CSM_Poison_RiskAssessment: RECORDTYPE.CSM_Poison_RiskAssessment,
  CSM_Poison_Indemnity: RECORDTYPE.CSM_Poison_Indemnity,
  CSM_Poison_PestSpeciesType: RECORDTYPE.CSM_Poison_PestSpeciesType,
  EHealth_Application: RECORDTYPE.EHealth_Application,
  EHealth_PortalUser: RECORDTYPE.EHealth_PortalUser,
  EHealth_Premises: RECORDTYPE.EHealth_Premises,
  EHealth_PortalContact: RECORDTYPE.EHealth_PortalContact,
  AMS_Department: RECORDTYPE.AMS_Department,
  AMS_AppointmentType: RECORDTYPE.AMS_AppointmentType,
  AMS_Appointment: RECORDTYPE.AMS_Appointment,
  AMS_AppointmentPlan: RECORDTYPE.AMS_AppointmentPlan,
  AMS_AppointmentPlanItem: RECORDTYPE.AMS_AppointmentPlanItem,
  EMS_Facility: RECORDTYPE.EMS_Facility,
  EMS_FacilityBookingPlan: RECORDTYPE.EMS_FacilityBookingPlan,
  EMS_FacilityBookingPlanItem: RECORDTYPE.EMS_FacilityBookingPlanItem,
  EMS_EventBooking: RECORDTYPE.EMS_EventBooking,
  EMS_FacilityPhoto: RECORDTYPE.EMS_FacilityPhoto,
  EMS_BookingDate: RECORDTYPE.EMS_BookingDate,
  EMS_EventType: RECORDTYPE.EMS_EventType,
  EMS_GroupEventBooking: RECORDTYPE.EMS_GroupEventBooking,
  EMS_FacilityBooking: RECORDTYPE.EMS_FacilityBooking,
  EMS_FacilityFeature: RECORDTYPE.EMS_FacilityFeature,
  Portal_Application: RECORDTYPE.Portal_Application,
  Portal_SiteUser: RECORDTYPE.Portal_SiteUser,
  ABS_Application: RECORDTYPE.ABS_Application,
  ABS_PortalUser: RECORDTYPE.ABS_PortalUser,
  ABS_Booking: RECORDTYPE.ABS_Booking,
  EBS_Application: RECORDTYPE.EBS_Application,
  EBS_PortalUser: RECORDTYPE.EBS_PortalUser,
  EBS_Booking: RECORDTYPE.EBS_Booking,
  EBS_FacilityPhoto: RECORDTYPE.EBS_FacilityPhoto,
  EBS_BookingDate: RECORDTYPE.EBS_BookingDate,
  EBS_FacilityBooking: RECORDTYPE.EBS_FacilityBooking,
  TM_CourseType: RECORDTYPE.TM_CourseType,
  TM_Competency: RECORDTYPE.TM_Competency,
  TM_CourseOutline: RECORDTYPE.TM_CourseOutline,
  TM_CourseSchedule: RECORDTYPE.TM_CourseSchedule,
  TM_TrainingRequest: RECORDTYPE.TM_TrainingRequest,
  TM_TrainingEvaluation: RECORDTYPE.TM_TrainingEvaluation,
  TM_DevelopmentPlan: RECORDTYPE.TM_DevelopmentPlan,
  TM_DevelopmentPlanItem: RECORDTYPE.TM_DevelopmentPlanItem,
  TM_RecordType: RECORDTYPE.TM_RecordType,
  TM_SkillAndCompliance: RECORDTYPE.TM_SkillAndCompliance,
  NSP_OrganisationOutlet: RECORDTYPE.NSP_OrganisationOutlet,
  NSP_Register: RECORDTYPE.NSP_Register,
  NSP_Training: RECORDTYPE.NSP_Training,
  NSP_PrevAddr: RECORDTYPE.NSP_PrevAddr,
  NSP_PrevReg: RECORDTYPE.NSP_PrevReg,
  RAD_Register_Licence: RECORDTYPE.RAD_Register_Licence,
  RAD_Register_Source: RECORDTYPE.RAD_Register_Source,
  RAD_Register_Place: RECORDTYPE.RAD_Register_Place,
  RAD_Register_Accreditation: RECORDTYPE.RAD_Register_Accreditation,
  RAD_Register_UseLicence: RECORDTYPE.RAD_Register_UseLicence,
  RAD_Register_ManagementLicence: RECORDTYPE.RAD_Register_ManagementLicence,
  RAD_Register_FacilityConstructionLicence:
    RECORDTYPE.RAD_Register_FacilityConstructionLicence,
  RAD_Authorisation: RECORDTYPE.RAD_Authorisation,
  RAD_AuthorisationBusinessRule: RECORDTYPE.RAD_AuthorisationBusinessRule,
  RAD_Register_ApprovedTester: RECORDTYPE.RAD_Register_ApprovedTester,
  RAD_Register_ApprovedAssessor: RECORDTYPE.RAD_Register_ApprovedAssessor,
  RAD_OccupationBusinessRule: RECORDTYPE.RAD_OccupationBusinessRule,
  RAD_AuthorisationPractice: RECORDTYPE.RAD_AuthorisationPractice,
  RAD_AuthorisationSource: RECORDTYPE.RAD_AuthorisationSource,
  RAD_Site: RECORDTYPE.RAD_Site,
  RAD_FeesConfiguration: RECORDTYPE.RAD_FeesConfiguration,
  RAD_MakeModelBusinessRule: RECORDTYPE.RAD_MakeModelBusinessRule,
  RAD_Radionuclide: RECORDTYPE.RAD_Radionuclide,
  RAD_AuthorisationAcquisition: RECORDTYPE.RAD_AuthorisationAcquisition,
  RAD_AuthorisationCompliance: RECORDTYPE.RAD_AuthorisationCompliance,
  RAD_Incident: RECORDTYPE.RAD_Incident,
  RAD_ResearchProject: RECORDTYPE.RAD_ResearchProject,
  RAD_Refund: RECORDTYPE.RAD_Refund,
  RAD_OccupationConditionBusinessRule:
    RECORDTYPE.RAD_OccupationConditionBusinessRule,
  RAD_SmartForms_Staging: RECORDTYPE.RAD_SmartForms_Staging,
  RAD_AuthorisationDisposalPathway: RECORDTYPE.RAD_AuthorisationDisposalPathway,
  RAD_AuthorisationAcquisitionProcess:
    RECORDTYPE.RAD_AuthorisationAcquisitionProcess,
  RAD_RegisterTransfer: RECORDTYPE.RAD_RegisterTransfer,
  CORE_WorkFlowType: RECORDTYPE.CORE_WorkFlowType,
  CORE_WorkFlowProcessType: RECORDTYPE.CORE_WorkFlowProcessType,
  CORE_StateRoad: RECORDTYPE.CORE_StateRoad,
  CORE_Region: RECORDTYPE.CORE_Region,
  CORE_Division: RECORDTYPE.CORE_Division,
  CORE_InterviewType: RECORDTYPE.CORE_InterviewType,
  CORE_Interview: RECORDTYPE.CORE_Interview,
  CORE_InterviewQuestion: RECORDTYPE.CORE_InterviewQuestion,
  CORE_DynamicQuestionList: RECORDTYPE.CORE_DynamicQuestionList,
  CORE_DynamicQuestionResponse: RECORDTYPE.CORE_DynamicQuestionResponse,
  Core_Undertaking: RECORDTYPE.Core_Undertaking,
  Core_Status: RECORDTYPE.Core_Status,
  CORE_Investigation: RECORDTYPE.CORE_Investigation,
  CORE_NationalPoliceCheck: RECORDTYPE.CORE_NationalPoliceCheck,
  CORE_SearchWarrant: RECORDTYPE.CORE_SearchWarrant,
  Core_Education: RECORDTYPE.Core_Education,
  Core_EducationProvider: RECORDTYPE.Core_EducationProvider,
  CORE_Allegation: RECORDTYPE.CORE_Allegation,
  CORE_IntegrationLog: RECORDTYPE.CORE_IntegrationLog,
  CORE_ContactExtensionDriverLicence:
    RECORDTYPE.CORE_ContactExtensionDriverLicence,
  CORE_Basket: RECORDTYPE.CORE_Basket,
  CORE_BasketItem: RECORDTYPE.CORE_BasketItem,
  SRU_Organisation: RECORDTYPE.SRU_Organisation,
  SRU_Registration: RECORDTYPE.SRU_Registration,
  SRU_Condition: RECORDTYPE.SRU_Condition,
  SRU_Audit: RECORDTYPE.SRU_Audit,
  SRU_Issue: RECORDTYPE.SRU_Issue,
  SRU_Application: RECORDTYPE.SRU_Application,
  SRU_IndependentReviewBody: RECORDTYPE.SRU_IndependentReviewBody,
  SRU_Location: RECORDTYPE.SRU_Location,
  SRU_RegistrationSanction: RECORDTYPE.SRU_RegistrationSanction,
  SRU_RegistrationStatement: RECORDTYPE.SRU_RegistrationStatement,
  SRU_SAVVIExpenditure: RECORDTYPE.SRU_SAVVIExpenditure,
  SRU_RegistrationClosure: RECORDTYPE.SRU_RegistrationClosure,
  SRU_RegistrationAdministration: RECORDTYPE.SRU_RegistrationAdministration,
  SRU_Seizure: RECORDTYPE.SRU_Seizure,
  SRU_ReportableIncident: RECORDTYPE.SRU_ReportableIncident,
  SRU_ReportableTransaction: RECORDTYPE.SRU_ReportableTransaction,
  SRU_Amendment: RECORDTYPE.SRU_Amendment,
  SRU_Risk: RECORDTYPE.SRU_Risk,
  SRU_SAVVIFundingPool: RECORDTYPE.SRU_SAVVIFundingPool,
  SRU_SAVVIPartnership: RECORDTYPE.SRU_SAVVIPartnership,
  SRU_Facility: RECORDTYPE.SRU_Facility,
  SRU_Notice: RECORDTYPE.SRU_Notice,
  SRU_SuspendAdmission: RECORDTYPE.SRU_SuspendAdmission,
  SRU_EnforcementPlan: RECORDTYPE.SRU_EnforcementPlan,
  SRU_Infringement: RECORDTYPE.SRU_Infringement,
  SSMS_ChangeRequest: RECORDTYPE.SSMS_ChangeRequest,
  SSMS_SpeedSign: RECORDTYPE.SSMS_SpeedSign,
  SSMS_Moc: RECORDTYPE.SSMS_Moc,
  SSMS_Repository: RECORDTYPE.SSMS_Repository,
  SSMS_LGARegion: RECORDTYPE.SSMS_LGARegion,
  SSMS_Suburb: RECORDTYPE.SSMS_Suburb,
  SSMS_Region: RECORDTYPE.SSMS_Region,
  SSMS_Document: RECORDTYPE.SSMS_Document,
  DWCMS_Worker: RECORDTYPE.DWCMS_Worker,
  DWCMS_Registration: RECORDTYPE.DWCMS_Registration,
  DWCMS_Application: RECORDTYPE.DWCMS_Application,
  DWCMS_CriminalHistory: RECORDTYPE.DWCMS_CriminalHistory,
  DWCMS_Employment: RECORDTYPE.DWCMS_Employment,
  DWCMS_Impairment: RECORDTYPE.DWCMS_Impairment,
  DWCMS_Insurance: RECORDTYPE.DWCMS_Insurance,
  DWCMS_DisciplinaryAction: RECORDTYPE.DWCMS_DisciplinaryAction,
  DWCMS_RegistrationType: RECORDTYPE.DWCMS_RegistrationType,
  DWCMS_Application_Registration: RECORDTYPE.DWCMS_Application_Registration,
  DWCMS_LimitedSPQualification: RECORDTYPE.DWCMS_LimitedSPQualification,
  DWCMS_Division: RECORDTYPE.DWCMS_Division,
  DWCMS_ServiceProvider: RECORDTYPE.DWCMS_ServiceProvider,
  DWCMS_Address_History: RECORDTYPE.DWCMS_Address_History,
  DWCMS_OtherNamesPracticed: RECORDTYPE.DWCMS_OtherNamesPracticed,
  DWCMS_ProfessionalRegistration: RECORDTYPE.DWCMS_ProfessionalRegistration,
  DWCMS_ProfessionalCompetence: RECORDTYPE.DWCMS_ProfessionalCompetence,
  DWCMS_PrimaryPlaceOfPractice: RECORDTYPE.DWCMS_PrimaryPlaceOfPractice,
  DWCMS_RequestInfo: RECORDTYPE.DWCMS_RequestInfo,
  INFRINGEMENTS_InfringementTicket: RECORDTYPE.INFRINGEMENTS_InfringementTicket,
  INFRINGEMENTS_InfringementCategory:
    RECORDTYPE.INFRINGEMENTS_InfringementCategory,
  INFRINGEMENTS_InfringementOffence:
    RECORDTYPE.INFRINGEMENTS_InfringementOffence,
  INFRINGEMENTS_InfringementAnimals:
    RECORDTYPE.INFRINGEMENTS_InfringementAnimals,
  CEM_Registration: RECORDTYPE.CEM_Registration,
  CEM_Entry: RECORDTYPE.CEM_Entry,
  CORE_FileNumber: RECORDTYPE.CORE_FileNumber,
  CORE_PaymentPlan: RECORDTYPE.CORE_PaymentPlan,
  CORE_Species: RECORDTYPE.CORE_Species,
  Core_Bookmark: RECORDTYPE.Core_Bookmark,
  FLEET_Vehicle: RECORDTYPE.FLEET_Vehicle,
  FLEET_VehicleBookingPlan: RECORDTYPE.FLEET_VehicleBookingPlan,
  FLEET_VehicleBookingPlanItem: RECORDTYPE.FLEET_VehicleBookingPlanItem,
  FLEET_EventBooking: RECORDTYPE.FLEET_EventBooking,
  FLEET_VehiclePhoto: RECORDTYPE.FLEET_VehiclePhoto,
  FLEET_BookingDate: RECORDTYPE.FLEET_BookingDate,
  FLEET_EventType: RECORDTYPE.FLEET_EventType,
  FLEET_GroupEventBooking: RECORDTYPE.FLEET_GroupEventBooking,
  FLEET_VehicleBooking: RECORDTYPE.FLEET_VehicleBooking,
  FLEET_VehicleFeature: RECORDTYPE.FLEET_VehicleFeature,
  AssetManager_AssetRegister: RECORDTYPE.AssetManager_AssetRegister,
  AssetManager_WorkOrder: RECORDTYPE.AssetManager_WorkOrder,
  CommunityProperty_Unknown: RECORDTYPE.CommunityProperty_Unknown,
  CommunityProperty_Assessment: RECORDTYPE.CommunityProperty_Assessment,
  CommunityProperty_Parcel: RECORDTYPE.CommunityProperty_Parcel,
  CommunityProperty_Title: RECORDTYPE.CommunityProperty_Title,
  CommunityProperty_Assessment_Transaction:
    RECORDTYPE.CommunityProperty_Assessment_Transaction,
  CommunityProperty_Certificate: RECORDTYPE.CommunityProperty_Certificate,
  CommunityProperty_Register_Account:
    RECORDTYPE.CommunityProperty_Register_Account,
  CommunityProperty_Supplementary: RECORDTYPE.CommunityProperty_Supplementary,
  CommunityProperty_Meter: RECORDTYPE.CommunityProperty_Meter,
  CommunityProperty_Scheme_Account: RECORDTYPE.CommunityProperty_Scheme_Account,
  CommunityProperty_Certificate_Request:
    RECORDTYPE.CommunityProperty_Certificate_Request,
  CommunityProperty_Charge: RECORDTYPE.CommunityProperty_Charge,
  CommunityProperty_Charge_Balances:
    RECORDTYPE.CommunityProperty_Charge_Balances,
  CommunityProperty_Notice_Group: RECORDTYPE.CommunityProperty_Notice_Group,
  CommunityProperty_Street_Locality:
    RECORDTYPE.CommunityProperty_Street_Locality,
  CommunityProperty_GIS_Selection: RECORDTYPE.CommunityProperty_GIS_Selection,
  CommunityProperty_Certificates_Type:
    RECORDTYPE.CommunityProperty_Certificates_Type,
  CommunityProperty_Active_Certificates:
    RECORDTYPE.CommunityProperty_Active_Certificates,
  CommunityProperty_Active_Certificates_for_Responsible_Area:
    RECORDTYPE.CommunityProperty_Active_Certificates_for_Responsible_Area,
  CommunityProperty_Register: RECORDTYPE.CommunityProperty_Register,
  CommunityProperty_Master_Property:
    RECORDTYPE.CommunityProperty_Master_Property,
  CommunityProperty_Scheme: RECORDTYPE.CommunityProperty_Scheme,
  CommunityProperty_Meter_Reading_Route:
    RECORDTYPE.CommunityProperty_Meter_Reading_Route,
  CommunityProperty_Meter_Reading_Device:
    RECORDTYPE.CommunityProperty_Meter_Reading_Device,
  CommunityProperty_Utility: RECORDTYPE.CommunityProperty_Utility,
  CommunityProperty_Scheme_Account_Transaction:
    RECORDTYPE.CommunityProperty_Scheme_Account_Transaction,
  CommunityProperty_Meter_Installation:
    RECORDTYPE.CommunityProperty_Meter_Installation,
  CommunityProperty_Register_Account_Transaction:
    RECORDTYPE.CommunityProperty_Register_Account_Transaction,
  CommunityProperty_Land_Tax_Exemption:
    RECORDTYPE.CommunityProperty_Land_Tax_Exemption,
  CommunityProperty_ACT_Lease_Transfer:
    RECORDTYPE.CommunityProperty_ACT_Lease_Transfer,
  CommunityProperty_Deferred_Duty_Account:
    RECORDTYPE.CommunityProperty_Deferred_Duty_Account,
  CommunityProperty_Deferred_Duty_Scheme:
    RECORDTYPE.CommunityProperty_Deferred_Duty_Scheme,
  CommunityProperty_Deferred_Duty_Transaction:
    RECORDTYPE.CommunityProperty_Deferred_Duty_Transaction,
  CommunityProperty_Deferred_Duty_Instalments:
    RECORDTYPE.CommunityProperty_Deferred_Duty_Instalments,
  CommunityProperty_Entity: RECORDTYPE.CommunityProperty_Entity,
  CommunityProperty_Entity_Name_and_Address:
    RECORDTYPE.CommunityProperty_Entity_Name_and_Address,
  CommunityProperty_Assessment_Levy_Id:
    RECORDTYPE.CommunityProperty_Assessment_Levy_Id,
  CommunityProperty_Tasks_Run_Id: RECORDTYPE.CommunityProperty_Tasks_Run_Id,
  CommunityProperty_Tasks_Id: RECORDTYPE.CommunityProperty_Tasks_Id,
  CommunityProperty_Collection: RECORDTYPE.CommunityProperty_Collection,
  CommunityProperty_MenuItem: RECORDTYPE.CommunityProperty_MenuItem,
  CommunityProperty_Journal: RECORDTYPE.CommunityProperty_Journal,
  CommunityProperty_Advanced_Search:
    RECORDTYPE.CommunityProperty_Advanced_Search,
  CommunityProperty_Report: RECORDTYPE.CommunityProperty_Report,
  CommunityProperty_Home: RECORDTYPE.CommunityProperty_Home,
  CommunityProperty_Charge_Instalment:
    RECORDTYPE.CommunityProperty_Charge_Instalment,
  CommunityProperty_Charge_Levy: RECORDTYPE.CommunityProperty_Charge_Levy,
  CommunityProperty_Application_Search_No_Results:
    RECORDTYPE.CommunityProperty_Application_Search_No_Results,
  CommunityProperty_Session: RECORDTYPE.CommunityProperty_Session,
  CommunityProperty_Associated_Name:
    RECORDTYPE.CommunityProperty_Associated_Name,
  CommunityProperty_Lease: RECORDTYPE.CommunityProperty_Lease,
  CommunityProperty_Debt_Recovery: RECORDTYPE.CommunityProperty_Debt_Recovery,
  CommunityProperty_Assessment_Rebate_Entitlement:
    RECORDTYPE.CommunityProperty_Assessment_Rebate_Entitlement,
  CommunityProperty_Arrangement: RECORDTYPE.CommunityProperty_Arrangement,
  CommunityProperty_Change_of_Ownership:
    RECORDTYPE.CommunityProperty_Change_of_Ownership,
  CommunityProperty_Supplementary_Assessment:
    RECORDTYPE.CommunityProperty_Supplementary_Assessment,
  CommunityProperty_Arrangement_Letter:
    RECORDTYPE.CommunityProperty_Arrangement_Letter,
  CommunityProperty_Name_and_Address:
    RECORDTYPE.CommunityProperty_Name_and_Address,
  CommunityProperty_Debt_Recovery_Action:
    RECORDTYPE.CommunityProperty_Debt_Recovery_Action,
  CommunityProperty_Debt_Recovery_PaymentsReceived:
    RECORDTYPE.CommunityProperty_Debt_Recovery_PaymentsReceived,
  CommunityProperty_Debt_Recovery_Exemption:
    RECORDTYPE.CommunityProperty_Debt_Recovery_Exemption,
  CommunityProperty_Mail_Box: RECORDTYPE.CommunityProperty_Mail_Box,
  CommunityProperty_Financial_Summary:
    RECORDTYPE.CommunityProperty_Financial_Summary,
  CommunityProperty_Rebate_Claim: RECORDTYPE.CommunityProperty_Rebate_Claim,
  CommunityProperty_Concession_Card:
    RECORDTYPE.CommunityProperty_Concession_Card,
  CommunityProperty_Valuation: RECORDTYPE.CommunityProperty_Valuation,
  CommunityProperty_Address: RECORDTYPE.CommunityProperty_Address,
  CommunityProperty_CommunityCentral_Town_Planning_Application:
    RECORDTYPE.CommunityProperty_CommunityCentral_Town_Planning_Application,
  CommunityProperty_CommunityCentral_Health_Manager_Premise:
    RECORDTYPE.CommunityProperty_CommunityCentral_Health_Manager_Premise,
  CommunityProperty_CommunityCentral_Animal_Registration:
    RECORDTYPE.CommunityProperty_CommunityCentral_Animal_Registration,
  CommunityProperty_CommunityCentral_Building_Application:
    RECORDTYPE.CommunityProperty_CommunityCentral_Building_Application,
  CommunityProperty_CommunityCentral_Local_Laws_Permit:
    RECORDTYPE.CommunityProperty_CommunityCentral_Local_Laws_Permit,
  CommunityProperty_CommunityCentral_Events_Management_System_Booking:
    RECORDTYPE.CommunityProperty_CommunityCentral_Events_Management_System_Booking,
  CommunityProperty_CommunityCentral_Waste_Water_Permit:
    RECORDTYPE.CommunityProperty_CommunityCentral_Waste_Water_Permit,
  CommunityProperty_HazardId_Fire_Prevention:
    RECORDTYPE.CommunityProperty_HazardId_Fire_Prevention,
  CommunityProperty_HazardId_Noxious_Weed:
    RECORDTYPE.CommunityProperty_HazardId_Noxious_Weed,
  CommunityProperty_CommunityFinance_Customer:
    RECORDTYPE.CommunityProperty_CommunityFinance_Customer,
  CommunityProperty_Charge_Run: RECORDTYPE.CommunityProperty_Charge_Run,
  CommunityProperty_Notice_Run:
    RECORDTYPE.CommunityProperty_Notice_Run_Assessment,
  CommunityProperty_CommunityCentral_DevelopmentApplication:
    RECORDTYPE.CommunityProperty_CommunityCentral_DevelopmentApplication,
  CommunityProperty_CommunityCentral_StreetProtection:
    RECORDTYPE.CommunityProperty_CommunityCentral_StreetProtection,
  CommunityProperty_CommunityCentral_Other:
    RECORDTYPE.CommunityProperty_CommunityCentral_Other,
  CommunityProperty_Compliance: RECORDTYPE.CommunityProperty_Compliance,
  //"rechec":RrecheECORDTYPE.
  BUILDING: RECORDTYPE.BUILDING,
};
