import { eventEmitter } from "@/App";
import { VO_Workflow_Draft } from "@app/products/property/actions/model";
import { useConfirmCancelDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-cancel/store";
import { usePropertyWorkflow } from "@app/products/property/components/action-bar/property-workflow/component/hooks/useProprtyWorkflow/usePropertyWorkflow";
import { PROPERTY_ACTIONS_APPROVED_LIST_ROUTE } from "@app/products/property/components/action-bar/property-workflow/constant";
import {
  TypesActionsWorkflow,
  WorkflowApprovalStatus,
} from "@app/products/property/components/action-bar/property-workflow/model";
import { getTitleWorkflow } from "@app/products/property/components/action-bar/property-workflow/util";
import { GISReferenceDialog } from "@app/products/property/components/dialogs/gis-reference/_index";
import { colGISReference } from "@app/products/property/components/dialogs/gis-reference/config";
import { DTO_GIS } from "@app/products/property/components/dialogs/gis-reference/model";
import { GISAccordionEventType } from "@app/products/property/components/gis-accordion/constant";
import { WorkflowProcessMode } from "@app/products/property/model";
import {
  getWorkflowParcelGISReferenceByWorkflowDraftId,
  postNewWorkflowParcelGISReference,
  postWorkflowParcelGISReference,
} from "@app/products/property/parcels/[id]/components/forms/existed/components/dialogs/gis-reference/api";
import { GIS_REFERENCE_DIALOG_MODE } from "@app/products/property/parcels/[id]/components/forms/existed/components/dialogs/gis-reference/config";
import {
  DTO_WorkflowDetail_ModifyGIS,
  DTO_Workflow_ModifyGIS,
  IActionOnSubmitGISReference,
} from "@app/products/property/parcels/[id]/components/forms/existed/components/dialogs/gis-reference/model";
import { useParcelsStore } from "@app/products/property/parcels/[id]/store";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { DTO_LOV } from "@common/models/odataResponse";
import { useCommonProductStore } from "@common/stores/products/store";
import { nameOfFactory } from "@common/utils/common";
import {
  CCLocalNotification,
  ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCGrid } from "@components/cc-grid/_index";
import { CCGridEventType } from "@components/cc-grid/constant";
import { IColumnFields } from "@components/cc-grid/model";
import { CCInput } from "@components/cc-input/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import Loading from "@components/loading/Loading";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { differenceBy, isEmpty, isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useEffectOnce } from "react-use";

interface IModifyGISDialogProps {
  onClose: () => void;
  onSubmit: (data: DTO_GIS) => void;
  dataFromActionList?: VO_Workflow_Draft;
  typeList?: TypesActionsWorkflow;
  prefixTitle?: string;
  suffixTitle?: string;
}
const nameOfGISReference = nameOfFactory<DTO_GIS>();
const nameOf = nameOfFactory<DTO_WorkflowDetail_ModifyGIS>();

export const ModifyParcelGISDialog = observer(
  ({
    onClose,
    dataFromActionList,
    typeList,
    prefixTitle,
    suffixTitle,
  }: IModifyGISDialogProps) => {
    const { currentFormTitle } = useCommonProductStore();
    const { pushNotification, clearErrorNotification } =
      useCCAppNotificationStore();
    const { setDataForCancelDialog } = useConfirmCancelDialogStore();
    const history = useHistory();
    const {
      statusBadge,
      isShowCancelWorkflowButton,
      isToBeApprovalMode,
      isIncompleteMode,
      isApproved,
    } = usePropertyWorkflow(dataFromActionList);

    const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);
    const { parcelId } = useParcelsStore();
    const [isLoading, setIsLoading] = useState(false);
    const [submitting, setSubmitting] = useState<WorkflowProcessMode | null>(
      null
    );
    const [dialogMode, setDialogMode] = useState<GIS_REFERENCE_DIALOG_MODE>(
      GIS_REFERENCE_DIALOG_MODE.Hide
    );

    const [initialData, setInitialData] = useState<DTO_Workflow_ModifyGIS>();
    const [gisTypes, setGISTypes] = useState<DTO_LOV[]>([]);
    const [gisReferenceData, setGISReferenceData] = useState<DTO_GIS[]>([]);
    const [gisReferenceSelectedData, setGISReferenceSelectedData] = useState<
      DTO_GIS[]
    >([]);
    const [responseLoadError, setResponseLoadError] = useState<
      APIResponseError | undefined
    >();

    const columnFields = useMemo(() => {
      return colGISReference.map((col: IColumnFields) => {
        if (col.field === nameOfGISReference("GISReference") && !isApproved) {
          col.handleOnClick = (dataItem: DTO_GIS) => {
            setGISReferenceSelectedData([dataItem]);
            setDialogMode(GIS_REFERENCE_DIALOG_MODE.Edit);
          };
        } else {
          col.handleOnClick = undefined;
        }
        return col;
      });
    }, [isApproved]);

    const handleAddEditGISReferenceDialog = (data: DTO_GIS) => {
      let newGISReferences: DTO_GIS[] = [];
      if (dialogMode === GIS_REFERENCE_DIALOG_MODE.New) {
        newGISReferences = gisReferenceData
          ? [...gisReferenceData, data]
          : [data];
      } else {
        newGISReferences = gisReferenceData?.map((item) => {
          if (
            item?.GISReference === gisReferenceSelectedData?.[0]?.GISReference
          ) {
            const newItem = { ...item, ...data };
            if (!newItem) return item;
            setGISReferenceSelectedData([newItem]);
            return newItem;
          }
          return item;
        }) as DTO_GIS[];
      }
      setGISReferenceData(newGISReferences ?? []);
      setDialogMode(GIS_REFERENCE_DIALOG_MODE.Hide);
    };

    const handleRemoveGISReference = async () => {
      const newGISReferenceData = differenceBy(
        gisReferenceData,
        gisReferenceSelectedData,
        nameOfGISReference("GISReference")
      );
      setGISReferenceData(newGISReferenceData ?? []);
    };

    const handleCancelButton = () => {
      if (initialData?.WorkflowHeader?.WorkflowDraft?.Workflow_Draft_Id) {
        setDataForCancelDialog({
          cancelAPI: postWorkflowParcelGISReference,
          dataCancel: initialData,
          defaultSuccessMessage:
            "The edit GIS reference was cancelled successfully",
          defaultErrorMessage: "Edit GIS reference cancel failed",
        });
      } else {
        onClose();
      }
    };

    const processActionOnSubmit = (mode: WorkflowProcessMode) => {
      let actionData = new IActionOnSubmitGISReference();

      switch (mode) {
        case WorkflowProcessMode.Save:
          actionData = {
            notification: {
              success: "Modify parcel GIS saved successfully",
              error: "Saving modify parcel GIS failed",
            },
          };
          break;
        case WorkflowProcessMode.Finish:
          actionData = {
            notification: {
              success: "Modify parcel GIS created successfully.",
              error: "Modify parcel GIS failed.",
            },
            action: (res: any) => {
              if (!typeList) {
                //Refresh GIS Reference accordion if the workflow is auto approved
                if (
                  res?.data?.WorkflowApprovalStatus ===
                  WorkflowApprovalStatus.Approved
                ) {
                  eventEmitter.emit(
                    GISAccordionEventType.reloadGISReferenceGrid
                  );
                }
              } else {
                eventEmitter.emit(CCGridEventType.RefreshOData);
              }
            },
          };
          break;
        case WorkflowProcessMode.Approve:
          actionData = {
            notification: {
              success: "Modify parcel GIS approved successfully",
              error: "Approving modify parcel GIS failed",
            },
            action: () => history.push(PROPERTY_ACTIONS_APPROVED_LIST_ROUTE),
          };
          break;
        // case WorkflowProcessMode.Incomplete:
        //   actionData = {
        //     notification: {
        //       success: "Modify parcel GIS rejected successfully",
        //       error: "Rejecting modify parcel GIS failed",
        //     },
        //     action: () => history.push(PROPERTY_ACTIONS_REJECTED_LIST_ROUTE),
        //   };
        //   break;
        default:
          actionData = {
            notification: {
              success: "Workflow processed successfully",
              error: "Processing workflow failed",
            },
          };
      }
      return actionData;
    };

    const handleSubmit = async (mode: WorkflowProcessMode) => {
      const req = {
        ...initialData,
        WorkflowDetail: {
          ...initialData?.WorkflowDetail,
          SelectGISReference: gisReferenceData,
        },
      } as DTO_Workflow_ModifyGIS;
      setSubmitting(mode);
      const res = await postWorkflowParcelGISReference(mode, req);
      setSubmitting(null);
      const actionOnSubmit = processActionOnSubmit(mode);
      if (isSuccessResponse(res)) {
        clearErrorNotification();
        if (res.data.IsSuccess) {
          onClose();
          if (actionOnSubmit?.action) actionOnSubmit.action(res);
          pushNotification({
            title:
              res?.data?.Notification ?? actionOnSubmit.notification?.success,
            type: "success",
          });
        } else {
          notificationRef.current?.pushNotification({
            title:
              res?.data?.ErrorMessage ?? actionOnSubmit.notification?.error,
            type: "error",
            autoClose: false,
          });
        }
      } else {
        notificationRef.current?.pushNotification({
          title: res?.data?.ErrorMessage ?? actionOnSubmit.notification?.error,
          type: "error",
          autoClose: false,
        });
      }
    };

    const loadWorkflowData = async () => {
      setIsLoading(true);
      const workflowDraftId = dataFromActionList?.Workflow_Draft_Id;
      let res = undefined;
      let errorResponse = undefined;

      if (workflowDraftId) {
        res = await getWorkflowParcelGISReferenceByWorkflowDraftId(
          workflowDraftId
        );
      } else {
        if (!parcelId) return;
        res = await postNewWorkflowParcelGISReference(parcelId);
      }

      if (isSuccessResponse(res)) {
        setInitialData(res?.data);
        setGISReferenceData(res?.data?.WorkflowDetail?.SelectGISReference);
        setGISTypes(res?.data?.WorkflowDetail?.GISTypes ?? []);
      } else {
        errorResponse = {
          status: res.status,
          error: res.error ?? "Initial data load failed",
        };
      }
      setIsLoading(false);
      setResponseLoadError(errorResponse);
    };

    /**
     * process title dialog
     */
    const titleHeader = useMemo(() => {
      const formId = initialData?.WorkflowHeader.WorkflowDraft.WD_Form_Id;
      const title = currentFormTitle(formId ?? 0) || "GIS References";
      return getTitleWorkflow(title, prefixTitle, suffixTitle);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [prefixTitle, suffixTitle, initialData]);

    useEffectOnce(() => {
      loadWorkflowData();
    });

    return (
      <Form
        render={(formRenderProps: FormRenderProps) => {
          return (
            <CCDialog
              maxWidth={"40%"}
              height="auto"
              onClose={onClose}
              titleHeader={titleHeader}
              disabled={!isNil(submitting)}
              badge={statusBadge}
              bodyElement={
                isLoading ? (
                  <Loading isLoading={isLoading} />
                ) : responseLoadError ? (
                  <CCLoadFailed
                    responseError={responseLoadError}
                    onReload={() => {
                      loadWorkflowData();
                    }}
                  />
                ) : (
                  <FormElement>
                    <div className="cc-form">
                      <div className="cc-field-group">
                        <CCLocalNotification ref={notificationRef} />
                        <div className="cc-form-cols-1">
                          <div className="cc-field">
                            <label className="cc-label">Parcel reference</label>
                            <Field
                              name={nameOf("ParcelReference")}
                              placeholder={"Parcel reference"}
                              component={CCInput}
                              value={
                                initialData?.WorkflowDetail?.ParcelReference
                              }
                              readOnly
                            />
                          </div>
                          <CCGrid
                            toolbar={
                              <div className="cc-grid-tools-bar">
                                <Button
                                  type="button"
                                  iconClass="fas fa-plus"
                                  disabled={isApproved}
                                  title="Add"
                                  onClick={() =>
                                    setDialogMode(GIS_REFERENCE_DIALOG_MODE.New)
                                  }
                                />
                                <Button
                                  type="button"
                                  iconClass="fas fa-minus"
                                  title="Remove"
                                  onClick={handleRemoveGISReference}
                                  disabled={
                                    isApproved ||
                                    isEmpty(gisReferenceSelectedData)
                                  }
                                />
                              </div>
                            }
                            columnFields={columnFields}
                            data={gisReferenceData ?? []}
                            primaryField={nameOfGISReference("GISReference")}
                            selectableMode={isApproved ? "none" : "multiple"}
                            onSelectionChange={(dataItem: DTO_GIS[]) => {
                              if (dataItem)
                                setGISReferenceSelectedData([...dataItem]);
                            }}
                            selectedRows={gisReferenceSelectedData}
                          />
                        </div>
                      </div>
                    </div>
                    {(dialogMode === GIS_REFERENCE_DIALOG_MODE.New ||
                      dialogMode === GIS_REFERENCE_DIALOG_MODE.Edit) && (
                      <GISReferenceDialog
                        onClose={() =>
                          setDialogMode(GIS_REFERENCE_DIALOG_MODE.Hide)
                        }
                        onSubmit={handleAddEditGISReferenceDialog}
                        initialGISReference={
                          dialogMode === GIS_REFERENCE_DIALOG_MODE.Edit &&
                          gisReferenceSelectedData[0]
                            ? ({
                                ...gisReferenceSelectedData[0],
                                GISTypeObj: {
                                  Code: gisReferenceSelectedData[0].GIS_Type_Id?.toString(),
                                  Name: gisReferenceSelectedData[0].GIS_Type,
                                },
                              } as DTO_GIS)
                            : undefined
                        }
                        gisReferenceData={gisReferenceData}
                        gisTypes={gisTypes}
                      />
                    )}
                  </FormElement>
                )
              }
              footerElement={
                !isApproved ? (
                  <div className={"cc-dialog-footer-actions-right"}>
                    {isShowCancelWorkflowButton && (
                      <Button
                        className={"cc-dialog-button"}
                        disabled={
                          !!responseLoadError || isLoading || !isNil(submitting)
                        }
                        onClick={handleCancelButton}
                      >
                        Cancel
                      </Button>
                    )}
                    {isToBeApprovalMode && (
                      <Button
                        className={"cc-dialog-button"}
                        disabled={
                          !!responseLoadError || isLoading || !isNil(submitting)
                        }
                        iconClass={
                          submitting === WorkflowProcessMode.Save
                            ? "fas fa-spinner fa-spin"
                            : ""
                        }
                        onClick={() => handleSubmit(WorkflowProcessMode.Save)}
                      >
                        Save
                      </Button>
                    )}
                    {(!typeList || isIncompleteMode) && (
                      <Button
                        className={"cc-dialog-button"}
                        onClick={() => handleSubmit(WorkflowProcessMode.Park)}
                        disabled={
                          !!responseLoadError || isLoading || !isNil(submitting)
                        }
                        iconClass={
                          submitting === WorkflowProcessMode.Park
                            ? "fas fa-spinner fa-spin"
                            : ""
                        }
                      >
                        Park
                      </Button>
                    )}
                    {isToBeApprovalMode && (
                      <>
                        <Button
                          themeColor="primary"
                          className={"cc-dialog-button"}
                          // onClick={() =>
                          //   handleSubmit(WorkflowProcessMode.Incomplete)
                          // }
                          disabled={
                            !!responseLoadError ||
                            isLoading ||
                            !isNil(submitting)
                          }
                          iconClass={
                            submitting === WorkflowProcessMode.Reject
                              ? "fas fa-spinner fa-spin"
                              : ""
                          }
                        >
                          Reject
                        </Button>
                        <Button
                          themeColor="primary"
                          disabled={
                            !!responseLoadError ||
                            isLoading ||
                            !isNil(submitting)
                          }
                          className={"cc-dialog-button"}
                          onClick={() =>
                            handleSubmit(WorkflowProcessMode.Approve)
                          }
                          iconClass={
                            submitting === WorkflowProcessMode.Approve
                              ? "fas fa-spinner fa-spin"
                              : ""
                          }
                        >
                          Approve
                        </Button>
                      </>
                    )}
                    {(!typeList || isIncompleteMode) && (
                      <Button
                        className={"cc-dialog-button"}
                        themeColor="primary"
                        onClick={() => handleSubmit(WorkflowProcessMode.Finish)}
                        disabled={
                          !!responseLoadError || isLoading || !isNil(submitting)
                        }
                        iconClass={
                          submitting === WorkflowProcessMode.Finish
                            ? "fas fa-spinner fa-spin"
                            : ""
                        }
                      >
                        Finish
                      </Button>
                    )}
                  </div>
                ) : undefined
              }
            />
          );
        }}
      />
    );
  }
);
