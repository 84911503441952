import { classificationsForWriteOffTransactionType } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/adjust-charges/components/steps/adjust-transaction/config";
import { useAdjustChargeDialogStore } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/adjust-charges/store";
import { DTO_LOV_Transaction } from "@app/products/property/journals/list/components/dialogs/create-journal/model";
import { convertValueLOVToNumber } from "@app/products/property/util";
import { DATETIME_FORMAT } from "@common/constants/common-format";
import { requiredValidator } from "@common/utils/field-validators";
import { CCDateTimePicker } from "@components/cc-date-time-picker/_index";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCLabel } from "@components/cc-label/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { Field, FieldArray } from "@progress/kendo-react-form";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";

export const AdjustTransactionFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};

const FormStepElement = observer(
  ({
    formRenderProps: { valueGetter },
    nameOf,
    options = {
      isReadOnly: false,
    },
  }: IFormStepElement) => {
    const getFieldValue = (name: string) => valueGetter(nameOf(name));
    const { adjustChargeLOVs } = useAdjustChargeDialogStore();

    const onlyShowWriteOff = getFieldValue("OnlyShowWriteOff");
    const transactionTypesData = useMemo(() => {
      const transactionTypesInt = convertValueLOVToNumber(
        adjustChargeLOVs?.TransactionTypes ?? [],
        "Code"
      );
      if (!transactionTypesInt) return [];
      if (!onlyShowWriteOff) return transactionTypesInt;
      return transactionTypesInt?.filter(
        (item: DTO_LOV_Transaction) =>
          !isNil(item?.Classifications) &&
          classificationsForWriteOffTransactionType.includes(
            item.Classifications
          )
      );
    }, [adjustChargeLOVs, onlyShowWriteOff]);

    return (
      <section className="cc-field-group">
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <CCLabel title="Transaction date" isMandatory />
            <Field
              name={nameOf("TransactionDateTime")}
              component={CCDateTimePicker}
              format={DATETIME_FORMAT.DATETIME_CONTROL}
              disabled={options.isReadOnly}
              validator={!options?.isReadOnly ? requiredValidator : undefined}
            />
          </div>
        </div>
        <div className="cc-field">
          <label className="cc-label">
            Only show write off transaction types
          </label>
          <Field
            name={nameOf("OnlyShowWriteOff")}
            checked={getFieldValue("OnlyShowWriteOff")}
            component={CCSwitch}
            disabled={options.isReadOnly}
          />
        </div>

        <div className="cc-field">
          <CCLabel title="Transaction type" isMandatory />
          <Field
            name={nameOf("Transaction_Type")}
            filterable={true}
            placeholder="Transaction type"
            textField="Name"
            dataItemKey="Code"
            data={transactionTypesData}
            component={CCSearchComboBox}
            isUseDefaultOnchange
            suggest={true}
            disabled={options.isReadOnly}
            validator={!options.isReadOnly ? requiredValidator : undefined}
          />
        </div>
        <div className="cc-field">
          <label className="cc-label">Description</label>
          <Field
            name={nameOf("TransactionDescription")}
            placeholder="Description"
            rows={4}
            component={CCTextArea}
          />
        </div>
      </section>
    );
  }
);
