import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React from "react";

export const RefusePermitButton = observer(() => {
  const handleClickButton = async (event: any) => {
    return;
  };
  return <CCNavButton title="Refuse Permit" onClick={handleClickButton} />;
});
