import { INotificationPortalContent } from "@components/cc-notification-portal/components/notification-portal-content/model";

interface Notification {
  route: string;
  notification: INotificationPortalContent;
}

interface GroupedNotification {
  route: string;
  notifications: INotificationPortalContent[];
}

export const groupNotificationsByRoute = (
  notifications: Notification[]
): GroupedNotification[] => {
  return notifications.reduce(
    (acc: GroupedNotification[], curr: Notification) => {
      const existingRoute = acc.find(
        (routeObj) => routeObj.route === curr.route
      );

      if (existingRoute) {
        existingRoute.notifications.push(curr.notification);
      } else {
        acc.push({
          route: curr.route,
          notifications: [curr.notification],
        });
      }
      return acc;
    },
    []
  );
};

export const getCurrentRouteActive = (dataRoute: Record<string, any>) => {
  const currentRoute = window.location.pathname;
  /* ====  Handle route is Regex  ==== */
  const routeRegexMatchCurrentRoute = Object.keys(dataRoute).find(
    (item: string) => new RegExp(item).test(currentRoute)
  );
  /* ====  / Handle route is Regex  ==== */

  const _routeActive = routeRegexMatchCurrentRoute
    ? routeRegexMatchCurrentRoute
    : currentRoute;
  return _routeActive;
};
