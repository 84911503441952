import { useGlobalStore } from "@common/stores/global/store";
import { getCoordinateFromAddress } from "@components/map/api";
import MapMarker from "@components/map/components/MapMarker";
import {
  GEOCODE_STATUS_CODES,
  IMapMapCenterProps,
} from "@components/map/model";
import { ThemeType } from "@styles/constant";
import GoogleMapReact, { Coords, MapOptions } from "google-map-react";
import { isEqual, isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";
import { useDeepCompareEffect } from "react-use";
import { defaultMapCenter, defaultMapZoom } from "./config";
import darkStyle from "./map-styles/dark.json";
import indigoStyle from "./map-styles/indigo.json";
import lightStyle from "./map-styles/light.json";
export interface IMapView {
  coordinate?: Coords;
  zoom?: number;
  width?: string | number;
  height?: string | number;
  address?: string;
  onError?: (errorMessage: string) => void;
}
const mapViewOption: MapOptions = {
  streetViewControl: true,
  mapTypeControl: true,
  controlSize: 30,
};

const MapView = ({
  coordinate,
  zoom = 10,
  width = "100%",
  height = 500,
  address,
  onError,
}: IMapView) => {
  const { allSetting } = useGlobalStore();
  const bootstrapURLKeys = allSetting.googleMap;
  const chosenTheme = localStorage.getItem("OOTheme") || ThemeType.Light;
  const styles = useMemo(
    () =>
      chosenTheme === ThemeType.Light || chosenTheme === ThemeType.Ready
        ? lightStyle
        : chosenTheme === ThemeType.Indigo
        ? indigoStyle
        : darkStyle,
    [chosenTheme]
  );
  const [location, setLocation] = useState<IMapMapCenterProps>();

  const loadMap = async () => {
    if (!coordinate && !address) {
      setLocation(defaultMapCenter);
      return;
    }
    if (
      coordinate &&
      !isNil(coordinate.lat) &&
      !isNil(coordinate.lng) &&
      (coordinate.lat !== 0 || coordinate.lng !== 0)
    ) {
      if (
        coordinate.lat > 90 ||
        coordinate.lat < -90 ||
        coordinate.lng > 180 ||
        coordinate.lng < -180
      ) {
        setLocation(defaultMapCenter);
      } else {
        setLocation(coordinate);
      }
      return;
    }
    if (address && allSetting.googleMap) {
      const response = await getCoordinateFromAddress(
        address,
        allSetting.googleMap
      );
      if (response?.data) {
        if (
          response.data.status === GEOCODE_STATUS_CODES.OK &&
          response.data.results &&
          response.data.results.length > 0
        ) {
          const newCoordinate = {
            lat: response.data.results[0]?.geometry.location.lat,
            lng: response.data.results[0]?.geometry.location.lng,
          };
          setLocation(newCoordinate);
        } else {
          if (onError && response.data?.results.length === 0) {
            onError(`Can't find the map location with address "${address}"`);
          } else if (onError && response.data.error_message) {
            onError(response.data.error_message);
          }
          setLocation(defaultMapCenter);
          return;
        }
      } else {
        if (onError) onError("Google API load failed");
      }
    } else {
      setLocation(defaultMapCenter);
    }
  };

  useDeepCompareEffect(() => {
    loadMap();
  }, [coordinate, address, allSetting.googleMap]);

  if (!location) {
    return null;
  }

  return (
    <div
      className="cc-google-map-container"
      style={{ width: width, height: height }}
    >
      <GoogleMapReact
        options={{
          ...mapViewOption,
          styles: styles,
        }}
        bootstrapURLKeys={bootstrapURLKeys}
        defaultCenter={defaultMapCenter}
        defaultZoom={defaultMapZoom}
        center={location}
        zoom={isEqual(location, defaultMapCenter) ? 0 : zoom}
      >
        {!isEqual(location, defaultMapCenter) && location && (
          <MapMarker lat={location.lat} lng={location.lng} />
        )}
      </GoogleMapReact>
    </div>
  );
};
export default observer(MapView);
