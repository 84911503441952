import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import MapView from "@components/map/MapView";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";

export interface IMapTabProps {
  address?: string;
  addressField?: string;
  zoom?: number;
  onError?: (errorMessage: string) => void;
}
export const MapTab = observer(
  ({
    address,
    addressField = "Property_Name_Address_Locality",
    onError,
    zoom = 10,
  }: IMapTabProps) => {
    const { lastSelectedRow } = useCCProductListViewStore();
    const searchAddress = useMemo(() => {
      if (address || lastSelectedRow) {
        return address || lastSelectedRow[addressField];
      }
      return undefined;
    }, [address, addressField, lastSelectedRow]);

    return (
      <div className="cc-ref-map-container">
        <MapView
          zoom={zoom}
          width="100%"
          height="100%"
          address={searchAddress}
          onError={onError}
        />
      </div>
    );
  }
);
