import { getUUID } from "@common/utils/common";
import {
  INotificationPortalContent,
  INotificationPortalContentExcludeId,
} from "@components/cc-notification-portal/components/notification-portal-content/model";
import {
  TypePlaceId,
  notificationPortalDefaultValue,
} from "@components/cc-notification-portal/constant";
import { getCurrentRouteActive } from "@components/cc-notification-portal/util";

import {
  configure,
  makeAutoObservable,
  reaction,
  runInAction,
  toJS,
} from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" });

export type TState = any;
class NotificationPortalStore {
  private _dataRoute: Record<string, TState> = {};
  private _timers: NodeJS.Timeout[] = [];
  private _placeId: string = TypePlaceId.Main;
  private _notificationsCurrentPage: INotificationPortalContent[] = [];
  private _notificationsOtherPage: INotificationPortalContent[] = [];

  constructor() {
    makeAutoObservable(this);
    reaction(
      () => this.notificationsCurrentPage,
      (notificationsCurrentPage: INotificationPortalContent[]) => {
        if (notificationsCurrentPage.length === 0) {
          this.timers.forEach((timerId: NodeJS.Timeout) =>
            clearTimeout(timerId)
          );
          this._timers = [];
        }
      }
    );
  }

  get notificationsOtherPage() {
    return toJS(this._notificationsOtherPage);
  }
  setNotificationsOtherPage = (notification: INotificationPortalContent) => {
    runInAction(() => {
      this._notificationsOtherPage.unshift(notification);
    });
  };

  get notificationsCurrentPage() {
    return toJS(this._notificationsCurrentPage);
  }
  pushNotificationsCurrentPage = (notification: INotificationPortalContent) => {
    runInAction(() => {
      this._notificationsCurrentPage.unshift(notification);
    });
  };
  setNotificationsCurrentPage = (
    notifications: INotificationPortalContent[]
  ) => {
    runInAction(() => {
      this._notificationsCurrentPage = notifications;
    });
  };

  get placeId() {
    return toJS(this._placeId);
  }
  setPlaceId = (id: string) => {
    runInAction(() => {
      this._placeId = id;
    });
  };

  get timers() {
    return toJS(this._timers);
  }
  pushTimer = (timerId: NodeJS.Timeout) => {
    runInAction(() => {
      this._timers.push(timerId);
    });
  };
  clearAllTimers = () => {
    runInAction(() => {
      this.timers.forEach((timerId: NodeJS.Timeout) => clearTimeout(timerId));
      this._timers = [];
    });
  };
  removeAllOldNotificationPortal = () => {
    runInAction(() => {
      this.clearAllTimers();
      this.setNotificationsCurrentPage([]);
    });
  };

  get dataRoute() {
    return toJS(this._dataRoute);
  }
  pushDataRoute = (
    route: string,
    notification: INotificationPortalContent[],
    _removeStateAtRoute?: string // TODO: enhance later
  ) => {
    runInAction(() => {
      this._dataRoute[route] = notification;
    });
  };

  pushNotificationPortal = (
    notification: INotificationPortalContentExcludeId,
    isClearAllNotification?: boolean
  ) => {
    if (isClearAllNotification) {
      this.removeAllOldNotificationPortal();
    }

    const notificationId = getUUID();

    notification = {
      ...notificationPortalDefaultValue,
      ...notification,
      onClose: (noTimeout: boolean) => {
        if (noTimeout) {
          this.removeNotificationPortal(notificationId);
          return;
        }
        const timer = setTimeout(
          () => this.removeNotificationPortal(notificationId),
          500
        );
        this.pushTimer(timer);
      },
      id: notificationId,
    } as INotificationPortalContent;

    if (notification?.placeId) this.setPlaceId(notification.placeId);

    if (!notification?.route) {
      this.pushNotificationsCurrentPage(
        notification as INotificationPortalContent
      );
    } else {
      if (
        !new RegExp(notification.route).test(window.location.pathname) &&
        notification.route !== window.location.pathname
      ) {
        this.setNotificationsOtherPage(
          notification as INotificationPortalContent
        );
        this.pushDataRoute(
          notification.route,
          this.notificationsOtherPage as INotificationPortalContent[]
        );
      }
    }
    if (notification?.callBack) {
      notification.callBack();
    }
  };

  removeNotificationPortal = (id: string): void => {
    const newNotifications = this.notificationsCurrentPage.filter(
      (notification) => id !== notification.id
    );
    this.setNotificationsCurrentPage(newNotifications);
  };

  listenNotificationPortal = () => {
    const routeActive = getCurrentRouteActive(this.dataRoute);
    const notificationInStore = this.dataRoute[routeActive] || [];
    const notificationInStoreMergeDefaultValue = notificationInStore.map(
      (item: INotificationPortalContent) => {
        return { id: getUUID(), ...notificationPortalDefaultValue, ...item };
      }
    );
    let _placeId = TypePlaceId.Main;
    if (notificationInStoreMergeDefaultValue?.length) {
      _placeId = notificationInStoreMergeDefaultValue[0].placeId;
      this.setNotificationsCurrentPage(notificationInStoreMergeDefaultValue);
      this.dataRoute[routeActive] = [];
    }
    this.setPlaceId(_placeId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  //#region Reset Data Form ========/
  resetNotification = () => {
    runInAction(() => {
      this.removeAllOldNotificationPortal();
    });
  };
  //#endregion Reset Data Form =====/
}

export const notificationPortalStoreInstance = new NotificationPortalStore();
const notificationPortalStoreContext = createContext(
  notificationPortalStoreInstance
);
export const useNotificationPortalStore = () => {
  return useContext(notificationPortalStoreContext);
};
