import { AddAttachmentButton } from "@app/core/attachment/components/buttons/add-attachment/_index";
import { Comments } from "@app/core/comments/_index";
import { AddCommentButton } from "@app/core/comments/components/buttons/add-comment/_index";
import { CommunicationButtonListView } from "@app/core/communication/buttons-list-view/_index";
import { AddComplaintEvent } from "@app/core/complaints/_index";
import { AdditionalContactButton } from "@app/core/contacts/components/buttons/additional-contact/_index";
import { ContactAccordion } from "@app/core/contacts/components/contact-accordion/_index";
import { Documents } from "@app/core/documents/_index";
import { AddDocumentButton } from "@app/core/documents/components/buttons/add-document/_index";
import { AddFeeWithParentButton } from "@app/core/fees/fee-button/_index";
import Inspections from "@app/core/inspections/inspections-accordion/_index";
import { AddInspectionsWithParentButton } from "@app/core/inspections/inspections-button/_index";
import { InvoicesAccordion } from "@app/core/invoice/invoice-accordion/_index";
import { AddInvoiceWithParentButton } from "@app/core/invoice/invoice-button/_index";
import { MAILMERGEDATASET } from "@app/core/new-mail-merge/dialogs/model";
import { AddNoticeWithParentButton } from "@app/core/notices/notices-button/_index";
import { RelatedAccordion } from "@app/core/related/related-accordion/_index";
import { ICoreRelatedParams } from "@app/core/related/related-accordion/model";
import { AddRelatedButton } from "@app/core/related/related-button/_index";
import { ITabProps, TabTable } from "@app/core/tab-table/_index";
import { TabTableType } from "@app/core/tab-table/model";
import { ViewOnMapByGISButton } from "@app/core/view-on-map/components/buttons/view-map-by-gis/_index";
import { Svc_ViewOnMap } from "@app/core/view-on-map/components/buttons/view-map-by-gis/model";
import { AnimalTypeSubmitActions } from "@app/products/animals/[id]/model";
import { useAnimalStore } from "@app/products/animals/[id]/store";
import { RegenerateFeeButton } from "@app/products/animals/components/action-bar/buttons/regenerate-fee/_index";
import { AnimalForm } from "@app/products/animals/components/forms/_index";
import { AnimalDeceasedButton } from "@app/products/animals/components/forms/components/workflow/deceased/_index";
import { AnimalDeregisterButton } from "@app/products/animals/components/forms/components/workflow/deregister/_index";
import { AnimalIssueRegistrationButton } from "@app/products/animals/components/forms/components/workflow/issue-registration/_index";
import { AnimalLostTagButton } from "@app/products/animals/components/forms/components/workflow/lost-tag/_index";
import { AnimalNewTagIssueButton } from "@app/products/animals/components/forms/components/workflow/new-tag-issue/_index";
import { AnimalReactivateButton } from "@app/products/animals/components/forms/components/workflow/reactivate/_index";
import { AnimalRenewButton } from "@app/products/animals/components/forms/components/workflow/renew/_index";
import { AnimalSameDetailsButton } from "@app/products/animals/components/forms/components/workflow/same-details/_index";
import { AnimalUpdateRegistrationButton } from "@app/products/animals/components/forms/components/workflow/update-registration/_index";
import { AnimalsContactsTab } from "@app/products/animals/components/reference-sidebar/contacts/_index";
import { AnimalDetailsTab } from "@app/products/animals/components/reference-sidebar/details/_index";
import { AnimalsHistoryTab } from "@app/products/animals/components/reference-sidebar/history/_index";
import { ProductType } from "@app/products/crms/components/dialogs/acknowledge-events/model";
import { EventsAccordion } from "@app/products/crms/components/event-accordion/_index";
import { FormAction, FormIdentifier } from "@app/products/crms/model";
import { checkCRMSProductionIsActive } from "@app/products/crms/util";
import { PPRActions } from "@app/products/town-planning/ppr/[id]/components/child-screens/actions/_index";
import { AddActionWithParentButton } from "@app/products/town-planning/ppr/components/action-bar/buttons/add/action/_index";
import { COMPLAINTSMENU } from "@common/constants/enumerations";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useCheckPermissions } from "@common/hooks/permissions/useCheckPermissions";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { useCommonCoreStore } from "@common/stores/core/store";
import { useGlobalStore } from "@common/stores/global/store";
import {
  getBoolValueSetting,
  getNumberValueSetting,
} from "@common/stores/products/util";
import { getDisplayTextWithDashes } from "@common/utils/common";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { ActionBarBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/_index";
import { IBookmarkItem } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/model";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { ReferenceSideBar } from "@components/cc-reference-sidebar/_index";
import { MapTab } from "@components/cc-reference-sidebar/components/Map/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";
import { useParams } from "react-router-dom";

export const ExistManageAnimal = observer(() => {
  const {
    isLoading,
    isLoadingSuperAdmin,
    animalInfo,
    responseLoadError,
    loadAnimalsInfo,
    animalId,
    pidNo,
    onSubmit,
  } = useAnimalStore();
  const { productsActive } = useGlobalStore();
  const [showSlideBar, setShowSlideBar] = useState(true);
  const { currentUserInfo } = useGlobalStore();
  const { id } = useParams<{ id: string }>();
  const managePageUrl = window.location.pathname;
  const { settings } = useCommonCoreStore();
  const isEnableMultiLineInvoice = getBoolValueSetting(
    settings[ECorporateSettingsField.CorporateSettings_EnableMultiLineInvoice]
  );
  const { checkPermissions, isLoadingPermission } = useCheckPermissions({
    formIdentifier: FormIdentifier.Animals_Form_Registration,
    productType: PRODUCT_TYPE_NUMBER.Animals,
  });

  const bookmarkList: IBookmarkItem[] = [
    {
      LinkText: `Animals - ${animalId ?? 0}`,
      Text: `${getDisplayTextWithDashes([
        animalInfo?.AnimalName ? animalInfo?.AnimalName : "No Name",
        animalInfo?.BreedDisplay,
      ])}`,
      LinkUrl: managePageUrl,
      User_ID: currentUserInfo?.UserPKID ? +currentUserInfo.UserPKID : 0,
      ProductType_ENUM: PRODUCT_TYPE.Animals,
      RecordType_ENUM: RECORDTYPE.Animals_Registration,
      Record_ID: animalId ?? 0,
    },
  ];

  const relatedAccordionParams = useMemo(() => {
    if (animalInfo && animalId) {
      const data: ICoreRelatedParams = {
        RecordType: RECORDTYPE.Animals_Registration,
        RecordID: animalId,
        InvoiceNumber: null,
        AssessmentNumberKey: null,
        AddressLine1: null,
        Suburb: null,
        PersonKey: null,
        WasteWaterSchemeKey: null,
        AncestorRecordType: null,
        AncestorRecordId: null,
      };

      if (animalInfo?.Address) {
        data.AssessmentNumberKey = pidNo;
        data.AddressLine1 = animalInfo.Address?.Formatted_AddressLine1;
        data.Suburb = animalInfo.Address?.Suburb;
      }

      return data;
    }
    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [animalInfo?.Address, animalInfo, animalId]);

  const getTitle = useMemo(() => {
    return `${animalInfo?.AnimalName ? animalInfo?.AnimalName : "No Name"} ${
      animalInfo?.BreedDisplay ? `- ${animalInfo?.BreedDisplay}` : ""
    }`;
  }, [animalInfo]);

  const isShowCUSTEventAccordion = useMemo(() => {
    const complaintsMenu = getNumberValueSetting(
      settings[ECorporateSettingsField.CorporateSettings_ComplaintsMenu]
    );
    const checkIsActiveCRMS = checkCRMSProductionIsActive(productsActive);
    return checkIsActiveCRMS && complaintsMenu === COMPLAINTSMENU.CRMS;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productsActive]);

  const listPanelBar: ITabProps[] = [
    {
      title: "Actions",
      tabType: TabTableType.Actions,
      component: (
        <PPRActions
          id={animalId}
          recordType={RECORDTYPE.Animals_Registration}
        />
      ),
    },
    {
      title: "Comments",
      tabType: TabTableType.Comments,
      component: (
        <Comments id={animalId} recordType={RECORDTYPE.Animals_Registration} />
      ),
    },
    {
      title: "Contacts",
      tabType: TabTableType.Contacts,
      component: (
        <ContactAccordion
          id={animalId}
          recordType={RECORDTYPE.Animals_Registration}
        />
      ),
    },
    {
      title: "Documents",
      tabType: TabTableType.Documents,
      component: (
        <Documents id={animalId} recordType={RECORDTYPE.Animals_Registration} />
      ),
    },
    {
      title: "Invoices",
      tabType: TabTableType.Invoices,
      component: (
        <InvoicesAccordion
          id={animalId}
          recordType={RECORDTYPE.Animals_Registration}
        />
      ),
    },
    {
      title: "Inspections",
      tabType: TabTableType.Inspections,
      component: (
        <Inspections
          id={animalId}
          recordType={RECORDTYPE.Animals_Registration}
        />
      ),
    },
    {
      title: "Related",
      tabType: TabTableType.Related,
      component: <RelatedAccordion relatedParam={relatedAccordionParams} />,
    },
    {
      title: "Events",
      isVisible: isShowCUSTEventAccordion,
      tabType: TabTableType.Events,
      component: (
        <EventsAccordion
          id={animalId}
          recordType={RECORDTYPE.Animals_Registration}
        />
      ),
    },
  ];

  const badgeTitles = () => {
    const badgeTitles: string[] = [];
    if (animalInfo?.Status_Name) badgeTitles.push(animalInfo?.Status_Name);
    if (animalInfo?.Flag_Dangerous) badgeTitles.push("Dangerous");
    if (animalInfo?.Flag_Menacing) badgeTitles.push("Menacing");
    if (
      animalInfo?.Contact?.Contact?.ContactAlert ||
      animalInfo?.Address?.PropertyAlert
    )
      badgeTitles.push("Alert");
    return badgeTitles;
  };

  return (
    <>
      <Loading
        isLoading={isLoading || isLoadingSuperAdmin || isLoadingPermission}
        isFullScreen
      />
      <FormNavigation title={"Animal Registration"} />
      {responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => loadAnimalsInfo(parseInt(id))}
        />
      ) : (
        <>
          <FormTitle title={getTitle} badge={badgeTitles()} />
          <CCManagePageActionBar
            leftActions={[
              <CCNavButton
                title={"Workflow"}
                type="sub-workflow"
                className="cc-workflow-button"
              >
                <AnimalIssueRegistrationButton />
                <AnimalRenewButton />
                <AnimalDeregisterButton />
                <AnimalLostTagButton />
                <AnimalNewTagIssueButton />
                <AnimalDeceasedButton />
                <AnimalReactivateButton />
              </CCNavButton>,
              <CCNavButton
                title={"Save"}
                name={AnimalTypeSubmitActions.Save}
                onClick={onSubmit}
                disabled={
                  !checkPermissions([
                    FormAction.CORE_ALLOW_SAVE,
                    FormAction.CORE_ALLOW_EDIT,
                  ])
                }
              />,
              <CCNavButton title={"Add"} type="sub">
                <AddActionWithParentButton
                  id={animalId}
                  recordType={RECORDTYPE.Animals_Registration}
                  disabled={
                    !checkPermissions(FormAction.CORE_TOOLBAR_ADD_ACTION)
                  }
                />
                <AdditionalContactButton
                  id={animalId}
                  recordType={RECORDTYPE.Animals_Registration}
                  disabled={
                    !checkPermissions(FormAction.CORE_TOOLBAR_ADD_CONTACT)
                  }
                />
                <AddAttachmentButton
                  id={animalId}
                  recordType={RECORDTYPE.Animals_Registration}
                  disabled={
                    !checkPermissions(FormAction.CORE_TOOLBAR_ADD_ATTACHMENT)
                  }
                />
                <AddCommentButton
                  id={animalId}
                  recordType={RECORDTYPE.Animals_Registration}
                  isDisabled={
                    !checkPermissions(FormAction.CORE_TOOLBAR_ADD_COMMENT)
                  }
                />
                <CommunicationButtonListView
                  productType={PRODUCT_TYPE_NUMBER.Animals}
                  recordType={RECORDTYPE.Animals_Registration}
                  recordId={animalId}
                />
                <AddComplaintEvent
                  recordType={RECORDTYPE.Animals_Registration}
                  parentId={animalId}
                  parentName={animalInfo?.AnimalName}
                  disabled={
                    !checkPermissions(FormAction.CORE_TOOLBAR_ADD_COMPLAINT)
                  }
                />
                <AddDocumentButton
                  dataSetFilter={MAILMERGEDATASET.Animals_Registration}
                  recordType={RECORDTYPE.Animals_Registration}
                  productType={PRODUCT_TYPE_NUMBER.Animals}
                  id={animalId}
                  isDisabled={
                    !checkPermissions(FormAction.CORE_TOOLBAR_ADD_DOCUMENT)
                  }
                />
                <AddInspectionsWithParentButton
                  id={animalId}
                  recordType={RECORDTYPE.Animals_Registration}
                  bubbleUps={animalInfo?.BubbleUp}
                  productType={PRODUCT_TYPE_NUMBER.Animals}
                  isDisabled={
                    !checkPermissions(FormAction.CORE_TOOLBAR_ADD_INSPECTION)
                  }
                />
                {isEnableMultiLineInvoice ? (
                  <AddInvoiceWithParentButton
                    id={animalId}
                    recordType={RECORDTYPE.Animals_Registration}
                    bubbleUps={animalInfo?.BubbleUp}
                    productType={PRODUCT_TYPE_NUMBER.Animals}
                  />
                ) : (
                  <AddFeeWithParentButton
                    id={parseInt(id)}
                    recordType={RECORDTYPE.Animals_Registration}
                    productType={PRODUCT_TYPE_NUMBER.Animals}
                    titleButton="Fee"
                    isDisabled={
                      !checkPermissions(FormAction.CORE_TOOLBAR_ADD_FEE)
                    }
                  />
                )}
                <AddNoticeWithParentButton
                  id={animalId}
                  recordType={RECORDTYPE.Animals_Registration}
                  bubbleUps={animalInfo?.BubbleUp}
                  productType={PRODUCT_TYPE_NUMBER.Animals}
                  isDisabled={
                    !checkPermissions(FormAction.CORE_TOOLBAR_ADD_NOTICE)
                  }
                />
                <AddRelatedButton
                  id={animalId}
                  recordType={RECORDTYPE.Animals_Registration}
                />
              </CCNavButton>,
              <RegenerateFeeButton />,
              <CCNavButton title="Map" type="sub">
                <ViewOnMapByGISButton
                  objViewOnMapRequest={
                    {
                      ReturnControllName: "GISKey",
                      GISKey: animalInfo?.Address?.AssessmentNo ?? "",
                      ObjAddress: animalInfo?.Address,
                      Module: "",
                      Layer: "",
                      ProductType: ProductType.Animals,
                    } as Svc_ViewOnMap
                  }
                  isInManagePage
                />
              </CCNavButton>,
              <CCNavButton title="Tools" type="more">
                <AnimalSameDetailsButton />
                <AnimalUpdateRegistrationButton />
              </CCNavButton>,
            ]}
            rightActions={[
              <CCNavButton
                title={"Slide Bar"}
                iconClass="fal fa-info-circle"
                isActive={showSlideBar}
                onClick={() => {
                  setShowSlideBar(!showSlideBar);
                }}
              />,

              <ActionBarBookmarkIcon bookmarkList={bookmarkList} />,
            ]}
          />

          <div className="cc-manage-container">
            <div className="cc-manage-left">
              <CCAppNotification />
              <div className="cc-manage-form-body">
                {animalInfo && <AnimalForm />}
                <TabTable
                  id={animalId}
                  recordType={RECORDTYPE.Animals_Registration}
                  initialPanels={listPanelBar}
                  options={{
                    invoiceNumber: relatedAccordionParams?.InvoiceNumber,
                    propertyAssessmentNoKey:
                      relatedAccordionParams?.AssessmentNumberKey,
                    addressLine1: relatedAccordionParams?.AddressLine1,
                    suburb: relatedAccordionParams?.Suburb,
                    personKey: relatedAccordionParams?.PersonKey,
                    schemeKey: relatedAccordionParams?.WasteWaterSchemeKey,
                    refNoKey: relatedAccordionParams?.CsmRefNoKey
                      ? relatedAccordionParams.CsmRefNoKey.toString()
                      : undefined,
                    ancestorRecordId: relatedAccordionParams?.AncestorRecordId,
                    ancestorRecordType:
                      relatedAccordionParams?.AncestorRecordType,
                  }}
                />
              </div>
            </div>

            {showSlideBar && (
              <div className="cc-manage-right">
                <ReferenceSideBar
                  components={[
                    { title: "Details", component: <AnimalDetailsTab /> },
                    {
                      title: "Map",
                      component: (
                        <MapTab
                          address={animalInfo?.Address?.Formatted_SingleLine}
                          zoom={18}
                        />
                      ),
                    },
                    { title: "Contacts", component: <AnimalsContactsTab /> },
                    {
                      title: "History",
                      component: <AnimalsHistoryTab />,
                    },
                  ]}
                />
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
});
