import { EventBatchCloseDialog } from "@app/products/crms/[id]/components/forms/components/action-bar/dialogs/event-bactch-close/_index";
import { EventBatchClose } from "@app/products/crms/[id]/components/forms/components/action-bar/dialogs/event-bactch-close/model";
import { postPhoneMessageBatchClose } from "@app/products/crms/phone-messages/components/action-bar/buttons/close-events/api";
import { PhoneMessageBatchClose } from "@app/products/crms/phone-messages/components/action-bar/buttons/close-events/model";
import { isSuccessIdentityPacket } from "@common/apis/util";
import { IdentityPacketErrorStatusNumber } from "@common/models/sysEnumerations";
import { ActionSubmitActions } from "@common/pages/actions/model";
import { ICCLocalNotificationHandle } from "@components/cc-app-notification/_index";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React from "react";

export interface IPhoneMessageCloseEventsButtonProps {
  isDisabled?: boolean;
}

export const PhoneMessageCloseEventsButton = observer(
  ({ isDisabled = false }: IPhoneMessageCloseEventsButtonProps) => {
    const { gridSelectedIds } = useCCProductListViewStore();
    const { pushNotification, clearNotifications } =
      useCCAppNotificationStore();
    const [phoneMessageBatchClose, setPhoneMessageBatchClose] = React.useState<
      EventBatchClose | undefined
    >(undefined);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    const handleBatchCloseEvent = async (
      data: EventBatchClose,
      notificationRef:
        | React.MutableRefObject<ICCLocalNotificationHandle | null>
        | undefined
    ) => {
      setIsLoading(true);
      const respRequest: PhoneMessageBatchClose = {
        PhoneMessageIds: gridSelectedIds,
        EventBatchClose: data,
      };
      const resp = await postPhoneMessageBatchClose(respRequest);
      if (
        isSuccessIdentityPacket(resp) &&
        resp.data?.ErrorStatus === IdentityPacketErrorStatusNumber.Success
      ) {
        setPhoneMessageBatchClose(undefined);
        setIsLoading(false);
        clearNotifications();
        pushNotification({
          title: "Close event(s) successfully.",
          type: "success",
        });
      } else {
        setIsLoading(false);
        notificationRef?.current?.pushNotification({
          autoClose: false,
          title: "Close event(s) failed.",
          type: "error",
          description: resp.error,
        });
      }
    };

    return (
      <>
        <CCNavButton
          title={"Close Event(s)"}
          onClick={() => {
            setPhoneMessageBatchClose({
              DateClosed: new Date(),
              ClosureComment: "",
              FinalNotification: false,
              Flag_HardRubbish: false,
            });
          }}
          name={ActionSubmitActions.CloseEvent}
          disabled={isLoading || gridSelectedIds.length === 0 || isDisabled}
        />

        {!isNil(phoneMessageBatchClose) && (
          <EventBatchCloseDialog
            eventBatchClose={phoneMessageBatchClose}
            onClosePopup={() => {
              setPhoneMessageBatchClose(undefined);
            }}
            onSubmit={handleBatchCloseEvent}
            isLoading={isLoading}
          />
        )}
      </>
    );
  }
);
