import { ContactsTab } from "@app/core/contacts/components/contact-tab/_index";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React from "react";
import { useParams } from "react-router-dom";

export const PermitTypeContactsTab = observer(() => {
  const { lastSelectedId } = useCCProductListViewStore();
  const { id } = useParams<{ id: string }>();
  const permitTypeId: number = id ? parseInt(id) : lastSelectedId;

  const { dataForm } = useFlexibleFormStore();
  const permitTypeFromStore = dataForm?.GeneralForm;

  return (
    <ContactsTab
      id={permitTypeId}
      recordType={RECORDTYPE.LLP_PermitType}
      triggerReload={JSON.stringify(permitTypeFromStore)}
    />
  );
});
