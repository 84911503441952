import { useTownPlanningStore } from "@app/products/town-planning/[id]/store";
import { getRegionByPostcodeAndSuburb } from "@app/products/town-planning/ppr/[id]/api";
import {
  optionInputPickerApplicationCategoryManagePage,
  optionInputPickerDoTOffice,
  optionInputPickerOfficer,
  optionInputPickerRating,
  optionInputPickerReferralTrigger,
  optionInputPickerResponsibleAuthority,
  optionInputPickerTeamLeader,
  pprDecisionDateMap,
} from "@app/products/town-planning/ppr/[id]/components/child-screens/general/components/form-element/config";
import {
  pprApplicationCategoryDisplay,
  pprProduceContactValue,
  pprProduceRespAuthorityPostalAddressValue,
} from "@app/products/town-planning/ppr/[id]/components/child-screens/general/components/form-element/util";
import { ApplicationDescription } from "@app/products/town-planning/ppr/[id]/components/input-picker/application-description/_index";
import { ContactPicker } from "@app/products/town-planning/ppr/[id]/components/input-picker/contact-picker/_index";
import {
  InputPickerSearch,
  TEventForSubmit,
} from "@app/products/town-planning/ppr/[id]/components/input-picker/input-picker-search/_index";
import { getSiteUser } from "@app/products/town-planning/ppr/[id]/components/input-picker/officer-picker/api";
import { IOfficer } from "@app/products/town-planning/ppr/[id]/components/input-picker/officer-picker/model";
import { PropertyDetail } from "@app/products/town-planning/ppr/[id]/components/input-picker/property-details/_index";
import { AddressValidationType } from "@app/products/town-planning/ppr/[id]/components/input-picker/property-details/complex/components/form-elements/address-validation/model";
import { StateRoadsPickerSearch } from "@app/products/town-planning/ppr/[id]/components/input-picker/state-roads-picker-search/_index";
import { optionInputPickerStateRoads } from "@app/products/town-planning/ppr/[id]/components/input-picker/state-roads-picker-search/config";
import { StateDeclaredRoad } from "@app/products/town-planning/ppr/[id]/components/input-picker/state-roads-picker-search/model";
import { SubdivisionDetail } from "@app/products/town-planning/ppr/[id]/components/input-picker/subdivision-detail/_index";
import { ISubdivisionDetail } from "@app/products/town-planning/ppr/[id]/components/input-picker/subdivision-detail/model";
import {
  Address_BuildAddress,
  Application,
  ApplicationAssessmentPlanner,
  ApplicationUpdateTriggers,
  Application_Decision,
  ContactRelationshipType,
  Council,
  Overlay,
  PPRSubmitActions,
  PROPERTYSYSTEM,
  RECORDSSYSTEM,
  StateRoad,
  TRIMSTORAGEMETHOD,
  Zone,
} from "@app/products/town-planning/ppr/[id]/model";
import { usePPRStore } from "@app/products/town-planning/ppr/[id]/store";
import { Application_Status } from "@app/products/town-planning/ppr/constant";
import { requiredAddressValidation } from "@app/products/town-planning/ppr/enquiries/_id/components/child-screens/general/components/form-element/util";
import { PPRApplicationCategory } from "@app/products/town-planning/ppr/system-admin/application-categories/model";
import {
  Address,
  Contact,
  Contact_Relation,
  SiteUser,
} from "@app/products/waste-water/[id]/model";
import { isSuccessResponse } from "@common/apis/util";
import {
  DATE_FORMAT,
  DECIMAL_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { useIsNew } from "@common/hooks/useIsNew";
import { CoreKeyword } from "@common/models/coreKeyword";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { useCommonProductStore } from "@common/stores/products/store";
import {
  getBoolValueSetting,
  getNumberValueSetting,
} from "@common/stores/products/util";
import {
  requiredValidator,
  requiredValidatorCheckEmptyArray,
} from "@common/utils/field-validators";
import {
  formatDisplayValue,
  getFullAddressHTML,
  getStringDisplayFromArrayObject,
} from "@common/utils/formatting";
import { sanitizeHtml } from "@common/utils/sanitized-parser";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCCurrencyInput } from "@components/cc-currency-input/_index";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { ICCInputPickerChangeEvent } from "@components/cc-input-picker/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCNumericTextBox } from "@components/cc-numeric-text-box/_index";
import { CCPanelsBar } from "@components/cc-panels-bar/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { CCValueField } from "@components/cc-value-field/_index";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import {
  Field,
  FieldProps,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { InputChangeEvent } from "@progress/kendo-react-inputs";
import { addDays } from "date-fns";
import { isArray, isEmpty, isNil, isNull, isString } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useCallback, useMemo } from "react";
import { useEffectOnce } from "react-use";

interface IPPRFormElementProps {
  formRenderProps: FormRenderProps;
}

export const PPRFormElement = observer(
  ({ formRenderProps }: IPPRFormElementProps) => {
    //#region STORE ========/
    const { submitFormGetData } = useFlexibleFormStore();
    const { loadZoneAndOverlayKeywords } = useTownPlanningStore();
    const { pushNotification } = useCCAppNotificationStore();
    const { settings } = useCommonProductStore();
    const { ppr, isInactive, savePPR } = usePPRStore();
    //#endregion STORE =====/

    //#region HOOK ========/
    const isNew = useIsNew();
    //#endregion HOOK =====/

    const { valueGetter, onChange } = formRenderProps;

    //Get settings section
    const isStreetTypeEnabled = getBoolValueSetting(
      settings[ECorporateSettingsField.TP_DisplayPropertyZoneOverlay]
    );
    const propertySystem = getNumberValueSetting(
      settings[ECorporateSettingsField.CorporateSettings_PropertySystem]
    );
    const addressValidationType = getNumberValueSetting(
      settings[ECorporateSettingsField.CorporateSettings_AddressValidation]
    );
    const propertiesAutoPopup = getBoolValueSetting(
      settings[
        ECorporateSettingsField.CorporateSettings_PropertyAutoPopupDialog
      ]
    );
    const recordsSystem = getNumberValueSetting(
      settings[ECorporateSettingsField.CorporateSettings_RecordsSystem]?.Value
    );
    const trimStorageMethod = getNumberValueSetting(
      settings[ECorporateSettingsField.TRIMAdmin_TownPlanningStorageMethod]
    );
    const setFileNoSetting = getBoolValueSetting(
      settings[
        ECorporateSettingsField.CorporateSettings_RecordsSetFileNoWithPropertyNo
      ]
    );
    const propertyNoMask =
      settings[ECorporateSettingsField.Records_PropertyNoMask]?.Value;
    const customLabelForTL =
      settings[ECorporateSettingsField.TP_PPRLabelForTL]?.Value ??
      "Delegated approver";

    //Get Values
    const application_ID = valueGetter("Application_ID");
    const flagPlansToComply = valueGetter("Flag_PlansToComply");
    const ldAllotments = valueGetter("LD_Allotments");
    const statusEnum = valueGetter("Status_ENUM");
    const flagPADetailsFilled = valueGetter(
      "PPRDetails.Flag_ProposedApplicationDetailsFilled"
    );
    const applicant: Contact_Relation = valueGetter("Applicant");
    const siteAddress: Address = valueGetter("SiteAddress");
    const respAuthority: Council = valueGetter(
      "PPRDetails.ResponsibleAuthority"
    );
    const councilName: string = valueGetter(
      "PPRDetails.ResponsibleAuthority.CouncilName"
    );
    const assessmentPlanner: ApplicationAssessmentPlanner =
      valueGetter("AssessmentPlanner");
    const decisionDetails: Application_Decision | null | undefined =
      valueGetter("Decision_ENUM");
    const respAuthorityCouncilContact: Contact =
      respAuthority?.CouncilDetails?.Contact;
    const applicantContact = applicant?.Contact;

    //Handle logics section
    const displayRespAuthorityCouncilAddress = useMemo(() => {
      //TODO: Re-check this function
      return getFullAddressHTML(respAuthorityCouncilContact?.PreferredAddress);
    }, [respAuthorityCouncilContact]);

    const displayRespAuthorityPostalAddress = useMemo(() => {
      return pprProduceRespAuthorityPostalAddressValue(
        respAuthorityCouncilContact
      );
    }, [respAuthorityCouncilContact]);

    const displayApplicantContact = useMemo(() => {
      return pprProduceContactValue(applicantContact);
    }, [applicantContact]);

    const displayApplicantPostalAddress = useMemo(() => {
      return getFullAddressHTML(applicantContact?.PostalAddress);
    }, [applicantContact]);

    const displaySiteAddress = useMemo(() => {
      if (siteAddress) {
        return getFullAddressHTML(siteAddress);
      } else return "";
    }, [siteAddress]);

    const displayZone = useMemo(() => {
      let retval = "";
      if (assessmentPlanner) {
        retval =
          getStringDisplayFromArrayObject(
            assessmentPlanner?.AssessmentPlanner_Zones,
            "Zone_Name"
          ) ?? "";
      }
      if (
        isStreetTypeEnabled &&
        siteAddress &&
        siteAddress?.PropertyAssessment?.Zones
      ) {
        retval = siteAddress?.PropertyAssessment?.Zones;
      }
      return retval;
    }, [assessmentPlanner, isStreetTypeEnabled, siteAddress]);

    const displayOverlay = useMemo(() => {
      let retval = "";
      if (assessmentPlanner) {
        retval =
          getStringDisplayFromArrayObject(
            assessmentPlanner?.AssessmentPlanner_Overlays,
            "Overlay_Name"
          ) ?? "";
      }
      if (isStreetTypeEnabled && siteAddress?.PropertyAssessment?.Overlay) {
        retval = siteAddress?.PropertyAssessment?.Overlay;
      }
      return retval;
    }, [assessmentPlanner, isStreetTypeEnabled, siteAddress]);

    const PPRField = useCallback(
      (props: FieldProps) => {
        const { disabled, ...others } = props;
        return (
          <Field {...others} disabled={isInactive ? isInactive : disabled} />
        );
      },
      [isInactive]
    );

    useEffectOnce(() => {
      loadZoneAndOverlayKeywords();
    });

    const isShowPADetailsFilledSwitch = [
      Application_Status.AssessedByPlanner,
      Application_Status.PreliminaryAssessment,
    ].includes(statusEnum);

    const decisionDateTooltip: string | null | undefined = decisionDetails
      ? pprDecisionDateMap[decisionDetails]
      : null;

    const calculateReferralTriggerResponseDays = () => {
      const referralTriggerNoOfDays = valueGetter(
        "PPRDetails.Referraltrigger_NoOfDays"
      );
      if (referralTriggerNoOfDays) {
        if (isEmpty(ppr?.RevisionReference)) {
          if (ppr?.LodgedDate) {
            onChange("PPRDetails.Referraltrigger_ResponseDue", {
              value: addDays(ppr?.LodgedDate, referralTriggerNoOfDays),
            });
          } else {
            onChange("PPRDetails.Referraltrigger_ResponseDue", {
              value: addDays(new Date(), referralTriggerNoOfDays),
            });
          }
        } else if (ppr?.RevisionDate) {
          onChange("PPRDetails.Referraltrigger_ResponseDue", {
            value: addDays(ppr?.RevisionDate, referralTriggerNoOfDays),
          });
        }
      }
    };

    const updateSaveTriggers = (triggers: ApplicationUpdateTriggers) => {
      let saveTriggers: ApplicationUpdateTriggers[] =
        valueGetter("SaveTriggers") ?? [];

      if (!Array.isArray(saveTriggers)) saveTriggers = [];

      if (!saveTriggers?.some((item) => item === triggers)) {
        saveTriggers?.push(triggers);
        onChange("SaveTriggers", {
          value: saveTriggers,
        });
      }
    };

    /* ====  SiteUserHandle-Function is called when clicking "OK" button in Property Details dialog ==== */
    const handleSiteAddressChange = (value: any) => {
      const objBuildAddress: Address_BuildAddress | undefined = value;
      if (
        propertiesAutoPopup &&
        propertySystem !== PROPERTYSYSTEM.None &&
        addressValidationType === AddressValidationType.DELWPMapShare
      ) {
        if (
          objBuildAddress?.Address
          //TODO: will check the logic later
          // && !isEmpty(objBuildAddress?.Address?.Mapshare_Magickey)
        ) {
          onChange("SiteAddress", { value: value.Address });
          updateSaveTriggers(ApplicationUpdateTriggers.UpdateSiteAddress);
          //override global setting from each system if TRIM
          let setFileNo = setFileNoSetting;
          if (
            recordsSystem &&
            recordsSystem === RECORDSSYSTEM.TRIMContext &&
            trimStorageMethod &&
            trimStorageMethod !== TRIMSTORAGEMETHOD.PROPERTYNUMBER
          ) {
            setFileNo = false;
          }
          if (
            recordsSystem &&
            recordsSystem === RECORDSSYSTEM.TRIMContext &&
            trimStorageMethod &&
            trimStorageMethod === TRIMSTORAGEMETHOD.PROPERTYNUMBER
          ) {
            setFileNo = true;
          }

          if (setFileNo) {
            let propertyNumber = siteAddress?.AssessmentNo;
            if (
              !isEmpty(propertyNoMask) &&
              propertyNoMask.includes("[PROPERTYNUMBER]")
            )
              propertyNumber = propertyNoMask.replace(
                "[PROPERTYNUMBER]",
                propertyNumber
              );
            onChange("FileNo", { value: propertyNumber });
          }
          if (isArray(value.ZoneList)) {
            const newZone = value.ZoneList?.map((item: CoreKeyword) => {
              return {
                Application_ID: application_ID,
                Zone_KWD: item.Keyword_ID,
                Zone_Name: item.Name,
              };
            });
            onChange("AssessmentPlanner.AssessmentPlanner_Zones", {
              value: newZone,
            });
            updateSaveTriggers(
              ApplicationUpdateTriggers.UpdateAssessmentPlanner
            );
          }

          if (isArray(value.OverlayList)) {
            const newOverlay = value.OverlayList?.map((item: CoreKeyword) => {
              return {
                Application_ID: application_ID,
                Overlay_KWD: item.Keyword_ID,
                Overlay_Name: item.Name,
              };
            });
            onChange("AssessmentPlanner.AssessmentPlanner_Overlays", {
              value: newOverlay,
            });
            updateSaveTriggers(
              ApplicationUpdateTriggers.UpdateAssessmentPlanner
            );
          }
          if (objBuildAddress?.Address && objBuildAddress?.Address?.Suburb) {
            getRegionByPostcodeAndSuburb(
              objBuildAddress.Address.Suburb,
              objBuildAddress.Address.Postcode
            ).then((response) => {
              if (isSuccessResponse(response)) {
                onChange("PPRDetails.VROffice", { value: response.data });
                if (!isNull(response.data)) {
                  onChange("PPRDetails.VR_OfficeID", {
                    value: response.data?.Region_ID,
                  });
                }
              }
            });
          }
        }
      } else {
        onChange("SiteAddress", { value: value.Address });
        updateSaveTriggers(ApplicationUpdateTriggers.UpdateSiteAddress);

        //override global setting from each system if TRIM
        let setFileNo = setFileNoSetting;
        if (
          recordsSystem &&
          recordsSystem === RECORDSSYSTEM.TRIMContext &&
          trimStorageMethod &&
          trimStorageMethod !== TRIMSTORAGEMETHOD.PROPERTYNUMBER
        ) {
          setFileNo = false;
        }
        if (
          recordsSystem &&
          recordsSystem === RECORDSSYSTEM.TRIMContext &&
          trimStorageMethod &&
          trimStorageMethod === TRIMSTORAGEMETHOD.PROPERTYNUMBER
        ) {
          setFileNo = true;
        }

        if (setFileNo) {
          let propertyNumber = siteAddress?.AssessmentNo;
          if (
            !isEmpty(propertyNoMask) &&
            propertyNoMask.includes("[PROPERTYNUMBER]")
          )
            propertyNumber = propertyNoMask.replace(
              "[PROPERTYNUMBER]",
              propertyNumber
            );
          onChange("FileNo", { value: propertyNumber });
        }
        if (isArray(value.ZoneList)) {
          const newZone = value.ZoneList?.map((item: CoreKeyword) => {
            return {
              Application_ID: application_ID,
              Zone_KWD: item.Keyword_ID,
              Zone_Name: item.Name,
            };
          });
          onChange("AssessmentPlanner.AssessmentPlanner_Zones", {
            value: newZone,
          });
          updateSaveTriggers(ApplicationUpdateTriggers.UpdateAssessmentPlanner);
        }

        if (isArray(value.OverlayList)) {
          const newOverlay = value.OverlayList?.map((item: CoreKeyword) => {
            return {
              Application_ID: application_ID,
              Overlay_KWD: item.Keyword_ID,
              Overlay_Name: item.Name,
            };
          });
          onChange("AssessmentPlanner.AssessmentPlanner_Overlays", {
            value: newOverlay,
          });
          updateSaveTriggers(ApplicationUpdateTriggers.UpdateAssessmentPlanner);
        }
        if (objBuildAddress?.Address && objBuildAddress?.Address?.Suburb) {
          getRegionByPostcodeAndSuburb(
            objBuildAddress.Address.Suburb,
            objBuildAddress.Address.Postcode
          ).then((response) => {
            if (isSuccessResponse(response)) {
              onChange("PPRDetails.VROffice", { value: response.data });
              if (!isNull(response.data)) {
                onChange("PPRDetails.VR_OfficeID", {
                  value: response.data?.Region_ID,
                });
              }
            }
          });
        }
      }
    };
    /* ====  / SiteUserHandle-Function is called when clicking "OK" button in Property Details dialog ==== */

    const handleOnChangeApplicant = (event: ComboBoxChangeEvent) => {
      const value = event.value;
      const currentApplication = valueGetter("Applicant");
      onChange("Applicant_RID", { value: null });
      onChange("Applicant", {
        value: {
          ContactRelationshipType_ENUM:
            currentApplication?.ContactRelationshipType_ENUM ??
            ContactRelationshipType.Applicant,
          Contact: value,
        },
      });
    };

    const handleOnChangeSubdivision = (event: ICCInputPickerChangeEvent) => {
      const subdivisionDetail: ISubdivisionDetail = event.value;
      onChange("LD_Allotments", {
        value: subdivisionDetail?.LD_Allotments,
      });
      onChange("LD_Certified", {
        value: subdivisionDetail?.LD_Certified,
      });
      onChange("LD_StatementOfCompliance", {
        value: subdivisionDetail?.LD_StatementOfCompliance,
      });
    };

    const handleOnChangeAppCategory = (
      data: PPRApplicationCategory[] | null
    ) => {
      onChange("ApplicationCategories", {
        value: data,
      });
      onChange("ApplicationCategories_Display", {
        value: pprApplicationCategoryDisplay(
          valueGetter("ApplicationCategories")
        ),
      });
      onChange("ApplicationCategories_Namelist", {
        value: data?.map(
          (item: PPRApplicationCategory) => item.ApplicationCategory_Name
        ),
      });
      onChange("ApplicationCategoriesIDs", {
        value: data?.map((item: PPRApplicationCategory) => item.ID),
      });
      //TODO: RecalculateTargetDate
      updateSaveTriggers(ApplicationUpdateTriggers.UpdateApplicationCategory);
    };

    const handleOnChangeReferralTrigger = (data: CoreKeyword) => {
      onChange("PPRDetails.ReferralTrigger.Name", {
        value: data?.Name,
      });
      onChange("PPRDetails.Referraltrigger_NoOfDays", {
        value: data?.TargetDays,
      });
      // 11005 clear Response due if Number of days is empty
      if (!data?.TargetDays) {
        onChange("PPRDetails.Referraltrigger_ResponseDue", {
          value: null,
        });
      }
      onChange("PPRDetails.Referraltrigger_KWD", {
        value: data?.Keyword_ID,
      });
      calculateReferralTriggerResponseDays();
    };

    const handleOnPickOfficerForTeamLeader = (value: IOfficer | null) => {
      if (valueGetter("Planner_ID") && valueGetter("Planner_ID") === value?.ID)
        pushNotification({
          title: "Officer cannot be the delegated approver",
          type: "warning",
        });
      else {
        onChange("PPRDetails.TeamLeader_ID", { value: value?.ID });
        if (!value) {
          onChange("PPRDetails.TeamLeader", {
            value: null,
          });
          onChange("PPRDetails.TeamLeader.DisplayName", {
            value: null,
          });
        } else {
          getSiteUser(value.ID).then((response) => {
            if (isSuccessResponse(response)) {
              if (response.data) {
                onChange("PPRDetails.TeamLeader", {
                  value: response.data,
                });
              }
            } else {
              pushNotification({
                title: "Officer is not found",
                type: "error",
                autoClose: false,
              });
            }
          });
        }
      }
    };

    const handleOnPickOfficerForOfficer = (
      value: SiteUser | null,
      eventForSubmit: TEventForSubmit
    ) => {
      submitFormGetData("GeneralForm", eventForSubmit).then((dataForm) => {
        const application = dataForm?.GeneralForm as Application;
        !isNew &&
          savePPR(application, PPRSubmitActions.ChangePickOfficer, false);
      });
      if (
        valueGetter("PPRDetails.TeamLeader_ID") &&
        valueGetter("PPRDetails.TeamLeader_ID") === value?.Contact_ID
      )
        pushNotification({
          title: "Officer cannot be the delegated approver",
          type: "warning",
        });
      else {
        onChange("Planner_ID", { value: value?.Contact_ID ?? null });
        onChange("Planner", { value: value?.DisplayName ?? null });
      }
    };

    const handleOnChangeReasonForPermit = (
      event: ICCInputPickerChangeEvent
    ) => {
      onChange("ReasonForPermit", {
        value: event.value.ReasonForPermit,
      });
      onChange("Flag_IsSecondaryConsent", {
        value: event.value.Flag_IsSecondaryConsent,
      });
      onChange("LongDescription", {
        value: event.value.LongDescription,
      });
      onChange("EPlanning_ExistingConditions", {
        value: event.value.EPlanning_ExistingConditions,
      });
      onChange("EPlanning_ProposalDescription", {
        value: event.value.EPlanning_ProposalDescription,
      });
    };
    const currentApplication: Application = valueGetter("");

    /* ====  SiteUserHandle-Set initial values  ==== */
    const siteAddressInitData = useMemo(() => {
      if (!currentApplication) return undefined;
      let result: any = {
        Address: siteAddress,
        _option: {
          checkComplexAddress: false,
          LocationDescriptor:
            siteAddress?.PropertyAssessment?.AddressLocationDescriptor,
        },
      };
      if (siteAddress) {
        //Set zones
        result.ZoneList = assessmentPlanner?.AssessmentPlanner_Zones?.map(
          (zone: Zone): CoreKeyword => {
            return {
              Keyword_ID: zone.Zone_KWD,
              Name: zone.Zone_Name,
              Description: "",
            };
          }
        );
        result.Zone_Display = assessmentPlanner?.AssessmentPlanner_Zones?.map(
          (zone: Zone): CoreKeyword => {
            return {
              Keyword_ID: zone.Zone_KWD,
              Name: zone.Zone_Name,
              Description: "",
            };
          }
        );

        //Set overlays
        result.OverlayList = assessmentPlanner?.AssessmentPlanner_Overlays?.map(
          (overlay: Overlay): CoreKeyword => {
            return {
              Keyword_ID: overlay.Overlay_KWD,
              Name: overlay.Overlay_Name,
              Description: "",
            };
          }
        );

        result.Overlay_Display =
          assessmentPlanner?.AssessmentPlanner_Overlays?.map(
            (overlay: Overlay): CoreKeyword => {
              return {
                Keyword_ID: overlay.Overlay_KWD,
                Name: overlay.Overlay_Name,
                Description: "",
              };
            }
          );
      }

      return result;
    }, [currentApplication, assessmentPlanner, siteAddress]);
    /* ====  / SiteUserHandle-Set initial values  ==== */

    return (
      <FormElement
        className="cc-ppr-form-elements"
        id="cc-form-manage-page-general"
      >
        <section className="cc-field-group">
          {!flagPlansToComply && (
            <>
              <div className="cc-form-cols-3">
                <div className="cc-field">
                  <label className="cc-label">
                    Responsible authority
                    <CCTooltip type="validator" position="right" />
                  </label>
                  <PPRField
                    name="PPRDetails.ResponsibleAuthority"
                    nameDisplay="CouncilName"
                    placeholder="Responsible authority"
                    component={InputPickerSearch}
                    validator={requiredValidator}
                    onChange={(data: Council) => {
                      onChange("PPRDetails.ResponsibleAuthority", {
                        value: data,
                      });
                      onChange("PPRDetails.ResponsibleAuthority_ID", {
                        value: data?.Council_ID,
                      });
                      if (!valueGetter("PPRDetails.PlanScheme")) {
                        onChange("PPRDetails.PlanScheme", {
                          value: valueGetter(
                            "PPRDetails.ResponsibleAuthority.CouncilName"
                          ),
                        });
                      }
                    }}
                    onError={(error: any) => {
                      pushNotification({
                        title: error,
                        type: "warning",
                      });
                    }}
                    options={optionInputPickerResponsibleAuthority}
                  />
                </div>

                <CCValueField
                  label="Council address"
                  value={sanitizeHtml(displayRespAuthorityCouncilAddress)}
                  tooltip={<CCTooltip type="validator" position="right" />}
                />
                <CCValueField
                  label="Postal address"
                  value={displayRespAuthorityPostalAddress}
                />
              </div>
              <div className="cc-form-cols-3">
                <div className="cc-field">
                  <label className="cc-label">
                    Council officer
                    <CCTooltip type="validator" position="right" />
                  </label>
                  <PPRField
                    name={"PPRDetails.Council_Officer"}
                    placeholder={"Council officer"}
                    component={CCInput}
                    validator={requiredValidator}
                  />
                </div>
                <CCValueField
                  label="Council email"
                  value={valueGetter(
                    "PPRDetails.ResponsibleAuthority.Officer.Contact.Email"
                  )}
                  tooltip={<CCTooltip type="validator" position="right" />}
                />
              </div>
            </>
          )}
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">
                Applicant
                <CCTooltip type="validator" position="right" />
              </label>
              <PPRField
                name={"Applicant.Contact"}
                component={ContactPicker}
                validator={requiredValidator}
                onError={(error: any) => {
                  pushNotification({
                    type: "error",
                    title: "Pick contact errors",
                    description: error,
                    autoClose: false,
                  });
                }}
                placeholder={"Applicant"}
                onChange={handleOnChangeApplicant}
              />
            </div>
            <CCValueField
              label={"Postal address"}
              value={displayApplicantPostalAddress}
            />
            <CCValueField label={"Contact"} value={displayApplicantContact} />
          </div>
          <div className="cc-form-cols-3">
            {!flagPlansToComply && (
              <>
                {/* Please dont remove this field, this can be added to the application again when customer request */}
                {/* <div className="cc-field">
                  <label className="cc-label">Planing number</label>
                  <PPRField
                    name={"BuildingDetails.PlanningPermitNo"}
                    placeholder={"Planing number"}
                    component={CCInput}
                  />
                </div> */}
                <div className="cc-field">
                  <label className="cc-label">
                    Council reference number
                    <CCTooltip type="validator" position="right" />
                  </label>
                  <PPRField
                    name={"PPRDetails.CouncilReferenceNo"}
                    placeholder={"Council reference number"}
                    component={CCInput}
                    validator={requiredValidator}
                  />
                </div>
              </>
            )}
            <div className="cc-field">
              <label className="cc-label">Planning scheme</label>
              <PPRField
                name={"PPRDetails.PlanScheme"}
                placeholder={"Planning scheme"}
                component={CCInput}
              />
            </div>
          </div>
          <div className="cc-form-cols-3">
            {!flagPlansToComply && (
              <div className="cc-field">
                <label className="cc-label">
                  Cost of works <CCTooltip type="validator" position="right" />
                </label>
                <PPRField
                  name={"CostOfWorks"}
                  validator={requiredValidator}
                  component={CCCurrencyInput}
                />
              </div>
            )}
            <div className="cc-field">
              <label className="cc-label">SPEAR reference</label>
              <PPRField
                name={"PPRDetails.SPEARReference"}
                placeholder={"SPEAR reference"}
                component={CCInput}
              />
            </div>
            {flagPlansToComply && (
              <div className="cc-field">
                <label className="cc-label">Number of lots</label>
                <PPRField
                  name={"PPRDetails.ProposedNoOfLots"}
                  placeholder={"Number of lots"}
                  component={CCNumericTextBox}
                />
              </div>
            )}
          </div>
        </section>
        <hr className="cc-divider" />
        <section className="cc-field-group">
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">
                Site address <CCTooltip type="validator" position="right" />
                {!isNil(siteAddress?.VMAS_Verified) && (
                  <div
                    className={`fa fa-home ${
                      siteAddress?.VMAS_Verified ? "text-success" : "text-error"
                    }`}
                  />
                )}
              </label>
              <PPRField
                name={"SiteAddress"}
                placeholder={"Site address"}
                component={PropertyDetail}
                validator={requiredAddressValidation}
                onSubmit={handleSiteAddressChange}
                value={displaySiteAddress}
                initialData={siteAddressInitData}
                formRenderProps={formRenderProps}
                updateSaveTriggers={() => {
                  updateSaveTriggers(
                    ApplicationUpdateTriggers.UpdateSiteAddress
                  );
                }}
              />
            </div>
            {!flagPlansToComply && (
              <>
                <div className="cc-field">
                  <label className="cc-label">Subdivision</label>
                  <PPRField
                    name={"LD_Allotments"}
                    value={valueGetter("")}
                    placeholder={"Subdivision"}
                    component={SubdivisionDetail}
                    onChange={handleOnChangeSubdivision}
                  />
                </div>

                <div className="cc-field">
                  <label className="cc-label">
                    Application category
                    <CCTooltip type="validator" position="right" />
                  </label>
                  <PPRField
                    name="ApplicationCategories"
                    nameDisplayMerge="ApplicationCategory_Name"
                    component={InputPickerSearch}
                    placeholder="Application category"
                    validator={requiredValidatorCheckEmptyArray}
                    onChange={handleOnChangeAppCategory}
                    options={optionInputPickerApplicationCategoryManagePage}
                  />
                </div>
              </>
            )}
          </div>
          <div className="cc-form-cols-3">
            <CCValueField label="Zone" value={displayZone} />
            <CCValueField label="Overlay" value={displayOverlay} />
            <div className="cc-field">
              <label className="cc-label">Estate</label>
              <PPRField
                name={"LD_EstateName"}
                placeholder={"Estate"}
                component={CCInput}
              />
            </div>
          </div>
        </section>
        <br />
        <section className="cc-field-group">
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">
                Declared arterial
                <CCTooltip type="validator" position="right" />
              </label>
              <PPRField
                //must be required
                uniqueKey={"PPR-VRStreet"}
                name={"PPRDetails.VRStreet"}
                placeholder={"Declared arterial"}
                component={StateRoadsPickerSearch}
                onChange={(value: StateDeclaredRoad) => {
                  const newValue: StateDeclaredRoad = { ...value };
                  if (value) {
                    onChange("PPRDetails.VRStreetID", {
                      value: value.StateRoad_ID ?? value.ID,
                    });
                  }
                  onChange("PPRDetails.VRStreet", {
                    value: newValue,
                  });
                }}
                currentCouncil={councilName}
                validator={requiredValidator}
                options={optionInputPickerStateRoads}
                textProduce={(value: StateRoad) => {
                  return !isEmpty(value?.RoadName)
                    ? `<span>${value?.RoadName}${
                        value?.RoadIDNo
                          ? `&nbsp<span class='text-error' style='display:inline-block;text-decoration:none;'>${value?.RoadIDNo}`
                          : ""
                      }  </span></span>`
                    : "</span class='text-disabled'>Declared arterial</span>";
                }}
                onError={() => {
                  pushNotification({
                    title: "State road is not found",
                    type: "warning",
                  });
                }}
              />
            </div>

            {!flagPlansToComply && (
              <div className="cc-field">
                <label className="cc-label">
                  Rating
                  <CCTooltip type="validator" position="right" />
                </label>
                <PPRField
                  name="Rating_Name"
                  placeholder="Rating"
                  component={InputPickerSearch}
                  validator={requiredValidator}
                  onChange={(data: CoreKeyword) => {
                    onChange("Rating_Name", {
                      value: data?.Name,
                    });
                    onChange("Rating_KWD", {
                      value: data?.Keyword_ID,
                    });
                  }}
                  options={optionInputPickerRating}
                  onError={(error: any) => {
                    pushNotification({
                      title: error,
                      type: "error",
                      autoClose: false,
                    });
                  }}
                />
              </div>
            )}
            <div className="cc-field">
              <label className="cc-label">File number</label>
              <PPRField
                name={"FileNumber"}
                placeholder={"File number"}
                component={CCInput}
              />
            </div>
          </div>
          {/* TODO: Need update more logic */}
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">
                DTP office
                <CCTooltip type="validator" position="right" />
              </label>
              <PPRField
                name="PPRDetails.VROfficeName"
                placeholder="DTP office"
                component={InputPickerSearch}
                validator={requiredValidator}
                onChange={(data: CoreKeyword) => {
                  onChange("PPRDetails.VROfficeName", {
                    value: data?.Name,
                  });
                  onChange("PPRDetails.VR_OfficeID", {
                    value: data?.Keyword_ID,
                  });
                }}
                options={optionInputPickerDoTOffice}
                onError={(error: any) => {
                  pushNotification({
                    title: error,
                    type: "warning",
                  });
                }}
              />
            </div>

            <div className="cc-field">
              <label className="cc-label">
                {customLabelForTL}
                <CCTooltip type="validator" position="right" />
              </label>
              <PPRField
                name={"PPRDetails.TeamLeader.DisplayName"}
                placeholder={customLabelForTL}
                component={InputPickerSearch}
                validator={requiredValidator}
                onChange={handleOnPickOfficerForTeamLeader}
                options={optionInputPickerTeamLeader}
              />
            </div>

            <div className="cc-field">
              <label className="cc-label">Officer</label>
              <PPRField
                name="Planner"
                placeholder="Officer"
                component={InputPickerSearch}
                onChange={handleOnPickOfficerForOfficer}
                options={optionInputPickerOfficer}
                onError={(error: any) => {
                  pushNotification({
                    title: error,
                    type: "error",
                    autoClose: false,
                  });
                }}
              />
            </div>
          </div>
          {ldAllotments && (
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <label className="cc-label">DoT certified</label>
                <PPRField
                  name={"LD_Certified"}
                  component={CCDatePicker}
                  format={DATE_FORMAT.DATE_CONTROL}
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">DoT stat of comp</label>
                <PPRField
                  name={"LD_StatementOfCompliance"}
                  component={CCDatePicker}
                  format={DATE_FORMAT.DATE_CONTROL}
                />
              </div>
            </div>
          )}
        </section>
        <br />
        {/* TODO: Need re-check then "Officer Assessment" complete */}
        {isShowPADetailsFilledSwitch && (
          <section className="cc-field-group">
            <div className="cc-form-cols-1">
              <div className="cc-field">
                <label className="cc-label">
                  Proposed application details not required
                </label>
                <PPRField
                  name={"PPRDetails.Flag_ProposedApplicationDetailsFilled"}
                  component={CCSwitch}
                  checked={flagPADetailsFilled}
                />
              </div>
            </div>
            {!flagPADetailsFilled && (
              <div className={"cc-custom-sub-panel-bar"}>
                <CCPanelsBar
                  initialPanels={[
                    {
                      title: `Application Details - Proposed`,
                      component: (
                        <section className="cc-field-group">
                          <div className="cc-form-cols-3">
                            <div className="cc-field">
                              <label className="cc-label">
                                Additional dwellings
                                <CCTooltip type="validator" position="right" />
                              </label>
                              <PPRField
                                name={
                                  "PPRDetails.TotalNumberOfAdditionalDwellings_Proposed"
                                }
                                component={CCNumericTextBox}
                                format={NUMBER_FORMAT.NUMBER2}
                                placeholder={"Additional dwellings"}
                                validator={requiredValidator}
                              />
                            </div>
                            <div className="cc-field">
                              <label className="cc-label">
                                Additional bicycle spaces
                              </label>
                              <PPRField
                                name={
                                  "PPRDetails.TotalNumberOfAdditionalBicycleSpaces_Proposed"
                                }
                                component={CCNumericTextBox}
                                format={NUMBER_FORMAT.NUMBER2}
                                placeholder={"Additional bicycle spaces"}
                              />
                            </div>
                            <div className="cc-field">
                              <label className="cc-label">
                                Number of showers
                              </label>
                              <PPRField
                                name={
                                  "PPRDetails.TotalNumberOfShowers_Proposed"
                                }
                                component={CCNumericTextBox}
                                format={NUMBER_FORMAT.NUMBER2}
                                placeholder={"Number of showers"}
                              />
                            </div>
                          </div>
                          <div className="cc-form-cols-3">
                            <div className="cc-field">
                              <label className="cc-label">
                                Additional car parks
                              </label>
                              <PPRField
                                name={
                                  "PPRDetails.TotalNumberOfAdditionalCarParks_Proposed"
                                }
                                component={CCNumericTextBox}
                                format={NUMBER_FORMAT.NUMBER2}
                                placeholder={"Additional car parks"}
                              />
                            </div>
                            <div className="cc-field">
                              <label className="cc-label">
                                Additional crossovers
                              </label>
                              <PPRField
                                name={
                                  "PPRDetails.TotalNumberOfAdditionalCrossovers_Proposed"
                                }
                                component={CCNumericTextBox}
                                format={NUMBER_FORMAT.NUMBER2}
                                placeholder={"Additional crossovers"}
                              />
                            </div>
                            <div className="cc-field">
                              <label className="cc-label">
                                Additional commercial space
                              </label>
                              <PPRField
                                name={
                                  "PPRDetails.TotalAdditionalFloorSpace_Commercial_Proposed"
                                }
                                component={CCNumericTextBox}
                                format={DECIMAL_FORMAT.DECIMAL_2_DIGIT}
                                placeholder={"Additional commercial space"}
                              />
                            </div>
                          </div>
                          <div className="cc-form-cols-3">
                            <div className="cc-field">
                              <label className="cc-label">
                                Additional retail space
                              </label>
                              <PPRField
                                name={
                                  "PPRDetails.TotalAdditionalFloorSpace_Retail_Proposed"
                                }
                                component={CCNumericTextBox}
                                format={DECIMAL_FORMAT.DECIMAL_2_DIGIT}
                                placeholder={"Additional retail space"}
                              />
                            </div>
                            <div className="cc-field">
                              <label className="cc-label">
                                Additional industrial space
                              </label>
                              <PPRField
                                name={
                                  "PPRDetails.TotalAdditionalFloorSpace_Industrial_Proposed"
                                }
                                component={CCNumericTextBox}
                                format={DECIMAL_FORMAT.DECIMAL_2_DIGIT}
                                placeholder={"Additional industrial space"}
                              />
                            </div>
                            <div className="cc-field">
                              <label className="cc-label">
                                Additional government space
                              </label>
                              <PPRField
                                name={
                                  "PPRDetails.TotalAdditionalFloorSpace_Government_Proposed"
                                }
                                component={CCNumericTextBox}
                                format={DECIMAL_FORMAT.DECIMAL_2_DIGIT}
                                placeholder={"Additional government space"}
                              />
                            </div>
                          </div>
                          <div className="cc-form-cols-3">
                            <div className="cc-field">
                              <label className="cc-label">
                                Additional education space
                              </label>
                              <PPRField
                                name={
                                  "PPRDetails.TotalAdditionalFloorSpace_Education_Proposed"
                                }
                                component={CCNumericTextBox}
                                format={DECIMAL_FORMAT.DECIMAL_2_DIGIT}
                                placeholder={"Additional education space"}
                              />
                            </div>
                            <div className="cc-field">
                              <label className="cc-label">
                                Additional health space
                              </label>
                              <PPRField
                                name={
                                  "PPRDetails.TotalAdditionalFloorSpace_Health_Proposed"
                                }
                                component={CCNumericTextBox}
                                format={DECIMAL_FORMAT.DECIMAL_2_DIGIT}
                                placeholder={"Additional health space"}
                              />
                            </div>
                            <div className="cc-field">
                              <label className="cc-label">
                                Additional other space
                              </label>
                              <PPRField
                                name={
                                  "PPRDetails.TotalAdditionalFloorSpace_Other_Proposed"
                                }
                                component={CCNumericTextBox}
                                format={DECIMAL_FORMAT.DECIMAL_2_DIGIT}
                                placeholder={"Additional other space"}
                              />
                            </div>
                          </div>
                        </section>
                      ),
                      isExpanded: true,
                    },
                  ]}
                />
              </div>
            )}
          </section>
        )}
        <br />
        {!flagPlansToComply && (
          <section className="cc-field-group">
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <label className="cc-label">
                  Referral trigger
                  <CCTooltip type="validator" position="right" />
                </label>
                <PPRField
                  name="PPRDetails.ReferralTrigger.Name"
                  placeholder="Referral trigger"
                  component={InputPickerSearch}
                  validator={requiredValidator}
                  onChange={handleOnChangeReferralTrigger}
                  options={optionInputPickerReferralTrigger}
                  onError={(error: any) => {
                    pushNotification({
                      title: error,
                      type: "warning",
                    });
                  }}
                />
              </div>

              <CCValueField
                label="Number of days"
                value={valueGetter("PPRDetails.Referraltrigger_NoOfDays")}
              />
              <CCValueField
                label="Response due"
                value={
                  valueGetter("PPRDetails.Referraltrigger_ResponseDue")
                    ? formatDisplayValue(
                        new Date(
                          valueGetter("PPRDetails.Referraltrigger_ResponseDue")
                        ),
                        DATE_FORMAT.DATE
                      )
                    : ""
                }
              />
            </div>
            <div className="cc-form-cols-1">
              <div className="cc-field">
                <label className="cc-label">
                  Reason for permit
                  <CCTooltip type="validator" position="right" />
                </label>
                <PPRField
                  name={"ReasonForPermit"}
                  value={valueGetter("")}
                  placeholder={"Reason for permit"}
                  isUseTextArea
                  component={ApplicationDescription}
                  validator={requiredValidator}
                  onChange={handleOnChangeReasonForPermit}
                  onInputChange={(event: InputChangeEvent) => {
                    onChange("ReasonForPermit", {
                      value: isString(event.target.value)
                        ? sanitizeHtml(event.target.value)
                        : event.target.value,
                    });
                  }}
                  readOnly={false}
                />
              </div>
            </div>
            <div className="cc-form-cols-1">
              <div className="cc-field">
                <label className="cc-label">Comments</label>
                <PPRField
                  name={"Comments"}
                  placeholder={"Comments"}
                  component={CCTextArea}
                  rows={3}
                />
              </div>
            </div>
          </section>
        )}
        <br />
        {decisionDetails && (
          <section className="cc-field-group">
            <div className="cc-form-cols-3">
              <CCValueField
                label="Decision"
                value={valueGetter("Decision_Name")}
              />
              <CCValueField
                label="Decision date"
                value={formatDisplayValue(
                  valueGetter("DecisionDate"),
                  DATE_FORMAT.DATE
                )}
                tooltip={
                  decisionDateTooltip ? (
                    <CCTooltip
                      type="info"
                      position="right"
                      content={decisionDateTooltip}
                    />
                  ) : undefined
                }
              />
            </div>
          </section>
        )}
      </FormElement>
    );
  }
);
