import { CommunicationButtonListView } from "@app/core/communication/buttons-list-view/_index";
import { DeleteButton } from "@app/core/delete/buttons/_index";
import { MailMergeButtonListView } from "@app/core/new-mail-merge/buttons/_index";
import { MAILMERGEDATASET } from "@app/core/new-mail-merge/dialogs/model";
import { FormAction, FormIdentifier } from "@app/products/crms/model";
import { LocalLawsActionBarNavDropdown } from "@app/products/local-laws/components/toolbar/nav-dropdown/_index";
import {
  Svc_LLPermit,
  Svc_LLPermitsViewType,
} from "@app/products/local-laws/permits/model";
import { permitsRoute } from "@app/products/local-laws/permits/route";
import { localLawsPermitsURL } from "@app/products/local-laws/permits/util";
import { localLawsRoute } from "@app/products/local-laws/route";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { LoadingPermissionWrapper } from "@common/hocs/permissions/check-security-loading-wrapper";
import { useCheckPermissions } from "@common/hooks/permissions/useCheckPermissions";
import { nameOfFactory } from "@common/utils/common";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { observer } from "mobx-react-lite";
import React from "react";
import { colLocalLawsPermitsRefused } from "./config";

const nameOf = nameOfFactory<Svc_LLPermit>();
export default observer(() => {
  const { checkPermissions, isLoadingPermission } = useCheckPermissions({
    formIdentifier: FormIdentifier.LLPermits_Form_Permit,
    productType: PRODUCT_TYPE_NUMBER.LLPermits,
  });

  // Implement later - Use for bookmark
  // const { listViewDisplayURL, listViewDisplayTitle, recordDisplayURL } =
  //   llPermitsBookmark("Refused");

  useCCListViewActionBar({
    title: localLawsRoute.name,
    leftComponents: [
      <LocalLawsActionBarNavDropdown category={permitsRoute.path} />,
    ],
    centerComponents: [
      /* Implementing this button later */
      // <NewLocalLawsButton />,
      <MailMergeButtonListView
        productType={PRODUCT_TYPE_NUMBER.LLPermits}
        dataSetFilter={MAILMERGEDATASET.LLP_Permit}
        recordType={RECORDTYPE.LLP_Permit}
        isDisabled={!checkPermissions(FormAction.CORE_ALLOW_MAILMERGE)}
      />,
      <CommunicationButtonListView
        productType={PRODUCT_TYPE_NUMBER.LLPermits}
        recordType={RECORDTYPE.LLP_Permit}
        keyField={nameOf("ID")}
        isDisabled={
          !checkPermissions(FormAction.CORE_TOOLBAR_ADD_COMMUNICATION)
        }
      />,
      <DeleteButton
        recordType={RECORDTYPE.LLP_Permit}
        disabled={!checkPermissions(FormAction.CORE_ALLOW_DELETE)}
      />,
    ],
    rightComponents: [
      <ActionBarExportIcon
        disabled={!checkPermissions(FormAction.CORE_ALLOW_EXPORT)}
      />,
      <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
      // Implement later when finishing the manage page
      // <ListViewBookmarkIcon
      //   linkUrl={LOCAL_LAWS_MANAGE_ROUTE}
      //   productType={PRODUCT_TYPE.LLPermits}
      //   recordType={RECORDTYPE.LLP_Permit}
      //   detail={listViewDisplayURL}
      //   displayName={recordDisplayURL}
      //   listViewDetail={listViewDisplayTitle}
      //   listViewDisplayName={listViewDisplayURL}
      // />,
    ],
  });

  return (
    <LoadingPermissionWrapper isLoadingPermission={isLoadingPermission}>
      <CCProductListView
        dataUrl={localLawsPermitsURL(Svc_LLPermitsViewType.Refused)}
        columnFields={colLocalLawsPermitsRefused}
        primaryField={nameOf("ID")}
        state={{
          sort: [{ field: nameOf("PermitNumber"), dir: "desc" }],
        }}
      />
    </LoadingPermissionWrapper>
  );
});
