import { getPermitTypeById } from "@app/products/local-laws/system-admin/permit-types/[id]/api";
import {
  PermitType,
  TApiPermitTypeResponse,
} from "@app/products/local-laws/system-admin/permit-types/[id]/model";
import { useCallApiHandleLoadFailed } from "@common/hooks/useCallApiHandleLoadFailed";
import { useCancelRequest } from "@common/hooks/useCancelRequest";
import { usePageType } from "@common/hooks/usePageType";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import Loading from "@components/loading/Loading";
import { NoData } from "@components/no-data/NoData";
import {
  ProductReferenceBlock,
  ProductReferenceBody,
} from "@components/reference-sidebar/ProductReferenceBody";
import { ProductReferenceHeading } from "@components/reference-sidebar/ProductReferenceHeading";
import { ProductReferenceRow } from "@components/reference-sidebar/ProductReferenceRow";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export const PermitTypeDetailsTab = observer(() => {
  //#region STORE ========/
  const { dataForm } = useFlexibleFormStore();
  const permitTypeFromStore = dataForm?.GeneralForm as PermitType;
  //#endregion STORE =====/

  //#region STATE ========/
  const [permitType, setPermitType] = useState<PermitType>(permitTypeFromStore);

  const { isProductListView } = usePageType();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { lastSelectedId } = useCCProductListViewStore();
  const { cancelToken } = useCancelRequest();
  //#endregion STATE =====/

  //#region WATCH ========/
  useEffect(() => {
    if (permitTypeFromStore) setPermitType(permitTypeFromStore);
  }, [permitTypeFromStore]);
  //#endregion WATCH =====/

  //#region VARIABLE ========/
  const { id } = useParams<{ id: string }>();
  const permitTypeId: string | number = id ? id : lastSelectedId;
  let errorComponent: JSX.Element | null = null;
  //#endregion VARIABLE =====/

  //#region API ========/
  errorComponent = useCallApiHandleLoadFailed({
    isActive: isProductListView,
    setIsLoading,
    api: async () => getPermitTypeById(+permitTypeId, cancelToken()),
    watch: [permitTypeId],
    handler: async (response: TApiPermitTypeResponse["Value"]) => {
      const _permitType = response.data?.ReturnObj;
      setPermitType(_permitType);
    },
  });
  //#endregion API =====/

  if (isLoading) return <Loading isLoading />;
  if (!permitType) return <NoData />;
  if (errorComponent) return errorComponent;

  return (
    <ProductReferenceBody>
      <ProductReferenceHeading
        title={"Permit type ID:"}
        value={permitType?.PermitType_ID?.toString()}
        primary
      />
      <ProductReferenceBlock>
        <ProductReferenceRow title={"Name:"} value={permitType?.Name} />
        <ProductReferenceRow
          title={"Description:"}
          value={permitType?.Description}
        />
      </ProductReferenceBlock>
    </ProductReferenceBody>
  );
});
