import { NotificationPortalContent } from "@components/cc-notification-portal/components/notification-portal-content/_index";
import {
  AttributeYieldNotificationPortal,
  TypePlaceId,
  notificationPortalDefaultValue,
} from "@components/cc-notification-portal/constant";
import { useNotificationPortalStore } from "@components/cc-notification-portal/store";
import { Slide } from "@progress/kendo-react-animation";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";
import { createPortal } from "react-dom";

export const YieldNotificationPortal = ({
  placeId,
}: {
  placeId: TypePlaceId;
}) => {
  return <div {...{ [AttributeYieldNotificationPortal]: placeId }} />;
};

export const CCNotificationPortal = observer(() => {
  const { placeId = TypePlaceId.Main, notificationsCurrentPage } =
    useNotificationPortalStore();

  const parentRoot: Element | null = document.querySelector(
    `[${AttributeYieldNotificationPortal}="${placeId}"]`
  );

  const notificationsDomList = useMemo(() => {
    return (
      <div className="cc-app-notification-container">
        <Slide direction={"down"}>
          {notificationsCurrentPage?.map((notification) => (
            <NotificationPortalContent
              {...notificationPortalDefaultValue}
              key={notification.id}
              {...notification}
            />
          ))}
        </Slide>
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationsCurrentPage]);

  if (parentRoot && notificationsDomList)
    return createPortal(notificationsDomList, parentRoot);
  return null;
});
