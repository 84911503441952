import { OrganisationMode } from "@common/stores/products/model";
import { useCommonProductStore } from "@common/stores/products/store";
import {
  IMapTabProps,
  MapTab,
} from "@components/cc-reference-sidebar/components/Map/_index";
import { NoData } from "@components/no-data/NoData";
import { ProductReferenceBody } from "@components/reference-sidebar/ProductReferenceBody";
import { isFunction } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";
import "./_index.scss";

export interface IMapSpatialLinkTabProps extends IMapTabProps {
  spatialLink?: string;
  showSpatialLink?: () => boolean;
}

export const MapSpatialLinkTab = observer((props: IMapSpatialLinkTabProps) => {
  const { spatialLink, showSpatialLink, ...otherProps } = props;
  const { currentOrganisationMode } = useCommonProductStore();
  const isLLS = currentOrganisationMode(OrganisationMode.LLS);

  const isShowMapSpatialLink = useMemo(() => {
    if (!isFunction(showSpatialLink)) return isLLS; //Default show with LLS
    return showSpatialLink();
  }, [isLLS, showSpatialLink]);

  if (isShowMapSpatialLink) {
    if (!spatialLink) return <NoData />;
    return (
      <div className="cc-map-spatial-link-tab">
        <ProductReferenceBody>
          <a
            href={spatialLink}
            target="_blank"
            rel="noopener noreferrer"
            className="cc-grid-link-text"
          >
            {spatialLink}
          </a>
        </ProductReferenceBody>
      </div>
    );
  }
  return <MapTab {...otherProps} />;
});
