import { sanitizeHtml } from "@common/utils/sanitized-parser";
import { INotificationPortalContent } from "@components/cc-notification-portal/components/notification-portal-content/model";
import { notificationPortalDefaultValue } from "@components/cc-notification-portal/constant";
import { Notification } from "@progress/kendo-react-notification";
import { isString } from "lodash";
import React, { ReactNode, memo, useCallback, useEffect } from "react";
import { useEffectOnce } from "react-use";

export const NotificationPortalContent = memo(
  (props: INotificationPortalContent) => {
    const {
      title,
      description,
      className,
      dir,
      style,
      onClose,
      autoClose,
      onUnmount,
      type,
      showIcon,
      closable,
    } = props;

    useEffect(() => {
      if (!autoClose) return;
      const timeOut = setTimeout(onClose, notificationPortalDefaultValue.delay);
      return () => clearTimeout(timeOut);
    });

    useEffectOnce(() => {
      return () => {
        if (onUnmount) onUnmount(props);
      };
    });

    const handleOnClose = () => {
      if (onClose) onClose(true);
    };

    const convertNodeToHtml = useCallback(
      (node?: ReactNode) => (isString(node) ? sanitizeHtml(node) : node),
      []
    );

    return (
      <Notification
        className={`cc-app-notification-item ${className ?? ""}`}
        type={{
          style: type,
          icon: showIcon,
        }}
        closable={closable}
        dir={dir}
        style={style}
        onClose={handleOnClose}
      >
        {title ? (
          <span className="cc-app-notification-item-title">
            {convertNodeToHtml(title)}
          </span>
        ) : null}
        {description && (
          <div className="cc-app-notification-item-descriptions">
            {Array.isArray(description) ? (
              description.map((desc, index) => (
                <span key={index}>{convertNodeToHtml(desc)}</span>
              ))
            ) : (
              <span>{convertNodeToHtml(description)}</span>
            )}
          </div>
        )}
      </Notification>
    );
  }
);
