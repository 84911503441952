import { CRMS_ROUTE } from "@app/products/crms/[id]/constant";
import {
  EventAnimalsRegistrationSectionData,
  EventKennelSectionData,
  IEventsParentSection,
} from "@app/products/crms/[id]/model";
import { COMPLAINTSMENU } from "@common/constants/enumerations";
import { PRODUCT_TYPE } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { useCommonCoreStore } from "@common/stores/core/store";
import { useGlobalStore } from "@common/stores/global/store";
import { getNumberValueSetting } from "@common/stores/products/util";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { ActiveProduct } from "@components/layout/model";
import { observer } from "mobx-react-lite";
import React from "react";
import { useHistory } from "react-router-dom";

interface IAddComplaintEventProps {
  recordType: RECORDTYPE;
  parentId?: number;
  parentName?: string;
  disabled?: boolean;
}

export const AddComplaintEvent = observer(
  ({ recordType, parentId, parentName, disabled }: IAddComplaintEventProps) => {
    const { pushNotification } = useCCAppNotificationStore();
    const { productsActive } = useGlobalStore();
    const { settings } = useCommonCoreStore();
    const history = useHistory();

    const handleOnClick = () => {
      const complaintsMenu = getNumberValueSetting(
        settings[ECorporateSettingsField.CorporateSettings_ComplaintsMenu]
      );
      const checkIsActiveCRMS =
        productsActive.length === 0
          ? false
          : productsActive.some(
              (item: ActiveProduct) =>
                item.ProductType_ENUM === PRODUCT_TYPE.CustomerService &&
                item.Flag_CommunityCentral
            );

      if (checkIsActiveCRMS && complaintsMenu === COMPLAINTSMENU.CRMS) {
        switch (recordType) {
          case RECORDTYPE.Animals_Registration:
            history.push(`${CRMS_ROUTE}/new`, {
              isFromAnimals: true,
              animalsRegistrationData: {
                Registration_ID: parentId,
                AnimalName: parentName ?? "",
              } as EventAnimalsRegistrationSectionData,
            } as IEventsParentSection);
            break;
          case RECORDTYPE.Animals_Kennel:
            history.push(`${CRMS_ROUTE}/new`, {
              isFromKennel: true,
              kennelData: {
                Kennel_ID: parentId,
              } as EventKennelSectionData,
            } as IEventsParentSection);
            break;
        }
      } else {
        pushNotification({
          autoClose: false,
          type: "warning",
          title: "Add Core Form Complaint haven't implement yet!",
        });
      }
    };

    return (
      <CCNavButton
        title="Complaint"
        onClick={handleOnClick}
        disabled={disabled}
      />
    );
  }
);
