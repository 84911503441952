import {
  CORE_INSPECTION_TYPES_ODATA,
  CORE_OFFICERS_ODATA,
} from "@app/core/components/common/constant";
import {
  InsuranceCompanyData,
  OfficerType,
  PermitTableType,
} from "@app/products/local-laws/[id]/components/general/components/form-element/config";
import { ILocalLaws } from "@app/products/local-laws/model";
import { RegisterAddressPopup } from "@app/products/property/components/register-address-popup/_index";
import { CURRENCY_FORMAT, DATE_FORMAT } from "@common/constants/common-format";
import { useIsNew } from "@common/hooks/useIsNew";
import { getDropdownValue, nameOfFactory } from "@common/utils/common";
import { validateRequired } from "@common/utils/field-validators";
import { formatOSFees } from "@common/utils/formatting";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { CCValueField } from "@components/cc-value-field/_index";
import InputPicker from "@components/input-picker/InputPicker";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import {
  Field,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";

const nameOf = nameOfFactory<ILocalLaws>();
export interface ILocalLawsFormElementProps {
  formRenderProps: FormRenderProps;
}

export const LocalLawsFormElement = observer(
  ({ formRenderProps }: ILocalLawsFormElementProps) => {
    const { modified, valid, valueGetter, onSubmit, onChange } =
      formRenderProps;
    const { pushNotification } = useCCAppNotificationStore();
    const validateForm = (event: React.SyntheticEvent<any>) => {
      if (modified) {
        onSubmit(event);
        return;
      }

      if (!valid) return;

      pushNotification({
        title: "The form is not modified",
        type: "warning",
      });
    };

    const history = useHistory();
    const isNew = useIsNew();

    const [isOpenRegisterAddressPopup, setIsOpenRegisterAddressPopup] =
      useState(false);

    return (
      <FormElement onSubmit={onSubmit}>
        {/* popup */}
        {isOpenRegisterAddressPopup && (
          <RegisterAddressPopup
            onClosePopup={() => setIsOpenRegisterAddressPopup(false)}
          />
        )}

        <input hidden type="submit" id="btn-submit" onClick={validateForm} />
        <div className="cc-field-group">
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">
                Permit type
                <CCTooltip
                  type="validator"
                  position="right"
                  content={formRenderProps.errors.permitType}
                />
              </label>
              <Field
                component={InputPicker}
                name={nameOf("PermitType")}
                validator={validateRequired}
                isMultiSelect={false}
                titleHeader={"Permit Type"}
                placeholder={"Permit type"}
                tableTypes={PermitTableType}
                url={CORE_INSPECTION_TYPES_ODATA}
                disabled={false}
                fieldName={nameOf("PermitType")}
                value={valueGetter(nameOf("PermitType"))}
              />
            </div>
          </div>
        </div>
        <hr className="cc-divider" />
        <div className="cc-field-group">
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Area of council</label>
              {/* <CCTooltip type="validator" position="right" content={formRenderProps.errors.permitType} /> */}
              <Field
                component={CCSearchComboBox}
                name={nameOf("AreaOfCouncil")}
                textField="Value"
                dataItemKey="Key"
                // validator={validateRequired}
                data={[]}
                value={getDropdownValue(
                  valueGetter(nameOf("AreaOfCouncil")),
                  []
                )}
                onChange={(event: ComboBoxChangeEvent) => {
                  onChange(nameOf("AreaOfCouncil"), {
                    value: event.target.value?.Key ?? null,
                  });
                }}
              />
            </div>
          </div>
        </div>
        <hr className="cc-divider" />
        <div className="cc-field-group">
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">
                Permit holder
                <CCTooltip
                  type="validator"
                  position="right"
                  content={formRenderProps.errors.permitHolder}
                />
              </label>
              <Field
                autoComplete="off"
                placeholder={"Permit holder"}
                name={nameOf("PermitHolder")}
                component={InputPicker}
                validator={validateRequired}
                onClick={() => {
                  history.push(`/managecontactrelationship/${"123"}`);
                }}
              />
            </div>
            <div className="cc-field">
              <CCValueField
                label="Address"
                value={valueGetter(nameOf("Address"))}
              />
            </div>
            <div className="cc-field">
              <CCValueField
                label="Postal address"
                value={valueGetter(nameOf("PostalAddress"))}
              />
            </div>
          </div>

          {/* ROW 2 */}
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <CCValueField
                label="Phone (H)"
                value={valueGetter(nameOf("HomePhone"))}
              />
            </div>
            <div className="cc-field">
              <CCValueField
                label="Phone (W)"
                value={valueGetter(nameOf("WorkPhone"))}
              />
            </div>
            <div className="cc-field">
              <CCValueField
                label="Preferred contact"
                value={valueGetter(nameOf("PreferredContact"))}
              />
            </div>
          </div>

          {/* ROW 3 */}
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <CCValueField
                label="Mobile"
                value={valueGetter(nameOf("Mobile"))}
              />
            </div>
            <div className="cc-field">
              <CCValueField
                label="Email"
                value={valueGetter(nameOf("Email"))}
              />
            </div>
          </div>
        </div>
        <hr className="cc-divider" />
        <div className="cc-field-group">
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <div className="cc-item-label-wrapper">
                <label className="cc-label">
                  Site address
                  <CCTooltip type="validator" position="right" />
                </label>
              </div>
              <Field
                component={InputPicker}
                name={"SiteAddress"}
                placeholder={"Site address"}
                validator={validateRequired}
                disabled
                onClick={() => {
                  setIsOpenRegisterAddressPopup(true);
                }}
                autoComplete="off"
                className="disabled"
              />
            </div>

            <div className="cc-field">
              <CCValueField
                label="Owner"
                value={valueGetter(nameOf("Owner"))}
              />
            </div>
            <div className="cc-field">
              <CCValueField
                label="Details"
                value={valueGetter(nameOf("Details"))}
              />
            </div>
          </div>
        </div>
        <hr className="cc-divider" />
        <div className="cc-field-group">
          <div className="cc-field">
            <label className="cc-label">Description</label>
            <Field
              rows={4}
              component={CCTextArea}
              name={nameOf("Description")}
              value={valueGetter(nameOf("Description"))}
              placeholder={"Description"}
            />
          </div>

          <div className="cc-form-cols-3">
            <div className="cc-field">
              <div className="cc-item-label-wrapper">
                <label className="cc-label">
                  Effective from <CCTooltip type="validator" position="right" />
                </label>
              </div>
              <Field
                name={nameOf("DateEffectiveFrom")}
                validator={validateRequired}
                format={DATE_FORMAT.DATE_CONTROL}
                component={CCDatePicker}
              />
            </div>

            <div className="cc-field">
              <label className="cc-label">
                Effective to
                <CCTooltip type="validator" position="right" />
              </label>

              <Field
                name={nameOf("DateEffectiveTo")}
                validator={validateRequired}
                format={DATE_FORMAT.DATE_CONTROL}
                component={CCDatePicker}
              />
            </div>

            <div className="cc-field">
              <label className="cc-label">Officer</label>
              <Field
                component={InputPicker}
                name={nameOf("Officer")}
                titleHeader={"Pick Officer"}
                isMultiSelect={false}
                tableTypes={OfficerType}
                url={CORE_OFFICERS_ODATA}
                placeholder={"Officer"}
                disabled={false}
                fieldName={"inspection.officer"}
              />
            </div>
          </div>
        </div>
        <hr className="cc-divider" />
        <div className="cc-field-group">
          {!isNew && (
            <>
              <div className="cc-form-cols-3">
                <div className="cc-field">
                  <label className="cc-label">Chairs</label>
                  <Field
                    name={nameOf("Chairs")}
                    placeholder={"Chairs"}
                    component={CCInput}
                    type={"Number"}
                  />
                </div>
                <div className="cc-field">
                  <label className="cc-label">Tables</label>
                  <Field
                    name={nameOf("Tables")}
                    placeholder={"Tables"}
                    component={CCInput}
                    type={"Number"}
                  />
                </div>
              </div>
              <div className="cc-form-cols-3">
                <div className="cc-field">
                  <label className="cc-label">Insurance company</label>
                  <Field
                    name={nameOf("InsuranceCompany")}
                    component={CCSearchComboBox}
                    textField="Value"
                    dataItemKey="Key"
                    data={InsuranceCompanyData}
                    value={getDropdownValue(
                      valueGetter(nameOf("InsuranceCompany")),
                      InsuranceCompanyData,
                      "Key"
                    )}
                    onChange={(event: ComboBoxChangeEvent) => {
                      onChange(nameOf("InsuranceCompany"), {
                        value: event.target.value?.Key ?? null,
                      });
                    }}
                  />
                </div>
                <div className="cc-field">
                  <label className="cc-label">Policy number</label>
                  <Field
                    name={nameOf("PolicyNumber")}
                    placeholder={"Policy number"}
                    component={CCInput}
                  />
                </div>
              </div>
              <div className="cc-form-cols-3">
                <div className="cc-field">
                  <label className="cc-label">Policy issue date</label>
                  <Field
                    name={nameOf("PolicyIssueDate")}
                    format={DATE_FORMAT.DATE_CONTROL}
                    component={CCDatePicker}
                  />
                </div>
                <div className="cc-field">
                  <label className="cc-label">Policy expires</label>
                  <Field
                    name={nameOf("PolicyExpiresDate")}
                    format={DATE_FORMAT.DATE_CONTROL}
                    component={CCDatePicker}
                  />
                </div>
              </div>
            </>
          )}
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Debtor number</label>
              <Field
                placeholder={"Debtor number"}
                name={nameOf("DebtorNumber")}
                component={CCInput}
              />
            </div>

            <div className="cc-field">
              <label className="cc-label">File number</label>
              <Field
                name={nameOf("FileNumber")}
                component={CCInput}
                placeholder={"File number"}
              />
            </div>

            <div className="cc-field">
              <CCValueField
                label="OS fees"
                format={CURRENCY_FORMAT.CURRENCY1}
                value={valueGetter("OSFees")}
                style={formatOSFees(valueGetter("OSFees"))}
              />
            </div>
          </div>
        </div>
      </FormElement>
    );
  }
);
