import { checkOrganisationOrPerson } from "@app/products/property/contacts-central-names/list/components/dialogs/components/form-elements/postal-and-physical-address/util";
import {
  DTO_Entity_Detail,
  MODE_CONTACT,
} from "@app/products/property/contacts-central-names/list/components/dialogs/new-contact/model";
import { isNil } from "lodash";

export const defaultFields: Array<keyof DTO_Entity_Detail> = [
  "Id",
  "Change_Of_Ownership_Id",
  "Type",
  "Category_Id",
  "CareOf",
  "Job_Description",
  "Organisation",
];

interface IFieldGroupByType {
  [key: string]: Array<keyof DTO_Entity_Detail>;
}
const personFieldsGroupedByType: IFieldGroupByType = {
  [MODE_CONTACT.ORGANISATION]: [
    ...defaultFields,
    "Organisation_Name",
    "ACN",
    "ABN",
    "AttentionOf",
  ],
  [MODE_CONTACT.PERSON]: [
    ...defaultFields,
    "Title",
    "GivenName",
    "Surname",
    "Distinction",
  ],
  [MODE_CONTACT.UNKNOWN]: [...defaultFields],
};

export const processPersonData = (
  typeId: number | null,
  originalData: DTO_Entity_Detail
) => {
  if (isNil(typeId)) return originalData;
  let processedData: any = {};
  const contactType = checkOrganisationOrPerson(typeId);
  personFieldsGroupedByType[contactType].forEach(
    (field: keyof DTO_Entity_Detail) => {
      processedData[field] = originalData[field];
    }
  );
  return processedData;
};
