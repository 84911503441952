import { CS_CustomFlag } from "@app/products/crms/[id]/model";
import { useCRMSEventStore } from "@app/products/crms/[id]/store";
import { DATE_FORMAT } from "@common/constants/common-format";
import { useIsNew } from "@common/hooks/useIsNew";
import { AccessControl } from "@common/models/accessControl";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { useCommonCoreStore } from "@common/stores/core/store";
import {
  getBoolValueSetting,
  getNumberValueSetting,
  getStringValueSetting,
} from "@common/stores/products/util";
import { formatDisplayValue, splitCamelCase } from "@common/utils/formatting";
import { sanitizeHtml } from "@common/utils/sanitized-parser";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import Loading from "@components/loading/Loading";
import { NoData } from "@components/no-data/NoData";
import {
  ProductReferenceBlock,
  ProductReferenceBody,
} from "@components/reference-sidebar/ProductReferenceBody";
import { ProductReferenceHeading } from "@components/reference-sidebar/ProductReferenceHeading";
import { ProductReferenceRow } from "@components/reference-sidebar/ProductReferenceRow";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import { observer } from "mobx-react-lite";
import React, { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";

export const CRMSEventDetailsTab = observer(() => {
  const { settings } = useCommonCoreStore();
  const isNew = useIsNew();
  const {
    isLoadingDetails,
    crmsEvent,
    uiControl,
    loadCRMSEventDetails,
    isHasVip,
    setIsHasVip,
    eventLitBannerMiddle,
    responseLoadError,
  } = useCRMSEventStore();
  const { lastSelectedId, lastSelectedRow } = useCCProductListViewStore();
  const params: { id: string } = useParams();
  const eventId = params.id || lastSelectedId;

  const enableVIP = getBoolValueSetting(
    settings[ECorporateSettingsField.EnableVIP]
  );

  const customFlag1Workflow = getNumberValueSetting(
    settings[ECorporateSettingsField.CUSTOMERSERVICE_Event_CustomFlag1_Workflow]
  );

  const customFlag2Workflow = getNumberValueSetting(
    settings[ECorporateSettingsField.CUSTOMERSERVICE_Event_CustomFlag2_Workflow]
  );

  const customFlag3Workflow = getNumberValueSetting(
    settings[ECorporateSettingsField.CUSTOMERSERVICE_Event_CustomFlag3_Workflow]
  );

  useEffect(() => {
    if (
      (customFlag3Workflow === CS_CustomFlag.VIP ||
        customFlag2Workflow === CS_CustomFlag.VIP ||
        customFlag1Workflow === CS_CustomFlag.VIP) &&
      enableVIP
    ) {
      setIsHasVip(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    customFlag3Workflow,
    customFlag2Workflow,
    customFlag1Workflow,
    enableVIP,
  ]);

  const actionOfficerLabel = getStringValueSetting(
    settings[ECorporateSettingsField.CUSTOMERSERVICE_Common_ActionOfficerLabel]
  );
  const coordinatorLabel = getStringValueSetting(
    settings[ECorporateSettingsField.CUSTOMERSERVICE_Common_CoordinatorLabel]
  );

  useEffect(() => {
    if (!isNew && !params.id) loadCRMSEventDetails(eventId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventId]);

  const specialInterestDisplayAll = useMemo(() => {
    if (crmsEvent?.SpecialInterest && crmsEvent?.SpecialInterest?.length > 0) {
      let displayContactName = "";

      crmsEvent?.SpecialInterest.forEach((item: AccessControl) => {
        displayContactName += item?.EntityDisplayName + "<br />";
      });
      return displayContactName;
    }
    return "";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [crmsEvent?.SpecialInterest]);

  const readersDisplayAll = useMemo(() => {
    if (crmsEvent?.Readers && crmsEvent?.Readers?.length > 0) {
      let displayContactName = "";

      crmsEvent?.Readers.forEach((item: AccessControl) => {
        displayContactName += item?.EntityDisplayName + "<br />";
      });
      return displayContactName;
    }
    return "";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [crmsEvent?.Readers]);

  const writersDisplayAll = useMemo(() => {
    if (crmsEvent?.Writers && crmsEvent?.Writers?.length > 0) {
      let displayContactName = "";

      crmsEvent?.Writers.forEach((item: AccessControl) => {
        displayContactName += item?.EntityDisplayName + "<br />";
      });
      return displayContactName;
    }
    return "";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [crmsEvent?.Writers]);

  const emailManualEntries = useMemo(() => {
    let manualEntries =
      crmsEvent?.SpecialInterest_Email_ManualEntries?.replaceAll(",", ";");
    return manualEntries?.replaceAll(";", "<br/>");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [crmsEvent?.SpecialInterest_Email_ManualEntries]);

  const location =
    uiControl?.LnkLocation?.Value ??
    uiControl?.LblLocation?.Value ??
    crmsEvent?.IssueLocation?.LocationAddress?.Formatted_AddressLine1;

  if (isLoadingDetails) return <Loading isLoading={isLoadingDetails} />;

  if (responseLoadError) {
    return (
      <CCLoadFailed
        responseError={responseLoadError}
        onReload={() => {
          eventId && loadCRMSEventDetails(eventId);
        }}
      />
    );
  }

  if ((!crmsEvent?.Event_ReferenceNo && !isNew) || (!crmsEvent && isNew))
    return <NoData />;

  if (isNew)
    return (
      <ProductReferenceBody>
        <ProductReferenceBlock>
          <ProductReferenceRow
            title={"Received on:"}
            value={formatDisplayValue(
              crmsEvent?.DateReceived,
              DATE_FORMAT.DATE
            )}
          />
          <ProductReferenceRow
            title={"Target date:"}
            value={formatDisplayValue(crmsEvent?.TargetDate, DATE_FORMAT.DATE)}
          />
        </ProductReferenceBlock>
        <hr />
        <ProductReferenceBlock>
          <ProductReferenceRow
            title={"Categorised as:"}
            value={crmsEvent?.ServiceStandard?.Category ?? "Uncategorised"}
          />
          <ProductReferenceRow
            title={"Description:"}
            value={crmsEvent?.Event_Description}
          />
          <ProductReferenceRow title={"Location:"} value={location} />
        </ProductReferenceBlock>
        <hr />
        <ProductReferenceBlock>
          <ProductReferenceRow
            title={"Sub category:"}
            value={crmsEvent?.ServiceStandard?.SubCategory ?? "Uncategorised"}
          />
          <ProductReferenceRow
            title={
              actionOfficerLabel ? `${actionOfficerLabel}:` : "Action officer:"
            }
            value={crmsEvent?.ActionOfficer_DisplayName ?? "Unassigned"}
          />
          <ProductReferenceRow
            title={coordinatorLabel ? `${coordinatorLabel}:` : "Coordinator:"}
            value={crmsEvent?.Coordinator_DisplayName ?? "Unassigned"}
          />
          {isHasVip && (
            <ProductReferenceRow
              title={"VIP:"}
              value={crmsEvent?.VIP_Display ?? "Unassigned"}
            />
          )}
        </ProductReferenceBlock>
      </ProductReferenceBody>
    );

  return (
    <ProductReferenceBody>
      <PanelBar expandMode={"multiple"}>
        <ProductReferenceHeading
          title={"Ref no:"}
          value={crmsEvent?.Event_ReferenceNo + ""}
          primary
        />
        <ProductReferenceBlock>
          <ProductReferenceRow
            title={"Status:"}
            value={
              params.id
                ? splitCamelCase(eventLitBannerMiddle?.Value)
                : lastSelectedRow?.Status
            }
          />
          <ProductReferenceRow
            title={"Received on:"}
            value={formatDisplayValue(
              crmsEvent?.DateReceived,
              DATE_FORMAT.DATE
            )}
          />
          <ProductReferenceRow
            title={"Target date:"}
            value={formatDisplayValue(crmsEvent?.TargetDate, DATE_FORMAT.DATE)}
          />
        </ProductReferenceBlock>
        <hr />
        <ProductReferenceBlock>
          <ProductReferenceRow
            title={"Categorised as:"}
            value={crmsEvent?.ServiceStandard?.FullName ?? "Uncategorised"}
          />
          <ProductReferenceRow
            title={"Description:"}
            value={crmsEvent?.Event_Description}
          />
          <ProductReferenceRow
            title={"Location:"}
            value={
              crmsEvent?.IssueLocation?.LocationAddress?.Formatted_SingleLine ??
              location ??
              "No Location"
            }
          />
        </ProductReferenceBlock>
        <hr />
        <ProductReferenceBlock>
          <ProductReferenceRow
            title={
              actionOfficerLabel ? `${actionOfficerLabel}:` : "Action person:"
            }
            value={crmsEvent?.ActionOfficer_DisplayName ?? "Unassigned"}
          />
          <ProductReferenceRow
            title={
              coordinatorLabel ? `${coordinatorLabel}:` : "Coordinator person:"
            }
            value={crmsEvent?.Coordinator_DisplayName ?? "Unassigned"}
          />
          {isHasVip && (
            <ProductReferenceRow
              title={"VIP:"}
              value={crmsEvent?.VIP_Display ?? "Unassigned"}
            />
          )}
        </ProductReferenceBlock>
        <hr />
        <PanelBarItem
          title={<ProductReferenceHeading title="Special interest" />}
        >
          <ProductReferenceBlock>
            <ProductReferenceRow
              title={"Users & groups:"}
              value={sanitizeHtml(specialInterestDisplayAll)}
            />
            <ProductReferenceRow
              title={"Manual entries:"}
              value={
                <span
                  title={crmsEvent?.SpecialInterest_Email_ManualEntries}
                  className="cc-text-ellipsis"
                >
                  {crmsEvent?.SpecialInterest_Email_ManualEntries &&
                  emailManualEntries
                    ? sanitizeHtml(emailManualEntries)
                    : ""}
                </span>
              }
            />
          </ProductReferenceBlock>
        </PanelBarItem>

        <PanelBarItem
          title={<ProductReferenceHeading title="Access control" />}
        >
          <ProductReferenceBlock>
            <ProductReferenceRow
              title={"Readers:"}
              value={sanitizeHtml(readersDisplayAll)}
            />
            <ProductReferenceRow
              title={"Writers:"}
              value={sanitizeHtml(writersDisplayAll)}
            />
          </ProductReferenceBlock>
        </PanelBarItem>
      </PanelBar>
    </ProductReferenceBody>
  );
});
