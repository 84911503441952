import { loadViewConfiguresColumns } from "@app/products/property/api";
import { CancelNoticeOfSaleButton } from "@app/products/property/changes-of-ownership/notice-of-sales/components/action-bar/buttons/cancel-notice-of-sale/_index";
import { DeleteImportedFileButton } from "@app/products/property/changes-of-ownership/notice-of-sales/components/action-bar/buttons/delete-imported-file/_index";
import { ImportNoticeOfSaleButton } from "@app/products/property/changes-of-ownership/notice-of-sales/components/action-bar/buttons/import-notice-of-sale/_index";
import { SuspendNoticeOfSaleButton } from "@app/products/property/changes-of-ownership/notice-of-sales/components/action-bar/buttons/suspend-notice-of-sale/_index";
import { UpdateNoticeOfSaleButton } from "@app/products/property/changes-of-ownership/notice-of-sales/components/action-bar/buttons/update-notice-of-sale/_index";
import { NoticeOfSaleDetails } from "@app/products/property/changes-of-ownership/notice-of-sales/components/detail/_index";
import { NoticeOfSaleDetailTab } from "@app/products/property/changes-of-ownership/notice-of-sales/components/reference-sidebar/detail/_index";
import {
  noticeOfSaleFilterState,
  noticeOfSalesColumns,
} from "@app/products/property/changes-of-ownership/notice-of-sales/config";
import { PROPERTY_NOTICE_OF_SALE_LIST_VIEW_URL } from "@app/products/property/changes-of-ownership/notice-of-sales/constant";
import { VO_NoticeOfSale } from "@app/products/property/changes-of-ownership/notice-of-sales/model";
import { changesOfOwnershipRoute } from "@app/products/property/changes-of-ownership/route";
import { PropertyActionBarNavDropdown } from "@app/products/property/components/action-bar/nav-dropdown/_index";
import { ViewConfiguration } from "@app/products/property/model";
import { propertyRoute } from "@app/products/property/route";
import { APIResponseError } from "@common/apis/model";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import Loading from "@components/loading/Loading";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";

const nameOf = nameOfFactory<VO_NoticeOfSale>();
export default () => {
  useCCListViewActionBar({
    title: propertyRoute.name,
    leftComponents: [
      <PropertyActionBarNavDropdown category={changesOfOwnershipRoute.path} />,
    ],
    centerComponents: [
      <CCNavButton
        title="Workflow"
        type="sub-workflow"
        className="cc-workflow-button"
      >
        <CCNavButton title="Notice of sale" type="dropdown">
          <CancelNoticeOfSaleButton />
          <DeleteImportedFileButton />
          <ImportNoticeOfSaleButton />
          <SuspendNoticeOfSaleButton />
          <UpdateNoticeOfSaleButton />
        </CCNavButton>
      </CCNavButton>,
    ],
    rightComponents: [
      <ActionBarExportIcon />,
      <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
      <CCNavButton iconClass="fal fa-bookmark" title="Bookmark" />,
    ],
  });
  useReferenceSideBar({
    referenceComponents: [
      { title: "Details", component: <NoticeOfSaleDetailTab /> },
    ],
  });

  //State
  const [isLoading, setIsLoading] = useState(false);
  const [responseLoadError, setResponseLoadError] = useState<
    APIResponseError | undefined
  >(undefined);
  const [processedColumns, setProcessedColumns] =
    useState<IColumnFields[]>(noticeOfSalesColumns);

  const loadViewConfig = async () => {
    setIsLoading(true);
    setResponseLoadError(undefined);
    const response = await loadViewConfiguresColumns(
      ViewConfiguration.OData_NoticeOfSale,
      noticeOfSalesColumns
    );
    if (Array.isArray(response)) {
      setProcessedColumns(response);
    } else {
      setResponseLoadError(response);
    }
    setIsLoading(false);
  };

  useEffectOnce(() => {
    loadViewConfig();
  });

  if (isLoading) return <Loading isLoading={isLoading} isFullScreen />;
  if (responseLoadError)
    return (
      <CCLoadFailed
        responseError={responseLoadError}
        onReload={() => {
          loadViewConfig();
        }}
      />
    );

  return (
    <CCProductListView
      detail={NoticeOfSaleDetails}
      columnFields={processedColumns}
      dataUrl={PROPERTY_NOTICE_OF_SALE_LIST_VIEW_URL}
      primaryField={nameOf("NoticeofSale_Id")}
      state={{
        filter: noticeOfSaleFilterState,
        sort: [
          {
            field: nameOf("Extract_Date"),
            dir: "desc",
          },
          {
            field: nameOf("File_Id"),
            dir: "desc",
          },
        ],
      }}
    />
  );
};
