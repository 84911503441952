import { ECustomColNameProperty } from "@app/products/property/config";
import { Label } from "@common/stores/products/config";
import { getDisplayTextWithDashes } from "@common/utils/common";

const assessmentLabel = () => {
  return Label.CommunityProperty.getLabel(ECustomColNameProperty.Assessments);
};

export const PropertyAssessmentBalancesBookmark = {
  getBookmarkDisplayName(selectedRow: any) {
    const dynamicDisplayName = selectedRow.Assessment_Id
      ? `- ${selectedRow.Assessment_Id}`
      : "";
    return `Property - ${assessmentLabel()} ${dynamicDisplayName}`;
  },
  getBookmarkDetail(selectedRow: any) {
    return getDisplayTextWithDashes([
      selectedRow.Assessment_Number_Formatted,
      selectedRow.Property_Name_Address_Locality,
    ]);
  },
  getBookmarkListViewDisplayName() {
    return `Property - ${assessmentLabel()}`;
  },
  getBookmarkListViewDetail() {
    return `Property - ${assessmentLabel()} - Balances`;
  },
};
