import { getContacts } from "@app/products/local-laws/components/sidebar/contacts/api";
import { ContactsSidebar } from "@common/pages/contacts/contacts-sidebar/_index";
import { ICommonContactItemRender } from "@common/pages/contacts/contacts-sidebar/model";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";

export const LocalLawsContactsTab = observer(() => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<ICommonContactItemRender[] | undefined>(
    undefined
  );
  useEffect(() => {
    setIsLoading(true);
    getContacts().then((data) => {
      setData(data);
      setIsLoading(false);
    });
  }, []);

  if (isLoading) return <Loading isLoading={isLoading} />;
  return <ContactsSidebar data={data || []} />;
});
