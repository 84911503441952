import { getMultipleSelectValue } from "@app/products/property/charge-and-notice-runs/notice-runs/components/dialogs/components/form-elements/groups-statuses-and-types/util";
import { useNoticeRunNoticeTypeStepStore } from "@app/products/property/charge-and-notice-runs/notice-runs/components/dialogs/components/form-elements/notice-type/store";
import {
  listTypeHideFinancialAreas,
  listTypeHideLevyBalances,
  listTypeHideUtilityAgent,
  listTypeShowAssessmentBalance,
} from "@app/products/property/charge-and-notice-runs/notice-runs/components/dialogs/components/form-elements/options/config";
import { useNoticeRunOptionStepStore } from "@app/products/property/charge-and-notice-runs/notice-runs/components/dialogs/components/form-elements/options/store";
import { isShowWithNoticeType } from "@app/products/property/charge-and-notice-runs/notice-runs/components/dialogs/new-notice-run/utils";
import { nameOfLov } from "@app/products/property/model";
import { isFieldVisible } from "@app/products/property/util";
import { DTO_LOV_Number } from "@common/models/odataResponse";
import { useCommonCoreStore } from "@common/stores/core/store";
import { formatBytes } from "@common/utils/formatting";
import {
  CCLocalNotification,
  ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCMultiSelectDropdown } from "@components/cc-multiple-selection-dropdown/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import CCUploadFile from "@components/cc-upload-file/_index";
import { MultiSelectChangeEvent } from "@progress/kendo-react-dropdowns";
import { Field, FieldArray } from "@progress/kendo-react-form";
import {
  UploadOnAddEvent,
  UploadOnRemoveEvent,
} from "@progress/kendo-react-upload";
import { observer } from "mobx-react-lite";
import React, { useMemo, useRef } from "react";
const ACCEPT_FORMAT = [".rpt", ".pdf", ".doc", ".docx"];

export const OptionsFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};
const FormStepElement = observer(
  ({
    formRenderProps,
    nameOf,
    options = {
      isReadOnly: false,
    },
  }: IFormStepElement) => {
    const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);
    //store
    const { optionLOVs } = useNoticeRunOptionStepStore();
    const { dataSelectedNoticeType } = useNoticeRunNoticeTypeStepStore();
    const { fileSize } = useCommonCoreStore();
    const { valueGetter, onChange } = formRenderProps;
    const getFieldValue = (name: string) => valueGetter(nameOf(name));

    const isShowIncludeIfNotInChargeRun = useMemo(() => {
      return isFieldVisible(dataSelectedNoticeType?.ChargeRunVisibility);
    }, [dataSelectedNoticeType?.ChargeRunVisibility]);

    const financialAreaLov = useMemo(() => {
      return optionLOVs?.FinancialAreas ?? [];
    }, [optionLOVs?.FinancialAreas]);

    const noticeOrderLov = useMemo(() => {
      return optionLOVs?.NoticeOrdering ?? [];
    }, [optionLOVs?.NoticeOrdering]);

    const isShowHeader = !(
      isShowWithNoticeType(listTypeHideLevyBalances, valueGetter) &&
      isShowWithNoticeType(listTypeHideUtilityAgent, valueGetter) &&
      !isShowWithNoticeType(listTypeShowAssessmentBalance, valueGetter) &&
      isShowWithNoticeType(listTypeHideFinancialAreas, valueGetter)
    );

    return (
      <div className="cc-form">
        <CCLocalNotification ref={notificationRef} />
        <div className="cc-field-group">
          <div className="cc-field">
            {isShowHeader && (
              <label className="cc-label">Balances to include</label>
            )}
            <div className="cc-custom-sub-panel-bar cc-form-cols-1">
              <div className="cc-form-cols-1">
                {!isShowWithNoticeType(
                  listTypeHideLevyBalances,
                  valueGetter
                ) && (
                  <div className="cc-form-cols-2">
                    <div className="cc-field">
                      <label className="cc-label">
                        Include levy balances on notice
                      </label>
                      <Field
                        name={nameOf("IncludeBalances")}
                        checked={getFieldValue("IncludeBalances")}
                        component={CCSwitch}
                        disabled={options?.isReadOnly}
                      />
                    </div>
                  </div>
                )}
                <div className="cc-form-cols-1">
                  {!isShowWithNoticeType(
                    listTypeHideUtilityAgent,
                    valueGetter
                  ) && (
                    <div className="cc-field">
                      <label className="cc-label">Use utility agent</label>
                      <Field
                        name={nameOf("UseUtilityAgent")}
                        checked={getFieldValue("UseUtilityAgent")}
                        component={CCSwitch}
                        disabled={options?.isReadOnly}
                      />
                    </div>
                  )}
                  {isShowIncludeIfNotInChargeRun &&
                  isShowWithNoticeType(
                    listTypeShowAssessmentBalance,
                    valueGetter
                  ) ? (
                    <div className="cc-field">
                      <label className="cc-label">
                        Include assessments with balances but is not in the
                        charge run
                      </label>
                      <Field
                        name={nameOf("IncludeIfNotInChargeRun")}
                        checked={getFieldValue("IncludeIfNotInChargeRun")}
                        component={CCSwitch}
                        disabled={options?.isReadOnly}
                      />
                    </div>
                  ) : null}
                </div>
                {!isShowWithNoticeType(
                  listTypeHideFinancialAreas,
                  valueGetter
                ) && (
                  <div className="cc-field">
                    <label className="cc-label">Financial areas</label>
                    <Field
                      name={nameOf("FinancialAreas")}
                      component={CCMultiSelectDropdown}
                      data={financialAreaLov ?? []}
                      onChange={(event: MultiSelectChangeEvent) => {
                        let value = event?.value;
                        if (value?.length) {
                          value = value.map(
                            (item: DTO_LOV_Number) => item.Code
                          );
                        }
                        onChange(nameOf("FinancialAreas"), {
                          value: value,
                        });
                      }}
                      value={getMultipleSelectValue(
                        getFieldValue("FinancialAreas"),
                        financialAreaLov ?? [],
                        "Code"
                      )}
                      textField="Name"
                      dataItemKey="Code"
                      disabled={options?.isReadOnly}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="cc-field">
            <label className="cc-label">Notice</label>
            <div className="cc-custom-sub-panel-bar cc-form-cols-1">
              <div className="cc-form-cols-1">
                <div className="cc-field">
                  <label className="cc-label">Notice order</label>
                  <Field
                    name={nameOf("NoticeOrdering_Id")}
                    component={CCSearchComboBox}
                    data={noticeOrderLov ?? []}
                    textField={nameOfLov("Name")}
                    dataItemKey={nameOfLov("Code")}
                    isUseDefaultOnchange
                    disabled={options?.isReadOnly}
                  />
                </div>
                <div className="cc-field">
                  <label className="cc-label">
                    Report template
                    <CCTooltip content="The Crystal Report Template is only required when printing internally" />
                  </label>
                  <Field
                    name={nameOf("ReportTemplate_Test")}
                    component={CCUploadFile}
                    autoUpload={false}
                    batch={false}
                    multiple={false}
                    withCredentials={false}
                    accept={ACCEPT_FORMAT.join(",")}
                    files={getFieldValue("ReportTemplate_Test")}
                    restrictions={{
                      allowedExtensions: ACCEPT_FORMAT,
                      // maxFileSize: 5000000,
                    }}
                    onAdd={(event: UploadOnAddEvent) => {
                      if (
                        event?.affectedFiles[0]?.validationErrors &&
                        event?.affectedFiles[0]?.validationErrors?.length
                      ) {
                        if (
                          event.affectedFiles[0].validationErrors.includes(
                            "invalidFileExtension"
                          )
                        ) {
                          notificationRef.current?.pushNotification({
                            title: `Only allow file type in ${ACCEPT_FORMAT.join(
                              ", "
                            )}`,
                            type: "warning",
                          });
                        } else if (
                          event.affectedFiles[0]?.validationErrors?.includes(
                            "invalidMaxFileSize"
                          )
                        ) {
                          notificationRef.current?.pushNotification({
                            title: `Maximum allowed file size is ${formatBytes(
                              fileSize
                            )}`,
                            type: "warning",
                          });
                        }
                      } else {
                        onChange(nameOf("ReportTemplate_Test"), {
                          value: event.newState,
                        });
                      }
                    }}
                    onRemove={(event: UploadOnRemoveEvent) => {
                      onChange(nameOf("ReportTemplate_Test"), {
                        value: event.newState,
                      });
                    }}
                    showActionButtons={false}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
);
