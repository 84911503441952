import { eventEmitter } from "@/App";
import { VO_Workflow_Draft } from "@app/products/property/actions/model";
import { listSubmitButton } from "@app/products/property/assessments/components/form-steps/new-assessment/config";
import { useConfirmCancelDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-cancel/store";
import { useConfirmCloseDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-close/store";
import { useConfirmFinishDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-finish/store";
import { CommentsFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/comments/_index";
import { DocumentsFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/documents/_index";
import { INewProcessWorkflow } from "@app/products/property/components/action-bar/property-workflow/model";
import { getTitleWorkflow } from "@app/products/property/components/action-bar/property-workflow/util";
import { ECustomColNameProperty } from "@app/products/property/config";
import {
  getInitialDataJournalTransaction,
  postProcessAddJournalTransaction,
} from "@app/products/property/journals/[id]/components/forms/existed/components/form-steps/add-transaction/api";
import { AllocationToChargeBalancesAddTransactionFormStep } from "@app/products/property/journals/[id]/components/forms/existed/components/form-steps/add-transaction/components/form-elements/allocations-to-charge-balances/_index";
import { loadAssessmentChargeBalanceDetail } from "@app/products/property/journals/[id]/components/forms/existed/components/form-steps/add-transaction/components/form-elements/allocations-to-charge-balances/api";
import { AssessmentAddTransactionJournalFormStep } from "@app/products/property/journals/[id]/components/forms/existed/components/form-steps/add-transaction/components/form-elements/assessment-journal/_index";
import {
  DTO_LOV_Transaction,
  eAssessmentAllocation,
} from "@app/products/property/journals/[id]/components/forms/existed/components/form-steps/add-transaction/components/form-elements/assessment-journal/model";
import { useAssessmentAddTransactionJournalStepStore } from "@app/products/property/journals/[id]/components/forms/existed/components/form-steps/add-transaction/components/form-elements/assessment-journal/store";
import { NewChargeDetailsAddTransactionFormStep } from "@app/products/property/journals/[id]/components/forms/existed/components/form-steps/add-transaction/components/form-elements/new-charge-details/_index";
import {
  DTO_LOV_Levy,
  DTO_LOV_RatingPeriod,
  INewChargeDetailsTransactionStepsLOVs,
} from "@app/products/property/journals/[id]/components/forms/existed/components/form-steps/add-transaction/components/form-elements/new-charge-details/model";
import { useNewChargeDetailsAddTransactionStepStore } from "@app/products/property/journals/[id]/components/forms/existed/components/form-steps/add-transaction/components/form-elements/new-charge-details/store";
import { DTO_ChargeBalance } from "@app/products/property/journals/[id]/components/forms/existed/components/form-steps/add-transaction/model";
import { PROPERTY_JOURNALS_ROUTE } from "@app/products/property/journals/[id]/constant";
import { TransactionAllocationMode } from "@app/products/property/journals/list/components/dialogs/create-journal/model";
import {
  DTO_WorkflowHeader,
  EListSubmitButton,
  EWorkflowStatus,
  WorkflowProcessMode,
  WorkflowTypes,
} from "@app/products/property/model";
import {
  convertValueLOVToNumber,
  isShowParkButton,
} from "@app/products/property/util";
import { APIResponse } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { RECORDTYPE } from "@common/constants/recordtype";
import { ResponsePacket } from "@common/models/identityPacket";
import { DTO_LOV_Number } from "@common/models/odataResponse";
import { Label } from "@common/stores/products/config";
import { useCommonProductStore } from "@common/stores/products/store";
import { ICCLocalNotificationHandle } from "@components/cc-app-notification/_index";
import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCDialog } from "@components/cc-dialog/_index";
import {
  CCFormStep,
  ICCFormStepNotificationHandle,
  ICCFormStepRender,
} from "@components/cc-form-step/_index";
import { IStep } from "@components/cc-form-step/model";
import { CCGridEventType } from "@components/cc-grid/constant";
import { Button } from "@progress/kendo-react-buttons";
import { cloneDeep, isNil, pickBy, uniqBy } from "lodash";
import React, { useMemo, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";
import {
  DTO_Journal_Add_Transaction_LOVs,
  DTO_Workflow_JournalAddTransaction,
  EKeysOfStepsAddTransaction,
  keysOfStepsAddTransaction,
} from "./model";
import { usePropertyWorkflow } from "@app/products/property/components/action-bar/property-workflow/component/hooks/useProprtyWorkflow/usePropertyWorkflow";
interface INewAssessmentTransactionDialog {
  onClose: () => void;
  prefixTitle?: string;
  suffixTitle?: string;
  dataFromActionList?: VO_Workflow_Draft;
  isSaveOnNextStep?: boolean;
}
export const AddAssessmentTransactionDialog = ({
  onClose,
  isSaveOnNextStep = false,
  prefixTitle,
  suffixTitle,
  dataFromActionList,
}: INewAssessmentTransactionDialog) => {
  //#region <Store>
  const { currentFormTitle } = useCommonProductStore();
  const {
    setOriginNewChargeDetailsStepLOVs,
    setNewChargeDetailsStepLOVs,
    originNewChargeDetailsStepLOVs,
    dataLOvsGroupByFinancialGroup,
  } = useNewChargeDetailsAddTransactionStepStore();
  const { setNewAssessmentJournalStepLOVs, newAssessmentJournalStepLOVs } =
    useAssessmentAddTransactionJournalStepStore();
  const { pushNotification } = useCCAppNotificationStore();
  const { setDataForCancelDialog } = useConfirmCancelDialogStore();
  const { setDataForFinishDialog } = useConfirmFinishDialogStore();
  const { setDataForCloseDialog, setIsLoadingClose } =
    useConfirmCloseDialogStore();
  const {
    isIncompleteMode,
    isFromActionList,
    isReadOnly,
    statusBadge,
    isShowCancelWorkflowButton,
    isToBeApprovalMode,
  } = usePropertyWorkflow(dataFromActionList);
  //#endregion

  //#region <Local state>
  const history = useHistory();
  const formStepRef = useRef<ICCFormStepNotificationHandle | null>(null);
  const localNotificationRef = useRef<ICCLocalNotificationHandle | null>(null);
  const { id } = useParams<{ id: string }>();
  const [workflowInitData, setWorkflowInitData] =
    useState<DTO_Workflow_JournalAddTransaction>();
  const [workflowDraftId, setWorkflowDraftId] = useState<number>(0);
  const [isFirstSave, setIsFirstSave] = useState<boolean>(true);
  const [isLoadingInStep, setIsLoadingInStep] = useState<boolean>(false);
  const [previousAssessmentID, setPreviousAssessmentID] = useState<number>();
  const [isLoadingProcess, setIsLoadingProcess] = useState<
    WorkflowProcessMode | undefined
  >();
  const [workflowHeader, setWorkflowHeader] = useState<DTO_WorkflowHeader>({
    WorkflowDraft: { Workflow_Draft_Id: 0 },
    AvailableSecondaryWorkflows: [],
    WorkflowApprovals: [],
  });
  const onChangeRef = useRef<any>();
  //#endregion

  //Get labels
  const [assessmentLabel, assessmentLowercaseLabel] =
    Label.CommunityProperty.getLabel([
      ECustomColNameProperty.Assessment,
      ECustomColNameProperty.AssessmentLowercase,
    ]);

  //#region <Title header>
  const workflowName = useMemo(() => {
    const formId = workflowInitData?.WorkflowHeader?.WorkflowDraft?.WD_Form_Id;
    return currentFormTitle(formId ?? 0) || "New Transaction";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workflowInitData]);

  const titleHeader = useMemo(() => {
    return getTitleWorkflow(workflowName, prefixTitle, suffixTitle);
  }, [prefixTitle, suffixTitle, workflowName]);
  //#region

  //#region <Initial value>
  const initialValue = useMemo((): any => {
    const transactionType =
      workflowInitData?.WorkflowDetail?.AssessmentTransaction
        ?.Transaction_Type || undefined;
    let optionTransactionType;
    if (!isNil(transactionType)) {
      optionTransactionType =
        newAssessmentJournalStepLOVs?.TransactionTypes?.find(
          (item: DTO_LOV_Transaction) => item?.Code === transactionType
        );
    }
    const assessments = workflowInitData?.WorkflowDetail?.Assessments ?? [];
    if (assessments?.length === 1) {
      setPreviousAssessmentID(assessments[0]?.Assessment_Id);
    }
    let initAssessmentTransaction: any = {
      ...workflowInitData?.WorkflowDetail?.AssessmentTransaction,
      Assessments: assessments,
      Transaction_Type: transactionType,
      TransactionDateTime:
        workflowInitData?.WorkflowDetail?.AssessmentTransaction
          ?.TransactionDateTime ?? new Date(),
      _option: { Transaction_Type: optionTransactionType },
    };
    const levyId =
      workflowInitData?.WorkflowDetail?.NewCharge?.LevyId || undefined;
    const ratingPeriod =
      workflowInitData?.WorkflowDetail?.NewCharge?.RatingPeriod;
    let optionLevyId;
    let optionRatingPeriod;
    if (levyId) {
      optionLevyId = originNewChargeDetailsStepLOVs?.Levy?.find(
        (item) => item?.Code === levyId
      );
    }
    if (ratingPeriod) {
      optionRatingPeriod = originNewChargeDetailsStepLOVs?.RatingPeriods?.find(
        (item) => item?.Code === ratingPeriod
      );
    }
    let initNewChargeDetail: any = {
      ...workflowInitData?.WorkflowDetail?.NewCharge,
      ChargeDate:
        workflowInitData?.WorkflowDetail?.NewCharge?.ChargeDate ?? new Date(),
      RatingPeriod: ratingPeriod,
      LevyId: levyId,
      Amount:
        workflowInitData?.WorkflowDetail?.NewCharge?.Amount ??
        workflowInitData?.WorkflowDetail?.AssessmentTransaction?.Amount,
      _option: { LevyId: optionLevyId, RatingPeriod: optionRatingPeriod },
    };
    const manualChargeBalances =
      workflowInitData?.WorkflowDetail?.AllocationChargeBalance
        ?.ManualChargeBalances ?? [];
    let chargeBalancesIDs: number[] = [];
    if (manualChargeBalances?.length) {
      chargeBalancesIDs = manualChargeBalances.map(
        (item) => item.Charge_Balances_Id
      );
    }

    let initAllocationChargeBalances: any = {
      ManualChargeBalances:
        workflowInitData?.WorkflowDetail?.AllocationChargeBalance
          ?.ManualChargeBalances ?? [],
      ChargeBalance:
        workflowInitData?.WorkflowDetail?.AllocationChargeBalance
          ?.ChargeBalance ?? [],
      _option: { ChargeBalancesIDs: chargeBalancesIDs },
    };

    formStepRef?.current?.setStepsVisible([
      {
        key: EKeysOfStepsAddTransaction.AllocationChargeBalances,
        visible:
          workflowInitData?.WorkflowDetail?.AssessmentTransaction
            ?.AllocationMode === TransactionAllocationMode.Manual,
        isClearData: true,
      },
      {
        key: EKeysOfStepsAddTransaction.NewChargeDetail,
        visible:
          workflowInitData?.WorkflowDetail?.AssessmentTransaction
            ?.AllocationMode === TransactionAllocationMode.NewCharge,
        isClearData: true,
      },
    ]);
    return {
      [EKeysOfStepsAddTransaction.AssessmentJournal]: initAssessmentTransaction,
      [EKeysOfStepsAddTransaction.NewChargeDetail]: initNewChargeDetail,
      [EKeysOfStepsAddTransaction.AllocationChargeBalances]:
        initAllocationChargeBalances,
      [EKeysOfStepsAddTransaction.Comments]: {},
      [EKeysOfStepsAddTransaction.Documents]: {},
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workflowInitData]);
  //#region

  //#region <Step>
  const steps: IStep[] = [
    {
      label: assessmentLabel,
      component: AssessmentAddTransactionJournalFormStep,
      visible: true,
      key: EKeysOfStepsAddTransaction.AssessmentJournal,
      options: {
        isReadOnly,
        workflowDraftId,
        onChangeRef,
        assessmentLowercaseLabel,
        setIsLoadingInStep,
      },
      initialValues: initialValue[EKeysOfStepsAddTransaction.AssessmentJournal],
    },
    {
      label: "New charge details",
      component: NewChargeDetailsAddTransactionFormStep,
      visible: true,
      key: EKeysOfStepsAddTransaction.NewChargeDetail,
      options: {
        isReadOnly,
        workflowDraftId,
      },
      initialValues: initialValue[EKeysOfStepsAddTransaction.NewChargeDetail],
    },
    {
      label: "Allocation to charge balances",
      component: AllocationToChargeBalancesAddTransactionFormStep,
      visible: false,
      key: EKeysOfStepsAddTransaction.AllocationChargeBalances,
      options: {
        isReadOnly,
        workflowDraftId,
      },
      initialValues:
        initialValue[EKeysOfStepsAddTransaction.AllocationChargeBalances],
    },
    {
      label: "Comments",
      component: CommentsFormStep,
      visible: true,
      key: EKeysOfStepsAddTransaction.Comments,
      customClassName: "cc-comment-step-fixed-height-grid",
      options: {
        isReadOnly,
        workflowDraftId,
        recordType: RECORDTYPE.CommunityProperty_Journal,
      },
      initialValues: initialValue[EKeysOfStepsAddTransaction.Comments],
    },
    {
      label: "Documents",
      component: DocumentsFormStep,
      visible: true,
      key: EKeysOfStepsAddTransaction.Documents,
      options: {
        isReadOnly,
        workflowDraftId,
        workflowType: WorkflowTypes.Journal_Add_Transaction,
      },
      initialValues: initialValue[EKeysOfStepsAddTransaction.Documents],
    },
  ];
  //#endregion

  //#region <Handle submit>
  const handleSubmit = async (data: any, buttonId?: string) => {
    switch (buttonId) {
      case EListSubmitButton.Finish:
        handleConfirmFinish(data);
        break;
      case EListSubmitButton.Cancel:
      case EListSubmitButton.ConfirmCloseNo:
        handleCancelButton(processData(data));
        break;
      case EListSubmitButton.ConfirmCloseYes:
        await handleParkProcess(
          processData(data),
          EListSubmitButton.ConfirmCloseYes
        );
        break;
      case EListSubmitButton.Park:
      case EListSubmitButton.Close:
        await handleParkProcess(processData(data));
        break;
    }
  };
  //#endregion

  //#region <Process data>
  const processData = (data: any): DTO_Workflow_JournalAddTransaction => {
    let workflowDetail: any = {};
    const newData = cloneDeep(data);
    const sendSteps = pickBy(newData, (value, key) => {
      if (
        keysOfStepsAddTransaction.includes(key as EKeysOfStepsAddTransaction)
      ) {
        return { [key]: value };
      }
    });
    for (const [key, value] of Object.entries(sendSteps)) {
      const dataStep = { ...value };
      if (dataStep && dataStep?._option) {
        delete dataStep._option;
      }
      workflowDetail[key] = dataStep;
    }
    //process data Assessment
    const assessment =
      data?.[EKeysOfStepsAddTransaction.AssessmentJournal]?.Assessments;
    workflowDetail.Assessments = assessment?.length ? assessment : [];
    //process data Allocation
    const chargeBalanceList = cloneDeep(
      data?.[EKeysOfStepsAddTransaction.AllocationChargeBalances]?.ChargeBalance
    );
    let manualChargeBalance =
      data?.[EKeysOfStepsAddTransaction.AllocationChargeBalances]
        ?.ManualChargeBalances ?? [];
    const listChargeBalanceIdChanges =
      data?.[EKeysOfStepsAddTransaction.AllocationChargeBalances]?._option
        ?.ChargeBalancesIDs ?? [];
    if (listChargeBalanceIdChanges?.length) {
      const chargeBalanceListChange = chargeBalanceList.filter(
        (item: DTO_ChargeBalance) =>
          listChargeBalanceIdChanges.includes(item.Charge_Balances_Id)
      );
      manualChargeBalance = chargeBalanceListChange?.map(
        (chargeBalance: DTO_ChargeBalance) => {
          return {
            Charge_Balances_Id: chargeBalance?.Charge_Balances_Id,
            BalanceAmount: chargeBalance?.Adjusted_Amount ?? null,
            Interest: chargeBalance?.Adjusted_Interest ?? null,
          };
        }
      );
    }
    workflowDetail.AllocationChargeBalance = {
      ChargeBalance: chargeBalanceList,
      ManualChargeBalances: manualChargeBalance,
    };

    return {
      WorkflowHeader: workflowHeader,
      WorkflowDetail: workflowDetail,
    };
  };
  //#endregion

  //#region <Handle process workflow>
  /**
   * common function
   * handle calling api with multiple process
   * @param props
   */
  const handleProcessWorkflow = async (
    props: INewProcessWorkflow<DTO_Workflow_JournalAddTransaction>
  ) => {
    const {
      actionSuccess,
      defaultFailedMessage,
      setLoading,
      modeProcess,
      payload,
    } = props;
    const response = await postProcessAddJournalTransaction(
      modeProcess,
      payload
    );
    setLoading();
    if (isSuccessResponse(response)) {
      if (response?.data?.IsSuccess) {
        actionSuccess(response?.data);
      } else {
        if (props?.actionFail) props?.actionFail(response);
        formStepRef?.current
          ?.getNotificationFormStep()
          ?.current?.pushNotification({
            title: response.data?.ErrorMessage ?? defaultFailedMessage,
            type: "error",
            autoClose: false,
          });
      }
    } else {
      if (props?.actionFail) props?.actionFail(response);
      formStepRef?.current
        ?.getNotificationFormStep()
        ?.current?.pushNotification({
          title: response?.data?.ErrorMessage ?? defaultFailedMessage,
          type: "error",
          autoClose: false,
        });
    }
    if (props?.actionClose) props.actionClose();
  };
  //#endregion

  //#region <Handle next button>
  /**
   * @param data
   * @param step
   * @param keyStep
   * @returns
   */
  const handleNextButton = async (data: DTO_Workflow_JournalAddTransaction) => {
    const newData = { ...data };
    const processPayload = processData(newData);
    //send data to call api save
    return handleSaveAndNext(processPayload, data);
  };
  //#endregion

  //#region <process Data after succeed previous step (Assessment step) >
  const processDataLovsNewChargeDetail = async (
    payload: DTO_Workflow_JournalAddTransaction,
    data: any
  ) => {
    if (!originNewChargeDetailsStepLOVs) return;
    const dataOriginLovsNewChargeDetail: INewChargeDetailsTransactionStepsLOVs =
      {
        ...originNewChargeDetailsStepLOVs,
      };
    //allocation mode
    const allocationMode =
      payload?.WorkflowDetail?.AssessmentTransaction?.AllocationMode;
    if (allocationMode === eAssessmentAllocation.NEW_CHARGES) {
      //clone data lovs
      const newRatingPeriod = cloneDeep(
        dataLOvsGroupByFinancialGroup?.RatingPeriods
      );
      const newLevy = cloneDeep(dataLOvsGroupByFinancialGroup?.Levy);
      //transactionType
      const transactionType =
        payload?.WorkflowDetail?.AssessmentTransaction?.Transaction_Type;
      //financial group in transaction
      let financialGroups =
        data?.AssessmentTransaction?._option?.Transaction_Type
          ?.Financial_Groups;
      if (isNil(financialGroups)) {
        financialGroups = newAssessmentJournalStepLOVs?.TransactionTypes?.find(
          (item: DTO_LOV_Transaction) => item?.Code === transactionType
        )?.Financial_Groups;
      }
      if (financialGroups?.length) {
        //filter data by financial group
        let ratingPeriodLovs: DTO_LOV_RatingPeriod[] = [];
        let levyLovs: DTO_LOV_Levy[] = [];
        financialGroups?.forEach((group: number) => {
          let ratingPeriodByGroup: DTO_LOV_RatingPeriod[] = [];
          if (newRatingPeriod) {
            ratingPeriodByGroup = newRatingPeriod[group] ?? [];
          }
          ratingPeriodLovs = [...ratingPeriodLovs, ...ratingPeriodByGroup];
          ratingPeriodLovs = uniqBy(ratingPeriodLovs, "Code");

          let levyByGroup: DTO_LOV_Levy[] = [];
          if (newLevy) {
            levyByGroup = newLevy[group] ?? [];
          }
          levyLovs = [...levyLovs, ...levyByGroup];
          levyLovs = uniqBy(levyLovs, "Code");
        });
        //set new lovs
        setNewChargeDetailsStepLOVs({
          ...dataOriginLovsNewChargeDetail,
          RatingPeriods: ratingPeriodLovs,
          Levy: levyLovs,
        });
      } else {
        //get current value
        const valueRatingPeriod =
          data?.[`${EKeysOfStepsAddTransaction.NewChargeDetail}`]?.RatingPeriod;
        const valueLevy =
          data?.[`${EKeysOfStepsAddTransaction.NewChargeDetail}`]?.LevyId;

        if (!isNil(valueRatingPeriod) && isNil(valueLevy)) {
          const financialGroup =
            data?.[`${EKeysOfStepsAddTransaction.NewChargeDetail}`]?._option
              ?.RatingPeriod?.Financial_Groups ?? [];
          if (financialGroup?.length) {
            let listLevy: DTO_LOV_Levy[] = [];
            financialGroups?.forEach((group: number) => {
              let levyByGroup: DTO_LOV_Levy[] = [];
              if (newLevy) {
                levyByGroup = newLevy[group] ?? [];
              }
              listLevy = [...listLevy, ...levyByGroup];
              listLevy = uniqBy(listLevy, "Code");
            });
            setNewChargeDetailsStepLOVs({
              ...dataOriginLovsNewChargeDetail,
              Levy: listLevy,
            });
          }
        } else if (isNil(valueRatingPeriod) && !isNil(valueLevy)) {
          const financialGroupId =
            data?.[`${EKeysOfStepsAddTransaction.NewChargeDetail}`]?._option
              ?.Levy?.Financial_Group_Id;
          if (financialGroupId) {
            let listRatingPeriod: DTO_LOV_RatingPeriod[] = [];
            financialGroups?.forEach((group: number) => {
              let ratingByGroup: DTO_LOV_RatingPeriod[] = [];
              if (newRatingPeriod) {
                ratingByGroup = newRatingPeriod[group] ?? [];
              }
              listRatingPeriod = [...listRatingPeriod, ...ratingByGroup];
              listRatingPeriod = uniqBy(listRatingPeriod, "Code");
            });
            setNewChargeDetailsStepLOVs({
              ...dataOriginLovsNewChargeDetail,
              RatingPeriods: listRatingPeriod,
            });
          }
        } else {
          setNewChargeDetailsStepLOVs(dataOriginLovsNewChargeDetail);
        }
      }
    } else if (allocationMode === eAssessmentAllocation.CHARGES_BALANCES) {
      const newAssessmentId =
        data?.AssessmentTransaction?.Assessments?.[0]?.Assessment_Id;
      if (previousAssessmentID !== newAssessmentId)
        setPreviousAssessmentID(newAssessmentId);
      const chargeBalanceData =
        data?.AllocationChargeBalance?.ChargeBalance ?? [];
      //check condition to load api list charge balance
      if (
        previousAssessmentID !== newAssessmentId ||
        chargeBalanceData?.length === 0
      ) {
        await loadAssessmentChargeBalanceDetail(newAssessmentId).then(
          (response) => {
            if (isSuccessResponse(response) && response.data) {
              const chargeBalanceItems = response.data?.ChargeBalances
                ? response.data?.ChargeBalances
                : [];
              if (onChangeRef?.current) {
                const { onChange } = onChangeRef.current;
                onChange(
                  `${EKeysOfStepsAddTransaction.AllocationChargeBalances}.ChargeBalance`,
                  {
                    value: chargeBalanceItems,
                  }
                );
              }
            } else {
              localNotificationRef?.current?.pushNotification({
                title: "Assessment charge balance load failed",
                type: "error",
              });
            }
          }
        );
      }
    }
  };
  //#endregion

  //#region <Handle save and next>
  const handleSaveAndNext = async (
    payload: DTO_Workflow_JournalAddTransaction,
    data: any
  ): Promise<boolean> => {
    //check condition use for Save button
    setIsLoadingProcess(WorkflowProcessMode.Save);
    //Calling process Save at next button
    const response = await postProcessAddJournalTransaction(
      WorkflowProcessMode.Save,
      payload
    );
    await processDataLovsNewChargeDetail(payload, data);
    setIsLoadingProcess(undefined);
    //set default notification
    const defaultSuccessMessage = "New transaction was saved successfully.";
    const defaultFailedMessage = "New transaction could not be saved.";
    if (isSuccessResponse(response) && response?.data?.IsSuccess) {
      if (!isSaveOnNextStep) {
        onClose();
        pushNotification({
          title: response?.data?.Notification ?? defaultSuccessMessage,
          type: "success",
        });
      } else {
        // check is the first saving
        if (isFirstSave) {
          setIsFirstSave(false);
          //set current workflowDraft Id
          setWorkflowDraftId(response?.data?.ID ?? 0);
          // set payload to send
          setWorkflowHeader({
            ...workflowHeader,
            WorkflowDraft: {
              ...workflowHeader.WorkflowDraft,
              Workflow_Draft_Id: response?.data?.ID,
            },
          });
        }
      }
      return true;
    } else {
      formStepRef?.current
        ?.getNotificationFormStep()
        ?.current?.pushNotification({
          title: response.data?.ErrorMessage ?? defaultFailedMessage,
          type: "error",
          autoClose: false,
        });
    }
    return false;
  };
  //#endregion

  //#region <Handle finish process>
  /**
   * @param payload
   */
  const handleFinishProcess = async (
    payload: DTO_Workflow_JournalAddTransaction
  ) => {
    //set loading button
    setIsLoadingProcess(WorkflowProcessMode.Finish);
    //props send to process workflow
    const finishProps: INewProcessWorkflow<DTO_Workflow_JournalAddTransaction> =
      {
        payload: payload,
        actionSuccess: (e) => {
          const notificationContent: IAppNotificationItemAddProps = {
            title:
              e?.SuccessMessage ??
              e?.Notification ??
              "New transaction was created successfully.",
            type: "success",
          };
          onClose();
          if (!isNil(e?.Component_ID)) {
            history.push(`${PROPERTY_JOURNALS_ROUTE}/${e?.Component_ID}`, {
              notification: notificationContent,
            });
          } else {
            pushNotification(notificationContent);
          }
        },
        setLoading: () => {
          setIsLoadingProcess(undefined);
        },
        defaultFailedMessage: "New transaction could not be created.",
        modeProcess: WorkflowProcessMode.Finish,
      };
    //calling api process workflow
    await handleProcessWorkflow(finishProps);
  };
  //#endregion

  //#region <Handle confirm finish>
  const handleConfirmFinish = (payload: DTO_Workflow_JournalAddTransaction) => {
    const dataProcessed = processData(payload);
    const finishCallback = function async() {
      return handleFinishProcess(dataProcessed);
    };
    setDataForFinishDialog({
      finishCallback,
      confirmMessage:
        "The new transaction will be created based on the information provided. Are you sure you want to submit?",
    });
  };
  //#endregion

  //#region <Handle park process>
  /**
   * @param payload
   * @param mode
   */
  const handleParkProcess = async (
    payload: DTO_Workflow_JournalAddTransaction,
    mode?: EListSubmitButton
  ) => {
    //set loading button and dialog
    setIsLoadingProcess(WorkflowProcessMode.Park);
    mode === EListSubmitButton.ConfirmCloseYes && setIsLoadingClose(true);
    const actionCloseRetainDialog = () => {
      setIsLoadingClose(false);
      setDataForCloseDialog();
    };
    //props send to process workflow
    const parkProps: INewProcessWorkflow<DTO_Workflow_JournalAddTransaction> = {
      payload: payload,
      actionSuccess: (e) => {
        onClose();
        pushNotification({
          title: e?.Notification ?? "New transaction was parked successfully.",
          type: "success",
        });
        isFromActionList && eventEmitter.emit(CCGridEventType.RefreshOData);
      },
      setLoading: () => {
        setIsLoadingProcess(undefined);
      },
      actionClose: () => {
        mode === EListSubmitButton.ConfirmCloseYes && actionCloseRetainDialog();
      },
      defaultFailedMessage: "New transaction could not be parked.",
      modeProcess: WorkflowProcessMode.Park,
    };
    //calling api process workflow
    await handleProcessWorkflow(parkProps);
  };
  //#endregion

  //#region <handle cancel button>
  const handleCancelButton = (data: any) => {
    if (isFromActionList || !isFirstSave) {
      setDataForCancelDialog({
        cancelAPI: postProcessAddJournalTransaction,
        dataCancel: data,
        defaultSuccessMessage: "New transaction was cancelled successfully",
        defaultErrorMessage: "New transaction could not be cancelled.",
      });
    } else {
      onClose();
    }
  };
  //#endregion

  //#region <Handle close dialog>
  /**
   * @param renderProps
   */
  const handleCloseDialog = (renderProps: ICCFormStepRender) => {
    if (!isFromActionList && !isFirstSave) {
      //Store submit event
      setDataForCloseDialog({
        closeCallback: renderProps.submitButton.onClick,
      });
    } else if (
      isIncompleteMode &&
      dataFromActionList?.Workflow_Status_Name === EWorkflowStatus.Park
    ) {
      onClose();
    } else if (
      dataFromActionList?.Workflow_Status_Name === EWorkflowStatus.Incomplete &&
      !isFirstSave
    ) {
      const newEvent = {
        currentTarget: { id: EListSubmitButton.Close },
      };
      renderProps.submitButton.onClick(newEvent);
    } else {
      onClose();
    }
  };
  //#endregion

  //#region <Get workflow data>
  /**
   * load initValue for FormStep
   * call once time
   */
  const getWorkflowData = async () => {
    const workflowDraftId = dataFromActionList?.Workflow_Draft_Id;
    formStepRef?.current?.setLoadingFormStep(true);
    const response = await getInitialDataJournalTransaction(
      workflowDraftId,
      +id
    );
    if (Array.isArray(response)) {
      const [lovsJournal, workflowData] = response;
      if (
        isSuccessResponse(lovsJournal) &&
        isSuccessResponse(workflowData) &&
        lovsJournal?.data &&
        workflowData?.data
      ) {
        //set Lovs Data Assessment
        const newChargeDetail = {
          RatingPeriods: convertValueLOVToNumber<DTO_LOV_Number>(
            lovsJournal?.data?.RatingPeriods ?? [],
            "Code"
          ),
          InstalmentPlans: convertValueLOVToNumber(
            lovsJournal?.data?.InstalmentPlans ?? [],
            "Code"
          ),
          Levy: convertValueLOVToNumber<DTO_LOV_Number>(
            lovsJournal?.data?.Levy ?? [],
            "Code"
          ),
        };
        setNewChargeDetailsStepLOVs(newChargeDetail);
        setOriginNewChargeDetailsStepLOVs(newChargeDetail);
        setNewAssessmentJournalStepLOVs({
          TransactionTypes: convertValueLOVToNumber(
            lovsJournal?.data?.TransactionTypes ?? [],
            "Code"
          ),
          PaymentAllocations: convertValueLOVToNumber(
            lovsJournal?.data?.PaymentAllocations ?? [],
            "Code"
          ),
          IsRebateTypeTransaction:
            lovsJournal?.data?.IsRebateTypeTransaction ?? false,
          AssessRebateEntitlements: convertValueLOVToNumber<DTO_LOV_Number>(
            lovsJournal?.data?.AssessRebateEntitlements ?? [],
            "Code"
          ),
        });
        setWorkflowInitData(workflowData?.data);
        if (workflowData.data?.WorkflowHeader) {
          setWorkflowHeader(workflowData.data?.WorkflowHeader);
          setWorkflowDraftId(
            workflowData.data?.WorkflowHeader?.WorkflowDraft
              ?.Workflow_Draft_Id ?? 0
          );
        }
        formStepRef?.current?.setLoadingFormStep(false);
      } else {
        let responseError: APIResponse<
          | DTO_Journal_Add_Transaction_LOVs
          | DTO_Workflow_JournalAddTransaction
          | ResponsePacket
        > = response[0];
        if (!isSuccessResponse(response[1])) {
          responseError = response[1];
        }
        formStepRef?.current?.setLoadingFormStep(false);
        formStepRef?.current?.setLoadFailedFormStep({
          onReload: () => getWorkflowData(),
          responseError: {
            status: responseError.status,
            error:
              (responseError.data as ResponsePacket)?.Errors ??
              "Load workflow failed",
          },
        });
      }
    } else {
      const responseError = response as APIResponse;
      formStepRef?.current?.setLoadingFormStep(false);
      formStepRef?.current?.setLoadFailedFormStep({
        onReload: () => getWorkflowData(),
        responseError: {
          status: responseError.status,
          error: "Load workflow failed",
        },
      });
    }
  };
  //#endregion

  //#region <Use Effect calling initial data>
  useEffectOnce(() => {
    getWorkflowData();
  });
  //#region

  return (
    <>
      <CCFormStep
        ref={formStepRef}
        onSubmit={handleSubmit}
        localNotificationRef={localNotificationRef?.current ?? null}
        listButtonId={listSubmitButton}
        initialSteps={steps}
        initialValues={initialValue}
        saveOnNextStep={isSaveOnNextStep ? handleNextButton : undefined}
        renderForm={(renderProps: ICCFormStepRender) => (
          <CCDialog
            maxWidth="60%"
            titleHeader={titleHeader}
            onClose={() => handleCloseDialog(renderProps)}
            bodyElement={renderProps.children}
            badge={statusBadge}
            disabled={isLoadingProcess !== undefined || isLoadingInStep}
            footerElement={
              <div className={"cc-dialog-footer-actions-right"}>
                {isShowParkButton(isFromActionList, isIncompleteMode) && (
                  <Button
                    iconClass={
                      isLoadingProcess === WorkflowProcessMode.Park
                        ? "fas fa-spinner fa-spin"
                        : ""
                    }
                    className={"cc-dialog-button"}
                    id={EListSubmitButton.Park}
                    onClick={renderProps.submitButton.onClick}
                    disabled={
                      renderProps.nextButton.disabled ||
                      isLoadingProcess === WorkflowProcessMode.Park
                    }
                  >
                    Park
                  </Button>
                )}
                {isShowCancelWorkflowButton && (
                  <Button
                    className={"cc-dialog-button"}
                    disabled={!isNil(isLoadingProcess)}
                    id={EListSubmitButton.Cancel}
                    onClick={renderProps.submitButton.onClick}
                  >
                    Cancel
                  </Button>
                )}
                {!renderProps.prevButton.disabled && (
                  <Button
                    className={"cc-dialog-button"}
                    themeColor="primary"
                    onClick={renderProps.prevButton.onClick}
                  >
                    Previous
                  </Button>
                )}
                <Button
                  themeColor="primary"
                  id={
                    !renderProps.isLastStep &&
                    (isToBeApprovalMode || isReadOnly)
                      ? "cc-next-step-button"
                      : renderProps.nextButton.idButton
                  }
                  disabled={
                    isLoadingProcess === WorkflowProcessMode.Save ||
                    renderProps.nextButton.disabled
                  }
                  className={"cc-dialog-button"}
                  iconClass={
                    isLoadingProcess === WorkflowProcessMode.Save
                      ? "fas fa-spinner fa-spin"
                      : ""
                  }
                  onClick={renderProps.nextButton.onClick}
                >
                  {isLoadingProcess === WorkflowProcessMode.Save
                    ? "Saving"
                    : renderProps.nextButton.label}
                </Button>
              </div>
            }
          />
        )}
      />
    </>
  );
};
