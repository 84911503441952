import { eventEmitter } from "@/App";
import { COMMENT_ACCORDION_GRID_ID } from "@app/core/comments/constant";
import { VO_Workflow_Draft } from "@app/products/property/actions/model";
import { AssessmentRebatesEventType } from "@app/products/property/assessments/[id]/components/child-screens/rebates/constant";
import {
  getInitialDataNewRebateEntitlement,
  postProcessNewRebateEntitlement,
} from "@app/products/property/assessments/components/form-steps/add-rebate/api";
import { CalculationsStep } from "@app/products/property/assessments/components/form-steps/add-rebate/components/form-elements/calculations/_index";
import { ConcessionCardsFormStep } from "@app/products/property/assessments/components/form-steps/add-rebate/components/form-elements/concession-cards/_index";
import { DetailsFormStep } from "@app/products/property/assessments/components/form-steps/add-rebate/components/form-elements/details/_index";
import { RebateEntitlementsFormStep } from "@app/products/property/assessments/components/form-steps/add-rebate/components/form-elements/rebate_entitlements/_index";
import {
  DTO_AssessmentRebate_LOVs,
  DTO_NewRebate_RebateTypeRatingPeriod,
  DTO_WorkflowDetail_NewRebate,
  DTO_Workflow_NewRebate,
  EKeysOfAddRebateSteps,
  RequestNewRebateObj,
  keysOfAddRebateSendSteps,
} from "@app/products/property/assessments/components/form-steps/add-rebate/model";

import { listSubmitButton } from "@app/products/property/assessments/components/form-steps/new-assessment/config";
import { useConfirmCancelDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-cancel/store";
import { useConfirmCloseDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-close/store";
import { useConfirmFinishDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-finish/store";
import { useConfirmReallocateDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-reallocate/store";
import { useConfirmRejectDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-reject/store";
import { useConfirmSendBackDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-send-back/store";
import { CommentsFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/comments/_index";
import { DocumentsFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/documents/_index";
import { WorkflowStepFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/workflow/_index";
import {
  getSuffixTitle,
  secondaryWorkflowUtilProcessing,
} from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/workflow/util";
import { usePropertyWorkflow } from "@app/products/property/components/action-bar/property-workflow/component/hooks/useProprtyWorkflow/usePropertyWorkflow";
import { PROPERTY_ACTIONS_APPROVED_LIST_ROUTE } from "@app/products/property/components/action-bar/property-workflow/constant";
import {
  IListDialog,
  INewProcessWorkflow,
} from "@app/products/property/components/action-bar/property-workflow/model";
import { usePropertyWorkflowStore } from "@app/products/property/components/action-bar/property-workflow/store";
import { getTitleWorkflow } from "@app/products/property/components/action-bar/property-workflow/util";
import { PropertyDocumentEventType } from "@app/products/property/components/child-screen/documents/constant";
import { officerUtilProcessing } from "@app/products/property/components/fields/officer-and-officer-region/util";
import { ECustomColNameProperty } from "@app/products/property/config";
import {
  EListSubmitButton,
  EWorkflowStatus,
  WorkflowProcessMode,
  WorkflowTypes,
} from "@app/products/property/model";
import { isShowParkButton } from "@app/products/property/util";
import { APIResponse } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { RECORDTYPE } from "@common/constants/recordtype";
import { ResponsePacket } from "@common/models/identityPacket";
import { Label } from "@common/stores/products/config";
import { OrganisationMode } from "@common/stores/products/model";
import { useCommonProductStore } from "@common/stores/products/store";
import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCDialog } from "@components/cc-dialog/_index";
import {
  CCFormStep,
  ICCFormStepNotificationHandle,
  ICCFormStepRender,
} from "@components/cc-form-step/_index";
import { IStep } from "@components/cc-form-step/model";
import { CCGridEventType } from "@components/cc-grid/constant";
import { Button } from "@progress/kendo-react-buttons";
import { head, isNil, pickBy } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";

interface IAddRebateDialogProps {
  onClose: () => void;
  assessmentID: number;
  statusID: number;
  dataFromActionList?: VO_Workflow_Draft;
  prefixTitle?: string;
  isSaveOnNextStep?: boolean;
  suffixTitle?: string;
  isRedirectManagePage?: boolean;
}

export const AddRebateDialog = observer(
  ({
    onClose,
    assessmentID,
    statusID,
    dataFromActionList,
    prefixTitle,
    isSaveOnNextStep = false,
    suffixTitle,
    isRedirectManagePage = false,
  }: IAddRebateDialogProps) => {
    //#region HOOKS -------------------------------------------->
    // Use history
    const history = useHistory();

    // Use params
    const params: { id: string } = useParams();
    const assessmentId: number = +params?.id || assessmentID;

    // Use store
    const { setDataForCloseDialog, setIsLoadingClose } =
      useConfirmCloseDialogStore();
    const { setDataForCancelDialog } = useConfirmCancelDialogStore();
    const { setListDialog } = usePropertyWorkflowStore();
    const { pushNotification } = useCCAppNotificationStore();
    const { setDataForFinishDialog } = useConfirmFinishDialogStore();
    const { setDataForSendBackDialog } = useConfirmSendBackDialogStore();
    const { setDataForReallocateDialog } = useConfirmReallocateDialogStore();
    const { setDataForRejectDialog } = useConfirmRejectDialogStore();
    const { currentFormTitle, currentOrganisationMode } =
      useCommonProductStore();
    const isLLS = currentOrganisationMode(OrganisationMode.LLS);
    const isActro = currentOrganisationMode(OrganisationMode.ACTRO);
    //@TODO Update when have the API
    const isWA = false;

    // Use state
    const [workflowInitialData, setWorkflowInitialData] =
      useState<DTO_Workflow_NewRebate>();
    const [workflowLOVs, setWorkflowLOVs] =
      useState<DTO_AssessmentRebate_LOVs>();
    const [isLoadingProcess, setIsLoadingProcess] = useState<
      WorkflowProcessMode | undefined
    >();
    const [isFirstSave, setIsFirstSave] = useState<boolean>(true);
    const [workflowDraftId, setWorkflowDraftId] = useState<number>(0);
    const notificationFormStepRef =
      useRef<ICCFormStepNotificationHandle | null>(null);

    const {
      isFromActionList,
      isIncompleteMode,
      isReadOnly,
      isShowCancelWorkflowButton,
      isShowReasonRejection,
      isToBeApprovalMode,
      statusBadge,
    } = usePropertyWorkflow(dataFromActionList);

    //Get label
    const titleLowercaseLabel = Label.CommunityProperty.getLabel(
      ECustomColNameProperty.TitleLowercase
    );

    const titleHeader = useMemo(() => {
      const formId =
        workflowInitialData?.WorkflowHeader?.WorkflowDraft?.WD_Form_Id;
      const title = currentFormTitle(formId ?? 0) ?? `New Rebate Entitlement`;

      return getTitleWorkflow(
        title,
        prefixTitle,
        getSuffixTitle(
          suffixTitle,
          isToBeApprovalMode,
          workflowInitialData?.WorkflowHeader?.WorkflowApprovals ?? []
        )
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      workflowInitialData?.WorkflowHeader,
      prefixTitle,
      suffixTitle,
      isToBeApprovalMode,
      workflowInitialData?.WorkflowHeader?.WorkflowApprovals,
    ]);

    const initialValue = useMemo(() => {
      const workflowDetail = workflowInitialData?.WorkflowDetail;

      const officerInitData =
        officerUtilProcessing.processDataInit(workflowInitialData);

      // Details step
      let initDetails = {
        ...workflowDetail?.Details,
        ...officerInitData,
        ReasonId:
          workflowDetail?.Details?.ReasonId &&
          workflowDetail.Details.ReasonId !== 0
            ? workflowDetail.Details.ReasonId.toString()
            : null,
      };

      // Rebate Entitlements step
      let initRebateEntitlement = {
        ...workflowDetail?.RebateEntitlement,
        RebateType_RatingPeriod:
          workflowDetail?.RebateEntitlement?.RebateType_RatingPeriod?.map(
            (item: DTO_NewRebate_RebateTypeRatingPeriod) => {
              const rebateTypes = {
                ...item,
                ...item.RebateType_RatingPeriod,
              };
              delete rebateTypes.RebateType_RatingPeriod;
              return rebateTypes;
            }
          ) ?? [],
      };

      // Concesssion cards step
      let initConcesssionCards = {
        ...workflowDetail?.ConcessionCard,
      };

      // Workflow step
      const initSecondaryWorkflow =
        secondaryWorkflowUtilProcessing.processDataInit(workflowInitialData);

      return {
        [EKeysOfAddRebateSteps.Details]: initDetails,
        [EKeysOfAddRebateSteps.RebateEntitlement]: initRebateEntitlement,
        [EKeysOfAddRebateSteps.ConcessionCard]: initConcesssionCards,
        [EKeysOfAddRebateSteps.Calculations]: {},
        [EKeysOfAddRebateSteps.Comments]: {},
        [EKeysOfAddRebateSteps.Documents]: {},
        [EKeysOfAddRebateSteps.SecondaryWorkflow]: initSecondaryWorkflow,
      };
    }, [workflowInitialData]);

    //Use effect
    /**
     * get workflow data
     */
    const getWorkflowData = async () => {
      notificationFormStepRef?.current?.setLoadingFormStep(true);
      const payload: RequestNewRebateObj = {
        AssessmentId: assessmentId,
        StatusId: statusID,
      };

      const response = await getInitialDataNewRebateEntitlement(
        payload,
        dataFromActionList?.Workflow_Draft_Id
      );
      notificationFormStepRef?.current?.setLoadingFormStep(false);
      const workflowData = response?.data;
      if (isSuccessResponse(response) && workflowData) {
        // Set workflow LOVs
        setWorkflowLOVs(workflowData.WorkflowDetail?.LOVs);
        //Set workflow initial data
        setWorkflowInitialData({
          WorkflowHeader: workflowData.WorkflowHeader,
          WorkflowDetail: workflowData.WorkflowDetail,
        });
        //Set workflow header and workflow draft ID
        if (workflowData?.WorkflowHeader) {
          setWorkflowDraftId(
            workflowData?.WorkflowHeader?.WorkflowDraft?.Workflow_Draft_Id ?? 0
          );
        }
      } else {
        const responseError =
          response as unknown as APIResponse<ResponsePacket>;
        notificationFormStepRef?.current?.setLoadFailedFormStep({
          onReload: () => getWorkflowData(),
          responseError: {
            status: responseError.status,
            error:
              (responseError.data as ResponsePacket)?.Errors ??
              "Load workflow failed",
          },
        });
      }
    };

    useEffectOnce(() => {
      getWorkflowData();
    });
    //#endregion

    //#region COMMON FUNCTIONS -------------------------------------------->
    /**
     * Handle submit to control all buttons in dialog
     * @param events
     * @param buttonId
     */
    const handleSubmit = async (data: any, buttonId?: string) => {
      switch (buttonId) {
        case EListSubmitButton.Approve:
          handleApproveProcess(processData(data));
          break;
        case EListSubmitButton.Save:
          await sendSaveWorkflow(processData(data), true);
          break;
        case EListSubmitButton.SaveWorkflow:
          await sendSaveWorkflow(processData(data), false, true);
          break;
        case EListSubmitButton.Finish:
          handleConfirmFinishProcess(data);
          break;
        case EListSubmitButton.Cancel:
        case EListSubmitButton.ConfirmCloseNo:
          handleCancelButton(processData(data));
          break;
        case EListSubmitButton.ConfirmCloseYes:
          handleParkProcess(
            processData(data),
            EListSubmitButton.ConfirmCloseYes
          );
          break;
        case EListSubmitButton.SendBack:
          handleConfirmSendBackProcess(processData(data));
          break;
        case EListSubmitButton.Reallocate:
          handleConfirmReallocateProcess(processData(data));
          break;
        case EListSubmitButton.Reject:
          handleRejectButton(processData(data));
          break;
        case EListSubmitButton.Park:
        case EListSubmitButton.Close:
          handleParkProcess(processData(data));
          break;
      }
    };

    /**
     * process data payload
     * @param data
     * @returns
     */
    const processData = (data: any) => {
      let workflowDetail: any = {};
      let workflowHeader: any = { ...workflowInitialData?.WorkflowHeader };

      // Process workflow header to send the WD_Assessment_Group_Id/Officer Region Id
      const { WD_Assessment_Group_Id: assessmentGroupIdWD } =
        officerUtilProcessing.processData(data, EKeysOfAddRebateSteps.Details);

      workflowHeader.WorkflowDraft.WD_Assessment_Group_Id = assessmentGroupIdWD;
      workflowDetail.AssessmentId =
        workflowInitialData?.WorkflowDetail?.AssessmentId;

      const sendSteps = pickBy(data, function (value, key) {
        if (keysOfAddRebateSendSteps.includes(key as EKeysOfAddRebateSteps)) {
          return { [key]: value };
        }
      });
      for (const [key, value] of Object.entries(sendSteps)) {
        const dataStep = { ...value };
        if (dataStep && dataStep?._option) {
          delete dataStep._option;
        }
        if (key === EKeysOfAddRebateSteps.RebateEntitlement) {
          const newRebateTypeRatingPeriod = value?.RebateType_RatingPeriod?.map(
            (item: DTO_NewRebate_RebateTypeRatingPeriod) => {
              const { Lots, Parcels, Owners, ...rebateType } = item;
              return {
                Lots,
                Parcels,
                Owners,
                RebateType_RatingPeriod: rebateType,
              };
            }
          );
          workflowDetail.RebateEntitlement = {
            ...value,
            RebateType_RatingPeriod: newRebateTypeRatingPeriod,
          };
        } else if (key === EKeysOfAddRebateSteps.SecondaryWorkflow) {
          secondaryWorkflowUtilProcessing.processData(
            value,
            workflowHeader,
            workflowDetail
          );
        } else {
          workflowDetail[key as keyof DTO_WorkflowDetail_NewRebate] = dataStep;
        }
      }
      return {
        WorkflowHeader: workflowHeader,
        WorkflowDetail: workflowDetail,
      };
    };

    /**
     * common function
     * handle calling api with multiple process
     * @param props
     */
    const handleProcessWorkflow = async (
      props: INewProcessWorkflow<DTO_Workflow_NewRebate>
    ) => {
      const {
        payload,
        actionSuccess,
        actionFail,
        defaultFailedMessage,
        setLoading,
        modeProcess,
        actionClose,
      } = props;
      let secondaryWorkflowSelectedList: IListDialog[] = [];
      if (modeProcess === WorkflowProcessMode.Finish) {
        if (payload.WorkflowDetail?.SecondaryWorkflowSelectedList) {
          secondaryWorkflowSelectedList =
            payload.WorkflowDetail.SecondaryWorkflowSelectedList ?? [];
          delete payload.WorkflowDetail.SecondaryWorkflowSelectedList;
        }
      }

      const response = await postProcessNewRebateEntitlement(
        modeProcess,
        payload
      );

      setLoading();
      if (isSuccessResponse(response) && response?.data?.IsSuccess) {
        actionSuccess(response?.data);
        //Show secondary workflow
        if (
          modeProcess === WorkflowProcessMode.Finish &&
          secondaryWorkflowSelectedList?.length
        ) {
          setListDialog(secondaryWorkflowSelectedList);
        }
      } else {
        if (actionFail) actionFail(response);
        notificationFormStepRef?.current
          ?.getNotificationFormStep()
          ?.current?.pushNotification({
            title: response?.data?.ErrorMessage ?? defaultFailedMessage,
            type: "error",
            autoClose: false,
          });
      }
      if (actionClose) actionClose();
    };
    //#endregion <--------------------------------------------

    //#region SAVE and NEXT -------------------------------------------->
    /**
     * Send save workflow data (call API with 'Save' mode)
     * @param payload
     * @param isCloseDialog
     * @param isRefreshWorkflowData
     */
    const sendSaveWorkflow = async (
      payload: DTO_Workflow_NewRebate,
      isCloseDialog: boolean = false,
      isRefreshWorkflowData: boolean = false
    ) => {
      setIsLoadingProcess(WorkflowProcessMode.Save);
      const response = await postProcessNewRebateEntitlement(
        WorkflowProcessMode.Save,
        payload
      );

      const defaultSuccessMessage =
        "New rebate entitilement was saved successfully";
      const defaultFailedMessage = "New rebate entitilement could not be saved";

      if (isSuccessResponse(response) && response?.data?.IsSuccess) {
        if (isCloseDialog) {
          onClose();
          pushNotification({
            title: response?.data?.SuccessMessage ?? defaultSuccessMessage,
            type: "success",
          });
        }
        if (isFirstSave) {
          setIsFirstSave(false);
          setWorkflowDraftId(response?.data?.ID ?? 0);

          // get new data from draftId after first save
          if (officerUtilProcessing.canReloadWorkflowData(isFromActionList)) {
            setWorkflowInitialData({
              WorkflowDetail: response.data?.ReturnData?.WorkflowDetail,
              WorkflowHeader: response.data?.ReturnData?.WorkflowHeader,
            });
          }
        }
        // TODO: Show notification after reloading the step -> enhance later
        if (isRefreshWorkflowData) {
          getWorkflowData().then(() => {
            notificationFormStepRef?.current
              ?.getNotificationFormStep()
              ?.current?.pushNotification({
                title: response?.data?.Notification ?? defaultSuccessMessage,
                type: "success",
              });
          });
        }
        setIsLoadingProcess(undefined);
        return true;
      } else {
        setIsLoadingProcess(undefined);
        const showNotification = () => {
          notificationFormStepRef?.current
            ?.getNotificationFormStep()
            ?.current?.pushNotification({
              title:
                (isRefreshWorkflowData
                  ? head(response?.data?.Errors)
                  : response.data?.ErrorMessage) ?? defaultFailedMessage,
              type: "error",
              autoClose: false,
            });
        };
        if (isRefreshWorkflowData) {
          getWorkflowData().then(showNotification);
        } else {
          showNotification();
        }
        return false;
      }
    };

    const handleNextButton = async (data: any) => {
      const newData = { ...data };
      const processPayload = processData(newData);
      return sendSaveWorkflow(processPayload);
    };
    //#endregion <--------------------------------------------

    //#region PARK -------------------------------------------->
    /**
     * handle park process
     * @param payload
     */
    const handleParkProcess = (
      payload: DTO_Workflow_NewRebate,
      mode?: EListSubmitButton
    ) => {
      //set loading button and dialog
      setIsLoadingProcess(WorkflowProcessMode.Park);
      mode === EListSubmitButton.ConfirmCloseYes && setIsLoadingClose(true);
      const actionCloseRetainDialog = () => {
        setIsLoadingClose(false);
        setDataForCloseDialog();
      };
      //props send to process workflow
      const parkProps: INewProcessWorkflow<DTO_Workflow_NewRebate> = {
        payload: payload,
        actionSuccess: (e) => {
          onClose();
          pushNotification({
            title:
              e?.Notification ??
              "New rebate entitlement was parked successfully.",
            type: "success",
          });
        },
        setLoading: () => {
          setIsLoadingProcess(undefined);
        },
        actionClose: () => {
          mode === EListSubmitButton.ConfirmCloseYes &&
            actionCloseRetainDialog();
        },
        defaultFailedMessage: "New rebate entitlement could not be parked.",
        modeProcess: WorkflowProcessMode.Park,
      };

      //calling api process workflow
      handleProcessWorkflow(parkProps);
    };
    //#endregion <--------------------------------------------

    //#region CANCEL -------------------------------------------->
    /**
     * Handle cancel process
     * @param data
     */
    const handleCancelButton = (data: DTO_Workflow_NewRebate) => {
      if (isFromActionList || !isFirstSave) {
        setDataForCancelDialog({
          cancelAPI: postProcessNewRebateEntitlement,
          dataCancel: data,
          defaultSuccessMessage:
            "New rebate entitlement was cancelled successfully.",
          defaultErrorMessage: "New rebate entitlement could not be cancelled.",
        });
      } else {
        onClose();
      }
    };
    //#endregion <--------------------------------------------

    //#region RETAIN -------------------------------------------->
    /**
     * Handle close dialog
     * @param renderProps
     */
    const handleCloseDialog = (renderProps: ICCFormStepRender) => {
      if (!isFromActionList && !isFirstSave) {
        setDataForCloseDialog({
          closeCallback: renderProps.submitButton.onClick,
        });
      } else if (
        isIncompleteMode &&
        dataFromActionList?.Workflow_Status_Name === EWorkflowStatus.Park
      ) {
        onClose();
      } else if (
        dataFromActionList?.Workflow_Status_Name ===
          EWorkflowStatus.Incomplete &&
        !isFirstSave
      ) {
        const newEvent = {
          currentTarget: { id: EListSubmitButton.Close },
        };
        renderProps.submitButton.onClick(newEvent);
      } else {
        onClose();
      }
    };
    //#endregion <--------------------------------------------

    //#region FINISH -------------------------------------------->
    /**
     * handle finish workflow process
     * @param payload
     */
    const handleConfirmFinishProcess = (payload: DTO_Workflow_NewRebate) => {
      setDataForFinishDialog({
        finishCallback: async () =>
          await handleFinishProcess(processData(payload)),
        confirmMessage:
          "The new rebate entitlement will be created based on the information provided. Are you sure you want to submit?",
      });
    };

    /**
     * handle finish process
     * @param payload
     */
    const handleFinishProcess = async (payload: DTO_Workflow_NewRebate) => {
      //props send to process workflow
      const finishProps: INewProcessWorkflow<DTO_Workflow_NewRebate> = {
        payload: payload,
        actionSuccess: async (e) => {
          eventEmitter.emit(CCGridEventType.RefreshOData);
          if (!isFromActionList) {
            //Refresh rebate grid in Rebates accordion
            eventEmitter.emit(AssessmentRebatesEventType.reloadRebatesGrid);

            //Refresh document and comment accordion
            eventEmitter.emit(PropertyDocumentEventType.RefreshData);
            eventEmitter.emit(CCGridEventType.RefreshOData, {
              gridIds: [COMMENT_ACCORDION_GRID_ID],
            });
          }
          onClose();
          const notificationContent: IAppNotificationItemAddProps = {
            title:
              e?.Notification ??
              "New rebate entitlement was completed successfully.",
            type: "success",
          };
          !isRedirectManagePage && pushNotification(notificationContent);
        },
        setLoading: () => {
          setIsLoadingProcess(undefined);
        },
        defaultFailedMessage: "New rebate entitlement could not be completed.",
        modeProcess: WorkflowProcessMode.Finish,
      };

      //calling api process workflow
      await handleProcessWorkflow(finishProps);
    };
    //#endregion <--------------------------------------------

    //#region APPROVE -------------------------------------------->
    /**
     * handle approve process
     * * @param payload
     */
    const handleApproveProcess = (payload: DTO_Workflow_NewRebate) => {
      //set loading button
      setIsLoadingProcess(WorkflowProcessMode.Approve);

      //props send to process workflow
      const approveProps: INewProcessWorkflow<DTO_Workflow_NewRebate> = {
        payload: payload,
        actionSuccess: (e) => {
          onClose();
          history.push(PROPERTY_ACTIONS_APPROVED_LIST_ROUTE);
          pushNotification({
            title:
              e?.Notification ??
              "New rebate entitlement was approved successfully.",
            type: "success",
          });
        },
        setLoading: () => {
          setIsLoadingProcess(undefined);
        },
        defaultFailedMessage: "New rebate entitlement could not be approved.",
        modeProcess: WorkflowProcessMode.Approve,
      };

      //calling api process workflow
      handleProcessWorkflow(approveProps);
    };
    //#endregion <--------------------------------------------

    //#region REJECT -------------------------------------------->
    /**
     * Handle reject process
     */
    const handleRejectButton = (data: DTO_Workflow_NewRebate) => {
      if (isFromActionList || !isFirstSave) {
        setDataForRejectDialog({
          rejectCallback: postProcessNewRebateEntitlement,
          dataReject: data,
          defaultSuccessMessage:
            "New rebate entitlement was rejected successfully.",
          defaultErrorMessage: "New rebate entitlement could not be rejected.",
        });
      } else {
        onClose();
      }
    };
    //#endregion <--------------------------------------------

    //#region SEND BACK -------------------------------------------->
    /**
     * handle confirm send back workflow process
     * @param payload
     */
    const handleConfirmSendBackProcess = (payload: DTO_Workflow_NewRebate) => {
      setDataForSendBackDialog({
        sendBackCallback: postProcessNewRebateEntitlement,
        dataSendBack: payload,
        defaultSuccessMessage:
          "New rebate entitlement was sent back successfully.",
        defaultErrorMessage: "New rebate entitlement could not be sent back.",
      });
    };
    //#endregion <--------------------------------------------

    //#region REALLOCATE -------------------------------------------->
    /**
     * handle confirm reallocate workflow process
     * @param payload
     */
    const handleConfirmReallocateProcess = (
      payload: DTO_Workflow_NewRebate
    ) => {
      setDataForReallocateDialog({
        reallocateCallback: postProcessNewRebateEntitlement,
        dataReallocate: payload,
        defaultSuccessMessage:
          "New rebate entitlement was reallocated successfully.",
        defaultErrorMessage: "New rebate entitlement could not be reallocated.",
      });
    };
    //#endregion <--------------------------------------------

    const steps: IStep[] = [
      {
        label: "Details",
        initialValues: initialValue[EKeysOfAddRebateSteps.Details],
        component: DetailsFormStep,
        visible: true,
        key: EKeysOfAddRebateSteps.Details,
        isValid: false,
        options: {
          isLLS,
          isReadOnly,
          isShowReasonRejection,
          isApprovalMode: isToBeApprovalMode,
          workflowDraftId,
          workflowLOVs,
        },
      },
      {
        label: "Rebate entitlements",
        initialValues: initialValue[EKeysOfAddRebateSteps.RebateEntitlement],
        component: RebateEntitlementsFormStep,
        visible: true,
        key: EKeysOfAddRebateSteps.RebateEntitlement,
        options: {
          isReadOnly,
          isLLS,
          isActro,
          isWA,
          lotLabelName: titleLowercaseLabel,
        },
      },
      {
        label: "Concession cards",
        initialValues: initialValue[EKeysOfAddRebateSteps.ConcessionCard],
        component: ConcessionCardsFormStep,
        visible: !isLLS,
        key: EKeysOfAddRebateSteps.ConcessionCard,
        options: {
          isReadOnly,
        },
      },
      {
        label: "Calculations",
        initialValues: initialValue[EKeysOfAddRebateSteps.Calculations],
        component: CalculationsStep,
        visible: false, // @TODO: Hide this step, we will need to move this to another dialog in a future task
        key: EKeysOfAddRebateSteps.Calculations,
        options: {
          isReadOnly,
        },
      },
      {
        label: "Comments",
        initialValues: initialValue[EKeysOfAddRebateSteps.Comments],
        component: CommentsFormStep,
        visible: true,
        key: EKeysOfAddRebateSteps.Comments,
        options: {
          isReadOnly,
          workflowDraftId,
          recordType: RECORDTYPE.CommunityProperty_Assessment,
        },
        customClassName: "cc-comment-step-fixed-height-grid",
      },
      {
        label: "Documents",
        component: DocumentsFormStep,
        initialValues: initialValue[EKeysOfAddRebateSteps.Documents],
        visible: true,
        key: EKeysOfAddRebateSteps.Documents,
        options: {
          isReadOnly,
          workflowDraftId,
          workflowType: WorkflowTypes.New_Rebate,
        },
      },
      {
        label: "Workflow",
        initialValues: initialValue[EKeysOfAddRebateSteps.SecondaryWorkflow],
        component: WorkflowStepFormStep,
        visible: true,
        key: EKeysOfAddRebateSteps.SecondaryWorkflow,
        options: {
          isReadOnly,
          isFromActionList,
          dataFromActionList,
        },
      },
    ];

    return (
      <CCFormStep
        ref={notificationFormStepRef}
        listButtonId={listSubmitButton}
        onSubmit={handleSubmit}
        initialSteps={steps}
        initialValues={initialValue}
        saveOnNextStep={isSaveOnNextStep ? handleNextButton : undefined}
        renderForm={(renderProps: ICCFormStepRender) => {
          return (
            <CCDialog
              maxWidth="65%"
              titleHeader={titleHeader}
              disabled={!isNil(isLoadingProcess)}
              onClose={() => handleCloseDialog(renderProps)}
              badge={statusBadge}
              bodyElement={renderProps.children}
              footerElement={
                <>
                  <div className={"cc-dialog-footer-actions-right"}>
                    {isShowParkButton(isFromActionList, isIncompleteMode) && (
                      <Button
                        iconClass={
                          isLoadingProcess === WorkflowProcessMode.Park
                            ? "fas fa-spinner fa-spin"
                            : ""
                        }
                        className={"cc-dialog-button"}
                        id={EListSubmitButton.Park}
                        onClick={renderProps.submitButton.onClick}
                        disabled={
                          renderProps.nextButton.disabled ||
                          isLoadingProcess === WorkflowProcessMode.Park
                        }
                      >
                        Park
                      </Button>
                    )}
                    {isShowCancelWorkflowButton && (
                      <Button
                        className={"cc-dialog-button"}
                        disabled={!isNil(isLoadingProcess)}
                        id={EListSubmitButton.Cancel}
                        onClick={renderProps.submitButton.onClick}
                      >
                        Cancel
                      </Button>
                    )}

                    {isToBeApprovalMode &&
                      workflowInitialData?.WorkflowHeader
                        ?.OfficerCanApprove && (
                        <>
                          <Button
                            themeColor="primary"
                            id={EListSubmitButton.SendBack}
                            disabled={renderProps.nextButton.disabled}
                            className={"cc-dialog-button"}
                            onClick={renderProps.submitButton.onClick}
                          >
                            Send Back
                          </Button>
                          <Button
                            themeColor="primary"
                            id={EListSubmitButton.Reallocate}
                            disabled={renderProps.nextButton.disabled}
                            className={"cc-dialog-button"}
                            onClick={renderProps.submitButton.onClick}
                          >
                            Reallocate
                          </Button>
                          <Button
                            themeColor="primary"
                            id={EListSubmitButton.Approve}
                            disabled={
                              isLoadingProcess ===
                                WorkflowProcessMode.Approve ||
                              renderProps.nextButton.disabled
                            }
                            className={"cc-dialog-button"}
                            onClick={renderProps.submitButton.onClick}
                            iconClass={
                              isLoadingProcess === WorkflowProcessMode.Approve
                                ? "fas fa-spinner fa-spin"
                                : ""
                            }
                          >
                            Approve
                          </Button>
                          <Button
                            themeColor="primary"
                            id={EListSubmitButton.Reject}
                            disabled={renderProps.nextButton.disabled}
                            className={"cc-dialog-button"}
                            onClick={renderProps.submitButton.onClick}
                          >
                            Reject
                          </Button>
                        </>
                      )}
                    {!renderProps.prevButton.disabled && (
                      <Button
                        className={"cc-dialog-button"}
                        themeColor="primary"
                        onClick={renderProps.prevButton.onClick}
                      >
                        Previous
                      </Button>
                    )}
                    {isToBeApprovalMode || isReadOnly ? (
                      !renderProps.isLastStep && (
                        <Button
                          themeColor="primary"
                          id="cc-next-step-button"
                          disabled={
                            isLoadingProcess === WorkflowProcessMode.Save ||
                            renderProps.nextButton.disabled
                          }
                          className={"cc-dialog-button"}
                          iconClass={
                            isLoadingProcess === WorkflowProcessMode.Save
                              ? "fas fa-spinner fa-spin"
                              : ""
                          }
                          onClick={renderProps.nextButton.onClick}
                        >
                          {isLoadingProcess === WorkflowProcessMode.Save
                            ? "Saving"
                            : renderProps.nextButton.label}
                        </Button>
                      )
                    ) : (
                      <Button
                        themeColor="primary"
                        id={renderProps.nextButton.idButton}
                        disabled={
                          isLoadingProcess === WorkflowProcessMode.Save ||
                          renderProps.nextButton.disabled
                        }
                        iconClass={
                          isLoadingProcess === WorkflowProcessMode.Save
                            ? "fas fa-spinner fa-spin"
                            : ""
                        }
                        className={"cc-dialog-button"}
                        onClick={renderProps.nextButton.onClick}
                      >
                        {isLoadingProcess === WorkflowProcessMode.Save
                          ? "Saving"
                          : renderProps.nextButton.label}
                      </Button>
                    )}
                  </div>
                </>
              }
            />
          );
        }}
      />
    );
  }
);
