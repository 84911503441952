import { getChangeOfOwnershipLRSTitleOwners } from "@app/products/property/changes-of-ownership/notice-of-sales/components/form-steps/update-notice-of-sale/components/form-elements/property/title-detail/api";
import { DTO_TitleOwners } from "@app/products/property/changes-of-ownership/notice-of-sales/components/form-steps/update-notice-of-sale/components/form-elements/property/title-detail/model";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import Loading from "@components/loading/Loading";
import { GridDetailRowProps } from "@progress/kendo-react-grid";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";
import { titleOwnersCol } from "./config";
const nameOf = nameOfFactory<DTO_TitleOwners>();
export const TitleDetail = observer((props: GridDetailRowProps) => {
  const dataItem = props.dataItem;

  const [data, setData] = useState<DTO_TitleOwners[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [responseLoadError, setResponseLoadError] = useState<
    APIResponseError | undefined
  >(undefined);

  const loadData = async () => {
    setIsLoading(true);
    setResponseLoadError(undefined);
    const response = await getChangeOfOwnershipLRSTitleOwners(
      dataItem.Title_Id
    );
    setIsLoading(false);
    if (isSuccessResponse(response) && response?.data) {
      setData(response.data.Title_Owners ?? []);
    } else {
      setResponseLoadError({
        status: response.status,
        error: response.error ?? "Load failed",
      });
    }
  };

  useEffectOnce(() => {
    loadData();
  });

  if (isLoading) return <Loading isLoading={isLoading} />;
  if (responseLoadError)
    return (
      <CCLoadFailed
        responseError={responseLoadError}
        onReload={() => {
          loadData();
        }}
      />
    );

  return (
    <CCGrid
      className="cc-title-detail-grid"
      data={data}
      columnFields={titleOwnersCol}
      primaryField={nameOf("id")}
      isLimitDetailWidth
    />
  );
});
