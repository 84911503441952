import { eventEmitter } from "@/App";
import { VO_Workflow_Draft } from "@app/products/property/actions/model";
import { listSubmitButton } from "@app/products/property/assessments/components/form-steps/new-assessment/config";
import { useConfirmCancelDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-cancel/store";
import { useConfirmCloseDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-close/store";
import { useConfirmFinishDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-finish/store";
import { useConfirmReallocateDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-reallocate/store";
import { useConfirmRejectDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-reject/store";
import { useConfirmSendBackDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-send-back/store";
import { CollectionFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/collection/collection/_index";
import { useAssessmentCollectionStepStore } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/collection/collection/store";
import { collectionUtilProcessing } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/collection/collection/util";
import { CommentsFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/comments/_index";
import { DocumentsFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/documents/_index";
import { WorkflowStepFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/workflow/_index";
import {
  getSuffixTitle,
  getWorkflowInstructions,
  secondaryWorkflowUtilProcessing,
} from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/workflow/util";
import { usePropertyWorkflow } from "@app/products/property/components/action-bar/property-workflow/component/hooks/useProprtyWorkflow/usePropertyWorkflow";
import { IProcessWorkflow } from "@app/products/property/components/action-bar/property-workflow/model";
import { getTitleWorkflow } from "@app/products/property/components/action-bar/property-workflow/util";
import { DTO_GIS } from "@app/products/property/components/dialogs/gis-reference/model";
import { officerUtilProcessing } from "@app/products/property/components/fields/officer-and-officer-region/util";
import {
  Collection,
  EListSubmitButton,
  EWorkflowStatus,
  WorkflowProcessMode,
  WorkflowTypes,
} from "@app/products/property/model";
import { PROPERTY_PARCEL_ROUTE } from "@app/products/property/parcels/[id]/constant";
import { useParcelsStore } from "@app/products/property/parcels/[id]/store";
import { AddressFormStep } from "@app/products/property/parcels/components/form-steps/create-parcel/components/form-elements/address/_index";
import { ConstraintsFormStep } from "@app/products/property/parcels/components/form-steps/create-parcel/components/form-elements/constraints/_index";
import { LandUseFormStep } from "@app/products/property/parcels/components/form-steps/create-parcel/components/form-elements/land-use/_index";
import { ParcelDetailsFormStep } from "@app/products/property/parcels/components/form-steps/create-parcel/components/form-elements/parcel-details/_index";
import { RelatedFormStep } from "@app/products/property/parcels/components/form-steps/create-parcel/components/form-elements/related/_index";
import { ZonesFormStep } from "@app/products/property/parcels/components/form-steps/create-parcel/components/form-elements/zones/_index";
import { DTO_Parcel_Address } from "@app/products/property/parcels/components/form-steps/create-parcel/model";
import {
  getInitialDataParcel,
  postProcessWorkflowParcel,
} from "@app/products/property/parcels/components/form-steps/modify-parcel/api";
import { DetailsFormStep } from "@app/products/property/parcels/components/form-steps/modify-parcel/components/form-elements/details/_index";
import {
  EKeysOfStepsParcel,
  keysOfSendStepsParcel,
} from "@app/products/property/parcels/components/form-steps/modify-parcel/config";
import {
  DTO_Parcel_LOVs,
  DTO_Workflow_ModifyParcel,
  RequestParcelModifyObj,
} from "@app/products/property/parcels/components/form-steps/modify-parcel/model";
import { useModifyParcelDialogStore } from "@app/products/property/parcels/components/form-steps/modify-parcel/store";
import { isShowParkButton } from "@app/products/property/util";
import { APIResponse } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { RECORDTYPE } from "@common/constants/recordtype";
import { ResponsePacket } from "@common/models/identityPacket";
import { OrganisationMode } from "@common/stores/products/model";
import { useCommonProductStore } from "@common/stores/products/store";
import { getUUID } from "@common/utils/common";
import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCDialog } from "@components/cc-dialog/_index";
import {
  CCFormStep,
  ICCFormStepNotificationHandle,
  ICCFormStepRender,
} from "@components/cc-form-step/_index";
import { IStep } from "@components/cc-form-step/model";
import { CCGridEventType } from "@components/cc-grid/constant";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { Button } from "@progress/kendo-react-buttons";
import { head, isNil, pickBy } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";

interface IModifyParcelDialogProps {
  onClose: () => void;
  dataFromActionList?: VO_Workflow_Draft;
  prefixTitle?: string;
  isSaveOnNextStep?: boolean;
  suffixTitle?: string;
  parcelID: number;
  statusID: number;
}

export const ModifyParcelDialog = observer(
  ({
    onClose,
    parcelID,
    statusID,
    prefixTitle,
    suffixTitle,
    dataFromActionList,
    isSaveOnNextStep = false,
  }: IModifyParcelDialogProps) => {
    // Get data from store
    const { currentFormTitle, currentOrganisationMode } =
      useCommonProductStore();
    const isActro = currentOrganisationMode(OrganisationMode.ACTRO);
    const isLLS = currentOrganisationMode(OrganisationMode.LLS);
    const isGeneral = currentOrganisationMode(OrganisationMode.General);
    const { pushNotification } = useCCAppNotificationStore();
    const { setDataForCancelDialog } = useConfirmCancelDialogStore();
    const { setDataForSendBackDialog } = useConfirmSendBackDialogStore();
    const { setDataForReallocateDialog } = useConfirmReallocateDialogStore();
    const { setDataForRejectDialog } = useConfirmRejectDialogStore();
    const { setDataForFinishDialog } = useConfirmFinishDialogStore();
    const { setDataForCloseDialog, setIsLoadingClose } =
      useConfirmCloseDialogStore();
    const { clearSelectedItems } = useCCProductListViewStore();
    const { modifyParcelLOVs, setModifyParcelLOVs } =
      useModifyParcelDialogStore();
    const { setAssessmentCollectionStepLOVs } =
      useAssessmentCollectionStepStore();
    const notificationFormStepRef =
      useRef<ICCFormStepNotificationHandle | null>(null);

    // Local state
    const [isLoadingOnNext, setIsLoadingOnNext] = useState<boolean>(false);
    const [isLoadingPark, setIsLoadingPark] = useState<boolean>(false);
    const [isLoadingApprove, setIsLoadingApprove] = useState<boolean>(false);
    const [modifyParcelInitData, setModifyParcelInitData] =
      useState<DTO_Workflow_ModifyParcel>();
    const [workflowDraftId, setWorkflowDraftId] = useState<number>(0);
    const [isLoadingInStep, setIsLoadingInStep] = useState<boolean>(false);

    const history = useHistory();
    const { parcel } = useParcelsStore();
    const {
      isFromActionList,
      isIncompleteMode,
      isReadOnly,
      isShowCancelWorkflowButton,
      isToBeApprovalMode,
      statusBadge,
    } = usePropertyWorkflow(dataFromActionList);

    const params: { id: string } = useParams();
    const parcelId: number = +params?.id || parcelID;
    // Get status from props or from the summary api in manage page
    const statusId: number | undefined = parcel?.StatusId ?? statusID;

    const isFirstSave = useMemo(() => {
      const draftId =
        modifyParcelInitData?.WorkflowHeader?.WorkflowDraft?.Workflow_Draft_Id;
      return isNil(draftId) || draftId === 0;
    }, [modifyParcelInitData]);

    /**
     * function process initial Data for form in each step
     */
    const initialValues = useMemo(() => {
      const workflowDetail = modifyParcelInitData?.WorkflowDetail;
      const workflowHeader = modifyParcelInitData?.WorkflowHeader;

      const parcelID = workflowDetail?.ParcelId || 0;

      //---step Details---
      const officerInitData =
        officerUtilProcessing.processDataInit(modifyParcelInitData);
      let initDetails: any = {
        ...workflowDetail?.Details,
        Instructions: getWorkflowInstructions(
          workflowHeader?.WorkflowApprovals ?? []
        ),
        ...officerInitData,
      };

      //---step Association---
      let initAssociation: any = {
        ...workflowDetail?.Associations,
      };

      //---step Address---
      const initAddress = (workflowDetail?.Address || []).map(
        (item: DTO_Parcel_Address) => ({
          ...item,
          RowId: getUUID(),
        })
      );

      //---step Parcel Details---
      let cloneDetailGIS: DTO_GIS[] = [];
      if (workflowDetail?.ParcelDetails?.GisReferences?.length) {
        cloneDetailGIS = workflowDetail?.ParcelDetails?.GisReferences?.map(
          (item: DTO_GIS) => ({
            ...item,
            Id: getUUID(),
          })
        );
      }

      let initParcelDetails: any = {
        ...workflowDetail?.ParcelDetails,
        GisReferences: cloneDetailGIS,
      };

      //---step Zones---
      let initZones: any = [...(workflowDetail?.SelectZones ?? [])];

      //---step Land Use---
      let initLaneUse: any = [...(workflowDetail?.SelectLandUses ?? [])];

      //---step Constraints---
      let initConstraints: any = [...(workflowDetail?.SelectConstraints ?? [])];

      //---step Secondary Workflow---
      const initSecondaryWorkflow =
        secondaryWorkflowUtilProcessing.processDataInit(modifyParcelInitData);

      return {
        [EKeysOfStepsParcel.ParcelId]: parcelID,
        [EKeysOfStepsParcel.Details]: initDetails,
        [EKeysOfStepsParcel.Associations]: initAssociation,
        [EKeysOfStepsParcel.ParcelDetails]: initParcelDetails,
        [EKeysOfStepsParcel.Address]: initAddress,
        [EKeysOfStepsParcel.SelectZones]: initZones,
        [EKeysOfStepsParcel.SelectLandUses]: initLaneUse,
        [EKeysOfStepsParcel.SelectConstraints]: initConstraints,
        [EKeysOfStepsParcel.Collection]:
          collectionUtilProcessing.processDataInit<Collection[]>(
            modifyParcelInitData
          ),
        [EKeysOfStepsParcel.Comments]: {},
        [EKeysOfStepsParcel.Documents]: {},
        [EKeysOfStepsParcel.SecondaryWorkflow]: initSecondaryWorkflow,
      };
    }, [modifyParcelInitData]);

    /**
     * List define list all steps
     */
    const steps: IStep[] = [
      {
        label: "Details",
        initialValues: initialValues.Details,
        component: DetailsFormStep,
        visible: true,
        key: EKeysOfStepsParcel.Details,
        options: {
          isReadOnly,
          isDisabled: workflowDraftId,
          isToBeApprovalMode,
        },
      },
      {
        label: "Related",
        initialValues: initialValues.Associations,
        component: RelatedFormStep,
        visible: true,
        key: EKeysOfStepsParcel.Associations,
        options: {
          isReadOnly,
          setIsLoadingInStep,
        },
      },
      {
        label: "Parcel Details",
        initialValues: initialValues.ParcelDetails,
        component: ParcelDetailsFormStep,
        visible: true,
        key: EKeysOfStepsParcel.ParcelDetails,
        options: {
          isReadOnly,
          isLLS,
          isGeneral,
          parcelLovs: modifyParcelLOVs,
        },
      },
      {
        label: "Address",
        initialValues: initialValues.Address,
        component: AddressFormStep,
        visible: true,
        key: EKeysOfStepsParcel.Address,
        options: {
          isReadOnly,
          isLLS,
          setIsLoadingInStep,
          parcelLovs: modifyParcelLOVs,
        },
      },
      {
        label: "Zones",
        initialValues: initialValues.SelectZones,
        component: ZonesFormStep,
        visible: !isActro,
        key: EKeysOfStepsParcel.SelectZones,
        options: {
          isReadOnly,
          setIsLoadingInStep,
        },
      },
      {
        label: "Land Use",
        initialValues: initialValues.SelectLandUses,
        component: LandUseFormStep,
        visible: !isActro,
        key: EKeysOfStepsParcel.SelectLandUses,
        options: {
          isReadOnly,
          setIsLoadingInStep,
        },
      },
      {
        label: "Constraints",
        initialValues: initialValues.SelectConstraints,
        component: ConstraintsFormStep,
        visible: !isActro,
        key: EKeysOfStepsParcel.SelectConstraints,
        options: {
          isReadOnly,
          setIsLoadingInStep,
        },
      },
      {
        label: "Collections",
        initialValues: initialValues[EKeysOfStepsParcel.Collection],
        component: CollectionFormStep,
        visible: true,
        key: EKeysOfStepsParcel.Collection,
        options: {
          isReadOnly,
        },
      },
      {
        label: "Comments",
        initialValues: initialValues.Comments,
        component: CommentsFormStep,
        visible: true,
        key: EKeysOfStepsParcel.Comments,
        options: {
          isReadOnly,
          workflowDraftId,
          recordType: RECORDTYPE.CommunityProperty_Parcel,
        },
        customClassName: "cc-comment-step-fixed-height-grid",
      },
      {
        label: "Documents",
        component: DocumentsFormStep,
        initialValues: initialValues.Documents,
        visible: true,
        key: EKeysOfStepsParcel.Documents,
        options: {
          isReadOnly,
          workflowDraftId,
          WorkflowTypes: WorkflowTypes.Modify_Parcel,
        },
      },
      {
        label: "Workflow",
        initialValues: initialValues.SecondaryWorkflow,
        component: WorkflowStepFormStep,
        visible: true,
        key: EKeysOfStepsParcel.SecondaryWorkflow,
        options: {
          isReadOnly,
          isFromActionList,
          dataFromActionList,
        },
      },
    ];

    /**
     * get workflow data
     * return LOVs and initial data of existed parcel
     * @returns
     */
    const getWorkflowData = async () => {
      const id = dataFromActionList?.Workflow_Draft_Id;
      notificationFormStepRef?.current?.setLoadingFormStep(true);
      const requestObjPayload: RequestParcelModifyObj = {
        ParcelId: parcelId,
        StatusId: statusId,
      };
      return await getInitialDataParcel(requestObjPayload, id).then(
        (response) => {
          if (Array.isArray(response)) {
            const [lovsParcel, workflowData] = response;
            if (
              isSuccessResponse(lovsParcel) &&
              isSuccessResponse(workflowData) &&
              lovsParcel?.data &&
              workflowData?.data
            ) {
              //set Lovs Data Parcel
              setModifyParcelLOVs(lovsParcel?.data?.dtoModify_Parcel_LOVs);
              setAssessmentCollectionStepLOVs({
                Collection_Type:
                  lovsParcel?.data?.dtoModify_Parcel_LOVs?.CollectionTypes,
              });
              if (workflowData?.data) {
                setModifyParcelInitData({
                  WorkflowDetail: workflowData.data?.WorkflowDetail,
                  WorkflowHeader: workflowData.data?.WorkflowHeader,
                });
                if (workflowData?.data?.WorkflowHeader) {
                  setWorkflowDraftId(
                    workflowData.data?.WorkflowHeader?.WorkflowDraft
                      ?.Workflow_Draft_Id ?? 0
                  );
                }
              }
              notificationFormStepRef?.current?.setLoadingFormStep(false);
            } else {
              let responseError: APIResponse<
                DTO_Parcel_LOVs | DTO_Workflow_ModifyParcel | ResponsePacket
              > = lovsParcel;
              if (!isSuccessResponse(workflowData)) {
                responseError = workflowData;
              }
              notificationFormStepRef?.current?.setLoadingFormStep(false);
              notificationFormStepRef?.current?.setLoadFailedFormStep({
                onReload: () => getWorkflowData(),
                responseError: {
                  status: responseError.status,
                  error:
                    (responseError.data as ResponsePacket)?.Errors ??
                    "Load workflow failed",
                },
              });
            }
          } else {
            const responseError = response as APIResponse;
            notificationFormStepRef?.current?.setLoadingFormStep(false);
            notificationFormStepRef?.current?.setLoadFailedFormStep({
              onReload: () => getWorkflowData(),
              responseError: {
                status: responseError.status,
                error: "Load workflow failed",
              },
            });
          }
        }
      );
    };

    /**
     * handle submit to control all button in dialog
     * @param data
     * @param buttonId
     */
    const handleSubmit = async (data: any, buttonId?: string) => {
      switch (buttonId) {
        case EListSubmitButton.Approve:
          handleApproveProcess(processData(data));
          break;
        case EListSubmitButton.Save:
          await handleSaveAndNext(processData(data), true);
          break;
        case EListSubmitButton.SaveWorkflow:
          await handleSaveAndNext(processData(data), false, true);
          break;
        case EListSubmitButton.Finish:
          handleConfirmFinishProcess(data);
          break;
        case EListSubmitButton.Cancel:
        case EListSubmitButton.ConfirmCloseNo:
          handleCancelButton(processData(data));
          break;
        case EListSubmitButton.Reject:
          handleRejectButton(processData(data));
          break;
        case EListSubmitButton.ConfirmCloseYes:
          handleConfirmRetainProcess(processData(data));
          break;
        case EListSubmitButton.SendBack:
          handleConfirmSendBackProcess(processData(data));
          break;
        case EListSubmitButton.Reallocate:
          handleConfirmReallocateProcess(processData(data));
          break;
        case EListSubmitButton.Park:
        case EListSubmitButton.Close:
          handleParkProcess(processData(data));
          break;
      }
    };

    /**
     * Handle reject process
     */
    const handleRejectButton = (data: DTO_Workflow_ModifyParcel) => {
      if (isFromActionList || !isFirstSave) {
        setDataForRejectDialog({
          rejectCallback: postProcessWorkflowParcel,
          dataReject: data,
          defaultSuccessMessage:
            "Modify parcel application was rejected successfully",
          defaultErrorMessage: "Modify parcel reject failed",
        });
      } else {
        onClose();
      }
    };

    /**
     * handle confirm send back workflow process
     * @param payload
     */
    const handleConfirmSendBackProcess = (
      payload: DTO_Workflow_ModifyParcel
    ) => {
      setDataForSendBackDialog({
        sendBackCallback: postProcessWorkflowParcel,
        dataSendBack: payload,
        defaultSuccessMessage: "Parcel approval sent back successfully",
        defaultErrorMessage: "Send parcel back failed",
      });
    };

    /**
     * handle confirm reallocate workflow process
     * @param payload
     */
    const handleConfirmReallocateProcess = (
      payload: DTO_Workflow_ModifyParcel
    ) => {
      setDataForReallocateDialog({
        reallocateCallback: postProcessWorkflowParcel,
        dataReallocate: payload,
        defaultSuccessMessage: "Parcel approved successfully",
        defaultErrorMessage: "Approve parcel failed",
      });
    };

    /**
     * handle finish workflow process
     * @param payload
     */
    // eslint-disable-next-line
    const handleConfirmFinishProcess = (payload: DTO_Workflow_ModifyParcel) => {
      const dataProcessed = processData(payload);
      const finishCallback = function async() {
        return handleFinishProcess(dataProcessed);
      };
      setDataForFinishDialog({
        finishCallback,
        confirmMessage:
          "The parcel will be modified based on the information provided. Are you sure you want to submit?",
      });
    };

    /**
     * handle finish process
     * @param payload
     */
    const handleFinishProcess = async (payload: DTO_Workflow_ModifyParcel) => {
      //props send to process workflow
      const finishProps: IProcessWorkflow<DTO_Workflow_ModifyParcel> = {
        payload: payload,
        actionSuccess: (e) => {
          onClose();
          clearSelectedItems();
          eventEmitter.emit(CCGridEventType.RefreshOData);
          const notificationContent: IAppNotificationItemAddProps = {
            title: e?.Notification ?? "Parcel modified successfully",
            type: "success",
          };
          if (
            modifyParcelInitData?.WorkflowHeader?.WorkflowApprovals?.length ===
              0 &&
            !isNil(e?.Component_ID)
          ) {
            history.push(`${PROPERTY_PARCEL_ROUTE}/${e?.Component_ID}`, {
              notification: notificationContent,
            });
          } else {
            pushNotification(notificationContent);
          }
        },
        defaultFailedMessage: "Modify parcel failed",
        modeProcess: WorkflowProcessMode.Finish,
      };

      const setLoading = () => {};
      //calling api process workflow
      await handleProcessWorkflow(finishProps, setLoading);
    };

    /**
     * common function
     * handle calling api with multiple process
     * @param props
     */
    const handleProcessWorkflow = async (
      props: IProcessWorkflow<DTO_Workflow_ModifyParcel>,
      setLoading: () => void
    ) => {
      const { payload, actionSuccess, defaultFailedMessage, modeProcess } =
        props;
      const response = await postProcessWorkflowParcel(modeProcess, payload);

      setLoading();
      if (isSuccessResponse(response)) {
        if (response?.data?.IsSuccess) {
          actionSuccess(response?.data);
        } else {
          notificationFormStepRef?.current
            ?.getNotificationFormStep()
            ?.current?.pushNotification({
              title: response.data?.ErrorMessage ?? defaultFailedMessage,
              type: "error",
              autoClose: false,
            });
        }
      } else {
        notificationFormStepRef?.current
          ?.getNotificationFormStep()
          ?.current?.pushNotification({
            title: response?.data?.ErrorMessage ?? defaultFailedMessage,
            type: "error",
            autoClose: false,
          });
      }
    };

    /**
     * process payload before sending to api
     * @param data
     * @returns
     */
    const processData: DTO_Workflow_ModifyParcel | any = (data: any) => {
      let workflowDetail: any = {};
      let workflowHeader: any = { ...modifyParcelInitData?.WorkflowHeader };

      //Officer process data
      //field update: WD_Assessment_Group_Id
      const { WD_Assessment_Group_Id } = officerUtilProcessing.processData(
        data,
        EKeysOfStepsParcel.Details
      );
      workflowHeader.WorkflowDraft.WD_Assessment_Group_Id =
        WD_Assessment_Group_Id;
      const sendSteps = pickBy(data, function (value, key) {
        if (keysOfSendStepsParcel.includes(key as EKeysOfStepsParcel)) {
          return { [key]: value };
        }
      });

      for (const [key, value] of Object.entries(sendSteps)) {
        let dataStep;
        if (
          key === EKeysOfStepsParcel.SelectLandUses ||
          key === EKeysOfStepsParcel.SelectZones ||
          key === EKeysOfStepsParcel.SelectConstraints ||
          key === EKeysOfStepsParcel.Collection ||
          key === EKeysOfStepsParcel.Address
        ) {
          dataStep = [...(value ?? [])];
        } else if (key === EKeysOfStepsParcel.ParcelId) {
          dataStep = value;
        } else {
          dataStep = { ...value };
        }
        if (dataStep && dataStep?._option) {
          delete dataStep._option;
        }
        workflowDetail[key] = value[key];
        if (key === EKeysOfStepsParcel.SecondaryWorkflow) {
          secondaryWorkflowUtilProcessing.processData(
            value,
            workflowHeader,
            workflowDetail
          );
        } else {
          workflowDetail[key as keyof any] = dataStep;
        }
      }

      return {
        WorkflowHeader: workflowHeader,
        WorkflowDetail: workflowDetail,
      };
    };

    /**
     * handle save parcel
     * @param payload
     * @param isCloseDialog
     * @returns Promise<boolean>
     */
    const handleSaveAndNext = async (
      payload: DTO_Workflow_ModifyParcel,
      isCloseDialog: boolean = false,
      isRefreshWorkflowData: boolean = false
    ): Promise<boolean> => {
      setIsLoadingOnNext(true);
      //Calling process Save at next button
      const response = await postProcessWorkflowParcel(
        WorkflowProcessMode.Save,
        payload
      );

      setIsLoadingOnNext(false);

      //set default notification
      const defaultSuccessMessage = "Parcel saved successfully";
      const defaultFailedMessage =
        "The modify parcel process could not be saved";

      if (isSuccessResponse(response) && response?.data?.IsSuccess) {
        if (isCloseDialog) {
          onClose();
          pushNotification({
            title: response?.data?.Notification ?? defaultSuccessMessage,
            type: "success",
          });
        }
        // check is the first saving
        if (isFirstSave) {
          //set current workflowDraft Id
          setWorkflowDraftId(response?.data?.ID || 0);
          // get new data from draftId after first save
          if (officerUtilProcessing.canReloadWorkflowData(isFromActionList)) {
            setModifyParcelInitData({
              WorkflowDetail: response?.data?.ReturnData?.WorkflowDetail,
              WorkflowHeader: response?.data?.ReturnData?.WorkflowHeader,
            });
          }
        }
        // TODO: Show notification after reloading the step -> enhance later
        if (isRefreshWorkflowData) {
          getWorkflowData().then(() => {
            notificationFormStepRef?.current
              ?.getNotificationFormStep()
              ?.current?.pushNotification({
                title: response?.data?.Notification ?? defaultSuccessMessage,
                type: "success",
              });
          });
        }
        return true;
      } else {
        const showNotification = () => {
          notificationFormStepRef?.current
            ?.getNotificationFormStep()
            ?.current?.pushNotification({
              title:
                (isRefreshWorkflowData
                  ? head(response?.data?.Errors)
                  : response.data?.ErrorMessage) ?? defaultFailedMessage,
              type: "error",
              autoClose: false,
            });
        };
        if (isRefreshWorkflowData) {
          getWorkflowData().then(showNotification);
        } else {
          showNotification();
        }
        return false;
      }
    };

    /**
     * handle park process
     * @param payload
     */
    const handleParkProcess = (payload: DTO_Workflow_ModifyParcel) => {
      //set loading button and dialog
      setIsLoadingPark(true);

      //props send to process workflow
      const parkProps: IProcessWorkflow<DTO_Workflow_ModifyParcel> = {
        payload: payload,
        actionSuccess: (e) => {
          onClose();
          pushNotification({
            title:
              e?.Notification ??
              e?.SuccessMessage ??
              "Parcel parked successfully",
            type: "success",
          });
        },
        defaultFailedMessage: "Park parcel failed",
        modeProcess: WorkflowProcessMode.Park,
      };

      const setLoading = () => setIsLoadingPark(false);

      //calling api process workflow
      handleProcessWorkflow(parkProps, setLoading);
    };

    /**
     * handle approve process
     */
    const handleApproveProcess = (payload: DTO_Workflow_ModifyParcel) => {
      //set loading button
      setIsLoadingApprove(true);

      //props send to process workflow
      const approveProps: IProcessWorkflow<DTO_Workflow_ModifyParcel> = {
        payload: payload,
        actionSuccess: (e) => {
          onClose();
          eventEmitter.emit(CCGridEventType.RefreshOData);
          pushNotification({
            title: e?.Notification ?? "Modify parcel approved successfully",
            type: "success",
          });
        },
        defaultFailedMessage: "Approve modify parcel failed",
        modeProcess: WorkflowProcessMode.Approve,
      };

      const setLoading = () => {
        setIsLoadingApprove(false);
      };
      //calling api process workflow
      handleProcessWorkflow(approveProps, setLoading);
    };

    /**
     * handle when hitting next button
     * @param data
     * @returns
     */
    // eslint-disable-next-line
    const handleNextButton = async (data: DTO_Workflow_ModifyParcel) => {
      const newData = processData(data);
      return await handleSaveAndNext(newData);
    };

    /**
     * process title dialog
     */
    const titleHeader = useMemo(() => {
      const formId =
        modifyParcelInitData?.WorkflowHeader?.WorkflowDraft?.WD_Form_Id;
      const title = currentFormTitle(formId ?? 0) || "Modify Parcel";

      return getTitleWorkflow(
        title,
        prefixTitle,
        getSuffixTitle(
          suffixTitle,
          isToBeApprovalMode,
          modifyParcelInitData?.WorkflowHeader?.WorkflowApprovals ?? []
        )
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [prefixTitle, suffixTitle, isToBeApprovalMode]);

    /**
     * handle close dialog
     * @param renderProps
     */
    const handleCloseDialog = (renderProps: ICCFormStepRender) => {
      if (!isFromActionList && !isFirstSave) {
        //Store submit event
        setDataForCloseDialog({
          closeCallback: renderProps.submitButton.onClick,
        });
      } else if (
        isIncompleteMode &&
        dataFromActionList?.Workflow_Status_Name === EWorkflowStatus.Park
      ) {
        onClose();
      } else if (
        dataFromActionList?.Workflow_Status_Name ===
          EWorkflowStatus.Incomplete &&
        !isFirstSave
      ) {
        const newEvent = {
          currentTarget: { id: EListSubmitButton.Close },
        };
        renderProps.submitButton.onClick(newEvent);
      } else {
        onClose();
      }
    };

    /**
     * handle cancel button
     */
    const handleCancelButton = (data: DTO_Workflow_ModifyParcel) => {
      if (isFromActionList || !isFirstSave) {
        setDataForCancelDialog({
          cancelAPI: postProcessWorkflowParcel,
          dataCancel: data,
          defaultSuccessMessage:
            "The modify parcel application was cancelled successfully",
          defaultErrorMessage: "Modify parcel cancel failed",
        });
      } else {
        onClose();
      }
    };

    /**
     * handle confirm retain workflow process
     * @param payload
     */
    const handleConfirmRetainProcess = (payload: DTO_Workflow_ModifyParcel) => {
      //set loading button and dialog
      setIsLoadingClose(true);

      //props send to process workflow
      const parkProps: IProcessWorkflow<DTO_Workflow_ModifyParcel> = {
        payload: payload,
        actionSuccess: (e) => {
          onClose();
          pushNotification({
            title:
              e?.Notification ??
              e?.SuccessMessages ??
              "Modify parcel was parked successfully.",
            type: "success",
          });
        },
        defaultFailedMessage: "Modify parcel could not be parked.",
        modeProcess: WorkflowProcessMode.Park,
      };

      const setLoading = () => {
        setIsLoadingClose(false);
        setDataForCloseDialog(undefined);
      };

      //calling api process workflow
      handleProcessWorkflow(parkProps, setLoading);
    };

    //Calling api get data the first time
    useEffectOnce(() => {
      getWorkflowData();
    });

    return (
      <CCFormStep
        ref={notificationFormStepRef}
        listButtonId={listSubmitButton}
        onSubmit={handleSubmit}
        initialSteps={steps}
        initialValues={initialValues}
        saveOnNextStep={isSaveOnNextStep ? handleNextButton : undefined}
        renderForm={(renderProps: ICCFormStepRender) => {
          return (
            <CCDialog
              maxWidth="65%"
              disabled={isLoadingApprove || isLoadingOnNext || isLoadingInStep}
              titleHeader={titleHeader}
              onClose={() => handleCloseDialog(renderProps)}
              badge={statusBadge}
              bodyElement={renderProps.children}
              footerElement={
                <>
                  <div className={"cc-dialog-footer-actions-right"}>
                    {isShowParkButton(isFromActionList, isIncompleteMode) && (
                      <Button
                        iconClass={
                          isLoadingPark ? "fas fa-spinner fa-spin" : ""
                        }
                        className={"cc-dialog-button"}
                        id={EListSubmitButton.Park}
                        onClick={renderProps.submitButton.onClick}
                        disabled={
                          renderProps.nextButton.disabled || isLoadingPark
                        }
                      >
                        Park
                      </Button>
                    )}
                    {isShowCancelWorkflowButton && (
                      <Button
                        className={"cc-dialog-button"}
                        disabled={
                          isLoadingOnNext || isLoadingPark || isLoadingApprove
                        }
                        id={EListSubmitButton.Cancel}
                        onClick={renderProps.submitButton.onClick}
                      >
                        Cancel
                      </Button>
                    )}

                    {isToBeApprovalMode &&
                      modifyParcelInitData?.WorkflowHeader
                        ?.OfficerCanApprove && (
                        <>
                          <Button
                            themeColor="primary"
                            id={EListSubmitButton.SendBack}
                            disabled={renderProps.nextButton.disabled}
                            className={"cc-dialog-button"}
                            onClick={renderProps.submitButton.onClick}
                          >
                            Send Back
                          </Button>
                          <Button
                            themeColor="primary"
                            id={EListSubmitButton.Reallocate}
                            disabled={renderProps.nextButton.disabled}
                            className={"cc-dialog-button"}
                            onClick={renderProps.submitButton.onClick}
                          >
                            Reallocate
                          </Button>
                          <Button
                            themeColor="primary"
                            id={EListSubmitButton.Approve}
                            disabled={
                              isLoadingApprove ||
                              renderProps.nextButton.disabled
                            }
                            className={"cc-dialog-button"}
                            onClick={renderProps.submitButton.onClick}
                            iconClass={
                              isLoadingApprove ? "fas fa-spinner fa-spin" : ""
                            }
                          >
                            Approve
                          </Button>
                          <Button
                            themeColor="primary"
                            id={EListSubmitButton.Reject}
                            disabled={renderProps.nextButton.disabled}
                            className={"cc-dialog-button"}
                            onClick={renderProps.submitButton.onClick}
                          >
                            Reject
                          </Button>
                        </>
                      )}
                    {!renderProps.prevButton.disabled && (
                      <Button
                        className={"cc-dialog-button"}
                        themeColor="primary"
                        onClick={renderProps.prevButton.onClick}
                      >
                        Previous
                      </Button>
                    )}
                    {isToBeApprovalMode || isReadOnly ? (
                      !renderProps.isLastStep && (
                        <Button
                          themeColor="primary"
                          id="cc-next-step-button"
                          disabled={
                            isLoadingOnNext || renderProps.nextButton.disabled
                          }
                          className={"cc-dialog-button"}
                          iconClass={
                            isLoadingOnNext ? "fas fa-spinner fa-spin" : ""
                          }
                          onClick={renderProps.nextButton.onClick}
                        >
                          {isLoadingOnNext
                            ? "Saving"
                            : renderProps.nextButton.label}
                        </Button>
                      )
                    ) : (
                      <Button
                        themeColor="primary"
                        id={renderProps.nextButton.idButton}
                        disabled={
                          isLoadingOnNext || renderProps.nextButton.disabled
                        }
                        iconClass={
                          isLoadingOnNext ? "fas fa-spinner fa-spin" : ""
                        }
                        className={"cc-dialog-button"}
                        onClick={renderProps.nextButton.onClick}
                      >
                        {isLoadingOnNext
                          ? "Saving"
                          : renderProps.nextButton.label}
                      </Button>
                    )}
                  </div>
                </>
              }
            />
          );
        }}
      />
    );
  }
);
