import { CommunicationButtonListView } from "@app/core/communication/buttons-list-view/_index";
import { AccordionsLocalLawsPermit } from "@app/products/local-laws/[id]/components/child-screen/_index";
import { GeneralLocalLaws } from "@app/products/local-laws/[id]/components/general/_index";
import { useLocalLawsStore } from "@app/products/local-laws/[id]/store";
import { LodgePermitButton } from "@app/products/local-laws/components/toolbar/buttons/lodge-permit/_index";
import { RefusePermitButton } from "@app/products/local-laws/components/toolbar/buttons/refuse-permit/_index";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { AddActionButton } from "@common/pages/actions/components/action-bar/buttons/new-actions/_index";
import { useGlobalStore } from "@common/stores/global/store";
import { getDisplayTextWithDashes } from "@common/utils/common";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { ActionBarBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/_index";
import { IBookmarkItem } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/model";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { ReferenceSideBar } from "@components/cc-reference-sidebar/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import { default as React, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

export const ExistManageLocalLaws = observer(() => {
  const {
    isLoading,
    responseLoadError,
    localLaws,
    loadLocalLaws,
    localLawsId,
  } = useLocalLawsStore();
  const { id } = useParams<{ id: string }>();
  const managePageUrl = window.location.pathname;

  const { currentUserInfo } = useGlobalStore();

  const bookmarkList: IBookmarkItem[] = [
    {
      LinkText: `Local Laws - ${localLawsId ?? 0}`,
      Text: `${getDisplayTextWithDashes([
        localLaws?.PermitHolder ?? "No Name",
        localLaws?.PermitType,
      ])}`,
      LinkUrl: managePageUrl,
      User_ID: currentUserInfo?.UserPKID ? +currentUserInfo.UserPKID : 0,
      ProductType_ENUM: PRODUCT_TYPE.LLPermits,
      //TODO don't have exactly record type, temporary use LLP_Permit
      RecordType_ENUM: RECORDTYPE.LLP_Permit,
      Record_ID: localLawsId ? +localLawsId : 0,
    },
  ];
  const [showSlideBar, setShowSlideBar] = useState(true);

  const formTitle = useMemo(() => {
    let title = localLaws?.PermitHolder ?? "No Name";
    if (localLaws?.PermitType) title += `- ${localLaws?.PermitType}`;

    let badge = localLaws?.Status_ENUM.toString().replace(
      /([a-z](?=[A-Z]))/g,
      "$1 "
    );
    return { title, badge };
  }, [localLaws]);

  return (
    <>
      <Loading isLoading={isLoading} isFullScreen />
      <FormNavigation title="Local Law" />
      {responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => loadLocalLaws(+id)}
        />
      ) : (
        <>
          <FormTitle title={formTitle.title} badge={formTitle.badge} />
          <CCManagePageActionBar
            leftActions={[
              <CCNavButton
                title={"Workflow"}
                type="sub-workflow"
                className="cc-workflow-button"
              >
                <LodgePermitButton />
                <RefusePermitButton />
                <CCNavButton title={"Withdraw permit"} />
              </CCNavButton>,
              <CCNavButton title={"Save"} htmlFor={"btn-submit"} />,
              <CCNavButton title={"Add"} type="sub">
                <AddActionButton />
                <CCNavButton title={"Add condition"} />
                <CCNavButton title={"Additional address"} />
                <CCNavButton title={"Contact"} />
                <CCNavButton title={"Attachment"} />
                <CCNavButton title={"Bond"} />
                <CCNavButton title={"Comment"} />
                {/* TODO: enhance later */}
                <CommunicationButtonListView
                  productType={PRODUCT_TYPE_NUMBER.LLPermits}
                  recordType={RECORDTYPE.LLP_Permit}
                  recordId={parseInt(id)}
                />
                <CCNavButton title={"Complaint"} />
                <CCNavButton title={"Document"} />
                <CCNavButton title={"Fee"} />
                <CCNavButton title={"Fee - multiple"} />
                <CCNavButton title={"Inspection"} />
                <CCNavButton title={"Notice"} />
                <CCNavButton title={"Prosecution"} />
                <CCNavButton title={"Related"} />
                <CCNavButton title={"Standard condition"} />
              </CCNavButton>,
              <CCNavButton title={"View"} />,
              <CCNavButton title={"Print"} />,
            ]}
            rightActions={[
              <CCNavButton
                title={"Slide Bar"}
                iconClass="fal fa-info-circle"
                isActive={showSlideBar}
                onClick={() => {
                  setShowSlideBar(!showSlideBar);
                }}
              />,

              <ActionBarBookmarkIcon bookmarkList={bookmarkList} />,
            ]}
          />

          <div className="cc-manage-container">
            <div className="cc-manage-left">
              <CCAppNotification />
              <div className="cc-manage-form-body">
                <GeneralLocalLaws />
                <AccordionsLocalLawsPermit />
              </div>
            </div>
            {showSlideBar && (
              <div className="cc-manage-right">
                <ReferenceSideBar />
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
});
