import { eventEmitter } from "@/App";
import { cancelNoticeOfSale } from "@app/products/property/changes-of-ownership/notice-of-sales/components/action-bar/buttons/cancel-notice-of-sale/api";
import { NoticeOfSaleStatus } from "@app/products/property/changes-of-ownership/notice-of-sales/constant";
import { isSuccessResponse } from "@common/apis/util";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCGridEventType } from "@components/cc-grid/constant";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import {
  ConfirmDialog,
  IConfirmDialogData,
} from "@components/dialog/ConfirmDialog";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

export const CancelNoticeOfSaleButton = observer(() => {
  const { gridSelectedRows, clearSelectedItems } = useCCProductListViewStore();
  const { pushNotification } = useCCAppNotificationStore();

  const [isShowConfirmDialog, setIsShowConfirmDialog] =
    useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  /**
   * Handle cancel notice of sale
   */
  const handleCancel = async (data: IConfirmDialogData) => {
    const selectedNoticeOfSaleId = gridSelectedRows[0].NoticeofSale_Id;
    setIsLoading(true);
    const response = await cancelNoticeOfSale({
      NoticeOfSale_Id: selectedNoticeOfSaleId,
      Comment: data.Reason,
    });
    setIsLoading(false);
    setIsShowConfirmDialog(false);
    if (isSuccessResponse(response) && response?.data?.IsSuccess) {
      eventEmitter.emit(CCGridEventType.RefreshOData); //Reload notice of sale grid
      pushNotification({
        title:
          response.data.SuccessMessage ??
          "Notice of sale cancelled successfully.",
        type: "success",
      });
      clearSelectedItems();
    } else {
      //Local notification
      pushNotification({
        autoClose: false,
        title: response.data.ErrorMessage ?? `Cancel notice of sale failed.`,
        type: "error",
      });
    }
  };

  return (
    <>
      <CCNavButton
        title="Cancel notice of sale"
        onClick={() => setIsShowConfirmDialog(true)}
        disabled={
          gridSelectedRows.length !== 1 ||
          !(
            gridSelectedRows[0].Status === NoticeOfSaleStatus.Imported ||
            gridSelectedRows[0].Status === NoticeOfSaleStatus.Suspended
          )
        }
      />
      {isShowConfirmDialog && (
        <ConfirmDialog
          isReasonRequired
          title="Confirm Cancellation"
          subMessage="Are you sure you wish to cancel this notice of sale?"
          onClosePopup={() => {
            setIsShowConfirmDialog(false);
          }}
          onAsyncConfirm={handleCancel}
          isLoadingYes={isLoading}
        />
      )}
    </>
  );
});
