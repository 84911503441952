import { useDetailAssessmentListViewStore } from "@app/products/property/assessments/collections/components/detail-assessment/components/grid-detail-assessment/store";
import { usePropertyWorkflowStore } from "@app/products/property/components/action-bar/property-workflow/store";
import { eComponent } from "@app/products/property/components/associations/model";
import { WorkflowTypes } from "@app/products/property/model";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";

export const ChangeOfOwnerShipButtonAtCollections = observer(() => {
  const { setListDialog } = usePropertyWorkflowStore();
  const { gridSelectedRows } = useDetailAssessmentListViewStore();

  const isDisabled = useMemo(() => {
    return gridSelectedRows.length !== 1;
  }, [gridSelectedRows.length]);

  return (
    <CCNavButton
      title={"Ownership"}
      onClick={() => {
        setListDialog([
          {
            type: WorkflowTypes.Change_Of_Ownership,
            data: {},
            props: {
              assessmentId: gridSelectedRows?.[0]?.Assessment_Id ?? null,
              componentNumber: eComponent.Assessment,
            },
          },
        ]);
      }}
      disabled={isDisabled}
    />
  );
});
