import { DTO_LevySummary } from "@app/products/property/charge-and-notice-runs/charge-runs/[id]/components/child-screens/levy-summary/model";
import {
  CURRENCY_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOfLevySummaryGrid = nameOfFactory<DTO_LevySummary>();
export const colLevySummary: IColumnFields[] = [
  {
    field: nameOfLevySummaryGrid("Group"),
    title: "Financial Area",
  },
  {
    field: nameOfLevySummaryGrid("Levy_Amount"),
    title: "Levy Amount",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOfLevySummaryGrid("Valuation"),
    title: "Valuation",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOfLevySummaryGrid("NumberOfLevies"),
    title: "Number of Levies",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOfLevySummaryGrid("BaseAmount"),
    title: "Base Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOfLevySummaryGrid("RateByValuationAmount"),
    title: "Rate by Valuation Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOfLevySummaryGrid("IncrementsToMinimum"),
    title: "Increments to Minimum",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOfLevySummaryGrid("ReductionToMaximum"),
    title: "Reduction to Maximum",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOfLevySummaryGrid("AdjustmentForCapping"),
    title: "Adjustment for Capping",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOfLevySummaryGrid("GrossLevyAmount"),
    title: "Gross Levy Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOfLevySummaryGrid("Rebates"),
    title: "Rebates",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOfLevySummaryGrid("NettLevyAmount"),
    title: "Nett Levy Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOfLevySummaryGrid("AttributableValue"),
    title: "Attribute Value",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
];
