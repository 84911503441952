import { DeleteButton } from "@app/core/delete/buttons/_index";
import { CrmsActionBarNavDropdown } from "@app/products/crms/components/action-bar/nav-dropdown/_index";
import { FormAction, FormIdentifier } from "@app/products/crms/model";
import { CRMSPhoneMessagesContactTab } from "@app/products/crms/phone-messages/[id]/components/forms/components/reference-sidebar/contact/_index";
import { CRMSPhoneMessageDetailsTab } from "@app/products/crms/phone-messages/[id]/components/forms/components/reference-sidebar/details/_index";
import { CRMSPhoneMessageHistoryTab } from "@app/products/crms/phone-messages/[id]/components/forms/components/reference-sidebar/history/_index";
import { CRMS_PHONE_MESSAGE_ROUTE } from "@app/products/crms/phone-messages/[id]/constant";
import { PhoneMessageCloseEventsButton } from "@app/products/crms/phone-messages/components/action-bar/buttons/close-events/_index";
import { NewPhoneMessageButton } from "@app/products/crms/phone-messages/components/action-bar/buttons/new/_index";
import { PHONE_MESSAGE_GRID_ID } from "@app/products/crms/phone-messages/constant";
import { colOpenMessages } from "@app/products/crms/phone-messages/open-messages/config";
import { CRMS_PHONE_MESSAGES_OPEN_MESSAGES_VIEW_URL } from "@app/products/crms/phone-messages/open-messages/constant";
import { PhoneMessageView } from "@app/products/crms/phone-messages/open-messages/models";
import { CRMSPhoneMessagesOpenMessagesBookmark } from "@app/products/crms/phone-messages/open-messages/util";
import { phoneMessagesRoute } from "@app/products/crms/phone-messages/route";
import { CRMSPhoneMessagesBookmark } from "@app/products/crms/phone-messages/util";
import { crmsRoute } from "@app/products/crms/route";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { LoadingPermissionWrapper } from "@common/hocs/permissions/check-security-loading-wrapper";
import { useCheckPermissions } from "@common/hooks/permissions/useCheckPermissions";
import { nameOfFactory } from "@common/utils/common";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { MapTab } from "@components/cc-reference-sidebar/components/Map/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import { observer } from "mobx-react-lite";
import React from "react";

const nameOf = nameOfFactory<PhoneMessageView>();

export default observer(() => {
  const { checkPermissions, isLoadingPermission } = useCheckPermissions({
    formIdentifier: FormIdentifier.CUSTOMERSERVICE_Form_PhoneMessage,
    productType: PRODUCT_TYPE_NUMBER.CustomerService,
  });

  useCCListViewActionBar({
    title: crmsRoute.name,
    leftComponents: [
      <CrmsActionBarNavDropdown category={phoneMessagesRoute.path} />,
    ],
    centerComponents: [
      <NewPhoneMessageButton
        isDisable={!checkPermissions(FormAction.CORE_ALLOW_NEW)}
      />,
      <DeleteButton
        recordType={RECORDTYPE.CUSTOMERSERVICE_PhoneMessage}
        disabled={!checkPermissions(FormAction.CORE_ALLOW_DELETE)}
        refreshGridIds={[PHONE_MESSAGE_GRID_ID]}
      />,
      <PhoneMessageCloseEventsButton />,
    ],
    rightComponents: [
      <ActionBarExportIcon />,
      <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
      <ListViewBookmarkIcon
        linkUrl={CRMS_PHONE_MESSAGE_ROUTE}
        productType={PRODUCT_TYPE.CRMS_PhoneMessages}
        recordType={RECORDTYPE.CUSTOMERSERVICE_PhoneMessage}
        detail={CRMSPhoneMessagesBookmark.getBookmarkDetail}
        displayName={CRMSPhoneMessagesBookmark.getBookmarkDisplayName}
        listViewDisplayName={
          CRMSPhoneMessagesOpenMessagesBookmark.getBookmarkListViewDisplayName
        }
        listViewDetail={
          CRMSPhoneMessagesOpenMessagesBookmark.getBookmarkListViewDetail
        }
      />,
    ],
  });

  useReferenceSideBar({
    referenceComponents: [
      { title: "Details", component: <CRMSPhoneMessageDetailsTab /> },
      {
        title: "Map",
        component: <MapTab />,
      },
      {
        title: "Contacts",
        component: <CRMSPhoneMessagesContactTab />,
      },
      {
        title: "History",
        component: <CRMSPhoneMessageHistoryTab />,
      },
    ],
  });

  return (
    <LoadingPermissionWrapper isLoadingPermission={isLoadingPermission}>
      <CCProductListView
        columnFields={colOpenMessages}
        primaryField={nameOf("ID")}
        dataUrl={CRMS_PHONE_MESSAGES_OPEN_MESSAGES_VIEW_URL}
        state={{
          sort: [{ field: nameOf("RefNo"), dir: "desc" }],
          group: [{ field: nameOf("Recipient"), dir: "asc" }],
        }}
        gridId={PHONE_MESSAGE_GRID_ID}
      />
    </LoadingPermissionWrapper>
  );
});
