import { ServiceStandardView } from "@app/products/crms/[id]/components/forms/components/reference-sidebar/details/model";
import {
  Event,
  PickServiceStandard_Types,
} from "@app/products/crms/[id]/model";
import { useCRMSEventStore } from "@app/products/crms/[id]/store";
import { colCategory } from "@app/products/crms/components/dialogs/category-sub-category/config";
import { processFilter } from "@app/products/crms/components/dialogs/category-sub-category/utils";
import { columnFieldsCustom } from "@app/products/crms/service-standards/util";
import { defaultGroupBy } from "@app/products/crms/util";
import { useIsNew } from "@common/hooks/useIsNew";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { useCommonCoreStore } from "@common/stores/core/store";
import {
  getBoolValueSetting,
  getNumberValueSetting,
  getStringValueSetting,
} from "@common/stores/products/util";
import { nameOfFactory } from "@common/utils/common";
import { GridSelectionDialog } from "@components/dialog/dialog-grid-selection/_index";
import { Form } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useEffectOnce } from "react-use";

const nameOf = nameOfFactory<ServiceStandardView>();

export interface ICategorySubCategoryDialogProps {
  onSubmit?: (serviceStandards: ServiceStandardView[]) => void;
  onClosePopup: () => void;
  titleHeader?: string;
  selectableMode?: "none" | "multiple" | "single" | undefined;
  formObject?: Event;
}

export const CategorySubCategoryDialog = observer(
  ({
    onSubmit,
    onClosePopup,
    titleHeader = "Please Select The Category - Sub Category",
    selectableMode = "single",
    formObject,
  }: ICategorySubCategoryDialogProps) => {
    const isNew = useIsNew();
    const {
      articleID,
      parentSection,
      categoryFilter,
      isLoadingCategoryFilter,
      loadCategoryFilter,
      pickCRMSEventServiceStandard,
    } = useCRMSEventStore();
    const { settings } = useCommonCoreStore();

    const itemPerPage = getNumberValueSetting(
      settings[ECorporateSettingsField.CorporateSettings_CategoriesGridPageSize]
    );
    const isShowCommentDialogSetting = getBoolValueSetting(
      settings[
        ECorporateSettingsField.CUSTOMERSERVICE_Event_CommentOnRecategorise
      ]
    );
    const serviceStandardCategoryLevels = getNumberValueSetting(
      settings[
        ECorporateSettingsField.CUSTOMERSERVICE_ServiceStandardCategoryLevels
      ]
    );
    const subCategoryLevel3Label = getStringValueSetting(
      settings[
        ECorporateSettingsField
          .CUSTOMERSERVICE_ServiceStandardSubCategoryLevel3Caption
      ]
    );
    const subCategoryLevel4Label = getStringValueSetting(
      settings[
        ECorporateSettingsField
          .CUSTOMERSERVICE_ServiceStandardSubCategoryLevel4Caption
      ]
    );

    const [stateGrid, setStateGrid] = useState<any>({
      group: defaultGroupBy(serviceStandardCategoryLevels),
      filter: {
        filters: processFilter(articleID, categoryFilter),
        logic: "and",
      },
    });

    const loadFilterStateGrid = async () => {
      if (formObject) {
        if (isNew && parentSection?.isFromAnimals) {
          await loadCategoryFilter({
            ArticleID: articleID ?? null,
            Event: {},
            PickServiceStandardType:
              PickServiceStandard_Types.PickServiceStandard_Animal,
          });
        } else if (isNew && parentSection?.isFromKennel) {
          await loadCategoryFilter({
            ArticleID: articleID ?? null,
            Event: {},
            PickServiceStandardType:
              PickServiceStandard_Types.PickServiceStandard_Kennel,
          });
        } else {
          await loadCategoryFilter({
            ArticleID: articleID ?? null,
            Event: formObject,
          });
        }
      }
    };

    useEffect(() => {
      setStateGrid({
        group: defaultGroupBy(serviceStandardCategoryLevels),
        filter: {
          filters: processFilter(articleID, categoryFilter),
          logic: "and",
        },
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [categoryFilter]);

    useEffectOnce(() => {
      loadFilterStateGrid();
    });

    return (
      <Form
        render={() => (
          <GridSelectionDialog
            isLoading={isLoadingCategoryFilter}
            selectableMode={selectableMode}
            maxWidth="60%"
            itemPerPage={itemPerPage}
            height={810}
            maxHeight="85%"
            titleHeader={titleHeader}
            onCloseDialog={() => onClosePopup()}
            dataUrl={`odata/crms/internal/servicestandard/GetPickServiceStandardList${
              articleID ? `(articleID=${articleID})` : ""
            }?$count=true&`}
            columnFields={columnFieldsCustom(
              serviceStandardCategoryLevels,
              colCategory,
              subCategoryLevel3Label,
              subCategoryLevel4Label
            )}
            setShowDialog={() => onClosePopup()}
            hasSearchField
            groupDragMode="auto"
            state={stateGrid}
            onDataStateChange={(state) => setStateGrid(state)}
            onSubmit={(serviceStandards: ServiceStandardView[]) => {
              if (onSubmit) {
                onSubmit(serviceStandards);
              } else {
                if (serviceStandards?.[0]) {
                  pickCRMSEventServiceStandard(
                    serviceStandards?.[0],
                    isNew,
                    isShowCommentDialogSetting
                  );
                }
              }

              onClosePopup();
            }}
            crmsGrid
            filterCol={nameOf("Name")}
          />
        )}
      />
    );
  }
);
