import { searchContacts } from "@app/products/property/components/fields/search-name/api";
import { searchNameConfig } from "@app/products/property/components/fields/search-name/config";
import { NewContactDialog } from "@app/products/property/contacts-central-names/list/components/dialogs/new-contact/_index";
import { DTO_Workflow_CreateContact } from "@app/products/property/contacts-central-names/list/components/dialogs/new-contact/model";
import { ResponseMessage } from "@app/products/property/model";
import { isSuccessResponse } from "@common/apis/util";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { ContactLookahead_JSON } from "@common/models/contact";
import { DTO_LOV } from "@common/models/odataResponse";
import { nameOfFactory } from "@common/utils/common";
import { CCComboboxWithNewOption } from "@components/cc-combobox-with-new-option/_index";
import { CCLabel } from "@components/cc-label/_index";
import {
  ComboBoxChangeEvent,
  ComboBoxFilterChangeEvent,
  ComboBoxProps,
  ListItemProps,
} from "@progress/kendo-react-dropdowns";
import { Field } from "@progress/kendo-react-form";
import axios, { CancelTokenSource } from "axios";
import React, { useRef, useState } from "react";
import { useUnmount } from "react-use";
interface ISearchNameCombobox extends ComboBoxProps {
  name: any;
  onSelectionChange: (values: any) => void;
  onError: (values: any) => void;
  onSubmitNewContactDialog?: (
    payload: DTO_Workflow_CreateContact,
    responseMessage: ResponseMessage
  ) => void;
  selectedSearchOptions?: DTO_LOV[];
  isShowHelpText?: boolean;
  helpText?: string;
  isShowNewFooter?: boolean;
  title?: string;
  placeholder?: string;
  isClearSearchValue?: boolean;
  textField?: keyof ContactLookahead_JSON;
}
const nameOf = nameOfFactory<ContactLookahead_JSON>();
export const SearchNameCombobox = (props: ISearchNameCombobox) => {
  const {
    name,
    onSelectionChange,
    onError,
    onSubmitNewContactDialog,
    isShowHelpText = true,
    helpText = "Enter surname to search for existing contacts. Click on 'New' to create a new contact.",
    isShowNewFooter = true,
    title = "Search name",
    placeholder = "Name",
    isClearSearchValue = true,
    textField = nameOf("DisplayName"),
    ...others
  } = props;

  const refTimeOut = useRef<NodeJS.Timeout | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = useState<ContactLookahead_JSON[]>([]);
  const [searchKey, setSearchKey] = useState<string | undefined>("");
  const [showNewContactDialog, setShowNewContactDialog] =
    useState<boolean>(false);
  const cancelRequest = useRef<CancelTokenSource>();

  //[13524] Contact - Workflow dialog contact lookup update - UX:
  // - Search options:  hide for all (not just LLS)
  // const searchParams = useMemo(() => {
  //   if (!selectedSearchOptions) return "";
  //   return selectedSearchOptions
  //     .map((item: DTO_LOV) => {
  //       return item.Code + "=true";
  //     })
  //     .join("&");
  // }, [selectedSearchOptions]);

  const handleSearchName = (event: ComboBoxFilterChangeEvent) => {
    if (showNewContactDialog) return;
    setData([]);
    setSearchKey(event.filter?.value ?? "");
    if (event.filter.value.length < searchNameConfig.minCharacters) return;
    if (refTimeOut.current) clearTimeout(refTimeOut.current);
    refTimeOut.current = setTimeout(() => {
      cancelRequest.current?.cancel("CancelSearching");
      cancelRequest.current = axios.CancelToken.source();
      setIsLoading(true);
      searchContacts(
        event.filter.value,
        PRODUCT_TYPE_NUMBER.CommunityProperty,
        cancelRequest.current
      ).then((response) => {
        if (isSuccessResponse(response)) {
          setData(response?.data ?? []);
          setIsLoading(false);
        } else if (response.error !== "CancelSearching") {
          if (onError) onError(response.error);
          setIsLoading(false);
        }
      });
    }, searchNameConfig.typeSpeed);
  };

  useUnmount(() => {
    if (refTimeOut.current) clearTimeout(refTimeOut.current);
  });

  const itemRender = (
    li: React.ReactElement<HTMLLIElement>,
    itemProps: ListItemProps
  ) => {
    const { dataItem } = itemProps;
    const displayName = dataItem.DisplayName ? `${dataItem.DisplayName}` : "";
    const contactInfo = dataItem.ContactInfo ? `, ${dataItem.ContactInfo}` : "";
    const email = dataItem.Email ? `, ${dataItem.Email}` : "";
    const organisation = dataItem.Organisation
      ? `, ${dataItem.Organisation}`
      : "";
    const entityID = dataItem.Entity_Id
      ? ` (Contact ID: ${dataItem.Entity_Id})`
      : "";

    const itemChildren = (
      <span>{`${displayName}${contactInfo}${email}${organisation}${entityID}`}</span>
    );

    return React.cloneElement(li, li.props, itemChildren);
  };

  return (
    <section className="cc-field-group">
      <div className="cc-field">
        <CCLabel
          title={title}
          informationTooltip={isShowHelpText ? helpText : undefined}
        />
        <Field
          name={name}
          filterable
          suggest
          onFilterChange={handleSearchName}
          data={data}
          loading={isLoading}
          textField={nameOf(textField)}
          placeholder={placeholder}
          component={CCComboboxWithNewOption}
          value={isClearSearchValue ? searchKey : undefined}
          onChange={(event: ComboBoxChangeEvent) => {
            onSelectionChange(event.target.value);
            if (isClearSearchValue && !showNewContactDialog) {
              setData([]);
              setSearchKey(undefined);
            }
            if (!isClearSearchValue) {
              setSearchKey(event.target.value?.[nameOf(textField)]);
            }
          }}
          isShowNewFooter={isShowNewFooter}
          onChangeNewButton={() => {
            setShowNewContactDialog(true);
          }}
          searchKey={searchKey}
          itemRender={itemRender}
          {...others}
        />
      </div>
      {showNewContactDialog && (
        <NewContactDialog
          isOpenedFromParentWorkflow
          isRedirectManagePage={false}
          currentEntity={{
            Surname: searchKey?.split(",")?.[0] || "",
            GivenName: searchKey?.split(",")?.[1] || "",
          }}
          onClose={() => {
            setShowNewContactDialog(false);
          }}
          handleSubmitDialog={(payload, responseMessage) => {
            if (!onSubmitNewContactDialog) return;
            if (isClearSearchValue) {
              setData([]);
              setSearchKey(undefined);
            }
            onSubmitNewContactDialog(payload, responseMessage);
          }}
          isSaveOnNextStep
        />
      )}
    </section>
  );
};
