import { ContactClassification } from "@app/core/contacts/_id/model";
import { generateGetContactURL } from "@app/core/contacts/components/dialogs/pick-contact/util";
import {
  CS_EventStatus,
  ElementDisplayStatus,
} from "@app/products/crms/[id]/model";
import { EventView } from "@app/products/crms/all-events/by-refno/model";
import {
  CRMS_PRODUCT_NAME,
  emailManualEntriesRegex,
} from "@app/products/crms/config";
import { ServiceStandardView } from "@app/products/crms/service-standards/model";
import { PRODUCT_TYPE } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { getDisplayTextWithDashes, nameOfFactory } from "@common/utils/common";
import { getFilterColumnsName } from "@components/cc-grid/util";
import { ActiveProduct } from "@components/layout/model";
import { GroupDescriptor, State } from "@progress/kendo-data-query";
import { isNil, some, uniq } from "lodash";

export const CRMSBookmark = {
  getBookmarkDisplayName(selectedRow: any) {
    const dynamicDisplayName = selectedRow?.RefNo
      ? `- ${selectedRow.RefNo}`
      : "";
    return `${CRMS_PRODUCT_NAME} ${dynamicDisplayName}`;
  },
  getBookmarkDetail(selectedRow: any) {
    return getDisplayTextWithDashes([selectedRow?.Description]);
  },
};

export const isFieldVisible = (visibility?: ElementDisplayStatus) => {
  if (isNil(visibility)) return false;

  return visibility === ElementDisplayStatus.Visible;
};

export const isFieldDisable = (disable?: ElementDisplayStatus) => {
  if (isNil(disable)) return false;

  return disable === ElementDisplayStatus.Disable;
};

export const generateContactManagerDataURL = (
  filter: number[],
  notHaveCount = false
) => {
  let url = undefined;
  if (filter.length > 0) {
    const filterString = filter.join(",");
    url = generateGetContactURL({
      classificationFilters: `'${ContactClassification.SiteUser}'`,
      hideCouncillors: "true",
      orgStructureFilter: `'${filterString}'`,
    });
  } else {
    url = generateGetContactURL({
      classificationFilters: `'${ContactClassification.SiteUser}'`,
      hideCouncillors: "true",
    });
  }

  if (notHaveCount) {
    return url;
  } else {
    return `${url}?$count=true&`;
  }
};

const nameOf = nameOfFactory<ServiceStandardView>();
export const defaultGroupBy = (serviceStandardCategoryLevels: number) => {
  const groupBy: GroupDescriptor[] = [
    {
      field: nameOf("Category"),
      dir: "asc",
    },
  ];

  if (serviceStandardCategoryLevels > 2) {
    groupBy.push({ field: nameOf("SubCategory"), dir: "asc" });
    if (serviceStandardCategoryLevels > 3) {
      groupBy.push({ field: nameOf("SubCategoryLevel3"), dir: "asc" });
    }
  }

  return groupBy;
};

// Use for event & service standard batch update pages that have no route enum. Details for tasks:
// - [9948] <CRMS Batch Tasks - Batch Update Event - UX>
// - [9950] <CRMS Batch Tasks - Batch Update Service Standards * 3 - UX>
export const spatialCRMSRouteCase = {
  isBathUpdate: (path: string) => path.search("batch-update") !== -1,
};

export const eventStatusAfterSendForAction = [
  CS_EventStatus.Pending,
  CS_EventStatus.Open,
  CS_EventStatus.Scheduled,
];

// Custom width for a column when has a filter, sort, group this column
export const calculatorDynamicWidthIconColumn = (
  defaultWidth: number,
  state: State,
  field: string
) => {
  let width = defaultWidth;
  const { sort, filter, group } = state;
  const filterColumnNames = getFilterColumnsName(filter);

  const hasSort = some(sort, ["field", field]);
  const hasGroup = some(group, ["field", field]);
  const hasFilter = some(filterColumnNames, (fieldName) => fieldName === field);

  if (hasFilter) width += 15;
  if (hasGroup) width += 10;
  if (hasSort) width += sort && sort?.length > 1 ? 25 : 15;

  return width;
};

// Manual entries validation
export const validatorManualEntriesEmail = (manualEntries: string) => {
  if (!manualEntries) return "";
  if (manualEntries.indexOf(" ") !== -1) return "Email is not valid format.";
  if (manualEntries.includes(";")) {
    const manualEntriesArr = manualEntries.split(";");
    const manualEntriesDuplicate = manualEntriesArr.filter(
      (item, index) => manualEntriesArr.indexOf(item) !== index
    );

    if (
      manualEntries.indexOf(";") === 0 ||
      manualEntries.lastIndexOf(";") === manualEntries.length - 1 ||
      manualEntriesArr.indexOf("") !== -1
    )
      return "Email is not valid format.";

    if (manualEntriesDuplicate.length !== 0) {
      return `Email is being duplicated.(${uniq(manualEntriesDuplicate).join(
        ", "
      )})`;
    }

    return manualEntriesArr.find(
      (manualEntry: string) => !emailManualEntriesRegex.test(manualEntry)
    )
      ? "Email is not valid format."
      : "";
  } else {
    return !emailManualEntriesRegex.test(manualEntries)
      ? "Email is not valid format."
      : "";
  }
};
// Get groups for event views based on Customer Service Category Levels
const nameOfEventView = nameOfFactory<EventView>();
export const defaultEventViewGroupBy = (
  serviceStandardCategoryLevels: number
) => {
  const groupBy: GroupDescriptor[] = [
    { field: nameOfEventView("Cat"), dir: "asc" },
    { field: nameOfEventView("SubCat"), dir: "asc" },
  ];
  if (serviceStandardCategoryLevels > 2) {
    groupBy.push({ field: nameOfEventView("SubCategoryLevel3"), dir: "asc" });
    if (serviceStandardCategoryLevels > 3) {
      groupBy.push({ field: nameOfEventView("SubCategoryLevel4"), dir: "asc" });
    }
  }
  return groupBy;
};

// check CRMS product is Active
export const checkCRMSProductionIsActive = (
  productsActive: ActiveProduct[]
) => {
  return productsActive.length === 0
    ? false
    : productsActive.some(
        (item: ActiveProduct) =>
          item.ProductType_ENUM === PRODUCT_TYPE.CustomerService
      );
};

export const checkProductActiveAndFlagCommunication = (
  productsActive: ActiveProduct[],
  productType: PRODUCT_TYPE
) => {
  return productsActive.length === 0
    ? false
    : productsActive.some(
        (item: ActiveProduct) =>
          item.ProductType_ENUM === productType && item.Flag_CommunityCentral
      );
};

export const getManageHubURL = (recordType: RECORDTYPE, id: number) => {
  switch (recordType) {
    case RECORDTYPE.CUSTOMERSERVICE_Event:
      return `Secure/Content/Products/CustomerService/Forms/ManageEvent.aspx?eid=${id}`;
    case RECORDTYPE.HealthManager_Premises:
      return `Secure/Content/Products/HealthManager/Forms/ManagePremises.aspx?eid=${id}`;
    case RECORDTYPE.WW_System:
      return `Secure/Content/Products/WasteWater/Forms/ManageSystem.aspx?eid=${id}`;
    case RECORDTYPE.Animals_Registration:
      return `Secure/Content/Products/Animals/Forms/ManageRegistration.aspx?eid=${id}`;
    case RECORDTYPE.LLP_Permit:
      return `Secure/Content/Products/LLP/Forms/ManagePermit.aspx?eid=${id}`;
    default:
      return "";
  }
};

// Generate ODATA URL
export const getMyCoordinatorEventOdataURL = (
  openEventsOnly: boolean,
  unresolvedEvents: boolean
) => {
  return `odata/crms/internal/events/GetMyCoordinatorEvents(openEventsOnly=${openEventsOnly},unresolvedEvents=${unresolvedEvents})?$count=true&`;
};

export const getEventsByActionPersonOdataURL = (
  openEventsOnly: boolean,
  unresolvedEvents: boolean
) => {
  return `odata/crms/internal/events/GetEventsByActionOfficer(openEventsOnly=${openEventsOnly},unresolvedEvents=${unresolvedEvents})?$count=true&`;
};

export const getEventsByCoordinatorOdataURL = (
  openEventsOnly: boolean,
  unresolvedEvents: boolean
) => {
  return `odata/crms/internal/events/GetEventsByCoordinator(openEventsOnly=${openEventsOnly},unresolvedEvents=${unresolvedEvents})?$count=true&`;
};

export const getEventsByRefNoOdataURL = (
  openEventsOnly: boolean,
  unresolvedEvents: boolean
) => {
  return `odata/crms/internal/events/GetEventsByRefNo(openEventsOnly=${openEventsOnly},unresolvedEvents=${unresolvedEvents})?$count=true&`;
};

export const getEventsByCategoryOdataURL = (
  openEventsOnly: boolean,
  unresolvedEvents: boolean
) => {
  return `odata/crms/internal/events/GetEventsByCategory(openEventsOnly=${openEventsOnly},unresolvedEvents=${unresolvedEvents})?$count=true&`;
};

export const getEventsByStreetAndSuburbOdataURL = (
  openEventsOnly: boolean,
  unresolvedEvents: boolean
) => {
  return `odata/crms/internal/events/GetEventsByStreet(openEventsOnly=${openEventsOnly},unresolvedEvents=${unresolvedEvents})?$count=true&`;
};

export const getEventsByOrgStructureOdataURL = (
  openEventsOnly: boolean,
  unresolvedEvents: boolean
) => {
  return `odata/crms/internal/events/GetEventsByOrgStructure(openEventsOnly=${openEventsOnly},unresolvedEvents=${unresolvedEvents})?$count=true&`;
};

export const getEventsByRecordedByOdataURL = (
  openEventsOnly: boolean,
  unresolvedEvents: boolean
) => {
  return `odata/crms/internal/events/GetEventsByRecordedBy(openEventsOnly=${openEventsOnly},unresolvedEvents=${unresolvedEvents})?$count=true&`;
};

export const getEventsByAssetOdataURL = (
  openEventsOnly: boolean,
  unresolvedEvents: boolean
) => {
  return `odata/crms/internal/events/GetEventsByAsset(openEventsOnly=${openEventsOnly},unresolvedEvents=${unresolvedEvents})?$count=true&`;
};

export const getEventsByStatusOdataURL = (
  openEventsOnly: boolean,
  unresolvedEvents: boolean
) => {
  return `odata/crms/internal/events/GetEventsByStatus(openEventsOnly=${openEventsOnly},unresolvedEvents=${unresolvedEvents})?$count=true&`;
};

export const getEventsSafetyOdataURL = (unresolvedEvents: boolean) => {
  return `odata/crms/internal/events/GetSafetyEvents(unresolvedEventsOnly=${unresolvedEvents})?$count=true&`;
};

export const getEventsVIPOdataURL = (unresolvedEvents: boolean) => {
  return `odata/crms/internal/events/GetVIPEvents(unresolvedEventsOnly=${unresolvedEvents})?$count=true&`;
};
