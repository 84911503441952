import { getViewConfigurations } from "@app/products/property/api";
import { requiredPICTypes } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/change-of-ownership/components/form-elements/pic/config";
import { picGridValidator } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/change-of-ownership/components/form-elements/pic/util";
import { loadRetainNames } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/change-of-ownership/components/form-elements/property/api";
import { useFormatNameStep } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/change-of-ownership/hooks/useChangeOfOwnership";
import {
  DTO_PICDetails,
  EKeysOfSteps,
} from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/change-of-ownership/model";
import { useChangeOfOwnershipDialogStore } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/change-of-ownership/store";
import { AddPICLookupDialog } from "@app/products/property/components/dialogs/add-pic-lookup/_index";
import { colPicLookup } from "@app/products/property/components/dialogs/add-pic-lookup/config";
import { DTO_PIC } from "@app/products/property/components/dialogs/add-pic-lookup/model";
import { ICCViewColumn, ViewConfiguration } from "@app/products/property/model";
import { processCombineData } from "@app/products/property/pic/list/components/action-bar/form-steps/new-pic/components/form-element/associations/util";
import { processDynamicColumns } from "@app/products/property/util";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { nameOfFactory } from "@common/utils/common";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCGrid } from "@components/cc-grid/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { CCLabel } from "@components/cc-label/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import Loading from "@components/loading/Loading";
import { Button } from "@progress/kendo-react-buttons";
import { FieldArray } from "@progress/kendo-react-form";
import { map } from "lodash";
import React, { useCallback, useState } from "react";
import { useEffectOnce } from "react-use";
const nameOfPIC = nameOfFactory<DTO_PIC>();
const nameOfPICDetail = nameOfFactory<DTO_PICDetails>();
export const PICFormStep = (props: IFormStepElement) => {
  const newValidator = useCallback(
    (value: any, valueGetter: (name: string) => any) => {
      if (props?.options?.isReadOnly) return undefined;
      const selectedTypeId = valueGetter(`${EKeysOfSteps.Type}.Type`);
      if (requiredPICTypes.includes(selectedTypeId)) {
        return picGridValidator(value);
      }
      return undefined;
    },
    [props?.options]
  );
  return (
    <FieldArray
      name={props.nameOf()}
      {...props}
      component={FormStepElement}
      validator={newValidator}
    />
  );
};

const FormStepElement = ({
  formRenderProps,
  localNotificationRef,
  nameOf,
  options = {
    isReadOnly: false,
  },
}: IFormStepElement) => {
  const { valueGetter, onChange, errors } = formRenderProps;
  const getFieldValue = (name: string) => valueGetter(nameOf(name));
  const { changeOfOwnershipLOVs } = useChangeOfOwnershipDialogStore();
  const { handleRetainNames, selectedType, handleUpdateRetainedNames } =
    useFormatNameStep(
      changeOfOwnershipLOVs,
      onChange,
      valueGetter,
      options?.setIsLoadingStep
    );
  const [showAddPicDialog, setShowAddPicDialog] = useState(false);
  const [responseLoadError, setResponseLoadError] = useState<
    APIResponseError | undefined
  >();
  const [colsPIC, setColsPic] = useState<IColumnFields[]>(colPicLookup);
  const [isLoadingViewPic, setIsLoadingViewPic] = useState<boolean>(false);
  const [isLoadingAddPIC, setIsLoadingAddPIC] = useState(false);
  const selectedTypeId = valueGetter(`${EKeysOfSteps.Type}.Type`);
  const selectedPic = getFieldValue("_option.PicSelected");
  const currentPIC = getFieldValue(nameOfPICDetail("PICs")) ?? [];

  const loadViewConfigurationPIC = async () => {
    setIsLoadingViewPic(true);
    const response = await getViewConfigurations(ViewConfiguration.PIC_Lookup);
    setIsLoadingViewPic(false);
    let errorResponse = undefined;
    let configColumn: IColumnFields[] = [...colPicLookup];
    if (isSuccessResponse(response)) {
      const viewConfig: ICCViewColumn[] | undefined =
        response?.data?.ColumnDefinitions?.Columns;
      if (viewConfig?.length) {
        configColumn = processDynamicColumns(configColumn, viewConfig);
      }
    } else {
      errorResponse = {
        status: response.status,
        error: response.error,
      };
    }
    setColsPic(configColumn);
    setResponseLoadError(errorResponse);
  };

  useEffectOnce(() => {
    loadViewConfigurationPIC();
  });

  const handleAddItemPIC = (data: DTO_PIC[]) => {
    const previousPic = currentPIC?.length ? [...currentPIC] : [];
    const picData = processCombineData(previousPic, data, nameOfPIC("PIC_Id"));
    onChange(nameOf(nameOfPICDetail("PICs")), {
      value: picData,
    });
  };

  const handleDeleteItemPIC = () => {
    const picId = selectedPic?.[0]?.PIC_Id;
    const newPic = currentPIC?.filter((item: DTO_PIC) => item.PIC_Id !== picId);
    onChange(nameOf("_option.PicSelected"), {
      value: [],
    });
    onChange(nameOf(nameOfPICDetail("PICs")), {
      value: newPic,
    });
    if (
      selectedType.COOT_Retain_Occupier ||
      selectedType.COOT_Retain_AssociatedNames
    ) {
      handleUpdateRelatedNames();
    }
  };
  if (isLoadingViewPic) {
    return <Loading isLoading />;
  }
  if (responseLoadError) {
    return (
      <CCLoadFailed
        responseError={responseLoadError}
        onReload={() => {
          loadViewConfigurationPIC();
        }}
      />
    );
  }

  const handleRetainNamesPIC = async (data: DTO_PIC[]) => {
    setIsLoadingAddPIC(true);
    const picIds = map(data, nameOfPIC("PIC_Id"));
    const retainedNames = await loadRetainNames({
      AssessmentIds: [],
      PIC_Ids: picIds as number[],
    });
    if (isSuccessResponse(retainedNames) && retainedNames?.data) {
      handleRetainNames(retainedNames?.data);
    } else {
      localNotificationRef?.current?.pushNotification({
        title: `Retain names failed.`,
        type: "error",
        autoClose: false,
      });
    }
    setIsLoadingAddPIC(false);
  };

  const handleUpdateRelatedNames = async () => {
    options?.setIsLoadingStep(true);
    const picIds = map(
      valueGetter(`${EKeysOfSteps.PICDetails}.PICs`) || [],
      nameOfPIC("PIC_Id")
    );
    const retainedNames = await loadRetainNames({
      AssessmentIds: [],
      PIC_Ids: picIds,
    });
    if (isSuccessResponse(retainedNames) && retainedNames?.data) {
      const newRetainedNames = [
        ...(retainedNames?.data?.OwnerDetails?.Contacts || []),
        ...(retainedNames?.data?.AssociatedNamesDetails || []),
        ...(retainedNames?.data?.RatePayerDetails?.Contacts || []),
      ];
      handleUpdateRetainedNames(newRetainedNames);
    } else {
      localNotificationRef?.current?.pushNotification({
        title: `Remove retained names failed.`,
        type: "error",
        autoClose: false,
      });
    }
    options?.setIsLoadingStep(false);
  };

  return (
    <>
      <section className="cc-field-group">
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <CCLabel
              title="PICs for which change is to apply"
              isMandatory={requiredPICTypes.includes(selectedTypeId)}
              errorMessage={
                requiredPICTypes.includes(selectedTypeId)
                  ? errors?.[nameOf("")]
                  : undefined
              }
            />
            <CCGrid
              className="cc-title-change-of-ownership"
              data={currentPIC}
              columnFields={colsPIC}
              readOnly={options?.isReadOnly}
              selectableMode="single"
              onSelectionChange={(dataItems) => {
                onChange(nameOf("_option.PicSelected"), {
                  value: dataItems,
                });
              }}
              primaryField={nameOfPIC("PIC_Id")}
              toolbar={
                <div className="cc-grid-tools-bar">
                  <Button
                    iconClass="fas fa-plus"
                    title="Add PIC"
                    onClick={() => setShowAddPicDialog(true)}
                  />
                  <Button
                    iconClass="fas fa-minus"
                    title="Remove PIC"
                    onClick={handleDeleteItemPIC}
                    disabled={selectedPic?.length !== 1}
                  />
                </div>
              }
            />
          </div>
        </div>
      </section>

      {showAddPicDialog && (
        <AddPICLookupDialog
          onClose={() => setShowAddPicDialog(false)}
          handleAddPIC={async (data: DTO_PIC[]) => {
            handleAddItemPIC(data);
            if (
              selectedType.COOT_Retain_Occupier ||
              selectedType.COOT_Retain_AssociatedNames
            ) {
              await handleRetainNamesPIC(data);
            }
            setShowAddPicDialog(false);
          }}
          isLoadingFinish={isLoadingAddPIC}
        />
      )}
    </>
  );
};
