import { SaveButtonSystemAdminPermitType } from "@app/products/local-laws/system-admin/permit-types/[id]/components/buttons/save";
import { PermitTypeForm } from "@app/products/local-laws/system-admin/permit-types/[id]/components/general/_index";
import { IManagePermitType } from "@app/products/local-laws/system-admin/permit-types/[id]/model";
import { PermitTypeContactsTab } from "@app/products/local-laws/system-admin/permit-types/components/sidebar/contacts/_index";
import { PermitTypeDetailsTab } from "@app/products/local-laws/system-admin/permit-types/components/sidebar/details/_index";
import { PermitTypeHistoryTab } from "@app/products/local-laws/system-admin/permit-types/components/sidebar/history/_index";
import { PRODUCT_TYPE } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useGlobalStore } from "@common/stores/global/store";
import { getDisplayTextWithDashes } from "@common/utils/common";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { ActionBarBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/_index";
import { IBookmarkItem } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/model";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { YieldNotificationPortal } from "@components/cc-notification-portal/_index";
import { TypePlaceId } from "@components/cc-notification-portal/constant";
import { ReferenceSideBar } from "@components/cc-reference-sidebar/_index";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";

export const ExistManagePermitType = observer(
  ({ errorComponent, id, titleManagePage, permitType }: IManagePermitType) => {
    //#region STORE ========/
    const { currentUserInfo } = useGlobalStore();
    //#endregion STORE =====/

    //#region STATE ========/
    const [showSlideBar, setShowSlideBar] = useState(true);
    //#endregion STATE =====/

    //#region HOOK ========/
    const location = useLocation();
    //#endregion HOOK =====/

    const bookmarkList: IBookmarkItem[] = [
      {
        LinkText: `Permit type - ${id ?? 0}`,
        Text: `${getDisplayTextWithDashes([permitType?.Name])}`,
        LinkUrl: location.pathname,
        User_ID: currentUserInfo?.UserPKID ? +currentUserInfo.UserPKID : 0,
        ProductType_ENUM: PRODUCT_TYPE.LLPermits,
        RecordType_ENUM: RECORDTYPE.LLP_PermitType,
        Record_ID: +id ?? 0,
      },
    ];

    return (
      <>
        <FormNavigation title={titleManagePage} />
        {errorComponent ? (
          errorComponent
        ) : (
          <>
            <FormTitle title={`${titleManagePage} - ${permitType?.Name}`} />
            <CCManagePageActionBar
              leftActions={[<SaveButtonSystemAdminPermitType />]}
              rightActions={[
                <CCNavButton
                  title={"Slide Bar"}
                  iconClass="fal fa-info-circle"
                  isActive={showSlideBar}
                  onClick={() => {
                    setShowSlideBar(!showSlideBar);
                  }}
                />,
                <ActionBarBookmarkIcon bookmarkList={bookmarkList} />,
              ]}
            />
            <div className="cc-manage-container">
              <div className="cc-manage-left">
                <YieldNotificationPortal placeId={TypePlaceId.Main} />
                <div className="cc-manage-form-body">
                  {permitType && <PermitTypeForm />}
                </div>
              </div>

              {showSlideBar && (
                <div className="cc-manage-right">
                  <ReferenceSideBar
                    components={[
                      {
                        title: "Details",
                        component: <PermitTypeDetailsTab />,
                      },
                      {
                        title: "Contact",
                        component: <PermitTypeContactsTab />,
                      },
                      {
                        title: "History",
                        component: <PermitTypeHistoryTab />,
                      },
                    ]}
                  />
                </div>
              )}
            </div>
          </>
        )}
      </>
    );
  }
);
