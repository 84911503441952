import { eventEmitter } from "@/App";
import { VO_Workflow_Draft } from "@app/products/property/actions/model";
import { listSubmitButton } from "@app/products/property/assessments/components/form-steps/new-assessment/config";
import { useConfirmCancelDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-cancel/store";
import { useConfirmCloseDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-close/store";
import { useConfirmFinishDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-finish/store";
import { CommentsFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/comments/_index";
import { DocumentsFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/documents/_index";
import { usePropertyWorkflow } from "@app/products/property/components/action-bar/property-workflow/component/hooks/useProprtyWorkflow/usePropertyWorkflow";
import { getTitleWorkflow } from "@app/products/property/components/action-bar/property-workflow/util";
import { useDeferredDutyStore } from "@app/products/property/deferred-duty/[id]/store";
import {
  getInitialDataModifyDeferredDuty,
  postNewModifyDeferredDuty,
} from "@app/products/property/deferred-duty/components/action-bar/dialogs/modify-deferred-duty/api";
import { ModifyDeferredDutyDetailsFormStep } from "@app/products/property/deferred-duty/components/action-bar/dialogs/modify-deferred-duty/form-elements/details/_index";
import { ModifyDeferredDutyOtherInformationFormStep } from "@app/products/property/deferred-duty/components/action-bar/dialogs/modify-deferred-duty/form-elements/other-information/_index";
import {
  DTO_Workflow_ModifyDeferredDuty,
  EKeysOfStepsModifyDeferredDuty,
  keysOfSendStepsModifyDeferredDuty,
} from "@app/products/property/deferred-duty/components/action-bar/dialogs/modify-deferred-duty/model";
import { useModifyDeferredDutyDialogStore } from "@app/products/property/deferred-duty/components/action-bar/dialogs/modify-deferred-duty/store";
import {
  DTO_WorkflowHeader,
  EListSubmitButton,
  WorkflowProcessMode,
  WorkflowTypes,
} from "@app/products/property/model";
import { isShowParkButton } from "@app/products/property/util";
import { APIResponse } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { RECORDTYPE } from "@common/constants/recordtype";
import { ResponsePacket } from "@common/models/identityPacket";
import { useCommonProductStore } from "@common/stores/products/store";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCDialog } from "@components/cc-dialog/_index";
import {
  CCFormStep,
  ICCFormStepNotificationHandle,
  ICCFormStepRender,
} from "@components/cc-form-step/_index";
import { IStep } from "@components/cc-form-step/model";
import { CCGridEventType } from "@components/cc-grid/constant";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { Button } from "@progress/kendo-react-buttons";
import { pickBy } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo, useRef, useState } from "react";
import { useEffectOnce } from "react-use";

interface IModifyDeferredDutyDialogProps {
  onClose: () => void;
  onSubmit: (data: any) => void;
  dataFromActionList?: VO_Workflow_Draft;
  prefixTitle?: string;
  suffixTitle?: string;
  isShowSearchType?: boolean;
  isShowReasonRejection?: boolean;
  isShowReason?: boolean;
  isSaveOnNextStep?: boolean;
  accountId?: number;
}

export const ModifyDeferredDutyDialog = observer(
  ({
    onClose,
    dataFromActionList,
    prefixTitle,
    suffixTitle,
    isSaveOnNextStep = false,
    accountId,
  }: IModifyDeferredDutyDialogProps) => {
    const { currentFormTitle } = useCommonProductStore();
    const { setDataForCancelDialog } = useConfirmCancelDialogStore();
    const { setDataForCloseDialog, setIsLoadingClose } =
      useConfirmCloseDialogStore();
    const { pushNotification } = useCCAppNotificationStore();
    const { setDataForFinishDialog } = useConfirmFinishDialogStore();
    const { clearSelectedItems } = useCCProductListViewStore();
    const { setIsLoadingOnDialog, isLoadingApprove } =
      useModifyDeferredDutyDialogStore();
    const { deferredDuty, reLoadDeferredDuty } = useDeferredDutyStore();
    const [deferredDutyInitData, setDeferredDutyInitData] =
      useState<DTO_Workflow_ModifyDeferredDuty>();
    const [workflowHeader, setWorkflowHeader] = useState<DTO_WorkflowHeader>({
      WorkflowDraft: { Workflow_Draft_Id: 0 },
      AvailableSecondaryWorkflows: [],
      WorkflowApprovals: [],
    });
    const [isLoadingOnNext, setIsLoadingOnNext] = useState<boolean>(false);
    const [isLoadingPark, setIsLoadingPark] = useState<boolean>(false);
    const [workflowDraftId, setWorkflowDraftId] = useState<number>(0);
    const [isFirstSave, setIsFirstSave] = useState<boolean>(true);

    //Hooks
    const {
      isReadOnly,
      isIncompleteMode,
      isShowCancelWorkflowButton,
      statusBadge,
      isFromActionList,
      isToBeApprovalMode,
    } = usePropertyWorkflow(dataFromActionList);

    const notificationFormStepRef =
      useRef<ICCFormStepNotificationHandle | null>(null);

    const titleHeader = useMemo(() => {
      const formId = workflowHeader?.WorkflowDraft?.WD_Form_Id;
      const title = currentFormTitle(formId ?? 0) || "Modify deferred duty";
      return getTitleWorkflow(title, prefixTitle, suffixTitle);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [prefixTitle, suffixTitle, workflowHeader]);

    const steps: IStep[] = [
      {
        label: "Details",
        component: ModifyDeferredDutyDetailsFormStep,
        visible: true,
        key: EKeysOfStepsModifyDeferredDuty.Details,
        options: {
          isReadOnly,
        },
      },
      {
        label: "Other information",
        component: ModifyDeferredDutyOtherInformationFormStep,
        visible: true,
        key: EKeysOfStepsModifyDeferredDuty.OtherInfo,
        options: {
          isReadOnly: true,
        },
      },
      {
        label: "Comments",
        component: CommentsFormStep,
        visible: true,
        key: EKeysOfStepsModifyDeferredDuty.Comments,
        customClassName: "cc-comment-step-fixed-height-grid",
        options: {
          isReadOnly,
          workflowDraftId,
          recordType: RECORDTYPE.CommunityProperty_Deferred_Duty_Account,
        },
      },
      {
        label: "Documents",
        component: DocumentsFormStep,
        visible: true,
        key: EKeysOfStepsModifyDeferredDuty.Documents,
        options: {
          isReadOnly,
          workflowDraftId,
          workflowType: WorkflowTypes.Modify_DeferredDuty,
        },
      },
    ];

    const handleSubmit = async (data: any, buttonId?: string) => {
      switch (buttonId) {
        case EListSubmitButton.Finish:
          handleConfirmFinishProcess(processData(data));
          break;
        case EListSubmitButton.Cancel:
        case EListSubmitButton.ConfirmCloseNo:
          handleCancelButton(processData(data));
          break;
        case EListSubmitButton.Park:
        case EListSubmitButton.ConfirmCloseYes:
        case EListSubmitButton.Close:
          await sendParkDeferredDuty(processData(data));
          break;
      }
    };

    /**
     * handle finish workflow process
     * @param payload
     */
    const handleConfirmFinishProcess = (
      payload: DTO_Workflow_ModifyDeferredDuty
    ) => {
      const finishCallback = function async() {
        return handleFinish(payload);
      };

      setDataForFinishDialog({
        finishCallback,
        confirmMessage:
          "The deferred duty will be modified based on the information provided. Are you sure you want to submit?",
      });
    };

    const sendParkDeferredDuty = async (
      payload: DTO_Workflow_ModifyDeferredDuty
    ) => {
      setIsLoadingPark(true);
      setIsLoadingOnDialog(true);
      setIsLoadingClose(true);
      const defaultSuccessMessage = "Deferred duty was parked successfully";
      const defaultFailedMessage = "Deferred duty could not be parked";

      const response = await postNewModifyDeferredDuty(
        WorkflowProcessMode.Park,
        payload
      );
      setIsLoadingClose(false);
      if (isSuccessResponse(response)) {
        if (response?.data?.IsSuccess) {
          onClose();
          pushNotification({
            title: response?.data?.SuccessMessage ?? defaultSuccessMessage,
            type: "success",
          });
        } else {
          notificationFormStepRef?.current
            ?.getNotificationFormStep()
            ?.current?.pushNotification({
              title: response?.data?.ErrorMessage ?? defaultFailedMessage,
              type: "error",
              autoClose: false,
            });
        }
      } else {
        notificationFormStepRef?.current
          ?.getNotificationFormStep()
          ?.current?.pushNotification({
            title: response?.data?.ErrorMessage ?? defaultFailedMessage,
            type: "error",
            autoClose: false,
          });
      }
      setIsLoadingOnDialog(false);
      setIsLoadingPark(false);
      setDataForCloseDialog(undefined);
    };

    const handleCancelButton = (data: any) => {
      if (isFromActionList || !isFirstSave) {
        setDataForCancelDialog({
          cancelAPI: postNewModifyDeferredDuty,
          dataCancel: data,
          defaultSuccessMessage:
            "Modify deferred duty was cancelled successfully",
          defaultErrorMessage: "Modify deferred duty could not be cancelled",
        });
      } else {
        onClose();
      }
    };

    const handleCloseDialog = (renderProps: ICCFormStepRender) => {
      if (!isFirstSave) {
        setDataForCloseDialog({
          closeCallback: renderProps.submitButton.onClick,
        });
      } else {
        onClose();
      }
    };

    const handleNextButton = async (data: any) => {
      setIsLoadingOnNext(true);
      return await sendSaveDeferredDuty(processData(data));
    };

    const sendSaveDeferredDuty = async (
      payload: DTO_Workflow_ModifyDeferredDuty
    ) => {
      const response = await postNewModifyDeferredDuty(
        WorkflowProcessMode.Save,
        payload
      );
      setIsLoadingOnNext(false);
      const defaultFailedMessage =
        "Modify new deferred duty process could not be saved.";
      if (isSuccessResponse(response)) {
        if (response?.data?.IsSuccess) {
          if (isFirstSave && workflowDraftId === 0) {
            setIsFirstSave(false);
            setWorkflowDraftId(response?.data?.ID ?? 0);
            setWorkflowHeader({
              ...workflowHeader,
              WorkflowDraft: {
                ...workflowHeader.WorkflowDraft,
                Workflow_Draft_Id: response?.data?.ID ?? 0,
              },
            });
          }
          return true;
        } else {
          notificationFormStepRef?.current
            ?.getNotificationFormStep()
            ?.current?.pushNotification({
              title: response.data?.ErrorMessage ?? defaultFailedMessage,
              type: "error",
              autoClose: false,
            });
          return false;
        }
      } else {
        notificationFormStepRef?.current
          ?.getNotificationFormStep()
          ?.current?.pushNotification({
            title: response?.data?.ErrorMessage ?? defaultFailedMessage,
            type: "error",
            autoClose: false,
          });
        return false;
      }
    };

    const handleFinish = async (payload: DTO_Workflow_ModifyDeferredDuty) => {
      const defaultSuccessMessage = "Deferred duty was completed successfully";
      const defaultFailedMessage = "Deferred duty could not be completed";

      const response = await postNewModifyDeferredDuty(
        WorkflowProcessMode.Finish,
        payload
      );
      if (isSuccessResponse(response)) {
        if (response?.data?.IsSuccess) {
          onClose();
          if (deferredDuty) {
            reLoadDeferredDuty().then(() => {
              pushNotification({
                title:
                  response?.data?.SuccessMessage ??
                  "Raise deferred duty interest successfully.",
                type: "success",
              });
            });
          } else {
            eventEmitter.emit(CCGridEventType.RefreshOData);
            clearSelectedItems();
            pushNotification({
              title: response?.data?.SuccessMessage ?? defaultSuccessMessage,
              type: "success",
            });
          }
        } else {
          notificationFormStepRef?.current
            ?.getNotificationFormStep()
            ?.current?.pushNotification({
              title: response?.data?.ErrorMessage ?? defaultFailedMessage,
              type: "error",
              autoClose: false,
            });
        }
      } else {
        notificationFormStepRef?.current
          ?.getNotificationFormStep()
          ?.current?.pushNotification({
            title: response?.data?.ErrorMessage ?? defaultFailedMessage,
            type: "error",
            autoClose: false,
          });
      }
    };

    const processData = (data: any) => {
      let workflowDetail: any = {};
      let workflowDetailConvert: any = {};
      const sendSteps = pickBy(data, function (value, key) {
        if (
          keysOfSendStepsModifyDeferredDuty.includes(
            key as EKeysOfStepsModifyDeferredDuty
          )
        ) {
          return { [key]: value };
        }
      });

      if (sendSteps) {
        for (const [key, value] of Object.entries(sendSteps)) {
          if (value && value._option) {
            delete value._option;
          }
          const dataStep = { ...value };
          workflowDetail[key as keyof DTO_Workflow_ModifyDeferredDuty] =
            dataStep;
        }
      }

      //covert model to save
      const { Detail, OtherInfo } = workflowDetail;

      workflowDetailConvert = {
        AccountDetails: Detail,
        Interest: {
          InterestCalculatedToDate: OtherInfo?.InterestCalculatedToDate,
          InterestRate: OtherInfo?.InterestRate,
          MinimumAmountForInterest: OtherInfo?.MinimumAmountForInterest,
          PremiumRate: OtherInfo?.PremiumRate,
        },
        TRSInformation: {
          ClientId: OtherInfo?.ClientId,
          AssessmentId: OtherInfo?.AssessmentId,
          LodgementNumber: OtherInfo?.LodgementNumber,
          RevenueType: OtherInfo?.RevenueType,
        },
      };

      return {
        WorkflowHeader: workflowHeader,
        WorkflowDetail: workflowDetailConvert,
      };
    };

    const getWorkflowData = async () => {
      const draftId = dataFromActionList?.Workflow_Draft_Id;
      notificationFormStepRef?.current?.setLoadingFormStep(true);

      const response = await getInitialDataModifyDeferredDuty(
        accountId,
        draftId
      );
      if (isSuccessResponse(response)) {
        const workflowDataResponse = response;
        const workflowData = workflowDataResponse?.data;
        if (workflowData) {
          //set New InitData
          setDeferredDutyInitData({
            WorkflowDetail: workflowData?.WorkflowDetail,
            WorkflowHeader: workflowData?.WorkflowHeader,
          });
          //set workflow header and workflow Draft Id
          if (workflowData?.WorkflowHeader) {
            setWorkflowHeader(workflowData.WorkflowHeader);
            setWorkflowDraftId(
              workflowData?.WorkflowHeader?.WorkflowDraft?.Workflow_Draft_Id ??
                0
            );
          }
          notificationFormStepRef?.current?.setLoadingFormStep(false);
        } else {
          const responseError = response as APIResponse<
            DTO_Workflow_ModifyDeferredDuty | ResponsePacket
          >;
          notificationFormStepRef?.current?.setLoadingFormStep(false);
          notificationFormStepRef?.current?.setLoadFailedFormStep({
            onReload: () => getWorkflowData(),
            responseError: {
              status: responseError.status,
              error:
                (responseError.data as ResponsePacket)?.Errors ??
                "Load workflow failed",
            },
          });
        }
      } else {
        const responseError = response as APIResponse;
        notificationFormStepRef?.current?.setLoadingFormStep(false);
        notificationFormStepRef?.current?.setLoadFailedFormStep({
          onReload: () => getWorkflowData(),
          responseError: {
            status: responseError.status,
            error: "Load workflow failed",
          },
        });
      }
    };

    const initialValues = useMemo(() => {
      let initDetails: any = {};
      let initOtherInfo: any = {};

      if (deferredDutyInitData) {
        const workflowDetail = deferredDutyInitData?.WorkflowDetail;
        //Details
        if (workflowDetail?.AccountDetails) {
          initDetails = {
            ...initDetails,
            ...workflowDetail?.AccountDetails,
          };
        }
        //Other information
        if (workflowDetail?.Interest) {
          initOtherInfo = {
            ...initOtherInfo,
            ...workflowDetail?.Interest,
          };
        }
        if (workflowDetail?.TRSInformation) {
          initOtherInfo = {
            ...initOtherInfo,
            ...workflowDetail?.TRSInformation,
          };
        }
      }
      return {
        [EKeysOfStepsModifyDeferredDuty.Details]: initDetails,
        [EKeysOfStepsModifyDeferredDuty.OtherInfo]: initOtherInfo,
        [EKeysOfStepsModifyDeferredDuty.Comments]: {},
        [EKeysOfStepsModifyDeferredDuty.Documents]: {},
      };
    }, [deferredDutyInitData]);

    useEffectOnce(() => {
      getWorkflowData();
    });

    return (
      <>
        <CCFormStep
          ref={notificationFormStepRef}
          onSubmit={handleSubmit}
          listButtonId={listSubmitButton}
          initialValues={initialValues}
          initialSteps={steps}
          saveOnNextStep={isSaveOnNextStep ? handleNextButton : undefined}
          renderForm={(renderProps: ICCFormStepRender) => (
            <CCDialog
              maxWidth="60%"
              disabled={isLoadingApprove}
              titleHeader={titleHeader}
              onClose={() => handleCloseDialog(renderProps)}
              bodyElement={renderProps.children}
              badge={statusBadge}
              footerElement={
                <>
                  <div className={"cc-dialog-footer-actions-right"}>
                    {isShowParkButton(isFromActionList, isIncompleteMode) && (
                      <Button
                        iconClass={
                          isLoadingPark ? "fas fa-spinner fa-spin" : ""
                        }
                        className={"cc-dialog-button"}
                        id={EListSubmitButton.Park}
                        onClick={renderProps.submitButton.onClick}
                        disabled={
                          renderProps.nextButton.disabled || isLoadingPark
                        }
                      >
                        Park
                      </Button>
                    )}
                    {isShowCancelWorkflowButton && (
                      <Button
                        className={"cc-dialog-button"}
                        disabled={
                          isLoadingApprove || isLoadingOnNext || isLoadingPark
                        }
                        id={EListSubmitButton.Cancel}
                        onClick={renderProps.submitButton.onClick}
                      >
                        Cancel
                      </Button>
                    )}
                    {!renderProps.prevButton.disabled && (
                      <Button
                        className={"cc-dialog-button"}
                        themeColor="primary"
                        onClick={renderProps.prevButton.onClick}
                      >
                        Previous
                      </Button>
                    )}
                    {isToBeApprovalMode || isReadOnly ? (
                      !renderProps.isLastStep && (
                        <Button
                          themeColor="primary"
                          id="cc-next-step-button"
                          disabled={
                            isLoadingOnNext || renderProps.nextButton.disabled
                          }
                          className={"cc-dialog-button"}
                          iconClass={
                            isLoadingOnNext ? "fas fa-spinner fa-spin" : ""
                          }
                          onClick={renderProps.nextButton.onClick}
                        >
                          {isLoadingOnNext
                            ? "Saving"
                            : renderProps.nextButton.label}
                        </Button>
                      )
                    ) : (
                      <Button
                        themeColor="primary"
                        id={renderProps.nextButton.idButton}
                        disabled={
                          isLoadingOnNext || renderProps.nextButton.disabled
                        }
                        iconClass={
                          isLoadingOnNext ? "fas fa-spinner fa-spin" : ""
                        }
                        className={"cc-dialog-button"}
                        onClick={renderProps.nextButton.onClick}
                      >
                        {isLoadingOnNext
                          ? "Saving"
                          : renderProps.nextButton.label}
                      </Button>
                    )}
                  </div>
                </>
              }
            />
          )}
        />
      </>
    );
  }
);
