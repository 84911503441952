import {
  hiddenSomeInputsTypes,
  hiddenSwitchTypes,
} from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/change-of-ownership/components/form-elements/detail/config";
import { loadRetainNames } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/change-of-ownership/components/form-elements/property/api";
import { useFormatNameStep } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/change-of-ownership/hooks/useChangeOfOwnership";
import {
  DTO_Assessment,
  DTO_PIC,
  EKeysOfSteps,
} from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/change-of-ownership/model";
import { useChangeOfOwnershipDialogStore } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/change-of-ownership/store";
import { ECustomColNameProperty } from "@app/products/property/config";
import { isSuccessResponse } from "@common/apis/util";
import { DATE_FORMAT } from "@common/constants/common-format";
import { Label } from "@common/stores/products/config";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { CCCurrencyInput } from "@components/cc-currency-input/_index";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { Field, FieldArray } from "@progress/kendo-react-form";
import { SwitchChangeEvent } from "@progress/kendo-react-inputs";
import { lowerFirst, map } from "lodash";
import { observer } from "mobx-react-lite";
import React from "react";

export const DetailsFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};
const nameOfAssessment = nameOfFactory<DTO_Assessment>();
const nameOfPIC = nameOfFactory<DTO_PIC>();
const FormStepElement = observer(
  ({
    formRenderProps,
    nameOf,
    localNotificationRef,
    options = {
      isReadOnly: false,
      isLLS: false,
    },
  }: IFormStepElement) => {
    const { valueGetter, onChange } = formRenderProps;
    const getFieldValue = (name: string) => valueGetter(nameOf(name));
    const { changeOfOwnershipLOVs } = useChangeOfOwnershipDialogStore();
    const {
      handleRetainNames,
      handleRemoveRetainedAsociatedNames,
      handleRemoveRetainedRatepayers,
    } = useFormatNameStep(
      changeOfOwnershipLOVs,
      onChange,
      valueGetter,
      options?.setIsLoadingStep
    );
    const typeChangeOfOwnership = valueGetter(`${EKeysOfSteps.Type}.Type`);

    const selectedType = valueGetter(`${EKeysOfSteps.Type}._option.Type`);

    //Get label
    const ratepayerLabel = Label.CommunityProperty.getLabel(
      ECustomColNameProperty.Ratepayer
    );

    const handleRetainNamesBySwitchButton = async (
      isRetainRatePayers?: boolean,
      isRetainAssociatedNames?: boolean
    ) => {
      options?.setIsLoadingStep(true);
      const assessmentIds = map(
        valueGetter(`${EKeysOfSteps.Property}.Assessments`) || [],
        nameOfAssessment("Assessment_Id")
      );
      const picIds = map(
        valueGetter(`${EKeysOfSteps.PICDetails}.PICs`) || [],
        nameOfPIC("PIC_Id")
      );
      const retainedNames = await loadRetainNames({
        AssessmentIds: assessmentIds,
        PIC_Ids: picIds,
      });
      if (isSuccessResponse(retainedNames) && retainedNames?.data) {
        handleRetainNames(
          retainedNames?.data,
          isRetainRatePayers,
          isRetainAssociatedNames
        );
      } else {
        localNotificationRef?.current?.pushNotification({
          title: `Retain names failed.`,
          type: "error",
          autoClose: false,
        });
      }
      options?.setIsLoadingStep(false);
    };
    return (
      <section className="cc-field-group">
        <div className="cc-form-cols-2">
          <div className="cc-field cc-col-span-2">
            <CCLabel title="Reference" isMandatory={options.isLLS} />
            <Field
              name={nameOf("Reference")}
              component={CCInput}
              placeholder="Reference"
              readOnly={options?.isReadOnly}
              validator={
                !options?.isReadOnly && options.isLLS
                  ? requiredValidator
                  : undefined
              }
            />
          </div>

          <div className="cc-field">
            <CCLabel title="Effective date" />
            <Field
              name={nameOf("EffectiveDate")}
              format={DATE_FORMAT.DATE_CONTROL}
              component={CCDatePicker}
              disabled={options?.isReadOnly}
            />
          </div>
          {!hiddenSomeInputsTypes.includes(typeChangeOfOwnership) && (
            <>
              <div className="cc-field">
                <CCLabel title="Sale price" />
                <Field
                  name={nameOf("SalePrice")}
                  component={CCCurrencyInput}
                  disabled={options?.isReadOnly}
                  min={0}
                />
              </div>

              <div className="cc-field">
                <CCLabel title="Contract date" />
                <Field
                  name={nameOf("ContractDate")}
                  format={DATE_FORMAT.DATE_CONTROL}
                  component={CCDatePicker}
                  disabled={options?.isReadOnly}
                />
              </div>
              <div className="cc-field">
                <CCLabel title="Chattels" />
                <Field
                  name={nameOf("Chattels")}
                  component={CCCurrencyInput}
                  disabled={options?.isReadOnly}
                  min={0}
                />
              </div>
            </>
          )}

          <div className="cc-field cc-col-span-2">
            <CCLabel title="Comment" />
            <Field
              name={nameOf("Comment")}
              component={CCTextArea}
              rows={3}
              placeholder="Comment"
              readOnly={options?.isReadOnly}
            />
          </div>
        </div>

        <div className="cc-form-cols-3">
          {selectedType?.COOT_Display_Retain_AssociatedNames && (
            <div className="cc-field">
              <CCLabel title="Retain associated names" />
              <Field
                name={nameOf("RetainAssociatedNames")}
                component={CCSwitch}
                checked={getFieldValue("RetainAssociatedNames")}
                disabled={options?.isReadOnly}
                onChange={(event: SwitchChangeEvent) => {
                  onChange(nameOf("RetainAssociatedNames"), {
                    value: event.value,
                  });
                  if (event.value) {
                    handleRetainNamesBySwitchButton(undefined, true);
                  } else {
                    handleRemoveRetainedAsociatedNames();
                  }
                }}
              />
            </div>
          )}
          {!options?.isLLS &&
            !hiddenSwitchTypes.includes(typeChangeOfOwnership) && (
              <div className="cc-field">
                <CCLabel title="Retain lease details" />
                <Field
                  name={nameOf("RetainLeaseDetails")}
                  component={CCSwitch}
                  checked={getFieldValue("RetainLeaseDetails")}
                  disabled={options?.isReadOnly}
                />
              </div>
            )}
          {selectedType?.COOT_Display_Retain_Occupier && (
            <div className="cc-field">
              <CCLabel title={`Retain ${lowerFirst(ratepayerLabel)} details`} />
              <Field
                name={nameOf("RetainRatePayerDetails")}
                component={CCSwitch}
                checked={getFieldValue("RetainRatePayerDetails")}
                disabled={options?.isReadOnly}
                onChange={(event: SwitchChangeEvent) => {
                  onChange(nameOf("RetainRatePayerDetails"), {
                    value: event.value,
                  });
                  if (event.value) {
                    handleRetainNamesBySwitchButton(true, undefined);
                  } else {
                    handleRemoveRetainedRatepayers();
                  }
                }}
              />
            </div>
          )}
        </div>
      </section>
    );
  }
);
