import { CreateAssessmentJournalCommonButton } from "@app/products/property/assessments/[id]/components/forms/existed/components/action-bar/buttons/create-journal/common/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";

export const CreateAssessmentJournalButton = observer(() => {
  const { gridSelectedRows } = useCCProductListViewStore();

  const listAssessmentID = useMemo(() => {
    return gridSelectedRows?.map((item) => item.Assessment_Id) ?? [];
  }, [gridSelectedRows]);

  return (
    <CreateAssessmentJournalCommonButton
      isDisabled={gridSelectedRows.length < 1}
      assessmentIDs={listAssessmentID}
    />
  );
});
