import {
  colAssociationParcel,
  colFoliosAssessment,
} from "@app/products/property/assessments/components/form-steps/modify-assessment/components/form-elements/associations-parcel/config";
import {
  DTO_ModifyAssessment_Parcel,
  DTO_Title,
} from "@app/products/property/assessments/components/form-steps/modify-assessment/components/form-elements/associations-parcel/model";
import { lotsValidator } from "@app/products/property/assessments/components/form-steps/modify-assessment/util";
import { EAssociationGridType } from "@app/products/property/assessments/components/form-steps/new-assessment/components/form-elements/associations-parcel/model";
import { AddParcelLookupDialog } from "@app/products/property/components/dialogs/add-parcel-lookup/_index";
import { DTO_Parcel } from "@app/products/property/components/dialogs/add-parcel-lookup/model";
import { AddTitleLookupDialog } from "@app/products/property/components/dialogs/add-title-lookup/_index";
import { getSearchTitleLookup } from "@app/products/property/components/dialogs/add-title-lookup/api";
import { eOptionSearchTitleLookup } from "@app/products/property/components/dialogs/add-title-lookup/config";
import {
  fnt_Title_LookupResult,
  folioGridLookupLoading,
} from "@app/products/property/components/dialogs/add-title-lookup/model";
import { ECustomColNameProperty } from "@app/products/property/config";
import { processCombineData } from "@app/products/property/pic/list/components/action-bar/form-steps/new-pic/components/form-element/associations/util";
import { isSuccessResponse } from "@common/apis/util";
import { Label } from "@common/stores/products/config";
import { getUUID, nameOfFactory } from "@common/utils/common";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCGrid } from "@components/cc-grid/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { CCLabel } from "@components/cc-label/_index";
import { Button } from "@progress/kendo-react-buttons";
import { FieldArray } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";
import "./_index.scss";

export const AssociationParcelsFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray
      name={props.nameOf()}
      {...props}
      component={FormStepElement}
      validator={!props?.options?.isReadOnly ? lotsValidator : undefined}
    />
  );
};

const nameOfParcel = nameOfFactory<DTO_ModifyAssessment_Parcel>();
const nameOfFolio = nameOfFactory<DTO_Title>();

const FormStepElement = observer(
  ({
    formRenderProps,
    localNotificationRef,
    nameOf,
    options = {
      isActro: false,
      isReadOnly: false,
      isLLS: false,
    },
  }: IFormStepElement) => {
    const { valueGetter, onChange, errors } = formRenderProps;
    const getFieldValue = (name: string) => valueGetter(nameOf(name));
    const [isShowDialog, setIsShowDialog] = useState<EAssociationGridType>();
    const parcels = getFieldValue("AssociateParcels") ?? [];
    const parcelSelected = getFieldValue("_option.ParcelSelected") ?? [];
    const loadingMode = getFieldValue("_option.Loading") ?? false;
    const folio = getFieldValue("AssociateTitles") ?? [];
    const folioSelected = getFieldValue("_option.FolioSelected") ?? [];
    const requiresGisApproval = getFieldValue("Requires_Gis_Approval") || false;

    //Get labels
    const [titlesLabel, titleLowercaseLabel] = Label.CommunityProperty.getLabel(
      [ECustomColNameProperty.Titles, ECustomColNameProperty.TitleLowercase]
    );

    const newColFoliosAssessment = useMemo(() => {
      if (options.isLLS) {
        return colFoliosAssessment?.filter((item: IColumnFields) => {
          return item.field !== nameOfFolio("ReferenceNumber");
        });
      }
      return colFoliosAssessment;
    }, [options.isLLS]);

    /**
     * handle add item
     * @param data
     */
    const handleAddItemParcel = (data: DTO_Parcel[]) => {
      const newData: DTO_ModifyAssessment_Parcel[] =
        data?.map((item: DTO_Parcel) => {
          return {
            ...item,
            AssociationFactorPercentage: 100,
            PropertyName: "",
            LandCategory: "",
            MapReference: "",
            MapNumber: "",
            LocationDescriptor: "",
          };
        }) ?? [];
      const previousParcel = parcels?.length ? [...parcels] : [];
      const newParcels = processCombineData(
        previousParcel,
        newData,
        nameOfParcel("Parcel_Id")
      );
      onChange(nameOf("AssociateParcels"), {
        value: newParcels,
      });
    };

    /**
     * handle delete item
     */
    const handleDeleteItem = () => {
      const parcelId = parcelSelected?.[0]?.Parcel_Id;
      const newParcel = parcels.filter(
        (item: DTO_ModifyAssessment_Parcel) => parcelId !== item.Parcel_Id
      );
      onChange(nameOf("AssociateParcels"), {
        value: newParcel,
      });
      onChange(nameOf("_option.ParcelSelected"), {
        value: [],
      });
    };

    const handleAddItemFolio = async (data: fnt_Title_LookupResult[]) => {
      const listTitleId = data.map((item) => item.Title_Id).toString();
      onChange(nameOf("_option.Loading"), {
        value: folioGridLookupLoading,
      });
      options?.setIsLoadingDialog(true);
      const responseTitleLookup = await getSearchTitleLookup({
        LookupKey: eOptionSearchTitleLookup.TitleId,
        LookupValue: listTitleId,
        Statuses: [0], //default task 12866
      });
      onChange(nameOf("_option.Loading"), {
        value: undefined,
      });
      if (isSuccessResponse(responseTitleLookup) && responseTitleLookup?.data) {
        const resTitle = responseTitleLookup?.data?.Titles ?? [];
        const newData: DTO_Title[] =
          resTitle?.map((item: DTO_Title) => {
            return {
              ...item,
              AssociationFactorPercentage: 100,
              RowId: getUUID(),
              isAddedToGridAfterSpatial: true,
            };
          }) ?? [];
        const previousFolio = folio?.length ? [...folio] : [];
        const newDataCombine = processCombineData(
          previousFolio,
          newData,
          nameOfFolio("TitleId")
        );
        onChange(nameOf("AssociateTitles"), {
          value: newDataCombine,
        });
      } else {
        localNotificationRef?.current?.pushNotification({
          title: `Load ${titlesLabel} failed`,
          type: "error",
          autoClose: false,
        });
      }
      options?.setIsLoadingDialog(false);
    };

    const handleDeleteItemFolio = () => {
      const folioId = folioSelected?.[0]?.RowId;
      const newFolio = folio.filter(
        (item: DTO_Title) => folioId !== item.RowId
      );
      onChange(nameOf("AssociateTitles"), {
        value: newFolio,
      });
      onChange(nameOf("_option.FolioSelected"), {
        value: [],
      });
    };

    const handleDataChange = (
      dataRow: any,
      gridName: string,
      primaryKey: "RowId" | "Parcel_Id",
      gridData: DTO_Title[] | DTO_Parcel[]
    ) => {
      const id = dataRow?.[primaryKey];
      let newGridData = [...gridData];
      newGridData = newGridData?.map((item: any) =>
        item?.[primaryKey] === id ? { ...dataRow } : item
      );
      onChange(nameOf(gridName), { value: newGridData });
    };

    const handleDataChangeGrid = (dataRow: any, fieldChange: string) => {
      if (fieldChange === nameOfFolio("Active_To")) {
        const associatedId = dataRow?.TitleId;
        let newFolio = [...folio];
        // Update value for Active_To
        newFolio = newFolio?.map((item: DTO_Title) => {
          if (item?.TitleId === associatedId) {
            return {
              ...item,
              Active_To: dataRow?.[nameOfFolio("Active_To")] ?? null,
            };
          }
          return item;
        });
        onChange(nameOf("AssociateTitles"), { value: newFolio });
      } else if (
        fieldChange === nameOfFolio("AssociationFactorPercentage") ||
        fieldChange === nameOfParcel("AssociationFactorPercentage")
      ) {
        if (!options?.isActro) {
          handleDataChange(dataRow, "AssociateTitles", "RowId", folio);
        } else {
          handleDataChange(dataRow, "AssociateParcels", "Parcel_Id", parcels);
        }
      }
    };

    return (
      <section className="cc-field-group cc-lots">
        {/* Task [14733]: Only show Title grid with LLS state */}
        {options?.isLLS && (
          <>
            <div className="cc-form-cols-1">
              <div className="cc-field">
                <CCLabel
                  title={titlesLabel}
                  errorMessage={
                    errors?.[nameOf("")] ? errors[nameOf("")] : undefined
                  }
                  isLoading={
                    getFieldValue("_option.Loading") === folioGridLookupLoading
                  }
                />
                <CCGrid
                  data={folio ?? []}
                  columnFields={newColFoliosAssessment}
                  selectableMode={requiresGisApproval ? "none" : "single"}
                  primaryField={nameOfFolio("RowId")}
                  selectedRows={folioSelected ?? []}
                  editableMode={!options?.isReadOnly ? "cell" : undefined}
                  onSelectionChange={(dataItems: DTO_Title[]) => {
                    onChange(nameOf("_option.FolioSelected"), {
                      value: dataItems ?? [],
                    });
                  }}
                  onDataRowChange={(dataRow: any, fieldChange: any) => {
                    handleDataChangeGrid(dataRow, fieldChange);
                  }}
                  readOnly={options?.isReadOnly || requiresGisApproval}
                  toolbar={
                    !options?.isReadOnly &&
                    !requiresGisApproval && (
                      <div className="cc-grid-tools-bar">
                        <Button
                          iconClass="fas fa-plus"
                          title={`Add ${titleLowercaseLabel}`}
                          onClick={() => {
                            setIsShowDialog(EAssociationGridType.Title);
                          }}
                        />
                        <Button
                          iconClass="fas fa-minus"
                          title={`Remove ${titleLowercaseLabel}`}
                          onClick={handleDeleteItemFolio}
                          disabled={
                            folioSelected.length < 1 ||
                            !folioSelected?.[0]?.isAddedToGridAfterSpatial
                          }
                        />
                      </div>
                    )
                  }
                />
              </div>
            </div>
            {isShowDialog === EAssociationGridType.Title ? (
              <AddTitleLookupDialog
                onClose={() => setIsShowDialog(undefined)}
                handleAddTitle={(data: fnt_Title_LookupResult[]) => {
                  setIsShowDialog(undefined);
                  handleAddItemFolio(data);
                }}
              />
            ) : null}
          </>
        )}
        {!options?.isLLS && (
          <div className="cc-form-cols-1">
            <div className="cc-field cc-association-parcels">
              <label className="cc-label">Parcels</label>
              <CCGrid
                toolbar={
                  <div className="cc-grid-tools-bar">
                    <Button
                      iconClass="fas fa-plus"
                      type="button"
                      title="Add parcel"
                      onClick={() => {
                        setIsShowDialog(EAssociationGridType.Parcel);
                      }}
                      disabled={options?.isReadOnly}
                    />
                    <Button
                      type="button"
                      iconClass="fas fa-minus"
                      title="Remove parcel"
                      disabled={
                        options?.isReadOnly || parcelSelected?.length <= 0
                      }
                      onClick={handleDeleteItem}
                    />
                  </div>
                }
                selectableMode="single"
                data={parcels ?? []}
                primaryField={nameOfParcel("Parcel_Id")}
                columnFields={colAssociationParcel}
                selectedRows={parcelSelected ?? []}
                onDataRowChange={(dataRow: any, fieldChange: any) => {
                  handleDataChangeGrid(dataRow, fieldChange);
                }}
                editableMode="cell"
                onSelectionChange={(
                  dataItem: DTO_ModifyAssessment_Parcel[]
                ) => {
                  onChange(nameOf("_option.ParcelSelected"), {
                    value: dataItem ?? [],
                  });
                }}
                readOnly={options?.isReadOnly}
              />
              {isShowDialog === EAssociationGridType.Parcel ? (
                <AddParcelLookupDialog
                  onClose={() => setIsShowDialog(undefined)}
                  handleAddParcel={(data: DTO_Parcel[]) => {
                    handleAddItemParcel(data);
                    setIsShowDialog(undefined);
                  }}
                  isLoadingFinish={loadingMode}
                />
              ) : null}
            </div>
          </div>
        )}
      </section>
    );
  }
);
