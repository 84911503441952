import { getRLPBoardLOV } from "@app/products/property/assessments/components/form-steps/new-assessment/api";
import { useNewAssessmentDialogStore } from "@app/products/property/assessments/components/form-steps/new-assessment/store";
import { checkNumberOnlyValidator } from "@app/products/property/assessments/components/form-steps/new-assessment/util";
import { ECustomColNameProperty } from "@app/products/property/config";
import { nameOfLov, WorkflowTypes } from "@app/products/property/model";
import {
  convertValueLOVToNumber,
  isFieldVisible,
} from "@app/products/property/util";
import { isSuccessResponse } from "@common/apis/util";
import {
  DATE_FORMAT,
  DECIMAL_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { DTO_LOV } from "@common/models/odataResponse";
import { Label } from "@common/stores/products/config";
import { getDropdownValue } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCNumericTextBox } from "@components/cc-numeric-text-box/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import Loading from "@components/loading/Loading";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import { Field, FieldArray } from "@progress/kendo-react-form";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";

export const AssessmentDetailsFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};

const requiredAndNumberOnlyValidator = (value: any) => {
  return requiredValidator(value) || checkNumberOnlyValidator(value);
};

const FormStepElement = observer(
  ({
    nameOf,
    options = {
      isReadOnly: false,
      isActro: false,
      isLLS: false,
      isGeneral: false,
    },
    formRenderProps,
    isLoadingStep,
    setIsLoadingStep = () => {},
    loadFailedStep,
    setLoadFailedStep = () => {},
  }: IFormStepElement) => {
    const { valueGetter, onChange } = formRenderProps;
    const getFieldValue = (name: string) => valueGetter(nameOf(name));
    const { createAssessmentLOVs } = useNewAssessmentDialogStore();

    const assessmentStatusLOV = convertValueLOVToNumber<DTO_LOV>(
      createAssessmentLOVs?.Assessment_Status ?? [],
      "Code"
    );

    const assessmentProposedStatusLOV = convertValueLOVToNumber<DTO_LOV>(
      createAssessmentLOVs?.Assessment_ProposedStatus ?? [],
      "Code"
    );

    const assessmentTypeLOV = convertValueLOVToNumber<DTO_LOV>(
      createAssessmentLOVs?.Assessment_Type ?? [],
      "Code"
    );
    const assessmentSuburbLOV = convertValueLOVToNumber<DTO_LOV>(
      createAssessmentLOVs?.LocalityName_Id ?? [],
      "Code"
    );
    const assessmentGroupLOV = convertValueLOVToNumber<DTO_LOV>(
      createAssessmentLOVs?.Assessment_Group ?? [],
      "Code"
    );

    const assessmentLgaLOV = convertValueLOVToNumber<DTO_LOV>(
      createAssessmentLOVs?.Assessment_LGA ?? [],
      "Code"
    );

    const assessmentPlanTypeLOV = convertValueLOVToNumber<DTO_LOV>(
      createAssessmentLOVs?.Instalment_Plan_Type ?? [],
      "Code"
    );

    const assessmentFSLLandStatusLOV = convertValueLOVToNumber<DTO_LOV>(
      createAssessmentLOVs?.FSLLandStatus ?? [],
      "Code"
    );

    const assessmentGroupId = getFieldValue("Sector");

    // State
    const [rlpBoard, setRlpBoard] = useState<DTO_LOV[]>();

    //Get labels
    const [
      provisionalHoldingNumberLabel,
      valuationNumberLabel,
      sectorLabel,
      categoryLabel,
    ] = Label.CommunityProperty.getLabel([
      ECustomColNameProperty.Provisional_Holding_Number,
      ECustomColNameProperty.Valuation_Number,
      ECustomColNameProperty.Sector,
      ECustomColNameProperty.Category,
    ]);

    const getRLPBoard = async () => {
      if (isNil(assessmentGroupId)) {
        setRlpBoard([]);
      } else {
        setIsLoadingStep(true);
        const response = await getRLPBoardLOV(
          +assessmentGroupId,
          WorkflowTypes.Create_Assessment
        );
        if (isSuccessResponse(response) && response?.data) {
          const assessmentRLP =
            convertValueLOVToNumber<DTO_LOV>(
              response.data?.dtoCreate_Assessment_LOVs
                ?.Differential_Rate_Classification ?? [],
              "Code"
            ) ?? [];
          setRlpBoard(assessmentRLP);
        } else {
          setLoadFailedStep({
            onReload: () => {
              getRLPBoardLOV(
                +assessmentGroupId,
                WorkflowTypes.Create_Assessment
              );
            },
            responseError: {
              status: response.status,
              error: response.error ?? "Load failed",
            },
          });
        }
        setIsLoadingStep(false);
      }
    };

    useEffect(() => {
      getRLPBoard();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assessmentGroupId]);

    if (isLoadingStep) {
      return <Loading isLoading={isLoadingStep} />;
    }

    if (loadFailedStep) {
      return (
        <CCLoadFailed
          onReload={loadFailedStep?.onReload}
          responseError={loadFailedStep?.responseError}
        />
      );
    }

    return (
      <section className="cc-field-group">
        {!options?.isActro && (
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <CCLabel title={provisionalHoldingNumberLabel} />
              <Field
                name={nameOf("AssessmentNumber")}
                placeholder={provisionalHoldingNumberLabel}
                component={CCNumericTextBox}
                format={NUMBER_FORMAT.NUMBER2}
                min={0}
                readOnly={options?.isReadOnly || options?.isLLS}
              />
            </div>
          </div>
        )}
        <>
          <div className="cc-field-group">
            {!options?.isLLS && <label className="cc-label">Reference</label>}
            <div className="cc-custom-sub-panel-bar cc-form-cols-1">
              <div className="cc-form-cols-3">
                {options?.isActro && (
                  <>
                    <div className="cc-field">
                      <label className="cc-label">
                        Suburb
                        <CCTooltip type="validator" position="right" />
                      </label>
                      <Field
                        name={nameOf("Suburb")}
                        component={CCSearchComboBox}
                        data={assessmentSuburbLOV ?? []}
                        textField={nameOfLov("Name")}
                        dataItemKey={nameOfLov("Code")}
                        disabled={options?.isReadOnly}
                        validator={
                          !options?.isReadOnly ? requiredValidator : undefined
                        }
                        value={getDropdownValue(
                          +valueGetter(nameOf("Suburb")),
                          assessmentSuburbLOV,
                          "Code"
                        )}
                        onChange={(event: ComboBoxChangeEvent) => {
                          onChange(nameOf("Suburb"), {
                            value: event.target.value?.Code ?? null,
                          });
                        }}
                      />
                    </div>
                    <div className="cc-field">
                      <label className="cc-label">
                        Section
                        <CCTooltip type="validator" position="right" />
                      </label>
                      <Field
                        name={nameOf("Section")}
                        placeholder={"Section"}
                        component={CCInput}
                        maxLength={3}
                        validator={
                          !options?.isReadOnly
                            ? requiredAndNumberOnlyValidator
                            : undefined
                        }
                        readOnly={options?.isReadOnly}
                      />
                    </div>
                    <div className="cc-field">
                      <label className="cc-label">
                        Block
                        <CCTooltip type="validator" position="right" />
                      </label>
                      <Field
                        name={nameOf("Block")}
                        placeholder={"Block"}
                        component={CCInput}
                        maxLength={4}
                        validator={
                          !options?.isReadOnly
                            ? requiredAndNumberOnlyValidator
                            : undefined
                        }
                        readOnly={options?.isReadOnly}
                      />
                    </div>
                    <div className="cc-field">
                      <label className="cc-label">Unit</label>
                      <Field
                        name={nameOf("Unit")}
                        placeholder={"Unit"}
                        component={CCInput}
                        maxLength={3}
                        validator={
                          !options?.isReadOnly
                            ? checkNumberOnlyValidator
                            : undefined
                        }
                        readOnly={options?.isReadOnly}
                      />
                    </div>
                    {getFieldValue("Unit") ? (
                      <div className="cc-field">
                        <label className="cc-label">Entitlement</label>
                        <Field
                          name={nameOf("UnitEntitlement")}
                          placeholder={"Entitlement"}
                          component={CCInput}
                          maxLength={3}
                          validator={
                            !options?.isReadOnly
                              ? checkNumberOnlyValidator
                              : undefined
                          }
                          readOnly={options?.isReadOnly}
                        />
                      </div>
                    ) : null}
                  </>
                )}
                {!options?.isLLS && (
                  <div className="cc-field">
                    <label className="cc-label">Is common property</label>
                    <Field
                      name={nameOf("IsCommonProperty")}
                      checked={getFieldValue("IsCommonProperty")}
                      component={CCSwitch}
                      disabled={options?.isReadOnly}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          {options?.isActro && getFieldValue("Unit") ? (
            <div className="cc-field-group">
              <label className="cc-label">Plan registration</label>
              <div className="cc-custom-sub-panel-bar cc-form-cols-1">
                <div className="cc-form-cols-3">
                  <div className="cc-field">
                    <label className="cc-label">Number</label>
                    <Field
                      name={nameOf("PlanRegistrationNumber")}
                      placeholder={"Number"}
                      component={CCInput}
                      validator={
                        !options?.isReadOnly
                          ? checkNumberOnlyValidator
                          : undefined
                      }
                      readOnly={options?.isReadOnly}
                    />
                  </div>
                  <div className="cc-field">
                    <label className="cc-label">Date</label>
                    <Field
                      name={nameOf("PlanRegistrationDate")}
                      component={CCDatePicker}
                      format={DATE_FORMAT.DATE_CONTROL}
                      disabled={options?.isReadOnly}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </>
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <CCLabel title="Status" isMandatory />
            <Field
              name={nameOf("Status")}
              component={CCSearchComboBox}
              disabled
              data={assessmentStatusLOV ?? []}
              textField={nameOfLov("Name")}
              dataItemKey={nameOfLov("Code")}
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Proposed status" isMandatory />
            <Field
              name={nameOf("ProposedStatus")}
              component={CCSearchComboBox}
              disabled={options?.isReadOnly}
              data={assessmentProposedStatusLOV ?? []}
              textField={nameOfLov("Name")}
              dataItemKey={nameOfLov("Code")}
              validator={!options?.isReadOnly ? requiredValidator : undefined}
              isUseDefaultOnchange
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">
              Type
              <CCTooltip type="validator" position="right" />
            </label>
            <Field
              name={nameOf("Type")}
              component={CCSearchComboBox}
              disabled={options?.isReadOnly}
              data={assessmentTypeLOV ?? []}
              textField={nameOfLov("Name")}
              dataItemKey={nameOfLov("Code")}
              validator={!options?.isReadOnly ? requiredValidator : undefined}
              onChange={(event: ComboBoxChangeEvent) => {
                onChange(nameOf("Type"), {
                  value: event.value?.Code,
                });
                onChange(nameOf("Type_Name"), {
                  value: event.value?.Name,
                });
              }}
            />
          </div>
          {!options?.isLLS && (
            <div className="cc-field">
              <CCLabel title={valuationNumberLabel} />
              <Field
                name={nameOf("ValuationNumber")}
                placeholder={valuationNumberLabel}
                component={CCNumericTextBox}
                format={NUMBER_FORMAT.NUMBER2}
                disabled={options?.isReadOnly}
              />
            </div>
          )}
          <div className="cc-field">
            <CCLabel title={sectorLabel} />
            <Field
              name={nameOf("Sector")}
              component={CCSearchComboBox}
              disabled={options?.isReadOnly}
              data={assessmentGroupLOV ?? []}
              textField={nameOfLov("Name")}
              dataItemKey={nameOfLov("Code")}
              onChange={(event: ComboBoxChangeEvent) => {
                onChange(nameOf("Sector"), {
                  value: event.value?.Code,
                });
                onChange(nameOf("Region_Name"), {
                  value: event.value?.Name,
                });
                onChange(nameOf("Differential_Rate_Classification_Id"), {
                  value: "",
                });
                onChange(nameOf("DifferentialCategoryName"), {
                  value: "",
                });
              }}
            />
          </div>
          {isFieldVisible(getFieldValue("RLPBoardVisibility")) ||
          options?.isLLS ? (
            <div className="cc-field">
              <CCLabel title={categoryLabel} />
              <Field
                name={nameOf("Differential_Rate_Classification_Id")}
                component={CCSearchComboBox}
                disabled={options?.isReadOnly}
                data={rlpBoard ?? []}
                textField={nameOfLov("Name")}
                dataItemKey={nameOfLov("Code")}
                onChange={(event: ComboBoxChangeEvent) => {
                  onChange(nameOf("Differential_Rate_Classification_Id"), {
                    value: event.value?.Code,
                  });
                  onChange(nameOf("DifferentialCategoryName"), {
                    value: event.value?.Name,
                  });
                }}
              />
            </div>
          ) : null}
          {options?.isLLS && (
            <div className="cc-field">
              <CCLabel title="LGA" />
              <Field
                name={nameOf("LGA_Id")}
                component={CCSearchComboBox}
                disabled={options?.isReadOnly}
                data={assessmentLgaLOV ?? []}
                textField={nameOfLov("Name")}
                dataItemKey={nameOfLov("Code")}
                isUseDefaultOnchange
              />
            </div>
          )}
          <div className="cc-field">
            <CCLabel title="Primary land use" />
            <Field
              name={nameOf("PrimaryLandUseId")}
              component={CCSearchComboBox}
              disabled={options?.isReadOnly}
              data={createAssessmentLOVs?.Assessment_Primary_Land_Use ?? []}
              textField={nameOfLov("Name")}
              dataItemKey={nameOfLov("Code")}
              value={getDropdownValue(
                "" + valueGetter(nameOf("PrimaryLandUseId")),
                createAssessmentLOVs?.Assessment_Primary_Land_Use ?? [],
                nameOfLov("Code")
              )}
              onChange={(event: ComboBoxChangeEvent) => {
                onChange(nameOf("PrimaryLandUseId"), {
                  value: event.value?.Code,
                });
                onChange(nameOf("PrimaryLandUse"), {
                  value: event.value?.Name,
                });
              }}
            />
          </div>
          {options?.isLLS || options?.isGeneral ? (
            <div className="cc-field">
              <CCLabel title="Secondary land use" />
              <Field
                name={nameOf("SecondaryLandUseId")}
                component={CCSearchComboBox}
                disabled={options?.isReadOnly}
                data={createAssessmentLOVs?.Assessment_Secondary_Land_Use ?? []}
                textField={nameOfLov("Name")}
                dataItemKey={nameOfLov("Code")}
                value={getDropdownValue(
                  "" + valueGetter(nameOf("SecondaryLandUseId")),
                  createAssessmentLOVs?.Assessment_Secondary_Land_Use ?? [],
                  nameOfLov("Code")
                )}
                onChange={(event: ComboBoxChangeEvent) => {
                  onChange(nameOf("SecondaryLandUseId"), {
                    value: event.value?.Code,
                  });
                  onChange(nameOf("SecondaryLandUse"), {
                    value: event.value?.Name,
                  });
                }}
              />
            </div>
          ) : null}
          {options?.isLLS && (
            <>
              <div className="cc-form-cols-2">
                <div className="cc-field">
                  <CCLabel title="Actual land area" />
                  <Field
                    name={nameOf("ActualLandArea")}
                    placeholder={"Actual area"}
                    component={CCNumericTextBox}
                    readOnly={options?.isReadOnly}
                    format={DECIMAL_FORMAT.DECIMAL_2_DIGIT}
                  />
                </div>
                <div className="cc-field">
                  <CCLabel title="Unit" />
                  <Field
                    name={nameOf("ActualLandAreaUnitCode")}
                    disabled={options?.isReadOnly}
                    component={CCSearchComboBox}
                    data={createAssessmentLOVs?.LandArea_Unit ?? []}
                    textField={nameOfLov("Name")}
                    dataItemKey={nameOfLov("Code")}
                    isUseDefaultOnchange
                  />
                </div>
              </div>
              <div className="cc-field">
                <label className="cc-label">Spatial land area m2</label>
                <Field
                  name={nameOf("SpatialLandArea")}
                  placeholder={"Spatial land area m2"}
                  component={CCNumericTextBox}
                  readOnly={options?.isReadOnly}
                  format={DECIMAL_FORMAT.DECIMAL_2_DIGIT}
                />
              </div>
            </>
          )}
          {options?.isActro && (
            <>
              <div className="cc-field">
                <label className="cc-label">Folio</label>
                <Field
                  name={nameOf("Folio")}
                  placeholder={"Folio"}
                  component={CCInput}
                  readOnly={options?.isReadOnly}
                  maxLength={20}
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">
                  Lease commencement date
                  <CCTooltip type="validator" position="right" />
                </label>
                <Field
                  name={nameOf("CreationDate")}
                  component={CCDatePicker}
                  disabled={options?.isReadOnly}
                  format={DATE_FORMAT.DATE_CONTROL}
                  validator={
                    !options?.isReadOnly ? requiredValidator : undefined
                  }
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">Volume</label>
                <Field
                  name={nameOf("Volume")}
                  placeholder={"Volume"}
                  component={CCInput}
                  readOnly={options?.isReadOnly}
                  maxLength={20}
                />
              </div>
            </>
          )}
          {options?.isGeneral && (
            <>
              <div className="cc-field">
                <label className="cc-label">Rateable land area m2</label>
                <Field
                  name={nameOf("RateableLandArea")}
                  placeholder={"Rateable land area m2"}
                  component={CCInput}
                  readOnly={options?.isReadOnly}
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">
                  Calculated from title land area m2
                </label>
                <Field
                  name={nameOf("CalculatedLandArea")}
                  placeholder={"Calculated from title land area m2"}
                  component={CCInput}
                  readOnly={options?.isReadOnly}
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">Instalment plan type</label>
                <Field
                  name={nameOf("InstalmentPlanTypeId")}
                  component={CCSearchComboBox}
                  disabled={options?.isReadOnly}
                  data={assessmentPlanTypeLOV ?? []}
                  textField={nameOfLov("Name")}
                  dataItemKey={nameOfLov("Code")}
                  isUseDefaultOnchange
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">Single farm enterprise flag</label>
                <Field
                  name={nameOf("SingleFarmEnterpriseFlag")}
                  checked={getFieldValue("SingleFarmEnterpriseFlag")}
                  component={CCSwitch}
                  disabled={options?.isReadOnly}
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">Commonwealth or crown land</label>
                <Field
                  name={nameOf("FSL_Land_Status")}
                  component={CCSearchComboBox}
                  data={assessmentFSLLandStatusLOV ?? []}
                  textField={nameOfLov("Name")}
                  dataItemKey={nameOfLov("Code")}
                  isUseDefaultOnchange
                  disabled={options?.isReadOnly}
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">Improvements</label>
                <Field
                  name={nameOf("Improvements")}
                  placeholder={"Improvements"}
                  component={CCInput}
                  readOnly={options?.isReadOnly}
                />
              </div>
            </>
          )}
        </div>
        <br />
      </section>
    );
  }
);
